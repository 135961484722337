import { doc, onSnapshot } from 'firebase/firestore';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useFirestore } from 'reactfire';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selfGlooUserImplAtom } from 'renderer/atoms/glooUser';
import { trpc } from 'renderer/common/client/trpc';
import LogCreator from 'renderer/common/LogCreator';
import { GlooUser } from 'renderer/models/Api';
import { useAuthHooks } from '../../../hooks/useAuthHooks';
import { MachineStateProvider } from './MachineStateProvider';

const logger = LogCreator();

const PageReloader: React.FC = () => {
  useEffect(() => {
    const tm = setTimeout(() => window.location.reload(), 10_000);
    return () => clearTimeout(tm);
  });
  return (
    <div className="flex items-center justify-center w-full h-full">
      <ClipLoader speedMultiplier={0.7} color="grey" />
    </div>
  );
};

export const GlooUserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [glooUser, setUserAtom] = useRecoilState(selfGlooUserImplAtom);

  const glooUserQuery = trpc.useQuery(['users.query'], {
    onSuccess: (data) => {
      setUserAtom(data);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  if (glooUserQuery.isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <ClipLoader color="grey" />
      </div>
    );
  }

  if (glooUserQuery.error || !glooUser) {
    logger.info(
      'Gloo user query errored out or userId is missing, reloading',
      glooUserQuery.error?.message
    );
    return <PageReloader />;
  }

  return <GlooUserMonitor userId={glooUser.userId}>{children}</GlooUserMonitor>;
};

const GlooUserMonitor: React.FC<{ userId: string } & PropsWithChildren> = ({
  userId,
  children,
}) => {
  const firestore = useFirestore();
  const setUserAtom = useSetRecoilState(selfGlooUserImplAtom);
  const { signout } = useAuthHooks();

  // Add a delayed sign out.
  const [forceSignOut, setForceSignOut] = useState(false);
  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    if (signingOut) {
      signout().catch((err) => logger.info('Error', err));
    }
  }, [signingOut, signout]);

  useEffect(() => {
    if (forceSignOut) {
      logger.info('Forcing sign out of user');
      const tm = setTimeout(() => setSigningOut(true), 10_000);
      return () => clearTimeout(tm);
    }
    return undefined;
  }, [forceSignOut]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, 'users-v2', userId),
      (res) => {
        // we always get an initial state upon connecting
        if (res.exists()) {
          const user = res.data() as GlooUser;
          console.log('USER LOGGER: USER IS', user);
          setUserAtom(user);
          setForceSignOut(false);
        } else {
          console.log('USER LOGGER: SIGNED OUT');
          setForceSignOut(true);
        }
      },
      (error: any) => {
        logger.info('Error subscribing', error);
      }
    );

    return unsubscribe;
  }, [setUserAtom, userId, firestore]);

  return <>{children}</>;
};
