import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';

const logger = LogCreator(LoggerNames.SCREENSHARE);

export const LocalCameraMediaPlayer: React.FC<{
  videoTrack: ILocalVideoTrack;
  audioTrack?: ILocalAudioTrack;
  isBubbleExpanded: boolean;
}> = ({ videoTrack, isBubbleExpanded }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;
    container.current.replaceChildren('');

    if (!videoTrack.isPlaying) {
      videoTrack.play(container.current);
    } else {
      logger.info('Video already playing?');
    }

    return () => {
      if (videoTrack.isPlaying) {
        logger.info('playing track: end');
        videoTrack?.stop();
      }
    };
  }, [container, videoTrack]);

  return (
    <div
      className={clsx('absolute flex w-full h-full overflow-hidden', [
        isBubbleExpanded ? 'p-2' : 'p-0',
      ])}
    >
      <div
        className={clsx('flex w-full h-full overflow-hidden', [
          isBubbleExpanded ? 'rounded-lg' : 'rounded-full',
        ])}
      >
        <div ref={container} className={clsx('w-full h-full')} />
      </div>
    </div>
  );
};

// TODO: delete, or use for video in the future.
export const RemoteCameraMediaPlayer = ({
  videoTrack,
  isEvent,
  isBubbleExpanded,
}: {
  videoTrack: IRemoteVideoTrack;
  // eslint-disable-next-line react/no-unused-prop-types
  audioTrack?: IRemoteAudioTrack;
  isEvent: boolean;
  isBubbleExpanded: boolean;
}) => {
  logger.info('RemoteCameraMediaPlayer render');
  const [frameReady, setFrameReady] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;
    const setFrameOn = () => {
      logger.info('RemoteCameraMediaPlayer FRAME READY');
      setFrameReady(true);
    };
    // Register video hooks.
    videoTrack.on('first-frame-decoded', setFrameOn);
    return () => videoTrack.off('first-frame-decoded', setFrameOn);
  }, [container, videoTrack]);

  useEffect(() => {
    if (!container.current) return;
    container.current.replaceChildren('');

    if (!videoTrack.isPlaying) {
      // This is a hack for typescript because agora doesn't correctly support 'scale-down' in their api.
      logger.info('RemoteCameraMediaPlayer Playing track', { videoTrack });
      videoTrack.play(container.current, { fit: 'cover' });
    } else {
      logger.info('Video already playing?');
    }

    return () => {
      if (videoTrack.isPlaying) {
        logger.info('RemoteCameraMediaPlayer cleaning up track');
        videoTrack?.stop();
        setFrameReady(false);
      }
    };
  }, [container, videoTrack]);

  return (
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1282686
    // border radius causes flickering
    <div
      className={clsx('relative w-full h-full', [isBubbleExpanded && 'p-2'])}
    >
      <div className={clsx('relative flex w-full h-full overflow-clip')}>
        <div
          ref={container}
          className={clsx('relative flex object-cover w-full h-full')}
        />
        <div
          className={clsx(
            'absolute w-full h-full bg-transparent outline outline-[120px] ',
            [
              isEvent ? 'outline-yellow-lightest-1' : 'outline-blue-lightest-2',
              isBubbleExpanded ? 'rounded-lg' : 'rounded-full',
            ]
          )}
        />
      </div>
    </div>
  );
};
