import { NotificationPanelState, NudgeState } from 'api/models';
import { useEffect } from 'react';
import {
  GetRecoilValue,
  selector,
  selectorFamily,
  useRecoilValue,
} from 'recoil';
import { selfGlooUserAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import { nudgesAtom } from 'renderer/atoms/nudge';
import {
  isBroadcastingAtom,
  isUserOnlineAtom,
  onlineCountRoomAtom,
  roomAtom,
  roomEventAtom,
  roomListAtom,
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import {
  FirebaseSyncAvailability,
  selectedTeamAvailableAtom,
  teamAtom,
  teamAvailableAtom,
} from 'renderer/atoms/team';
import {
  nextEventInstanceAtom,
  roomGroupsAtom,
} from 'renderer/atoms/userUtils';
import {
  agoraSubscribedMicTrackAtom,
  isStreamActiveFirebase,
  StreamType,
} from 'renderer/call/components/atoms/CallStateAtoms';
import { videoCamActiveRoomIdAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import { micActiveRoomIdAtom } from 'renderer/call/components/MicrophoneProvider';
import { selfScreenshareActiveRoomIdAtom } from 'renderer/call/components/ScreenShareProvider';
import { isWindowFocusedAtom } from 'renderer/common/hooks/useUserInputActiveMonitor';
import { logger } from 'renderer/common/LogCreator';
import { availableStatusAtom } from 'renderer/connection/state';
import { userSpeakingAtom } from 'renderer/connection/voiceCallState';

import { RoomKey } from 'renderer/models/Keys';
import {
  lastLeftConversationTimeAtom,
  leaveConvoTimeAtom,
} from './useConversationExpirationMonitor';

const conversationEventMetadata = (
  roomId: string,
  convoId: string,
  get: GetRecoilValue
) => {
  const eventKey = { roomId, eventId: convoId };
  const event = get(roomEventAtom(eventKey));
  const nextInstance = event ? get(nextEventInstanceAtom(eventKey)) : undefined;

  return event && nextInstance?.instance
    ? {
        eventId: event.eventId,
        title: event.name ?? 'Unnamed event',
        instance: nextInstance.instance,
        minsUntil: nextInstance.timeUntil,
      }
    : undefined;
};

const usersInConvo = (roomId: string, users: string[], get: GetRecoilValue) => {
  const selfUserId = get(selfUserIdAtom);
  return users
    .filter((userId) => {
      // megaphone users are put in a separate list
      return (
        userId !== selfUserId &&
        get(isUserOnlineAtom({ userId, roomId })) &&
        !get(isBroadcastingAtom({ userId, roomId }))
      );
    })
    .map((userId) => {
      const teamUser = get(roomUserAtom({ roomId, userId }));
      const isMicActive = get(
        isStreamActiveFirebase({
          roomId,
          userId,
          stream: StreamType.MIC,
        })
      );
      // TODO: we may want to pipe this down to the notification to show user is trying to talk but is
      // connecting.
      const micConnected = !!get(
        agoraSubscribedMicTrackAtom({ roomId, userId })
      );
      const isSpeaking = get(userSpeakingAtom({ roomId, userId }));
      return {
        photoUrl: teamUser.profile.photoUrl,
        name: teamUser.profile.displayName,
        devices: teamUser.convo.devices,
        status: {
          online: teamUser.online,
          busy: teamUser.busy,
        },
        isSpeaking,
        userId,
      };
    });
};

const roomMetaAtom = selectorFamily<
  NotificationPanelState['room'][0]['meta'],
  RoomKey
>({
  key: 'roomMetaAtom',
  get:
    (key) =>
    ({ get }) => {
      const { roomId, teamId, name } = get(roomAtom(key.roomId));
      const { name: teamName } = get(teamAtom(teamId));
      return { roomId, teamId, name, teamName };
    },
});

const activeConversationAtom = selectorFamily<
  NotificationPanelState['room'][0]['activeConvo'],
  RoomKey
>({
  key: 'activeConversationAtom',
  get:
    (key) =>
    ({ get }) => {
      const { SELF_USER_CONVO_IDX, CONVOS } = get(roomGroupsAtom(key));
      if (SELF_USER_CONVO_IDX === -1) return undefined;
      const convo = CONVOS[SELF_USER_CONVO_IDX];

      const ambientlyListeningUsers =
        get(onlineCountRoomAtom(key)) - convo.userIds.length;

      return {
        convoId: convo.convoId,
        lastLeftTime: get(
          lastLeftConversationTimeAtom({ convoId: convo.convoId })
        ),
        activeUsers: usersInConvo(key.roomId, convo.userIds, get),
        event: conversationEventMetadata(key.roomId, convo.convoId, get),
        leavingConvoTime: get(leaveConvoTimeAtom(key)),
        ambientUsers: ambientlyListeningUsers,
      };
    },
});

const ambientEventsAtom = selectorFamily<
  NotificationPanelState['room'][0]['ambientConvos'],
  RoomKey
>({
  key: 'ambientEventsAtom',
  get:
    (key) =>
    ({ get }) => {
      const { SELF_USER_CONVO_IDX, CONVOS } = get(roomGroupsAtom(key));
      return CONVOS.filter(
        (val, idx) => idx !== SELF_USER_CONVO_IDX && val.type === 'EVENT'
      ).map((convo) => {
        return {
          convoId: convo.convoId,
          lastLeftTime: get(
            lastLeftConversationTimeAtom({ convoId: convo.convoId })
          ),
          activeUsers: usersInConvo(key.roomId, convo.userIds, get),
          event: conversationEventMetadata(key.roomId, convo.convoId, get),
        };
      });
    },
});

const currentUserConversationStateAtom = selectorFamily<
  NotificationPanelState['room'][0],
  RoomKey
>({
  key: 'currentUserConversationState',
  get:
    (key) =>
    ({ get }) => {
      const meta = get(roomMetaAtom(key));
      const activeConvo = get(activeConversationAtom(key));
      const ambientConvos = get(ambientEventsAtom(key));
      const incomingNudges = get(incomingNudgeStateAtom(key));

      return {
        meta,
        activeConvo,
        ambientConvos,
        incomingNudges,
      };
    },
});

const incomingNudgeStateAtom = selectorFamily<NudgeState[], RoomKey>({
  key: 'incomingNudgeState',
  get:
    (key) =>
    ({ get }) => {
      // const selfUserId = get(selfUserIdAtom);
      // const selfRoomUser = get(
      //   roomUserAtom({ roomId: key.roomId, userId: selfUserId })
      // );
      const { incomingPings } = get(nudgesAtom);
      const nudges: NudgeState[] = [];
      incomingPings
        .filter(({ roomId }) => roomId === key.roomId)
        .forEach((p) => {
          try {
            const roomUser = get(
              roomUserAtom({ roomId: p.roomId, userId: p.senderUserId })
            );
            nudges.push({
              sender: {
                status: {
                  busy: roomUser.busy,
                  online: roomUser.online,
                },
                userId: p.senderUserId,
                name: roomUser.profile.displayName,
                photoUrl: roomUser.profile.photoUrl,
              },
              sentTime: p.timestamp,
              snoozed: false,
            });
          } catch (err) {
            console.log('Err', err);
          }
        });
      return nudges;
      // return [
      //   {
      //     sender: {
      //       status: {
      //         busy: selfRoomUser.busy,
      //         online: selfRoomUser.online,
      //       },
      //       userId: selfUserId,
      //       name: selfRoomUser.profile.displayName,
      //       photoUrl: selfRoomUser.profile.photoUrl,
      //     },
      //     sentTime: Date.now(),
      //     snoozed: false,
      //   },
      //   {
      //     sender: {
      //       status: {
      //         busy: selfRoomUser.busy,
      //         online: selfRoomUser.online,
      //       },
      //       userId: selfUserId,
      //       name: selfRoomUser.profile.displayName,
      //       photoUrl: selfRoomUser.profile.photoUrl,
      //     },
      //     sentTime: Date.now(),
      //     snoozed: false,
      //   },
      // ];
    },
});

const selfStateAtom = selector<NotificationPanelState['current']>({
  key: 'selfStateAtom',
  get: ({ get }) => {
    const windowActive = get(isWindowFocusedAtom);
    const selectedRoomId = get(selectedRoomKeyAtom);
    const selfUserId = get(selfUserIdAtom);
    const micActiveRoomId = get(micActiveRoomIdAtom);
    const glooUser = get(selfGlooUserAtom);
    let isMicActive = false;
    if (micActiveRoomId) {
      isMicActive = get(
        isStreamActiveFirebase({
          roomId: micActiveRoomId,
          userId: selfUserId,
          stream: StreamType.MIC,
        })
      );
    }
    const screenshareActiveRoomId = get(selfScreenshareActiveRoomIdAtom);
    const videoActiveRoomId = get(videoCamActiveRoomIdAtom);

    return {
      selectedRoomId,
      windowActive,
      online: glooUser.status.online,
      selfUser: {
        isSpeaking: false,
        userId: selfUserId,
        status: {
          // Fudge this in for now
          online: glooUser.status.online,
          busy:
            glooUser.status.details.idle !== undefined ||
            glooUser.status.details.inConvo,
        },
        devices: {
          mic: isMicActive,
          screen: !!screenshareActiveRoomId,
          camera: !!videoActiveRoomId,
        },
        activeButtonRoomIds: {
          mic: micActiveRoomId,
          screenshare: screenshareActiveRoomId,
          video: videoActiveRoomId,
        },
      },
    };
  },
});

export const notificationPanelStateAtom =
  selector<NotificationPanelState | null>({
    key: 'notificationPanelStateAtom',
    get: ({ get }) => {
      const roomList = get(roomListAtom);
      const roomConvos = roomList
        .filter((room) => {
          const teamId = get(roomTeamIdAtom({ roomId: room }));
          const isTeamAvailable =
            get(teamAvailableAtom({ teamId })) ===
            FirebaseSyncAvailability.AVAILABLE;
          if (!isTeamAvailable) {
            logger.warn('Team not available yet', { teamId });
          }
          return isTeamAvailable;
        })
        .map((room) => {
          return get(currentUserConversationStateAtom({ roomId: room }));
        });

      const current = get(selfStateAtom);
      return {
        current,
        room: roomConvos,
      };
    },
  });

export const useConversationStateUpdates = () => {
  const convoState = useRecoilValue(notificationPanelStateAtom);

  useEffect(() => {
    if (convoState) {
      window?.electron?.ipcRenderer.setNotificationPanelState(convoState);
    }
  }, [convoState]);
};
