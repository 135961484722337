import { useRecoilValue } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { CAMERA_STREAM_UID_PREFIX } from 'renderer/constants';
import { RoomKey } from 'renderer/models/Keys';
import {
  agoraRemoteUserListStreamAtom,
  RoomStreamKey,
  StreamType,
} from '../atoms/CallStateAtoms';
import { AgoraRemoteUserSubscriber } from './helpers/AgoraRemoteUserSubscriber';

const logger = LogCreator(LoggerNames.CALL);

const AgoraStreamSubscriber: React.FC<RoomStreamKey> = (props) => {
  const users = useRecoilValue(agoraRemoteUserListStreamAtom(props));
  const { roomId, stream } = props;

  const selfUser = useRecoilValue(selfGlooUserAtom);
  logger.info(`Room ${roomId}: AgoraStreamSubscriber: ${users} ${stream}`);
  if (!selfUser) {
    return null;
  }

  return (
    <>
      {users
        .filter((u) => {
          // skip the local user's camera track since it's published w/ a different client
          // and will show up as a remote user.
          // also the ID of this camera track "user" is prefixed with CAMERA_STREAM_UID_PREFIX
          if (stream === StreamType.VIDEO_CAM && u.includes(selfUser.userId)) {
            return false;
          }
          // see AgoraVideoPublisher
          if (
            stream === StreamType.SCREENSHARE &&
            u.includes(CAMERA_STREAM_UID_PREFIX)
          ) {
            return false;
          }
          return true;
        })
        .map((userId) => (
          <AgoraRemoteUserSubscriber
            key={userId.replace(CAMERA_STREAM_UID_PREFIX, '')}
            userId={userId}
            roomId={roomId}
            stream={stream}
          />
        ))}
    </>
  );
};

const AgoraSubscriber: React.FC<RoomKey> = ({ roomId }) => {
  return (
    <>
      <AgoraStreamSubscriber roomId={roomId} stream={StreamType.MIC} />
      <AgoraStreamSubscriber roomId={roomId} stream={StreamType.SCREENSHARE} />
      <AgoraStreamSubscriber roomId={roomId} stream={StreamType.VIDEO_CAM} />
    </>
  );
};

export default AgoraSubscriber;
