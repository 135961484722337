/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable promise/catch-or-return */
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { Spinner } from 'renderer/shared/Spinner';

import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { useAuthHooks } from 'renderer/hooks/useAuthHooks';
import { useRecoilValue } from 'recoil';
import { selfFirebaseUserImplAtom } from 'renderer/atoms/firebaseUser';
import { GlooUserProvider } from 'renderer/pages/dashboard/providers/GlooUserProvider';
import { ClipLoader } from 'react-spinners';

const logger = LogCreator(LoggerNames.AUTH);

// A Higher Order Component for requiring authentication
export function requireAuth<P>(Component: FunctionComponent<P>) {
  return (props: P) => {
    const { signout } = useAuthHooks(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { status, data: signinResult } = useSigninCheck();
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const fbUser = useRecoilValue(selfFirebaseUserImplAtom);

    useEffect(() => {
      if (shouldRedirect) {
        // signout can fail if user is already signed out.
        if (window.electron) {
          signout()
            .catch((err) => logger.error('Failed to redirect:', err))
            .finally(() => {
              navigate('/');
            });
        } else {
          // web has different route
          signout()
            .catch((err) =>
              logger.error('Failed to logout. May already be logged out', err)
            )
            .finally(() => {
              if (!localStorage.getItem('target-after-login')) {
                localStorage.setItem('target-after-login', location.pathname);
              }
              logger.info('redirecting to signup');
              window.location.replace('/auth/signup');
            });
        }
      }
    }, [shouldRedirect, navigate, signout]);

    logger.info({ status, shouldRedirect, signinResult });

    if (status !== 'loading' && !signinResult?.signedIn) {
      if (!shouldRedirect) {
        logger.info('Error, redirecting to login', location.pathname);
        setShouldRedirect(true);
      }
      return null;
    }

    if (status === 'loading' || fbUser == null) {
      // Loading spinner
      return <ClipLoader color="gray" />;
    }

    // Render component now that we have user
    // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
    return (
      <GlooUserProvider>
        <Component {...(props as any)} />
      </GlooUserProvider>
    );
  };
}
