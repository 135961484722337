import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { roomWhoNudgedMeAtom } from 'renderer/atoms/nudge';
import { availableUsersAtom } from 'renderer/atoms/userUtils';
import { RoomKey } from 'renderer/models/Keys';
import {
  ExcessUserCountCircle,
  GlooUserSidePanel,
} from 'renderer/shared/user/GlooUser';

const MAX_CIRCLES_SHOWN = 5;

export const UserListPreview: React.FC<RoomKey> = ({ roomId }) => {
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const availableUsers = useRecoilValue(availableUsersAtom({ roomId })).filter(
    (u) => u.userId !== selfUserId
  );
  const usersInConvo = useMemo(() => {
    return availableUsers.filter((u) => u.convo.engaged);
  }, [availableUsers]);

  const usersWhoNudgedMe = useRecoilValue(roomWhoNudgedMeAtom({ roomId }));

  const useAggregateRendering = availableUsers.length > MAX_CIRCLES_SHOWN;
  const excessUsers = availableUsers.length - (MAX_CIRCLES_SHOWN - 1);
  const renderedUsers = useAggregateRendering
    ? availableUsers.slice(0, MAX_CIRCLES_SHOWN - 1)
    : availableUsers;

  if (availableUsers.length === 0) return null;
  return (
    <div className="flex flex-row-reverse space-x-reverse transition-all duration-[50ms] ease-linear  bg-inherit pb-1">
      {useAggregateRendering && (
        <ExcessUserCountCircle iconSizePx={28} count={excessUsers} />
      )}
      {renderedUsers.map((u) => {
        return (
          <GlooUserSidePanel
            key={u.userId}
            roomId={roomId}
            userId={u.userId}
            iconSizePx={28}
          />
        );
      })}
    </div>
  );
};
