import { PageSelector } from 'renderer/atoms/pageselectors';
import { Room } from 'renderer/models/Api';
import { MdGroups, MdRoom, MdSettings } from 'react-icons/md';
import { GlooIcon } from 'renderer/shared/icons/GlooIcon';
import SlackIcon from 'renderer/shared/icons/SlackIcon';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { BsBell } from 'react-icons/bs';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export const usePageAttributes = (page: PageSelector, room: Room | null) => {
  switch (page) {
    case PageSelector.TEAM_ROOM: {
      return {
        title: room?.name ?? '',
        description: room?.description,
        // eslint-disable-next-line no-nested-ternary
        icon: room?.integrations?.slack ? (
          <SlackIcon className="w-3/4 h-3/4" />
        ) : room?.private ? (
          <LockRoundedIcon className="!w-[100%] !h-[100%]" />
        ) : (
          <GlooIcon className="w-full h-full" />
        ),
      };
    }
    case PageSelector.TEAM_SETTINGS:
      return {
        title: 'Settings',
        description: 'Some features here are admin only',
        icon: MdSettings({ className: 'w-full h-full' }),
      };
    case PageSelector.TEAM_ROOMS_OVERVIEW:
      return {
        title: 'Rooms',
        icon: MdRoom({ className: 'w-full h-full' }),
      };
    case PageSelector.TEAM_USER_OVERVIEW:
      return {
        title: 'Members',
        icon: MdGroups({ className: 'w-full h-full' }),
      };
    case PageSelector.NOTIFICATIONS_PAGE:
      return {
        title: 'Nudges',
        icon: <NotificationsNoneOutlinedIcon className="!w-8 h-8" />,
      };
    default:
      throw new Error('Unsupported page type');
  }
};
