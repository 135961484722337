import React from "react";
import {useLocation} from "react-router-dom";

interface PageNotFoundProps {
  page?: string;
}

const PageNotFoundView: React.FC = () => {
  const location = useLocation();
  const state = location.state as PageNotFoundProps | null;
  const page = state?.page || location.pathname;
  return (
    <>
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        The page <code>{page}</code> could not be found
      </div>
    </>
  );
};

export default PageNotFoundView;
