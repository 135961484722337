import React, { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import { useRecoilValue } from 'recoil';
import { selectedTeamKeyAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { OutlineButton } from '../Buttons/helpers/OutlineButton';
import { useModalErrors } from './atoms';

const EmailInviteLinkContent: React.FC = () => {
  const { setModalError } = useModalErrors();
  const [emails, setEmails] = useState<string[]>([]);
  const inviteByEmail = trpc.useMutation('teams.invite.email', {
    onError: (error, variables, context) => {
      setModalError(error.message);
    },
  });
  const teamId = useRecoilValue(selectedTeamKeyAtom);

  return (
    <>
      <div className="flex flex-col items-center text-black-primary">
        <p>
          <span className="text-base text-black-lightest">
            Invite via email
          </span>
        </p>
        <div className="flex px-2 pr-8 w-80">
          <ReactMultiEmail
            className="flex items-center"
            emails={emails}
            onChange={(_emails: string[]) => {
              setEmails(_emails);
            }}
            placeholder="Enter emails with a space"
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
        {emails.length > 0 && (
          <OutlineButton
            clickHandler={() => {
              inviteByEmail.mutate({
                teamId,
                invitees: emails,
              });
            }}
            enableChevron
            disabled={emails.length <= 0}
          >
            Invite {emails.length} new members
          </OutlineButton>
        )}
      </div>
    </>
  );
};

export const TeamInviteModal: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-black-lightest">
      <EmailInviteLinkContent />
    </div>
  );
};
