import { useState, useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { SyncLoader } from 'react-spinners';

export const ErrorFallbackWithReload: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const isDefaultError = error.name === 'ACCEPTABLE_ERROR';

  const [reload, setReload] = useState(false);
  const [showReload, setShowReload] = useState(!isDefaultError);

  useEffect(() => {
    if (reload) {
      const tm = setTimeout(resetErrorBoundary, 1_000);
      return () => clearTimeout(tm);
    }
  }, [reload, resetErrorBoundary]);

  useEffect(() => {
    if (isDefaultError) {
      const tm = setTimeout(() => setShowReload(true), 5_000);
      return () => clearTimeout(tm);
    }
  }, [isDefaultError]);

  if (!showReload) return null;

  return (
    <div className="flex items-center justify-center w-full h-full">
      {!reload && (
        <div className="flex flex-col gap-2">
          <div className="text-base">
            {isDefaultError ? 'Loading taking too long?' : 'Error loading...'}
          </div>
          <button
            type="button"
            onClick={() => setReload(true)}
            className="px-2 rounded-full bg-blue-primary text-white-1 hover:bg-blue-lighter"
          >
            Click to reload
          </button>
        </div>
      )}
      {reload && (
        <>
          <span>Reloading...</span>
          <SyncLoader />
        </>
      )}
    </div>
  );
};
