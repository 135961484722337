import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { localVideoCamTrackAtom } from '../atoms/videoCameraAtoms';
import { useVideoCamToggles } from './useVideoCamToggles';
import { VideoCameraMonitor } from './VideoCameraMonitor';
import { VideoCameraPublisher } from './VideoCameraPublisher';

export const VideoCameraProvider: React.FC = () => {
  const track = useRecoilValue(localVideoCamTrackAtom);
  const { turnOffTrack } = useVideoCamToggles();

  // Turn off the track when the copmonent dismounts.
  useEffect(() => {
    return () => {
      turnOffTrack();
    };
  }, []);

  return (
    <>
      {track && (
        <>
          <VideoCameraMonitor roomId={track.roomId} />
          <VideoCameraPublisher roomId={track.roomId} />
        </>
      )}
    </>
  );
};
