import { doc, onSnapshot } from 'firebase/firestore';
import { memo, useEffect } from 'react';
import { useFirestore } from 'reactfire';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  FirebaseStatus,
  firestoreConnectionStatusAtom,
} from 'renderer/atoms/connectionStatus';
import { useRoomAtomSetters } from 'renderer/atoms/room';
import { Room, Team } from 'renderer/models/Api';
import LogCreator from 'renderer/common/LogCreator';
import { RoomKey, TeamKey } from 'renderer/models/Keys';
import { teamAtom, useTeamAtomSetters } from 'renderer/atoms/team';

const logger = LogCreator();

export const FirestoreTeamUpdates: React.FC<TeamKey> = memo(({ teamId }) => {
  const firestore = useFirestore();
  const { addOrUpdateTeam, removeTeam } = useTeamAtomSetters();

  useEffect(() => {
    logger.info('subscribing to team updates for: ', JSON.stringify(teamId));
    const unsubscribe = onSnapshot(
      doc(firestore, 'teams-v2', teamId),
      (docRes) => {
        // we always get an initial state upon connecting
        const source = docRes.metadata.hasPendingWrites ? 'Local' : 'Server';
        logger.info(source, 'team data: ', docRes.data());
        if (docRes.data()) {
          const team = docRes.data() as Team;
          addOrUpdateTeam(team);
        } else {
          // Room might be deleted.
          removeTeam(teamId);
        }
      },
      (error) => {
        logger.info('Error subscribing', error);
      }
    );

    return () => {
      logger.info(`unsub from Team:${teamId} updates`);
      unsubscribe();
    };
  }, [addOrUpdateTeam, firestore, removeTeam, teamId]);

  return <></>;
});

export const FirestoreRoomUpdates: React.FC<RoomKey> = memo(({ roomId }) => {
  const firestore = useFirestore();

  const setFirestoreStatus = useSetRecoilState(
    firestoreConnectionStatusAtom(roomId)
  );
  const { addOrUpdateRoom, removeRoom } = useRoomAtomSetters();

  useEffect(() => {
    logger.info('subscribing to room updates for: ', JSON.stringify(roomId));
    const unsubscribe = onSnapshot(
      doc(firestore, 'rooms-v2', roomId),
      (docRes) => {
        // we always get an initial state upon connecting
        setFirestoreStatus(FirebaseStatus.CONNECTED);
        const source = docRes.metadata.hasPendingWrites ? 'Local' : 'Server';
        logger.info(source, 'room data: ', docRes.data());
        if (docRes.data()) {
          const room = docRes.data() as Room;
          addOrUpdateRoom(room);
        } else {
          // Room might be deleted.
          removeRoom(roomId);
        }
      },
      (error) => {
        setFirestoreStatus(FirebaseStatus.DISCONNECTED);
        logger.info('Error subscribing', error);
      }
    );

    return () => {
      logger.info(`unsub from RoomId:${roomId} updates`);
      setFirestoreStatus(FirebaseStatus.DISCONNECTED);
      unsubscribe();
    };
  }, [roomId, firestore, setFirestoreStatus, addOrUpdateRoom, removeRoom]);

  return <></>;
});
