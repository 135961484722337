import AgoraRTC, { ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';
import { useEffect } from 'react';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';
import {
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import {
  buttonActive,
  ButtonState,
  buttonStateAtom,
  ButtonType,
} from 'renderer/connection/buttons';

import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { trpcAtom } from 'renderer/common/components/TRPCReactQuery';
import {
  isUserInputActiveAtom,
  UserActivityState,
} from 'renderer/common/hooks/useUserInputActiveMonitor';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { usePrevious } from 'renderer/hooks/usePrevious';
import { RoomConvoKey, RoomKey } from 'renderer/models/Keys';
import {
  ModalTypes,
  screenShareModalRoomIdAtom,
  selectedModalAtom,
} from 'renderer/shared/Modals/atoms';

const logger = LogCreator(LoggerNames.SCREENSHARE);

export const localScreenshareTrackAtom = atom<{
  video: ILocalVideoTrack;
  audio?: ILocalAudioTrack;
  roomId: string;
  // Changing table closes screenshare.
  convoId: string;
} | null>({
  key: 'localScreenshareTrack',
  default: null,
  // So we can close the track.
  dangerouslyAllowMutability: true,
});

export const selfScreenshareActiveAtom = selectorFamily<
  boolean,
  { roomId: string }
>({
  key: 'screenshareActive',
  get:
    ({ roomId }) =>
    ({ get }) => {
      return get(localScreenshareTrackAtom)?.roomId === roomId;
    },
});

// returns roomId screenshare is active in
export const selfScreenshareActiveRoomIdAtom = selector<string | undefined>({
  key: 'screenshareActiveRoomId',
  get: ({ get }) => {
    return get(localScreenshareTrackAtom)?.roomId;
  },
});

export const getScreenShareTrackAtom = selector<{
  video: ILocalVideoTrack;
  audio?: ILocalAudioTrack;
  roomId: string;
}>({
  key: 'getScreenShareTrack',
  get: ({ get }) => {
    const track = get(localScreenshareTrackAtom);
    if (!track) throw new Error('No Track');
    return track;
  },
  dangerouslyAllowMutability: true,
});

export const useSceenshareToggles = () => {
  const toggleScreenshare = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({ roomId }: RoomKey) => {
        const selectScreenshareWindow = async () => {
          if (window.electron) {
            // proceed to customized screen selection
            set(screenShareModalRoomIdAtom, roomId);
            set(selectedModalAtom, ModalTypes.kScreenshareMenu);
          } else {
            // use the browser screen selection dialog
            const media = await navigator.mediaDevices.getDisplayMedia();
            logger.info('mediadevices', media);
            const videoTrack = media.getVideoTracks()[0];

            const trackToPublish = AgoraRTC.createCustomVideoTrack({
              mediaStreamTrack: videoTrack,
            });

            await turnOnTrack({ video: trackToPublish, roomId });
            set(selectedModalAtom, ModalTypes.kNone);
          }
        };

        const response = await snapshot.getPromise(localScreenshareTrackAtom);

        if (response) {
          // Turn of screensharing in any other room.
          await turnOffTrack();

          // Then if we aren't currently screensharing
          // in the current room, open screenshare panel.
          if (response.roomId !== roomId) {
            selectScreenshareWindow();
          }
        } else {
          selectScreenshareWindow();
        }
      }
  );

  const turnOffTrack = useRecoilCallback(
    ({ set, snapshot }) =>
      async (key?: RoomKey) => {
        const response = await snapshot.getPromise(localScreenshareTrackAtom);
        logger.debug('useSceenshareTrack: Turning off room', response);
        if (!response) return;
        const { video, audio, roomId } = response;
        if (key) {
          if (roomId !== key.roomId) return;
        }

        video.close();
        audio?.close();
        set(localScreenshareTrackAtom, null);
        set(
          buttonStateAtom({ button: ButtonType.SCREENSHARE }),
          ButtonState.OFF
        );
        const trpcClient = await snapshot.getPromise(trpcAtom);
        const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
        await trpcClient.mutation('users.status.device', {
          teamId,
          roomId,
          device: 'screen',
          mode: 'off',
        });
        window?.electron?.ipcRenderer.setDesktopSources('');
      }
  );

  const turnOnTrack = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({
        video,
        audio,
        roomId,
      }: {
        video: ILocalVideoTrack;
        audio?: ILocalAudioTrack;
      } & RoomKey) => {
        const localTrack = await snapshot.getPromise(localScreenshareTrackAtom);
        if (localTrack) throw new Error("Can't screenshare. Already sharing.");

        const { userId } = await snapshot.getPromise(selfGlooUserAtom);

        const { convo } = await snapshot.getPromise(
          roomUserAtom({ roomId, userId })
        );
        if (!convo.active) throw new Error('User must be on table');

        set(localScreenshareTrackAtom, {
          video,
          audio,
          roomId,
          convoId: convo.convoId,
        });
        set(
          buttonStateAtom({ button: ButtonType.SCREENSHARE }),
          ButtonState.ON
        );

        logger.debug('useSceenshareTrack: Turning on room', {
          video,
          audio,
          roomId,
          convoId: convo.convoId,
        });
        const trpcClient = await snapshot.getPromise(trpcAtom);
        const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
        await trpcClient.mutation('users.status.device', {
          teamId,
          roomId,
          device: 'screen',
          mode: 'on',
        });
      }
  );

  return { turnOffTrack, turnOnTrack, toggleScreenshare };
};

/** Responsible for turning off screenshare in the following scenarios:
 *   1. User goes offline
 *   2. User changes tables in the room they are screensharing
 *
 * Additionally monitors if we should or should not be publishing the microphone track.
 *   1. If at least 2 users (including yourself) are online at the table, publish the track.
 */
const MonitorScreenShareTable: React.FC<RoomKey> = ({ roomId }) => {
  const { turnOffTrack } = useSceenshareToggles();

  const user = useRecoilValue(selfGlooUserAtom);
  const roomUser = useRecoilValue(
    roomUserAtom({ roomId, userId: user.userId })
  );

  const pcActive = useRecoilValue(isUserInputActiveAtom);

  // If the user leaves a table, end the screen share.
  useEffect(() => {
    if (!roomUser.convo.active) turnOffTrack({ roomId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomUser.convo.active]);

  useEffect(() => {
    if (pcActive === UserActivityState.IDLE_LONG) turnOffTrack({ roomId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcActive]);

  return <></>;
};

export const ScreenShareProvider: React.FC = () => {
  const track = useRecoilValue(localScreenshareTrackAtom);
  const { turnOffTrack } = useSceenshareToggles();

  // Turn off the track when the copmonent dismounts.
  useEffect(() => {
    return () => {
      turnOffTrack();
    };
  }, []);

  return <>{track && <MonitorScreenShareTable roomId={track.roomId} />}</>;
};
