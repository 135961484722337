import clsx from 'clsx';
import { useState } from 'react';
import { BsFillCaretDownFill, BsFillCaretRightFill } from 'react-icons/bs';
import {
  MdAdd,
  MdBookmark,
  MdOutlineBookmarkAdd,
  MdSettings,
} from 'react-icons/md';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { PageSelector } from 'renderer/atoms/pageselectors';
import { roomListAtom } from 'renderer/atoms/room';
import {
  allowBookmarksAtom,
  teamAtom,
  teamBookmarkedAtom,
} from 'renderer/atoms/team';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { TeamKey } from 'renderer/models/Keys';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';
import { ToggleIconButton } from 'renderer/shared/Buttons/helpers/ToggleIconButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { Tooltip } from 'renderer/shared/Tooltip';
import { ActiveRoomPreview, TeamHomePreview } from './ActiveRoomPreview';

export const TeamSectionPreview: React.FC<TeamKey> = ({ teamId }) => {
  const { selectTeam } = usePageSelector();
  const useBookmarks = useRecoilValue(allowBookmarksAtom);
  const [bookmark, setBookmark] = useRecoilState(teamBookmarkedAtom(teamId));
  const [showRooms, setShowRooms] = useState(true); // TODO: persist in recoil
  const myRooms = useRecoilValue(roomListAtom);
  const { roomList, name, isPersonalTeam } = useRecoilValue(teamAtom(teamId));
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  const relevantRooms = roomList.filter((r) => myRooms.includes(r));

  // if (!relevantRooms.length && isPersonalTeam) return null;

  return (
    <div className="w-full group">
      {!isPersonalTeam && (
        <div className="flex items-center justify-between w-full">
          <span className="pl-3 pr-1 text-base font-semibold truncate text-blue-dark/80">
            {name}
          </span>
          <div className="flex w-8 text-gray-4">
            <Tooltip message="Team settings" placement="left">
              <IconButton
                className="hidden w-4 h-4 group-hover:block hover:text-black-primary"
                clickHandler={() =>
                  selectTeam({ teamId }, PageSelector.TEAM_SETTINGS)
                }
              >
                <MdSettings />
              </IconButton>
            </Tooltip>
            {useBookmarks && (
              <ToggleIconButton
                handleClick={() => setBookmark((prev) => !prev)}
                isActive={bookmark}
                active={{
                  children: <MdBookmark className="text-yellow-400" />,
                }}
                inactive={{
                  children: (
                    <MdOutlineBookmarkAdd className="hidden group-hover:block" />
                  ),
                }}
                className="w-4 h-4"
              />
            )}
          </div>
          {/* <button
          type="button"
          className="flex flex-row items-center text-base truncate lg:text-lg text-gray-3"
          onClick={() => setShow((prev) => !prev)}
        >
          {show ? (
            <ArrowDropDownRoundedIcon className="!w-8 !h-8" />
          ) : (
            <ArrowRightRoundedIcon className="!w-8 !h-8" />
          )}
          <span className="pr-2 font-semibold truncate text-blue-dark/90">
            {name}
          </span>
        </button> */}
        </div>
      )}

      <div className="flex flex-col pl-1">
        {!isPersonalTeam && (
          <>
            <TeamHomePreview
              teamId={teamId}
              page={PageSelector.TEAM_USER_OVERVIEW}
            />
            <div className="relative">
              <TeamHomePreview
                teamId={teamId}
                page={PageSelector.TEAM_ROOMS_OVERVIEW}
              >
                <ToggleIconButton
                  handleClick={() => setShowRooms((prev) => !prev)}
                  isActive={showRooms}
                  active={{
                    children: <BsFillCaretDownFill />,
                  }}
                  inactive={{ children: <BsFillCaretRightFill /> }}
                  className="flex items-center text-gray-4 hover:text-black-primary"
                />
              </TeamHomePreview>
              <div className="absolute inset-y-0 right-0 z-10 flex items-center h-full pr-3">
                <Tooltip message="Create room" placement="left">
                  <button
                    type="button"
                    className="hidden pr-1 group-hover:block text-gray-5 hover:text-black-primary"
                    onClick={() => {
                      selectTeam({ teamId }, PageSelector.TEAM_ROOMS_OVERVIEW);
                      // for now redirect to rooms page so they can browse too
                      setSelectedModal(ModalTypes.kCreateRoom);
                    }}
                  >
                    <MdAdd />
                  </button>
                </Tooltip>
              </div>
            </div>
          </>
        )}

        {showRooms && (
          <div
            className={clsx(
              'flex flex-col pr-2',
              isPersonalTeam ? 'pl-1' : 'pl-6'
            )}
          >
            {relevantRooms.map((p) => {
              return <ActiveRoomPreview teamId={teamId} roomId={p} key={p} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
