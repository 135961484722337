import {ButtonProps, SimpleButton} from "renderer/shared/SimpleButton";

export const OutlineButton : React.FC<ButtonProps> = ({children, clickHandler, disabled}) => {
  return (<SimpleButton
    className="p-2 px-3 duration-300 transform border-2 rounded-md hover:scale-105 border-blue-primary text-blue-primary"
    clickHandler={clickHandler}
    disabled={disabled}
  >
    {children}
  </SimpleButton>);
};

