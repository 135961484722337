import { Switch } from '@headlessui/react';
import { RecoilState, useRecoilState, useSetRecoilState } from 'recoil';

interface ToggleButtonProps {
  atom: RecoilState<boolean>;
  disabled?: boolean;
}
interface ToggleButtonModifierProps extends ToggleButtonProps {
  modifierTime: RecoilState<Date>;
}

export const ToggleButtonWithModifier = ({
  atom,
  disabled,
  modifierTime,
}: ToggleButtonModifierProps) => {
  const [enabled, setEnabled] = useRecoilState(atom);
  const setTime = useSetRecoilState(modifierTime);

  const toggle = (v: boolean) => {
    setEnabled(v);
    setTime(new Date());
  };

  return (
    <Switch
      checked={enabled}
      onChange={toggle}
      disabled={disabled}
      className={`${enabled ? 'bg-green-700' : 'bg-gray-800'}
          relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white-1 focus-visible:ring-opacity-75 disabled:bg-gray-400`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white-1 shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

export const ToggleButton = ({ atom, disabled }: ToggleButtonProps) => {
  const [enabled, setEnabled] = useRecoilState(atom);

  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      disabled={disabled}
      className={`${enabled ? 'bg-green-700' : 'bg-gray-800'}
          relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white-1 focus-visible:ring-opacity-75 disabled:bg-gray-400`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white-1 shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};
