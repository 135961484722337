import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import clsx from 'clsx';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';

export const ScreenshareStatusButton = ({
  screenshareButtonDisabled,
  isScreensharing,
  screenshareButtonHandler,
  isAtSelfUserTable,
  isCamActive,
}: {
  isCamActive: boolean;
  isScreensharing: boolean;
  screenshareButtonHandler?: () => void;
  screenshareButtonDisabled: boolean;
  isAtSelfUserTable: boolean;
}) => {
  return isScreensharing ? (
    <div
      className={clsx(
        'absolute flex -top-1 -right-[2px] rounded-full bg-white-1',
        // isAtSelfUserTable ? 'bg-blue-lightest-2' : 'bg-purple-lightest-1',
        screenshareButtonDisabled
          ? ''
          : 'hover:scale-125 animate-wiggle hover:animate-none'
      )}
    >
      <IconButton
        disabled={screenshareButtonDisabled}
        clickHandler={screenshareButtonHandler}
        className={clsx(
          'flex items-center justify-center bg-transparent rounded-full',
          isCamActive ? 'w-10 h-10' : 'w-6 h-6'
        )}
        // if we want the name here we need to correct for
        // selfUser since that name is set as "You"
        // toolTip="View screenshare"
      >
        <ScreenShareRoundedIcon className="!w-[60%] !h-fit text-black-lightest" />
      </IconButton>
    </div>
  ) : null;
};
