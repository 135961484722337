import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { selfGlooUserAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { CalendarLinkContainer } from 'renderer/shared/integrations/CalendarLinkContainer';
import { SettingsContent, SettingsSubsection } from '../components';

export const IntegrationSettings = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SettingsContent title="Integrations">
        <SettingsSubsection title="Calendars">
          <div className="flex w-full text-xs">
            Comming soon!
            {/* <CalendarLinkContainer /> */}
          </div>
        </SettingsSubsection>
      </SettingsContent>
    </Suspense>
  );
};
