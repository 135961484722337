import { add, getHours, getMinutes, lightFormat } from 'date-fns';
import { atom, selector } from 'recoil';
import { persistAtom } from 'renderer/atoms/effects';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';

export const timeModifiedAtom = atom<Date>({
  key: 'timeModified',
  default: new Date(0),
});

export const restrictOnWeekendsAtom = atom<boolean>({
  key: 'restrictOnWeekends',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const timeRestrictionEnabledAtom = atom<boolean>({
  key: 'timeRestrictionEnabled',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const timeRestrictionAtom = atom<{
  startMinute: number;
  endMinute: number;
}>({
  key: 'timeRestriction',
  default: {
    startMinute: 8 * 60, // 8 AM
    endMinute: 17 * 60, // 5 PM
  },
  effects_UNSTABLE: [persistAtom],
});

const formatSchedule = (minutes: number) => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return lightFormat(add(d, { minutes }), 'hh:mmaa');
};

export const timeRestrictionMessageAtom = selector({
  key: 'timeRestrictionMessageAtom',
  get: ({ get }) => {
    const restrictOnWeekends = get(restrictOnWeekendsAtom);
    const restrictionEnabled = get(timeRestrictionEnabledAtom);
    const restrictions = get(timeRestrictionAtom);
    const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_60));

    // Ignore weekends if set.
    if (restrictOnWeekends && [0, 6].includes(now.getDay())) {
      return 'Disabled on weekends';
    }

    // If no schedule.
    if (!restrictionEnabled) return 'Schedule not used';

    // Check to see if current time 0000-1439 (00:00 - 23:59) is within the specified slots.
    return `Schedule set to: ${formatSchedule(
      restrictions.startMinute
    )} - ${formatSchedule(restrictions.endMinute)}`;
  },
});

// Returns true if the schedule implies we should restrict the slot.
export const isRestrictedTimeAtom = selector({
  key: 'isRestrictedTime',
  get: ({ get }) => {
    const restrictOnWeekends = get(restrictOnWeekendsAtom);
    const restrictionEnabled = get(timeRestrictionEnabledAtom);
    const restrictions = get(timeRestrictionAtom);
    const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_30));

    // Ignore weekends if set.
    if (restrictOnWeekends && [0, 6].includes(now.getDay())) {
      return true;
    }

    // If no schedule.
    if (!restrictionEnabled) return false;

    // Check to see if current time 0000-1439 (00:00 - 23:59) is within the specified slots.
    const minsOfDay = getMinutes(now) + getHours(now) * 60;
    return !(
      restrictions.startMinute <= minsOfDay &&
      minsOfDay <= restrictions.endMinute
    );
  },
});
