/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import { addMinutes, formatISO, getDay, setDate, setDay } from 'date-fns';
import format from 'date-fns/format';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  roomAtom,
  roomMetadataAtom,
  roomUserAtom,
  selectedRoomAtom,
} from 'renderer/atoms/room';
import { CalEvent } from 'renderer/models/Api';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { trpc } from 'renderer/common/client/trpc';
import { PopoverMenu } from 'renderer/shared/PopoverMenu';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { atcb_action } from 'add-to-calendar-button';
import { add } from 'lodash';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';
import { teamAtom } from 'renderer/atoms/team';
import {
  linkedCalendarAtom,
  linkedCalendarsAtom,
} from 'renderer/atoms/calendarEvents';
import { openCreateCalendarUrl } from 'renderer/events';
import {
  nextEventActualTimes,
  nextEventCriteria,
} from 'renderer/atoms/userUtils';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { ModalTypes, selectedModalAtom } from '../atoms';
import { eventToModifyAtom } from './CreateOrUpdateEventModal';

export const ListEventsForRoomModal = () => {
  // get these from the room atom.
  const room = useRecoilValue(selectedRoomAtom);
  const setSelectedModal = useSetRecoilState(selectedModalAtom);
  const setEventToModify = useSetRecoilState(eventToModifyAtom);
  const baseUrl = useBaseUrl();
  const team = useRecoilValue(teamAtom(room.teamId));
  const linkedCalendarAccount = useRecoilValue(linkedCalendarAtom);
  const selfUserId = useRecoilValue(selfUserIdAtom);

  return (
    <div className="flex flex-col items-center w-full min-h-[50vh] gap-2">
      <div className="w-full text-2xl font-semibold text-center">
        {`Events in ${room.name}`}
      </div>
      <span className="text-xs">Coming Soon!</span>
      <div className="flex flex-row flex-wrap gap-3 px-12 whitespace-nowrap">
        <button
          type="button"
          className="flex flex-row items-center justify-center gap-2 p-6 transition rounded-md hover:scale-105 outline outline-1 outline-black-primary/5 shadow-shadow/soft/card bg-white-1 disabled:bg-neutral-3 disabled:text-black-lightest disabled:hover:scale-100"
          disabled
          onClick={() => {
            setEventToModify(undefined);

            if (!linkedCalendarAccount) {
              setSelectedModal(ModalTypes.kLinkCalendar);
              return;
            }

            openCreateCalendarUrl({
              linkedCalendarAccount,
              baseUrl,
              team,
              room,
              selfUserId,
            });

            // setSelectedModal(ModalTypes.kCreateOrUpdateEvent);
          }}
        >
          <EventRoundedIcon /> <span>Create an event</span>
        </button>

        {room.events?.map((e) => {
          return (
            <EventCardPreview event={e} key={e.eventId} roomId={room.roomId} />
          );
        })}
      </div>
    </div>
  );
};

const EventCardPreview = ({
  event,
  roomId,
}: {
  event: CalEvent;
  roomId: string;
}) => {
  const nextEvent = nextEventActualTimes(event, new Date());
  const { title } = useRecoilValue(roomMetadataAtom({ roomId }));
  const creatorUser = event.creator;
  console.log('creatorUser', creatorUser);
  console.log('nextEvent', nextEvent);
  return (
    <div className="flex flex-col p-3 rounded-md gap-y-2 outline outline-1 outline-black-primary/5 shadow-shadow/soft/card bg-white-1">
      <div className="text-xl font-semibold">{event.name}</div>
      <div className="flex flex-row gap-4">
        <div>
          {nextEvent?.start.toDateString()}{' '}
          {nextEvent
            ? event.isAllDay
              ? 'All day'
              : `${nextEvent.start.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })} - ${nextEvent.end.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZoneName: 'short',
                })}`
            : 'No next event scheduled'}
        </div>
        {/* <div>{event.recurrence.duration} mins</div> */}
      </div>
      <div className="flex flex-row gap-1">
        {/* {event.recurrence.days.map((d, i) => {
          const date = eventTime;
          const newDate = setDay(date, i, { weekStartsOn: 6 });
          return (
            <div
              className={clsx(
                'flex w-6 h-6 text-white-1 text-sm items-center justify-center rounded-full',
                [d ? 'bg-blue-primary' : 'bg-gray-3']
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={`date${i}`}
            >
              {format(newDate, 'EEEEE')}
            </div>
          );
        })} */}
      </div>
      <div className="flex flex-row gap-2 text-sm whitespace-nowrap text-gray-4">
        <div>Created by </div>
        <div className="flex items-center h-full bg-transparent w-fit">
          <Avatarv2
            photoUrl={creatorUser.photoUrl}
            iconSizePx={20}
            name={creatorUser.displayName}
          />
        </div>
        <div className="truncate w-28">{creatorUser.displayName}</div>
      </div>
      <div className="flex flex-row items-center justify-end">
        <EventSettingsIcon event={event} roomId={roomId} />
      </div>
    </div>
  );
};

const EventSettingsIcon = ({
  event,
  roomId,
}: {
  event: CalEvent;
  roomId: string;
}) => {
  // const deleteEventMutation = trpc.useMutation(['rooms.event.delete']);
  return (
    <PopoverMenu
      buttonContents={
        <div className="hover:text-black-lighter text-gray-3">
          <SettingsRoundedIcon />
        </div>
      }
      menu={[
        {
          name: 'Edit event',
          icon: <EditRoundedIcon />,
          clickHandler: () => {
            window.electron?.ipcRenderer.openUrl(event.providerLink);
            // TODO: link to google calendar or outlook here into the existing edit page for the event.
          },
        },
        {
          name: 'Delete event',
          icon: <DeleteForeverRoundedIcon />,
          clickHandler: () => {
            // deleteEventMutation.mutate({ roomId, eventId: event.eventId });
          },
        },
      ]}
    />
  );
};
