import { Suspense, useContext } from 'react';
import {
  advancedSettingsToggleAtom,
  AdvancedSettingsToggleType,
} from 'renderer/atoms/settings';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { SettingsContent, SettingsSubsection } from '../components';
import { ToggleSetting } from '../SettingsItem';

const AdvancedToggles = () => {
  return (
    <>
      <ToggleSetting
        disabled={false}
        atom={advancedSettingsToggleAtom(
          AdvancedSettingsToggleType.OPEN_ON_STARTUP
        )}
        title="Open on startup"
        description="Gloo starts automatically on startup"
      />
      <ToggleSetting
        disabled={false}
        atom={advancedSettingsToggleAtom(
          AdvancedSettingsToggleType.START_MINIMIZED
        )}
        title="Start minimized"
        description="Gloo will stay in the background when it first starts."
      />
    </>
  );
};
export const AdvancedSettings: React.FC<{ platform: string }> = ({
  platform,
}) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SettingsContent title="Advanced">
        <SettingsSubsection title="System Startup">
          <AdvancedToggles />
        </SettingsSubsection>
        {platform === 'darwin' && (
          <SettingsSubsection title="Permissions">
            <GradientButton
              clickHandler={() =>
                window.electron?.ipcRenderer.resetWalkThrough()
              }
            >
              Open Dialog
            </GradientButton>
          </SettingsSubsection>
        )}
      </SettingsContent>
    </Suspense>
  );
};
