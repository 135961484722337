/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { atomFamily, useRecoilValue } from 'recoil';
import {
  CalendarAccount,
  linkedCalendarAtom,
  linkedCalendarsAtom,
} from 'renderer/atoms/calendarEvents';
import { selfUserIdAtom, selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { trpc } from 'renderer/common/client/trpc';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';
import { GradientButton } from '../Buttons/helpers/GradientButton';
import { GoogleCalendarIcon } from '../icons/GoogleCalendarIcon';
import { MicrosoftOutlookIcon } from '../icons/MicrosoftOutlookIcon';

/**
 * Allow the user to link a calendar if not linked, or show linked calendar and let them unlinked it / change the settings.
 */
export const CalendarLinkContainer = () => {
  const url = useBaseUrl();

  const linkedAccs = useRecoilValue(linkedCalendarsAtom);
  const linkedCal = useRecoilValue(linkedCalendarAtom);
  const userId = useRecoilValue(selfUserIdAtom);

  return (
    <div className="flex flex-col gap-4">
      <CalendarAccountTypeContainer
        calendarAccounts={linkedAccs.google}
        type="google"
      />
      <CalendarAccountTypeContainer
        calendarAccounts={linkedAccs.microsoft}
        type="microsoft"
      />
      <div className="flex flex-col justify-start w-full gap-6 px-5">
        {!linkedCal && (
          <div className="flex flex-row items-center justify-between w-full whitespace-nowrap">
            <div className="flex flex-row items-center gap-2">
              <GoogleCalendarIcon className="w-10 h-10" />
              <div>Google Calendar</div>
            </div>
            <GradientButton
              enableChevron
              clickHandler={() => {
                const oauthUrl = `${url}/oauth?userId=${userId}&provider=google`;
                if (window.electron) {
                  window.electron?.ipcRenderer.openUrl(oauthUrl);
                } else {
                  window.open(oauthUrl);
                }
              }}
            >
              Add
            </GradientButton>
          </div>
        )}

        {!linkedCal && (
          <div className="flex flex-row items-center justify-between w-full gap-2 whitespace-nowrap">
            <div className="flex flex-row items-center gap-2">
              <MicrosoftOutlookIcon className="w-10 h-10" />
              <div>Microsoft Outlook</div>
            </div>
            <GradientButton
              enableChevron
              clickHandler={() => {
                const oauthUrl = `${url}/oauth?userId=${userId}&provider=microsoft`;
                if (window.electron) {
                  window.electron?.ipcRenderer.openUrl(oauthUrl);
                } else {
                  window.open(oauthUrl);
                }
              }}
            >
              Add
            </GradientButton>
          </div>
        )}
      </div>
    </div>
  );
};

const CalendarAccountTypeContainer = ({
  calendarAccounts,
  type,
}: {
  calendarAccounts: CalendarAccount[];
  type: CalendarAccount['provider'];
}) => {
  const unlinkCalendar = trpc.useMutation([
    'user.integrations.removeIntegration',
  ]);

  return (
    <>
      {calendarAccounts.map((linkedAccount) => {
        return (
          <div className="flex flex-col gap-2" key={linkedAccount.accountId}>
            <div className="flex flex-row items-center gap-2">
              {linkedAccount.provider === 'google' && (
                <>
                  <GoogleCalendarIcon className="w-8 h-8" />
                  <div>Google Calendar</div>
                </>
              )}
              {linkedAccount.provider === 'microsoft' && (
                <MicrosoftOutlookIcon className="w-8 h-8" />
              )}
            </div>
            <span className="pb-2 text-base">{linkedAccount.accountEmail}</span>
            <div>
              {linkedAccount.calendarsLinked.map((cal) => {
                return (
                  <div
                    className="flex flex-row items-center gap-2"
                    key={cal.calendarId}
                  >
                    <div className="flex items-center mb-2">
                      <CalendarCheckBox
                        cal={cal}
                        accountId={linkedAccount.accountId}
                      />
                    </div>
                    {/* <div
                          className="w-[16px] h-[16px]"
                          style={{ backgroundColor: cal.color || 'blue' }}
                        />
                        <div>{cal.name}</div> */}
                  </div>
                );
              })}
            </div>
            {unlinkCalendar.isLoading ? (
              <div className="flex items-center justify-center w-32 pt-2">
                <ClipLoader size={20} />
              </div>
            ) : (
              <button
                className="flex px-4 py-2 text-base text-red-500 rounded-md outline-1 outline outline-red-500 hover:outline-red-300 w-fit hover:text-red-400 shadow-shadow/soft/card"
                type="button"
                onClick={() => {
                  unlinkCalendar.mutate({
                    accountId: linkedAccount.accountId,
                  });
                }}
              >
                Unlink Calendar
              </button>
            )}
          </div>
        );
      })}
    </>
  );
};

const CalendarCheckBox = ({
  accountId,
  cal,
}: {
  accountId: string;
  cal: CalendarAccount['calendarsLinked'][0];
}) => {
  const updateEnabledCalendarsMutation = trpc.useMutation([
    'calendar.updateCalendarLink',
  ]);

  if (updateEnabledCalendarsMutation.isLoading) {
    return <ClipLoader color="gray" size={16} />;
  }

  return (
    <>
      <input
        id="default-checkbox"
        type="checkbox"
        checked={cal.enabled}
        onChange={(e) => {
          const { checked } = e.target;
          updateEnabledCalendarsMutation.mutate({
            calendarId: cal.calendarId,
            enableCalendar: checked,
            accountId,
          });
        }}
        value=""
        className="w-4 h-4 text-purple-500 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 focus:ring-2"
        // style={{ background: cal.color || 'green', accentColor: 'black' }}
      />
      <label
        form="default-checkbox"
        className="ml-2 text-base font-medium text-gray-900"
      >
        {cal.name}
      </label>
    </>
  );
};
