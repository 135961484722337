// This file includes all states relevant to a voice call.

import { differenceInMilliseconds, differenceInSeconds, max } from 'date-fns';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { selfGlooUserAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import {
  roomAtom,
  roomConvoAtom,
  roomListAtom,
  roomUserAtom,
} from 'renderer/atoms/room';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import { RoomUser } from 'renderer/models/Api';
import { RoomConvoKey, RoomKey, RoomUserKey } from 'renderer/models/Keys';

export const lastUserSpeakTimeAtom = atomFamily<Date, RoomUserKey>({
  key: 'lastUserSpeakTime',
  default: new Date(0),
});

// Doesn't include the logged in user.
export const lastTableSpeakTimeAtom = atomFamily<Date, RoomConvoKey>({
  key: 'lastTableSpeakTime',
  default: new Date(0),
});

// Returns the state if a user is currently speaking.
export const userSpeakingAtom = atomFamily<boolean, RoomUserKey>({
  key: 'userVolume',
  default: false,
});

export const userSpeakingVolumeAtom = atomFamily<number, RoomUserKey>({
  key: 'userSpeakingVolumeAtom',
  default: 0,
});

export const maxUserVolAtom = selectorFamily<number, RoomConvoKey>({
  key: 'combinedUserVolumeAtom',
  get:
    ({ roomId, convoId }) =>
    ({ get }) => {
      const convo = get(roomConvoAtom({ roomId, convoId }));
      const vols = convo?.userList.map((u) =>
        get(userSpeakingVolumeAtom({ userId: u, roomId }))
      );
      const maxVol = Math.min(1, Math.max(...(vols || []), 0));
      if (!Number.isFinite(maxVol)) {
        return 0;
      }

      return maxVol;
    },
});

// Returns the last time the users table spoke in a room.
export const lastSelfUserConvoTimeAtom = selectorFamily<Date, RoomKey>({
  key: 'lastUserRoomConversation',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const user = get(selfUserIdAtom);
      const roomUser = get(roomUserAtom({ roomId, userId: user }));
      if (!roomUser.convo.convoId) return new Date(0);

      return get(
        lastTableSpeakTimeAtom({ roomId, convoId: roomUser.convo.convoId })
      );
    },
});

// Return the last time any user table was having a converstation.
const lastSelfUserConvoTimeAllRoomsAtom = selector<Date>({
  key: 'lastUserTableConversation',
  get: ({ get }) => {
    const rooms = get(roomListAtom);

    const lastTalkTime = rooms
      .map((roomId) => get(lastSelfUserConvoTimeAtom({ roomId })))
      .reduce((prev, curr) => max([prev, curr]), new Date(0));

    return lastTalkTime;
  },
});

// get whether the users table had a convo in any of their active rooms
export const selfUserConvoActiveInAnyRoom = selectorFamily<boolean, number>({
  key: 'tableConversationActive',
  get:
    (thresholdSeconds) =>
    ({ get }) => {
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_30));
      const lastSpeakTime = get(lastSelfUserConvoTimeAllRoomsAtom);
      // If someone spoke at the table within the last thresholdSeconds
      return differenceInSeconds(now, lastSpeakTime) < thresholdSeconds;
    },
});

// same as above, but updates every second rather than every 30.
export const tableConversationActiveFasterUpdateAtom = selectorFamily<
  boolean,
  number
>({
  key: 'tableConversationActiveFasterUpdate',
  get:
    (thresholdSeconds) =>
    ({ get }) => {
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.MS_500));
      const lastSpeakTime = get(lastSelfUserConvoTimeAllRoomsAtom);
      // If someone spoke at the table within the last thresholdSeconds.
      return differenceInSeconds(now, lastSpeakTime) < thresholdSeconds;
    },
});

// Returns the state if a converstation might be happening at this table.
export const roomConversationActiveAtom = selectorFamily<boolean, RoomKey>({
  key: 'roomConversationActive',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.MS_500));
      const room = get(roomAtom(roomId));
      const selfUser = get(selfGlooUserAtom);
      const lastSpeakTime =
        room.memberList.reduce(
          (lastActive, userId) =>
            userId === selfUser.userId
              ? lastActive
              : max([
                  lastActive,
                  get(lastUserSpeakTimeAtom({ roomId, userId })),
                ]),
          new Date(0)
        ) || new Date(0);

      // If someone spoke at the table within the last 45 seconds.
      return differenceInMilliseconds(now, lastSpeakTime) < 250;
    },
});

export const hasActiveSpeakersAtom = selectorFamily<boolean, RoomConvoKey>({
  key: 'tableSpeakerActive',
  get:
    ({ roomId, convoId: tableId }) =>
    ({ get }) => {
      const table = get(roomConvoAtom({ roomId, convoId: tableId }));
      if (!table) {
        return false;
      }
      return !!table.users.find((u) =>
        get(userSpeakingAtom({ roomId, userId: u.userId }))
      );
    },
});

export const convoHoverAtom = atom<RoomConvoKey | null>({
  key: 'convoHover',
  default: null,
});

export const megaphoneUsersAtom = selectorFamily<RoomUser[], RoomKey>({
  key: 'megaphoneUsersAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      const megaphoningUsers = room.members.filter((u) => {
        const teamUser = get(roomUserAtom({ roomId, userId: u.userId }));
        return teamUser.convo.deviceBroadcast.mic;
      });
      return megaphoningUsers;
    },
});
