import { FC } from 'react';
import { ChildProps } from 'types/react';

export const VerticalNavBar: FC<ChildProps> = ({ children }) => {
  return (
    <div className="flex flex-col p-4 text-left divide-y-[2px] w-fit bg-slate-100 divide-slate-200 whitespace-nowrap text-black/70">
      {/* user settings section */}
      {children}
    </div>
  );
};
