import { GlooIcon } from 'renderer/shared/icons/GlooIcon';
import SlackIcon from 'renderer/shared/icons/SlackIcon';

export const IntegrationStatus = ({ slack }: { slack: boolean }) => {
  return (
    <div className="flex items-center justify-center w-3 h-3 rounded-full bg-white-1">
      {slack && <SlackIcon className="w-full h-full" />}
      {!slack && <GlooIcon className="w-full h-full" />}
    </div>
  );
};
