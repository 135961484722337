import {Transition} from "@headlessui/react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Center} from "components/Center";
import {GradientButton} from "components/GradientButton";
import {OutlineButton} from "components/OutlineButton";
import {Fragment, useEffect, useState} from "react";
import {AiFillCheckCircle} from "react-icons/ai";
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "reactfire";
import {useRecoilCallback} from "recoil";
import {trpc} from "renderer/common/client/trpc";
import {requireAuth} from "renderer/common/components/hoc/requireAuth";
import {trpcAtom} from "renderer/common/components/TRPCReactQuery";
import {Invite} from "../../model/invite";
import {AltLoginText} from "./AltLoginText";
const SignInSuccessLanding = () => {
  const [deeplinkShown, setDeeplinkShown] = useState(false);
  const user = useUser();
  const navigate = useNavigate();
  const {state} = useLocation();
  const getLoginTokenQuery = trpc.useQuery(["auth.getloginToken"], {
    onSuccess: (result) => {
      setDeeplinkShown(true);
    },
    enabled: !deeplinkShown,
    retry: 0,
  });
  const target = localStorage.getItem("target-after-login");
  useEffect(() => {
    if (target) {
      localStorage.removeItem("target-after-login");
      navigate(target);
    }
  }, [navigate, target]);

  const openDesktop = async (token: string) => {
    const p = new URLSearchParams();
    p.append("idToken", token);
    window.location.replace(`gloo://login?${p.toString()}`);
  };

  useEffect(() => {
    const fn = async () => {
      const result = await getLoginTokenQuery.refetch();
      if (!target) {
        if (result.data) {
          openDesktop(result.data.loginToken);
        }
      }
    };
    fn();
  }, [target]);

  const inviteInfo = ((state as any)?.invite as Invite | undefined);

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity transition ease-in-out duration-200 transform"
      enterFrom="opacity-0 -translate-x-full"
      enterTo="opacity-100 translate-x-0"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as={Fragment}
    >
      <div className="flex flex-col items-center w-full h-full p-10 space-y-8">
        <div className="flex flex-row items-center space-x-3 text-4xl font-bold">
          <AiFillCheckCircle className="text-green-600" />
          {inviteInfo ? (
            <div>You&apos;re now in {inviteInfo.userInTeam ? inviteInfo.name : inviteInfo.teamName}!</div>
          ) : (
            <div>{`Logged in as ${user.data?.email}!`}</div>
          )}

        </div>
        <GradientButton clickHandler={async () => {
          const result = await getLoginTokenQuery.refetch();
          if (result.data) {
            openDesktop(result.data.loginToken);
          }
        }}
        >
            Open the desktop app
          <ChevronRightIcon />
        </GradientButton>
        {/* We're encouraging ppl not to use web right now */}
        {/* <GradientButton clickHandler={async () => {
          const result = await getLoginTokenQuery.refetch();
          if (result.data) {
            const token = result.data.loginToken;
            const p = new URLSearchParams();
            p.append("idToken", token);
            console.log("url thing ", p.toString());
            navigate("/");
          }
        }}
        >
            Open Gloo for Web
          <ChevronRightIcon />
        </GradientButton>
        */}
        <a className="w-fit h-fit" href="/download">
          <OutlineButton clickHandler={() => {}}>
          Download Gloo

          </OutlineButton>
        </a>
        <AltLoginText />
      </div>
    </Transition>);
};

const SignInSuccessLandingWrapper = () => {
  return <><Center><SignInSuccessLanding /></Center></>;
};

export default requireAuth(SignInSuccessLandingWrapper);
