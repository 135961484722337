import { Transition } from '@headlessui/react';
import { useContext } from 'react';
import { HiOutlineLogout } from 'react-icons/hi';
import { useRecoilValue } from 'recoil';
import { selectedSettingCategoryAtom } from 'renderer/atoms/settings';
import { requireAuth } from 'renderer/common/components/hoc/requireAuth';
import { SettingsCategory } from 'renderer/models/SettingsCategory';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';
import { useAuthHooks } from 'renderer/hooks/useAuthHooks';
import { NavSection, NavSectionData } from './nav/NavSection';
import { VerticalNavBar } from './nav/VerticalNavBar';
import { NotificationsSettings } from './appsettings/NotificationsSettings';
import { AdvancedSettings } from './appsettings/AdvancedSettings';
import { IntegrationSettings } from './appsettings/IntegrationSettings';

const SettingsContainer = () => {
  const appInfo = useContext(AppInfoContext);

  const selectedSetting: SettingsCategory = useRecoilValue(
    selectedSettingCategoryAtom
  );
  const { signout } = useAuthHooks();

  const navItems = [
    {
      name: 'Notifications',
      category: SettingsCategory.NOTIFICATIONS,
    },
    {
      name: 'Integrations',
      category: SettingsCategory.INTEGRATIONS,
    },
  ];
  if (window.electron) {
    navItems.push({
      name: 'Advanced',
      category: SettingsCategory.ADVANCED,
    });
  }

  const navSections: NavSectionData[] = [
    {
      title: 'App Settings',
      navItems,
    },
  ];

  return (
    <Transition
      appear
      show
      enter="transition-opacity duration-[500ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex justify-center w-full h-screen">
        <div className="flex justify-center max-w-6xl align-middle bg-soft-white">
          <VerticalNavBar>
            <div>App Version: {appInfo?.appVersion}</div>
            {navSections.map((s) => {
              return (
                <NavSection
                  title={s.title}
                  navItems={s.navItems}
                  key={s.title}
                />
              );
            })}

            <button type="button" onClick={signout} className="rounded-md">
              <div className="flex flex-row gap-1 p-2 pr-12 space-y-1 font-semibold text-left text-gray-700">
                <HiOutlineLogout className="w-6 h-6 text-red-500 rotate-180" />
                Logout
              </div>
            </button>
          </VerticalNavBar>
          {
            {
              NOTIFICATIONS: <NotificationsSettings />,
              PREFERENCES: null,
              ADVANCED: <AdvancedSettings platform={appInfo.os ?? 'unknown'} />,
              INTEGRATIONS: <IntegrationSettings />,
            }[selectedSetting]
          }
        </div>
      </div>
    </Transition>
  );
};

export default requireAuth(SettingsContainer);
