import { Placement } from '@popperjs/core';
import { IconButton } from './IconButton';

export type ToggleIconButtonProps = {
  handleClick: (event?: MouseEvent) => void;
  isActive: boolean;
  toolTipPlacement?: Placement;
  inactive: {
    toolTip?: string;
    children: React.ReactNode;
  };
  active: {
    toolTip?: string;
    children: React.ReactNode;
  };
  disabled?: boolean;
  className?: string;
};
export const ToggleIconButton = ({
  handleClick,
  isActive,
  active,
  inactive,
  disabled,
  className,
  toolTipPlacement,
}: ToggleIconButtonProps) => {
  return (
    <>
      {isActive ? (
        <IconButton
          toolTip={active.toolTip}
          toolTipPlacement={toolTipPlacement}
          clickHandler={handleClick}
          disabled={disabled}
          className={className}
        >
          {active.children}
        </IconButton>
      ) : (
        <IconButton
          toolTip={inactive.toolTip}
          toolTipPlacement={toolTipPlacement}
          clickHandler={handleClick}
          disabled={disabled}
          className={className}
        >
          {inactive.children}
        </IconButton>
      )}
    </>
  );
};
