/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-undef */
import { getTrackBackground, Range } from 'react-range';
import { TooltipOld } from 'renderer/shared/Buttons/helpers/IconButton';

export const AmbientSoundSlider = ({
  displayedVolume,
  isMuted,
  min,
  max,
  step,
  onChange,
}: {
  displayedVolume: number;
  isMuted: boolean;
  min: number;
  max: number;
  step: number;
  onChange: (values: number[]) => void;
}) => {
  return (
    <Range
      values={[displayedVolume]}
      step={step}
      min={min}
      max={max}
      rtl={false}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: '24px',
            display: 'flex',
            width: '70%',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '8px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values: [displayedVolume],
                colors: [isMuted ? '#d1d3d9' : '#b5a9fd', '#dbdbdb'],
                min,
                max,
                rtl: false,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          style={{
            ...props.style,
            height: '14px',
            width: '14px',
            borderRadius: '14px',
            backgroundColor: isMuted ? '#d1d3d9' : '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: isMuted ? '' : '0px 2px 6px #AAA',
          }}
        >
          <TooltipOld message={`${displayedVolume}%`}>
            <div style={{ height: '18px', width: '18px' }} />
          </TooltipOld>
        </div>
      )}
    />
  );
};
