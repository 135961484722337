/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { motion } from 'framer-motion';
import {
  selectorFamily,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
} from 'recoil';
import {
  ConnectionStatusEnum,
  connectionStatusAtom,
} from 'renderer/atoms/connectionStatus';
import {
  hoveringOverNudgeAtom,
  userNudgedBySelfAtom,
  userNudgedMeAtom,
} from 'renderer/atoms/nudge';
import {
  isMicActiveAtom,
  roomTeamIdAtom,
  roomUserAtom,
  userDisplayNameAtom,
} from 'renderer/atoms/room';
import { teamImplAtom, teamUserImplAtom } from 'renderer/atoms/team';
import { availableStatusAtom } from 'renderer/connection/state';
import { RoomUserKey } from 'renderer/models/Keys';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { selectedBusyUserIdAtom } from 'renderer/shared/Modals/TalkToBusyUserModal';
import { Tooltip } from 'renderer/shared/Tooltip';
import { GlooUser } from 'renderer/shared/user/GlooUser';
import { PlaceholderAvatar } from 'renderer/shared/user/PlaceholderAvatar';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import {
  joinConvoLoadingAtom,
  useConvoHooks,
} from 'renderer/hooks/useConversationExpirationMonitor';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import {
  differenceInSeconds,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';
import { friendlyTimeDifferenceString } from 'renderer/shared/utilities';
import { ClipLoader, MoonLoader } from 'react-spinners';
import { SquareBubble } from './SquareBubble';

export const BubbleWrapper = ({
  convoId,
  roomId,
  userIds,
  convoType,
}: {
  convoId: string;
  roomId: string;
  userIds: string[];
  convoType: 'EVENT' | 'CONVO';
}) => {
  const { joinConvo } = useConvoHooks();
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));

  return (
    <SquareBubble
      participantIds={userIds}
      convoId={convoId}
      roomId={roomId}
      convoType={convoType}
      onClick={async () => {
        await joinConvo({ roomId, teamId }, { convoId });
      }}
    />
  );
};

const roomUserToolTipMessageAtom = selectorFamily<string, RoomUserKey>({
  key: 'roomUserToolTipMessage',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const displayName = get(userDisplayNameAtom({ roomId, userId }));

      const connectionState = get(connectionStatusAtom({ roomId, userId }));
      const isUserOnline = connectionState === ConnectionStatusEnum.Online;
      const hoveringOverNudge = get(hoveringOverNudgeAtom({ roomId, userId }));
      const didUserNudgedMe = get(userNudgedMeAtom({ userId, roomId }));
      const didINudgeUser = get(userNudgedBySelfAtom({ userId, roomId }));
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_60));
      const { busy: isBusy, invitedUser } = get(
        roomUserAtom({ roomId, userId })
      );

      if (invitedUser) {
        return 'Pending accept';
      }

      if (didUserNudgedMe) {
        return `${displayName} wants to talk (${friendlyTimeDifferenceString(
          now,
          new Date(didUserNudgedMe.timestamp)
        )})${isUserOnline ? ' \nClick to talk!' : ''}`;
      }

      if (hoveringOverNudge) {
        if (didINudgeUser) {
          return `Dismiss nudge (Sent ${friendlyTimeDifferenceString(
            now,
            new Date(didINudgeUser.timestamp)
          )})`;
        }
        return `Send ${displayName} a nudge`;
      }

      if (!isUserOnline) {
        return 'Away';
      }

      return `Talk to ${displayName}${isBusy ? ' (Busy)' : ''}`;
    },
});

export const SingleUserInGrid = ({
  userId,
  roomId,
  onClick,
}: {
  userId: string;
  roomId: string;
  onClick?: () => void;
}) => {
  return (
    <SingleUserInGridLoaded userId={userId} roomId={roomId} onClick={onClick} />
  );
};

export const SingleUserInGridLoaded = ({
  userId,
  roomId,
  onClick,
}: {
  userId: string;
  roomId: string;
  onClick?: () => void;
}) => {
  const tooltipMessage = useRecoilValue(
    roomUserToolTipMessageAtom({ roomId, userId })
  );
  const selfUser = useRecoilValue(selfGlooUserAtom);
  const connectionState = useRecoilValue(
    connectionStatusAtom({ roomId, userId })
  );
  const isUserOnline = connectionState === ConnectionStatusEnum.Online;
  const isSelfUserOnline = useRecoilValue(availableStatusAtom);

  const loading = useRecoilValue(joinConvoLoadingAtom({ userId }));
  const enableEffects = selfUser.userId !== userId && isUserOnline;
  const hoverClass =
    enableEffects && isSelfUserOnline ? 'hover:scale-105' : null;
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const isMicActive = useRecoilValue(isMicActiveAtom({ roomId, userId }));
  const isBusy = useRecoilValue(roomUserAtom({ roomId, userId })).busy;
  const { joinConvo } = useConvoHooks();

  const startConfirmBusyDialog = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (selectedUserId: string) => {
        set(selectedBusyUserIdAtom, selectedUserId);
        set(selectedModalAtom, ModalTypes.kTalkToBusyUser);
      }
  );
  return (
    <Tooltip
      message={loading ? 'Connecting' : tooltipMessage}
      disabled={selfUser.userId === userId}
      delayShowMs={enableEffects ? undefined : 500}
      placement="top"
    >
      <button
        disabled={loading || !enableEffects || !isSelfUserOnline}
        className={`relative flex items-center justify-center h-fit duration-100 transform ${hoverClass}`}
        type="button"
        onClick={() => {
          if (enableEffects) {
            if (isBusy && !isMicActive) {
              startConfirmBusyDialog(userId);
            } else {
              joinConvo({ teamId, roomId }, { userId });
            }
          }
        }}
      >
        {loading && (
          <svg
            aria-hidden="true"
            className="absolute w-[60px] h-[60px] text-gray-200 animate-spin fill-blue-lighter"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              strokeWidth="0.5"
              fill="currentFill"
            />
          </svg>
        )}
        <motion.div
          layoutId={roomId + userId}
          // initial={false}
          transition={{
            type: 'tween',
          }}
          layout="position"
          // layout={false}
        >
          <GlooUser roomId={roomId} userId={userId} />
        </motion.div>
      </button>
    </Tooltip>
  );
};
