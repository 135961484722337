import { GlobalStyles } from '@mui/styled-engine';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { logger } from 'renderer/common/LogCreator';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';
import {
  Attachment,
  Avatar,
  AvatarProps,
  isDate,
  messageHasReactions,
  MessageOptions,
  MessageProps,
  MessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext,
} from 'stream-chat-react';

export const CustomMessage = () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    groupStyles,
    firstOfGroup,
    endOfGroup,
    groupedByUser,
  } = useMessageContext();

  const messageWrapperRef = useRef(null);
  const hasReactions = messageHasReactions(message);

  const shouldRenderAvatarAndName =
    groupStyles?.includes('top') || groupStyles?.includes('single');
  // firstOfGroup;
  return (
    <div className="flex flex-row items-start rounded-sm gap-x-2 bg-inherit group hover:bg-neutral-200/70">
      {/* we hide and show this using App.css selecting on the avatar classname because stream-chat
      doesn't give us the styles in the context above (they are undefined) */}
      <div className="flex items-start avatar">
        <AvatarChat image={message.user?.image} name={message.user?.name} />
      </div>
      <div
        className="relative flex flex-col items-start w-full py-[2px] whitespace-pre-wrap bg-inherit"
        style={{
          hyphens: 'auto',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {/* <div className="absolute top-0 right-0 hidden group-hover:flex">
          <MessageOptions messageWrapperRef={messageWrapperRef} />
        </div> */}

        <div className="items-center gap-2 username">
          <div className="font-semibold text-black-lightest">
            {message.user?.name}
          </div>
          <div className="text-sm font-light text-gray-4">
            <MessageTimestamp />
          </div>
        </div>
        {showDetailedReactions && isReactionEnabled && (
          <ReactionSelector ref={reactionSelectorRef} />
        )}
        <MessageText
          customWrapperClass="str-chat__message-text flex w-full break-words text-left text-base items-center py-[1px] !z-0"
          customInnerClass="z-0 text-black-lightest font-[400]"
        />

        {/* <MessageStatus /> */}
        {message.attachments && message.attachments.length > 0 && (
          <Attachment attachments={message.attachments} />
        )}
        {hasReactions && !showDetailedReactions && isReactionEnabled && (
          <SimpleReactionsList />
        )}
        <MessageRepliesCountButton reply_count={message.reply_count} />
      </div>
    </div>
  );
};

export const AvatarChat = ({ image, name }: AvatarProps) => {
  return (
    <>
      {/* <CustomTooltip>{title}</CustomTooltip> */}
      <div className="w-8 h-8 avatar-image">
        {/* <img src={image} alt={title} /> */}
        <Avatarv2 name={name || 'noname'} photoUrl={image} iconSizePx={32} />
      </div>
    </>
  );
};
