/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ClipLoader, PuffLoader, SyncLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { PageSelector, pageSelectorAtom } from 'renderer/atoms/pageselectors';
import {
  roomImplAtom,
  selectedRoomAvailableAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import {
  FirebaseSyncAvailability,
  selectedTeamAvailableAtom,
  selectedTeamKeyAtom,
  sortedTeamListAtom,
  teamAtom,
} from 'renderer/atoms/team';
import { usePageAttributes } from 'renderer/hooks/usePageAttributes';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { ErrorFallbackWithReload } from 'renderer/shared/ErrorFallbackWithReload';
import { NoTeamsOrRoomsView } from './NoTeamsOrRoomsView';
import NotificationsPage from './NotificationsPage';
import { RoomView } from './RoomView.tsx';
import { RoomButtons } from './RoomView.tsx/RoomTitleBar';
import MembersOverView, {
  InviteTeammateButton,
} from './TeamView/MembersOverview';
import RoomsOverview, { CreateRoomButton } from './TeamView/RoomsOverview';
import TeamSettings from './TeamView/TeamSettings';

const TeamLoadedTitle = () => {
  const selectedPage = useRecoilValue(pageSelectorAtom);
  const roomKey = useRecoilValue(selectedRoomKeyAtom);
  const roomReady = useRecoilValue(selectedRoomAvailableAtom);
  const room = useRecoilValue(roomImplAtom(roomKey));

  const { title, description, icon } = usePageAttributes(selectedPage, room);

  if (
    selectedPage === PageSelector.TEAM_ROOM &&
    roomReady !== FirebaseSyncAvailability.AVAILABLE
  ) {
    return null;
  }

  return (
    <div className="relative z-40 flex flex-col w-full">
      <div className="flex items-center justify-start gap-2">
        <div className="flex items-center justify-center w-5 h-5 lg:w-6 lg:h-6">
          {icon}
        </div>
        <span className="text-xl font-medium truncate lg:text-2xl">
          {title}
        </span>
      </div>
      {selectedPage === PageSelector.TEAM_USER_OVERVIEW && (
        <InviteTeammateButton />
      )}
      {selectedPage === PageSelector.TEAM_ROOM && <RoomButtons />}
      {selectedPage === PageSelector.TEAM_ROOMS_OVERVIEW && (
        <CreateRoomButton />
      )}
      {/** TODO: Make this div always show up */}
      <div className="text-sm text-left text-black-primary/40">
        {description ?? ' '}
      </div>
    </div>
  );
};

const TeamLoadedContent: React.FC = () => {
  const selectedPage = useRecoilValue(pageSelectorAtom);
  switch (selectedPage) {
    case PageSelector.TEAM_ROOM:
      return <RoomView />;
    case PageSelector.TEAM_USER_OVERVIEW:
      return <MembersOverView />;
    case PageSelector.TEAM_ROOMS_OVERVIEW:
      return <RoomsOverview />;
    case PageSelector.TEAM_SETTINGS:
      return <TeamSettings />;
    case PageSelector.NOTIFICATIONS_PAGE:
      return <NotificationsPage />;
    default:
      return null;
  }
};

const TeamTitle = () => {
  const teamKey = useRecoilValue(selectedTeamKeyAtom);
  const { name: teamName } = useRecoilValue(teamAtom(teamKey));
  return (
    <span className="text-sm font-light text-left text-gray-4">{teamName}</span>
  );
};

const TeamLoadedPage: React.FC = () => {
  const selectedPage = useRecoilValue(pageSelectorAtom);
  const isTeamPage = ![
    PageSelector.NOTIFICATIONS_PAGE,
    PageSelector.NO_TEAMS,
    PageSelector.NO_NON_PERSONAL_TEAMS,
  ].includes(selectedPage);
  const pageDataReady = useRecoilValue(selectedTeamAvailableAtom);
  if (isTeamPage && pageDataReady !== FirebaseSyncAvailability.AVAILABLE) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <SyncLoader />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col items-center h-full pt-2 overflow-x-visible grow justify-items-start overflow-y-clip">
      <div className="relative flex flex-col w-full px-4">
        {isTeamPage && <TeamTitle />}
        <TeamLoadedTitle />
      </div>
      <div className="flex flex-row items-start w-full h-full gap-2 py-1 overflow-clip">
        <TeamLoadedContent />
      </div>
    </div>
  );
};

export const MainView = () => {
  const { resetPages } = usePageSelector();
  const selectedPage = useRecoilValue(pageSelectorAtom);
  const teamList = useRecoilValue(sortedTeamListAtom);

  useEffect(() => {
    if (teamList.length > 0 && selectedPage === PageSelector.NO_TEAMS) {
      resetPages();
    }
  }, [teamList.length, selectedPage]);

  if (teamList.length === 0 && selectedPage !== PageSelector.NO_TEAMS) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-2 text-gray-4">
        <ClipLoader color="gray" />
        <ErrorFallbackWithReload
          resetErrorBoundary={resetPages}
          error={{ name: 'ACCEPTABLE_ERROR', message: 'this is ok' }}
        />
      </div>
    );
  }

  if (
    selectedPage === PageSelector.NO_TEAMS ||
    PageSelector.NO_NON_PERSONAL_TEAMS === selectedPage
  ) {
    return <NoTeamsOrRoomsView />;
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallbackWithReload}
      onReset={resetPages}
    >
      <TeamLoadedPage />
    </ErrorBoundary>
  );
};
