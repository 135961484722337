import { WindowOutlined } from '@mui/icons-material';
import { signOut } from 'firebase/auth';
import Gleap from 'gleap';
import { useContext, useCallback } from 'react';
import { useAuth } from 'reactfire';
import { trpc } from 'renderer/common/client/trpc';
import { logger } from 'renderer/common/LogCreator';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';

export const useAuthHooks = (withReload = true) => {
  const appInfo = useContext(AppInfoContext);
  const auth = useAuth();
  const updateOnlineStatusMutation = trpc.useMutation(['users.status.online']);

  const glooSignOut = useCallback(async () => {
    Gleap.clearIdentity();
    try {
      await updateOnlineStatusMutation.mutateAsync({
        online: false,
      });
    } catch (error) {
      logger.error('Error updating status on signout', error);
    }

    try {
      await signOut(auth);
    } catch (error: any) {
      logger.error('Error signing out, will clear cookies', error);
    }
    // for now we nuke everything
    // TODO: preserve per-user local storage.
    localStorage.clear();
    sessionStorage.clear();
    // this is done since otherwise there is a bug
    // where firebase remembers the previous user
    // probably because it stores this data in memory.
    // this would cause the app to fail to load unless the user refreshed manually or restarted.
    if (withReload) {
      window.location.reload();
    }
    // updateOnlineStatusMutation always changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo?.appVersion, appInfo?.machineId, auth, withReload]);

  return { signout: glooSignOut };
};
