import { selectedRoomKeyAtom } from 'renderer/atoms/room';
import { MdMic, MdMicOff } from 'react-icons/md';
import { useRecoilValue } from 'recoil';
import { localMicActiveAtom } from 'renderer/call/components/MicrophoneProvider';
import { userSpeakingAtom } from 'renderer/connection/voiceCallState';
import { UserKey } from 'renderer/models/Keys';

import { useEffect, useState } from 'react';
import { GoMegaphone } from 'react-icons/go';
import {
  isStreamActiveFirebase,
  StreamType,
  agoraSubscribedMicTrackAtom,
} from 'renderer/call/components/atoms/CallStateAtoms';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import { availableStatusAtom } from 'renderer/connection/state';
import { trackIsPublishedAtom } from 'renderer/call/components/agora-publishers/helpers/WaitToPublish';
import { connectionStateAtom } from 'renderer/atoms/call';

export const MegaphoneDisplay: React.FC<{
  speaking: boolean;
  waiting_on_connect?: boolean;
}> = ({ speaking, waiting_on_connect }) => {
  const [showPulse, setShowPulse] = useState(false);

  useEffect(() => {
    if (waiting_on_connect) {
      const ret = setTimeout(() => setShowPulse(true), 1_500);
      return () => clearTimeout(ret);
    }
    setShowPulse(false);
  }, [waiting_on_connect]);

  return (
    <div className="rounded-full bg-soft-white">
      <GoMegaphone
        className={`${speaking ? 'text-green-600 ' : ''} ${
          showPulse ? 'animate-pulse text-yellow-400' : ''
        }`}
      />
    </div>
  );
};

export const MicDisplay: React.FC<{
  speaking: boolean;
  waiting_on_connect?: boolean;
  color?: string;
}> = ({ speaking, waiting_on_connect, color }) => {
  const [showPulse, setShowPulse] = useState(false);

  useEffect(() => {
    if (waiting_on_connect) {
      const ret = setTimeout(() => setShowPulse(true), 1_500);
      return () => clearTimeout(ret);
    }
    setShowPulse(false);
  }, [waiting_on_connect]);

  return (
    <div className={`rounded-full ${color ?? 'bg-soft-white'}`}>
      <MdMic
        className={`${speaking ? 'text-blue-primary ' : ''}  scale-75 ${
          showPulse ? 'animate-pulse text-yellow-400' : ''
        }`}
      />
    </div>
  );
};
