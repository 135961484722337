/* eslint-disable react/no-unescaped-entities */
import { Transition } from '@headlessui/react';

import { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import {
  availableStatusAtom,
  unavailableStatusReason,
} from 'renderer/connection/state';
import { GlooSelfUserWithStatus } from 'renderer/shared/user/GlooUser';
import { StatusToggle } from '../../SidePanel/StatusPanel/StatusToggle';

export const OfflinePanel = () => {
  const { status } = useRecoilValue(selfGlooUserAtom);
  const isUserAvailable = useRecoilValue(availableStatusAtom);
  const unavailableStatusReasonMsg = useRecoilValue(unavailableStatusReason);

  return (
    <>
      {/* for some reason this div sometimes shows up in a semi-transparent way and blocks clicks if we don't hide it completely */}
      {!isUserAvailable && (
        <Transition
          appear
          show={!isUserAvailable}
          as={Fragment}
          enter="transition ease-out duration-400"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-400"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 z-20 w-full h-full bg-[#cdcdcd]/40" />
        </Transition>
      )}
      <Transition
        appear
        show={!isUserAvailable}
        as={Fragment}
        enter="transition ease-out duration-400"
        enterFrom="opacity-0 translate-y-14"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-400"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-10"
      >
        <div className="absolute inset-x-0 z-30 flex justify-center bottom-32 lg:bottom-52">
          <div className="flex p-4 text-xl font-normal rounded-lg w-fit h-fit bg-gray-5 text-white-1/80">
            <div className="flex flex-row items-center gap-4">
              <GlooSelfUserWithStatus iconSizePx={50} status={status} />
              <div className="flex flex-col text-left w-60">
                <div>You're offline</div>
                <div className="text-sm">{unavailableStatusReasonMsg}</div>
                {/* <div className="text-sm text-left">
                  Go online to join and hear conversations
                </div> */}
              </div>
              <StatusToggle isInOfflinePanel />
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
