import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import { isWindowFocusedAtom } from 'renderer/common/hooks/useUserInputActiveMonitor';
import { SettingsCategory } from 'renderer/models/SettingsCategory';
import { persistAtom } from './effects';

export enum FilterType {
  ONE = 'ONE',
  TWO = 'TWO',
}
export interface FilterSettings {
  type: FilterType;
}

// TODO: enable/disable individual tables? Maybe keep track of blockedTableIds?
export interface OverhearingSettings {
  filter: FilterSettings;
}

export const selectedSettingCategoryAtom = atom<SettingsCategory>({
  key: 'selectedSettingCategory',
  default: SettingsCategory.NOTIFICATIONS,
});

// Keep track on a per room basis. We can later create a global setting
export const roomOverhearingSettingsAtom = atomFamily<
  OverhearingSettings,
  string
>({
  key: 'overhearingSettings',
  default: {
    filter: {
      type: FilterType.ONE,
    },
  },
  effects_UNSTABLE: [persistAtom],
});

export enum NotificationType {
  SOUND,
  BANNER,
}

export enum NotificationSettingsToggle {
  STATUS_CHANGE,
  STATUS_MIC,
  OTHER_JOIN_TABLE,
  OVERHEARING_TOGGLE,
  NEW_MESSAGE,
}

export const notificationSettingsToggleAtom = atomFamily<
  boolean,
  { action: NotificationSettingsToggle; type: NotificationType }
>({
  key: 'notificationSettingsToggle',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export enum OverhearingVolume {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export const overhearingSettingsVolumeAtom = atom<OverhearingVolume>({
  key: 'overhearingSettingsVolumeAtom',
  default: OverhearingVolume.LOW,
  effects_UNSTABLE: [persistAtom],
});

export const DEFAULT_OVERHEARING_VOLUME = 7;
export const DEFAULT_OVERHEARING_DAMPEND_VOLUME = 4;

export const ambientSoundAtom = atom<{ vol: number; muted: boolean }>({
  key: 'ambientSoundAtom',
  default: { vol: DEFAULT_OVERHEARING_VOLUME, muted: false },
  effects_UNSTABLE: [persistAtom],
});

export const useNotificationAtom = atomFamily<boolean, NotificationType>({
  key: 'useNotification',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const soundSettingsOnAtom = selectorFamily<
  boolean,
  NotificationSettingsToggle
>({
  key: 'soundSettingsOnAtom',
  get:
    (key) =>
    ({ get }) => {
      return (
        get(useNotificationAtom(NotificationType.SOUND)) &&
        get(
          notificationSettingsToggleAtom({
            action: key,
            type: NotificationType.SOUND,
          })
        )
      );
    },
});

export enum AdvancedSettingsToggleType {
  OPEN_ON_STARTUP = 'OPEN_ON_STARTUP',
  START_MINIMIZED = 'START_MINIMIZED',
}

export const advancedSettingsToggleAtom = atomFamily<
  boolean,
  // TODO: we haven't been able to import this in main.ts so we are manually
  // referencing the strings there. If these change you need to update main.ts + any
  // references to these in main/
  // We also don't use enums since recoil adds quotes to the key
  AdvancedSettingsToggleType
>({
  // DO NOT change this name unless you also change main/ files since this key is
  // used to index into the durable storage
  key: 'advancedSettingsToggleAtom',
  default: true,
  effects_UNSTABLE: (key) => [
    ({ onSet, setSelf, trigger }) => {
      const loadPersisted = async () => {
        const result = await window?.electron?.ipcRenderer?.getStoreValue(key);
        setSelf(
          result !== null && result !== undefined ? result : new DefaultValue()
        );
      };

      if (trigger === 'get') {
        loadPersisted();
      }

      onSet((newValue: any) => {
        if (newValue instanceof DefaultValue) {
          window?.electron?.ipcRenderer?.deleteStoreValue(key);
        } else {
          window?.electron?.ipcRenderer?.setStoreValue(key, newValue);
        }
      });
    },
  ],
});

export const bannerSettingsOnAtom = selectorFamily<
  boolean,
  NotificationSettingsToggle
>({
  key: 'bannerSettingsOnAtom',
  get:
    (key) =>
    ({ get }) => {
      return (
        !get(isWindowFocusedAtom) &&
        get(useNotificationAtom(NotificationType.BANNER)) &&
        get(
          notificationSettingsToggleAtom({
            action: key,
            type: NotificationType.BANNER,
          })
        )
      );
    },
});

export const avDeviceIdAtom = atomFamily<string, 'video' | 'audio' | 'output'>({
  key: 'avDeviceId',
  default: 'default', // explicitly set to default since that what enumerateDevices() returns
  effects_UNSTABLE: [persistAtom],
});

export const isMeetingDetectionEnabledAtom = atom<boolean>({
  key: 'isMeetingDetectionEnabled',
  default: true,
  effects_UNSTABLE: [persistAtom],
});
