import { doc, onSnapshot } from 'firebase/firestore';
import logger from 'js-logger';
import { useEffect } from 'react';
import { useFirestore } from 'reactfire';
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { nudgesAtom } from 'renderer/atoms/nudge';
import { Nudge } from 'renderer/models/Api';

export const FirestoreNudgeUpdates: React.FC = () => {
  const firestore = useFirestore();
  const { userId } = useRecoilValue(selfGlooUserAtom);
  const setNudges = useSetRecoilState(nudgesAtom);
  const resetNudges = useResetRecoilState(nudgesAtom);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, 'nudges-v2', userId),
      (docRes: any) => {
        const source = docRes.metadata.hasPendingWrites ? 'Local' : 'Server';
        logger.info(source, ' nudgeData: ', docRes.data());
        if (docRes.data()) {
          const nudgeData = docRes.data() as Nudge;
          setNudges(nudgeData);
        } else {
          resetNudges();
        }
      },
      (error: any) => {
        logger.info('Error subscribing to nudges', error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [firestore, resetNudges, setNudges, userId]);
  return <></>;
};
