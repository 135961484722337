import clsx from 'clsx';
import { ButtonProps, SimpleButton } from 'renderer/shared/SimpleButton';

export const OutlineButton: React.FC<ButtonProps> = ({
  children,
  clickHandler,
  disabled,
  enableChevron,
}) => {
  return (
    <SimpleButton
      className={clsx(
        'flex w-fit p-[6px] px-3 duration-300 transform border-2 rounded-md',
        [enableChevron && '!pr-0'],
        disabled
          ? 'bg-gray-2 text-gray-4 border-0'
          : 'border-blue-primary text-blue-primary hover:scale-105'
      )}
      clickHandler={clickHandler}
      disabled={disabled}
      enableChevron={enableChevron}
    >
      {children}
    </SimpleButton>
  );
};
