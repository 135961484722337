import { toBase64 } from 'js-base64';
import { CalendarAccount } from 'renderer/atoms/calendarEvents';
import { Room, Team } from 'renderer/models/Api';

export const openCreateCalendarUrl = ({
  linkedCalendarAccount,
  baseUrl,
  room,
  team,
  selfUserId,
}: {
  room: Room;
  team: Team;
  baseUrl: string;
  linkedCalendarAccount: CalendarAccount;
  selfUserId: string;
}): void => {
  const payload = {
    roomId: room.roomId,
    teamId: room.teamId,
  };
  const locationUrlParams = new URLSearchParams({
    event: toBase64(JSON.stringify(payload), true),
  });
  const locationUrl = `${baseUrl}/open?${locationUrlParams.toString()}`;
  const roomUserEmails = team.members
    .filter((m) => room.memberList.includes(m.userId))
    .filter((r) => r.userId !== selfUserId);

  // https://www.labnol.org/calendar/
  // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
  if (linkedCalendarAccount?.provider === 'google') {
    const calendarUrl = 'https://calendar.google.com/calendar/render';

    const calendarUrlParams = new URLSearchParams({
      action: 'TEMPLATE',
      location: locationUrl,
      // title
      text: `${room.name} Event`,
      add: roomUserEmails.map((u) => u.profile.email).join(','),
    });
    window.electron?.ipcRenderer.openUrl(`${calendarUrl}?${calendarUrlParams}`);
  } else {
    // https://interactiondesignfoundation.github.io/add-event-to-calendar-docs/services/outlook-web.html
    const calendarUrl = 'https://outlook.live.com/calendar/0/deeplink/compose';

    const startTime = new Date();
    startTime.setMinutes(
      startTime.getMinutes() - (startTime.getMinutes() % 15)
    );
    const endTime = new Date(startTime);
    endTime.setMinutes(endTime.getMinutes() + 30);

    const calendarUrlParams = new URLSearchParams({
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt: startTime.toISOString(),
      enddt: endTime.toISOString(),
      subject: `${room.name} Event`,
      location: locationUrl,
      to: roomUserEmails.map((u) => u.profile.email).join(','),
    });
    window.electron?.ipcRenderer.openUrl(`${calendarUrl}?${calendarUrlParams}`);
  }
};
