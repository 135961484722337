/* eslint-disable max-len */
import {WebsiteLogin} from "components/auth/WebsiteLogin";
import {signOut} from "firebase/auth";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {useAuth, useUser} from "reactfire";
import {trpc} from "renderer/common/client/trpc";
import {requireAuth} from "renderer/common/components/hoc/requireAuth";
import {Team} from "renderer/models/Api";
import GlooIcon from "resources/icons/gloo-icon.svg";
import {AltLoginText} from "../../components/auth/AltLoginText";

const SlackConnect: React.FC = () => {
  const teamId = useParams<"teamId">().teamId || "";

  const [errMsg, setErrMsg] = useState<string | null>(null);

  const onError = (error: string) => {
    setErrMsg(error);
  };

  const {data: team, isLoading} = trpc.useQuery(["teams.get", {teamId}], {
    onError: (err) => onError(err.message),
  });

  if (isLoading) {
    return <><ClipLoader /></>;
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      {errMsg && <div className="text-base italic font-bold text-red-900">
        {errMsg}
      </div>}
      {team &&
      <InviteContents errHandler={onError} team={team} teamId={teamId} />}
    </div>);
};

const InviteContents = ({team, teamId, errHandler}: { team: Team, teamId: string; errHandler: (err: string) => void }) => {
  const user = useUser();
  return (
    <div className="flex flex-col w-full h-[100%] justify-center items-center">
      <div className="flex flex-col items-center w-full pt-6 bg-blue-lightest-2/50">
        <div className="flex flex-row items-center pb-8 space-x-2">
          <img src={GlooIcon} className="w-12 h-12" />
          <div className="text-3xl font-bold text-black-lighter">Gloo</div>
        </div>
        <div className="space-x-3 text-5xl font-semibold text-black-primary/85 px-15">
          {/* Start talking in <b className="text-blue-900 capitalize">{invite.name}</b> */}
          Connect <b className="text-blue-900 capitalize">{team.name}</b> to Slack
        </div>
      </div>

      <div className="pt-10 pb-6 space-y-3">
        {user.data ? <AuthSectionWrapper errHandler={errHandler} teamId={teamId} /> :
        (
        <>
          <div className="text-base font-medium text-black-lighter">We suggest using the email account you use for work</div>
          <WebsiteLogin showTitle={false} />
        </>
)}
      </div>
    </div>
  );
};

const AuthSectionWrapper = ({teamId, errHandler}: {teamId: string; errHandler: (err: string) => void }) => {
  const [isWaiting, setWaiting] = useState(true);

  // wait so that the parent components re-render and the
  // trpc query client gets updated with the client
  // token. If we don't wait then the child
  // will re-render first it seems..
  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 20);
  }, []);

  if (isWaiting) {
    return <ClipLoader />;
  }
  return <AuthSection errHandler={errHandler} teamId={teamId} />;
};


// const ConnectWithSlackButton: React.FC<{teamId: string}> = ({teamId}) => {
//   const mutationReq = trpc.useMutation("teams.integrationUrl");

//   // [
//   //   "teams.integrationUrl",
//   //   {type: "SLACK", teamId},
//   // ]);


//   if (isLoading) {
//     return <ClipLoader />;
//   }


//   if (!slackUrl) return null;

//   return (
//     <>
//       <button
//         type="button"
//         onClick={() =>
//           window.electron?.ipcRenderer.openUrl(slackUrl) ??
//           window.open(slackUrl, "_blank")
//         }
//         className="items-center bg-white-1 text-black-primary border border-[#ddd] rounded-[4px] inline-flex text-lg justify-center w-[236px]"
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           className="w-5 h-5 mr-3"
//           viewBox="0 0 122.8 122.8"
//         >
//           <path
//             d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
//             fill="#e01e5a"
//           />
//           <path
//             d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
//             fill="#36c5f0"
//           />
//           <path
//             d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
//             fill="#2eb67d"
//           />
//           <path
//             d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
//             fill="#ecb22e"
//           />
//         </svg>
//         Add to Slack
//       </button>
//     </>
//   );
// };


const AuthSection = ({teamId, errHandler}: {teamId: string; errHandler: (err: string) => void }) => {
  const auth = useAuth();

  return (
    <>
      {/* <ConnectWithSlackButton teamId={teamId} /> */}
      <AltLoginText clickHandler={async () => {
        await signOut(auth);
        window.location.reload();
      }} />
    </>);
};


export default requireAuth(SlackConnect);
