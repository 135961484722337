/* eslint-disable max-len */
import React, {Fragment, useContext, useEffect, useState} from "react";
import {
  Navigate,
  NavigateProps,
  RouteObject,
  useLocation,
  useRoutes,
} from "react-router-dom";
import SignInSuccessLanding from "./components/auth/SignInSuccessLanding";
import InviteView from "./pages/public/InviteView";
import MainLayout from "./pages/public/MainLayout";
// import logo from "./logo.svg";
import useGlobalMonitors from "renderer/common/hooks/useMonitors";
import DiamondPattern from "resources/diamondpattern.png";
import {ChildProps} from "types/react";
import PageNotFoundView from "./pages/public/PageNotFoundView";
import Dashboard from "./renderer/pages/dashboard";
import {WebsiteLoginWrapper} from "components/auth/WebsiteLogin";
import {DownloadWrapper} from "pages/public/Download";
import {Popover, Transition} from "@headlessui/react";
import {AppInfoContext} from "renderer/providers/AppInfoProvider";
import SettingsContainer from "renderer/pages/settings/SettingsContainer";
import SlackConnect from "pages/public/SlackConnect";
import OauthStart from "components/auth/oauth/OauthStart";
import {Open} from "pages/public/Open";
import AcceptView from "pages/public/AcceptView";
import OauthCallback from "components/auth/oauth/OauthCallback";


declare global {
  // const google: typeof import("google-one-tap");
  interface Window {
    google: typeof import("google-one-tap") & {
      accounts: {
        oauth2: any;
      };
    };
  }
}


const AppContainer: React.FC<ChildProps> = ({children}) => {
  const {deployment} = useContext(AppInfoContext);

  return (
    <div
      className="flex flex-col w-full h-screen text-center shadow-sm drag"
      style={{
        backgroundImage: `url(${DiamondPattern})`,
        backgroundSize: 330,
      }}
    >

      <div className="flex flex-col w-full h-full bg-white-1/50">
        {["dev", "emulator"].includes(deployment) && (
          <div className="z-50 w-full text-base text-center uppercase bg-gray-500/40">
          CONNECTED TO {deployment} SERVER
          </div>
        )}
        <div className="flex flex-col flex-wrap w-full h-full">{children}</div>
      </div>
    </div>
  );
};


const UnkownRouteHandler = ({to}: NavigateProps) => {
  const prevRoute = useLocation();
  console.log(prevRoute);
  return <Navigate to={to} replace state={{page: prevRoute.pathname}} />;
};

const App: React.FC = (): JSX.Element => {
  useGlobalMonitors();

  const mainRoutes: RouteObject = {
    path: "/",
    element: <MainLayout />,
    children: [
      {path: "*", element: <UnkownRouteHandler to="/404" />},
      {path: "/", element: <Navigate to="/download" />},
      {path: "404", element: <PageNotFoundView />},
      {path: "slack/connect/:teamId", element: <SlackConnect />},
      {path: "join/:inviteId", element: <InviteView />},
      {path: "accept/:teamId/:userId", element: <AcceptView />},
      {path: "account", element: <Navigate to="/account/dashboard" />},
      {path: "oauth/", element: <OauthStart />},
      {path: "auth/signup", element: <WebsiteLoginWrapper />},
      {path: "auth/callback", element: <OauthCallback />},
      {path: "open", element: <Open />},
      {path: "launch", element: <SignInSuccessLanding />},
      {path: "download", element: <DownloadWrapper />},
      {path: "dashboard", element: <div className="flex flex-col w-full h-full">
        <DownloadMessage />
        <Dashboard />
      </div>},
      {path: "login", element: <Navigate to="/auth/signup" />},
      {path: "dashboard/settings", element: <SettingsContainer />},
    ],
  };

  const routing = useRoutes([mainRoutes]);

  return (<>
    <AppWrapper>
      {routing}
    </AppWrapper>
  </>
  );
};


const AppWrapper: React.FC<ChildProps> = ({children}) => {
  return (<>
    <NoInternetConnection>
      <AppContainer>
        {children}
      </AppContainer>
    </NoInternetConnection>
  </>);
};

const DownloadMessage = () => {
  return <div className="w-full text-xl font-semibold text-center bg-white-1">
    <span className="text-red-700">Gloo for web is deprecated</span>.{" "}
    <a className="underline hover:text-black-lightest" target="_blank" rel="noopener noreferrer" href="/launch">Download the desktop version</a> for the&nbsp;
    <Popover className="relative inline">
      <Popover.Button className="underline hover:text-black-lightest">
        full experience!
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 inline p-4 overflow-hidden text-sm text-left whitespace-pre-line border border-gray-200 shadow-md bg-white-1 -bottom-90 -left-0 w-[320px] rounded-xl">
          <>
            <div className="flex items-center justify-center w-full text-center">
              <div className="flex pb-2 font-bold text-center text-black-lightest">Desktop-only features</div>
            </div>

            <ul className="px-2 text-base list-disc">
              <li>
                <span className="font-bold">Better presence indicators:</span> Go offline automatically when you&apos;re away or join a meeting (e.g. in Zoom, Teams, Slack), so people never mistakenly interrupt you. On Web you&apos;ll need to manage your own status.
              </li>
              <li>
                <span className="font-bold">Gloo mini-view:</span> A notification panel that shows when you join a conversation, so you never have to open the app or search for the browser window.
              </li>
              <li>
                <span className="font-bold">Automatically pause music </span>
              when you join a conversation (Coming soon!)
              </li>
              <li>
              And many more improvements under the hood!
              </li>
            </ul>
          </>
        </Popover.Panel>
      </Transition>
    </Popover>
  </div>;
};

const NoInternetConnection: React.FC<ChildProps> = ({children}) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
    // event listeners to update the state
    window.addEventListener("online", () => {
      setOnline(true);
    });

    window.addEventListener("offline", () => {
      setOnline(false);
    });
  }, []);

  // if user is online, return the child component else return a custom component
  // if (isOnline) {
  return <>{children}</>;
  // }
  // return <h1>No Interner Connection. Please try again later.</h1>;
};

export default App;
