import { addHours, addMinutes, isWithinInterval } from 'date-fns';
import events from 'events';

export type UserState = 'unknown' | 'idle' | 'active' | 'locked';

export type DeploymentType = 'emulator' | 'dev' | 'prod';

export interface AppInfo {
  machineId: string;
  deployment: DeploymentType;
  os?:
    | 'aix'
    | 'android'
    | 'darwin'
    | 'freebsd'
    | 'haiku'
    | 'linux'
    | 'openbsd'
    | 'sunos'
    | 'win32'
    | 'cygwin'
    | 'netbsd';
  localLogin?: {
    username: string;
    password: string;
  };
  appVersion: string;
}

export interface UpdateInfo {
  version: string;
  downloaded: boolean;
}

export interface AppConfig {
  // render window on top of others
  popUpConfig: PopUpConfig;
  windowConfig: WindowConfig;
}

interface PopUpConfig {
  popUpRestrictions: {
    allowedTimeOfDay: {
      startHour: number;
      endHour: number;
    };
    // Sunday starts on 0
    allowedDaysOfWeek: number[];
  };
  surveyPollingIntervalMillis: number;
  popUpCheckIntervalMillis: number;
}

interface WindowConfig {
  initialRenderOnTop: boolean;
  // user cannot move it behind other windows.
  forceWindowOnTop: boolean;
  movable: boolean;

  // only takes effect if window is not movable
  dimBackground: boolean;
}

export interface DesktopInputSource {
  id: string;
  name: string;
  appIconUrl?: string;
  thumbnailUrl?: string;
}

export interface TeamUser {
  photoUrl?: string;
  name?: string;
  status: {
    online: boolean;
    busy: boolean;
  };
  userId: string;
}

export interface TeamUserInConvo extends TeamUser {
  devices: {
    mic: boolean;
    screen: boolean;
    camera: boolean;
  };
  isSpeaking: boolean;
}

export interface SelfUser extends TeamUserInConvo {
  activeButtonRoomIds: {
    mic?: string;
    screenshare?: string;
    video?: string;
  };
}

interface CurrentUserState {
  selfUser: SelfUser;
  online: boolean;
  selectedRoomId: string;
  windowActive: boolean;
}

export interface NotificationPanelState {
  current: CurrentUserState;
  room: RoomNotificationPanelState[];
}

interface RoomNotificationPanelState {
  meta: {
    roomId: string;
    teamId: string;
    teamName: string;
    name: string;
  };
  activeConvo?: ActiveConversationState;
  ambientConvos: ConversationState[];
  incomingNudges: NudgeState[];
}

export interface NudgeState {
  sender: TeamUser;
  sentTime: number;
  content?: string;
  snoozed: boolean;
}

// Metadata about the event.
interface EventState {
  eventId: string;
  title: string;
  instance: Interval;
  minsUntil: number;
}

interface ConversationState {
  convoId: string;
  activeUsers: TeamUserInConvo[];
  lastLeftTime: Date;
  // If the conversation is an event, include event metadata.
  event?: EventState;
}

// The conversation a logged in user is a part of.
interface ActiveConversationState extends ConversationState {
  leavingConvoTime: number;
  ambientUsers: number;
}

export const relevantNotificationEvents = (convos: ConversationState[]) => {
  return convos.filter(
    ({ event, lastLeftTime, activeUsers }) =>
      event &&
      (activeUsers.length > 0 ||
        !isWithinInterval(lastLeftTime, {
          start: event.instance.start,
          end: addHours(event.instance.end, 1),
        }))
  );
};

export const relevantNotificationNudges = (nudges: NudgeState[]) => {
  return nudges.filter((n) => n.sender.status.online && !n.sender.status.busy);
};

export const showNotification = (
  {
    activeConvo,
    incomingNudges,
    ambientConvos,
    meta,
  }: RoomNotificationPanelState,
  self: CurrentUserState,
  externalWindow: boolean
):
  | { show: false }
  | {
      show: true;
      nudges: boolean;
      convo: boolean;
      events: boolean;
      height: number;
    } => {
  const showConversation =
    self.online &&
    !!(
      activeConvo &&
      (externalWindow
        ? !self.windowActive
        : self.windowActive && meta.roomId !== self.selectedRoomId)
    );
  const nudgesToShow = relevantNotificationNudges(incomingNudges).length;

  const ambientEventsToShow = relevantNotificationEvents(ambientConvos).length;

  const showNudges =
    self.online &&
    (externalWindow ? !self.windowActive : self.windowActive) &&
    nudgesToShow > 0;
  const showEvents =
    (externalWindow
      ? !self.windowActive
      : self.windowActive && meta.roomId !== self.selectedRoomId) &&
    ambientEventsToShow > 0;
  if (showConversation || showNudges || showEvents) {
    return {
      show: true,
      nudges: showNudges,
      convo: showConversation,
      events: showEvents,
      height:
        // room container padding top and bottom combined
        8 +
        // room title
        15 +
        // subnotification padding top and bottom
        8 +
        // Subnotification title bar
        22 +
        // Padding between title bar and content
        4 +
        // 16 pixel gap if both convo and nudge are showing
        Math.max(
          0,
          (showNudges ? 1 : 0) +
            (showConversation ? 1 : 0) +
            (showEvents ? 1 : 0) -
            1
        ) *
          16 +
        (showNudges
          ? 43.5 * nudgesToShow + Math.max(0, nudgesToShow - 1) * 4
          : 0) +
        (showEvents
          ? 64 * ambientEventsToShow +
            Math.max(0, ambientEventsToShow - 1) * 4 +
            ambientEventsToShow * 16.5
          : 0) +
        (showConversation ? 64 + (activeConvo.event ? 16.5 : 0) : 0),
    };
  }

  return { show: false };
};

export type ButtonStateRequest = {
  roomId: string;
  teamId: string;
} & (
  | {
      button: 'MIC' | 'SCREENSHARE' | 'VIDEO';
      enabled: boolean;
    }
  | {
      button: 'EXIT_CONVO' | 'EXPAND';
    }
  | {
      button: 'START_CONVO' | 'EXPAND';
      userId: string;
    }
  | {
      // joins existing convo
      button: 'JOIN_CONVO';
      convoId: string;
    }
);

export interface IMusicTrack {
  mode: 'Spotify' | 'Music';
  artist: string;
  title: string;
  album: string;
  albumArt?: string;
  playing: boolean;
}

export interface RTScreenShareInterface {
  [convoId: string]: {
    // Every convo could technically have multiple users screensharing.
    [sharingUserId: string]: {
      [userId: string]: RTScreenShareUserInterface;
    };
  };
}

export interface RTScreenShareUserInterface {
  lastUpdated: number;
  active: boolean;
  mouse: {
    x: number;
    y: number;
  };
}

export interface ScreenUsers extends RTScreenShareUserInterface {
  userId: string;
  name: string;
}
