import { TRPCClient } from '@trpc/client';
import { useContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useUser } from 'reactfire';
import { atom, selector, useRecoilState } from 'recoil';
import { useDeploymentConfig } from 'renderer/hooks/useDeploymentConfig';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';
import { Spinner } from 'renderer/shared/Spinner';
import { AppRouter } from 'types/appRouter';
import { ChildProps } from 'types/react';
import { trpc } from '../client/trpc';

const trpcAtomImpl = atom<TRPCClient<AppRouter> | null>({
  key: 'trpcImpl',
  default: null,
});

export const trpcAtom = selector({
  key: 'trpc',
  get: ({ get }) => {
    const ret = get(trpcAtomImpl);
    if (!ret) throw new Error('TRPC not configured');
    return ret;
  },
});

export const ReactQuery: React.FC<ChildProps> = ({ children }) => {
  // const token = useAuthToken();
  const { appVersion, machineId } = useContext(AppInfoContext);
  const user = useUser();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // TODO: set something here
            retry: 0,
          },
          mutations: {
            retry: 0,
          },
        },
      })
  );

  const { functionUrl } = useDeploymentConfig();
  const [trpcClient, setTrpcClient] = useRecoilState(trpcAtomImpl);

  useEffect(() => {
    setTrpcClient(
      trpc.createClient({
        url: functionUrl,
        headers: async () => {
          const headers = {
            'X-Gloo-MachineTime': new Date().getTime().toString(),
            'X-Gloo-AppVersion': appVersion,
            'X-Gloo-MachineId': machineId,
          };
          const token = await user?.data?.getIdToken();
          return token
            ? { Authorization: `Bearer ${token}`, ...headers }
            : headers;
        },
      })
    );
  }, [setTrpcClient, user.data, functionUrl, appVersion, machineId]);

  if (!trpcClient) return <Spinner />;

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
