import { memo, Suspense, useEffect, useMemo, useState } from 'react';
import { MdRemove, MdAdd, MdArrowDropDown, MdArrowRight } from 'react-icons/md';
import { SyncLoader } from 'react-spinners';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { roomAtom, roomListAtom } from 'renderer/atoms/room';
import { selectedTeamKeyAtom, teamAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { RoomKey, TeamKey } from 'renderer/models/Keys';
import { ToggleIconButton } from 'renderer/shared/Buttons/helpers/ToggleIconButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { RoomSearchPreview } from 'renderer/shared/Modals/team/BrowseOrCreateRoomModal/RoomSearchPreview';
import { SubtitleButton } from 'renderer/shared/SimpleButton';

interface TeamRooms {
  myRooms: string[];
  otherRooms: string[];
}

const MyRoomPreview: React.FC<RoomKey> = memo(({ roomId }) => {
  const room = useRecoilValue(roomAtom(roomId));

  return <RoomSearchPreview room={room} isJoined />;
});

const OtherRoomsPreviewInner: React.FC<TeamKey & { roomList: string[] }> = ({
  teamId,
  roomList,
}) => {
  const { data, error, refetch } = trpc.useQuery(
    [
      'teams.rooms',
      {
        teamId,
      },
    ],
    {
      suspense: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: () => {
        console.log('ERROR');
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [roomList]);

  if (error) {
    return <>Some error: {error}</>;
  }

  if (data === undefined) {
    return <>Unexpected scenario.</>;
  }

  return (
    <>
      {data
        .filter((r) => roomList.includes(r.roomId))
        .map((r) => (
          <RoomSearchPreview room={r} key={r.roomId} isJoined={false} />
        ))}
    </>
  );
};

const OtherRoomsPreview: React.FC<TeamKey & { roomList: string[] }> = ({
  teamId,
  roomList,
}) => {
  const [show, setShow] = useState(true);
  return (
    <div>
      <div className="flex w-full pl-1 text-sm text-left text-blue-primary">
        <ToggleIconButton
          handleClick={() => setShow((prev) => !prev)}
          isActive={show}
          active={{ children: <MdAdd /> }}
          inactive={{ children: <MdRemove /> }}
          className="w-4 h-4"
        />
        <div className="text-sm font-medium ">Available rooms</div>
      </div>
      {!show && <div className="w-full border-t-[1px] border-gray-4/10" />}
      {show && (
        <Suspense fallback={<SyncLoader />}>
          <OtherRoomsPreviewInner teamId={teamId} roomList={roomList} />
        </Suspense>
      )}
    </div>
  );
};

export const CreateRoomButton = () => {
  const setSelectedModal = useSetRecoilState(selectedModalAtom);
  return (
    <SubtitleButton
      clickHandler={() => setSelectedModal(ModalTypes.kCreateRoom)}
    >
      <MdAdd />
      <span>Create a room</span>
    </SubtitleButton>
  );
};

const JoinedRoomsPreview: React.FC<{ roomList: string[] }> = ({ roomList }) => {
  const [show, setShow] = useState(true);
  return (
    <div className="flex flex-col">
      <div className="flex items-end justify-between w-full pl-1 text-sm text-left">
        <div className="text-sm font-medium text-blue-primary whitespace-nowrap">
          <ToggleIconButton
            handleClick={() => setShow((prev) => !prev)}
            isActive={show}
            active={{ children: <MdArrowDropDown /> }}
            inactive={{ children: <MdArrowRight /> }}
            className="w-4 h-4"
          />
          {roomList.length} joined rooms
        </div>
      </div>
      {!show && <div className="w-full border-t-[1px] border-gray-4/10" />}
      {show && roomList.map((r) => <MyRoomPreview roomId={r} key={r} />)}
    </div>
  );
};

const RoomsOverview: React.FC = () => {
  const selectedTeamKey = useRecoilValue(selectedTeamKeyAtom);
  const team = useRecoilValue(teamAtom(selectedTeamKey));
  const allMyRooms = useRecoilValue(roomListAtom);
  const teamRooms = useMemo(
    () =>
      team.roomList.reduce(
        (prev: TeamRooms, r) => {
          prev[allMyRooms.includes(r) ? 'myRooms' : 'otherRooms'].push(r);
          return prev;
        },
        { myRooms: [], otherRooms: [] }
      ),
    [team.roomList, allMyRooms]
  );

  return (
    <div className="flex flex-col w-full h-full gap-4 pt-5 pl-5 overflow-x-hidden overflow-y-scroll">
      <JoinedRoomsPreview roomList={teamRooms.myRooms} />
      {teamRooms.otherRooms.length > 0 && (
        <OtherRoomsPreview
          teamId={selectedTeamKey}
          roomList={teamRooms.otherRooms}
        />
      )}
    </div>
  );
};

export default RoomsOverview;
