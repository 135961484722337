import {Center} from "components/Center";
import React from "react";
import {useLocation} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {trpc} from "renderer/common/client/trpc";

const OauthStart = () => {
  const {search} = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  // TODO: get the provider from params
  const provider = params.get("provider") as "google" | "microsoft"; ;
  const oauthUrl = trpc.useQuery(["oauth.url", {idToken: "", provider: provider}], {
    retry: false,
    onSuccess: (data) => {
      localStorage.setItem("userId", params.get("userId")!);
      localStorage.setItem("provider", provider);
      console.log("redirecting to", data.url);
      window.location.replace(data.url);
    },
  });
  if (params.get("userId") === null) {
    throw new Error("Invalid url, userId missing");
  }

  return (
    <Center >
      <ClipLoader color="gray" />
    </Center>
  );
};

export default OauthStart;

