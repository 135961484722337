import { IAgoraRTCClient, ILocalTrack } from 'agora-rtc-sdk-ng';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  atomFamily,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { agoraClientAtom } from 'renderer/atoms/call';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { roomUserAtom } from 'renderer/atoms/room';
import LogCreator, { logger, LoggerNames } from 'renderer/common/LogCreator';
import {
  isStreamActiveFirebase,
  RoomStreamKey,
  StreamType,
} from '../../atoms/CallStateAtoms';
import { videoCamActiveAtom } from '../../atoms/videoCameraAtoms';
import { localMicActiveAtom } from '../../MicrophoneProvider';
import { selfScreenshareActiveAtom } from '../../ScreenShareProvider';

// Should the current user publish a track to the room.
export const shouldPublishToRoom = selectorFamily<boolean, RoomStreamKey>({
  key: 'shouldPublishToRoom',
  get:
    ({ roomId, stream }) =>
    ({ get }) => {
      const { userId } = get(selfGlooUserAtom);
      const isActive = get(isStreamActiveFirebase({ roomId, userId, stream }));
      // If firebase says no, we should never publish.
      if (!isActive) return false;

      if (stream === StreamType.MIC) {
        return get(localMicActiveAtom({ roomId }));
      }

      if (stream === StreamType.VIDEO_CAM) {
        return get(videoCamActiveAtom({ roomId }));
      }

      // For screenshare.
      if (!get(selfScreenshareActiveAtom({ roomId }))) return false;

      const roomUser = get(roomUserAtom({ roomId, userId }));
      logger.info('shouldPublish', { roomId, stream, roomUser, isActive });
      return roomUser.convo.active;
    },
});

export const trackIsPublishedAtom = atomFamily<boolean, RoomStreamKey>({
  key: 'trackPublished',
  default: false,
});

const UnPublishOnExit: React.FC<
  RoomStreamKey & { track: ILocalTrack; agoraClient: IAgoraRTCClient }
> = ({ roomId, stream, track, agoraClient }) => {
  const setPublished = useSetRecoilState(
    trackIsPublishedAtom({ roomId, stream })
  );

  useEffect(() => {
    setPublished(true);
    return () => {
      const target = stream === StreamType.MIC ? 'audio' : 'video';
      const logName = {
        MIC: LoggerNames.MIC,
        SCREENSHARE: LoggerNames.SCREENSHARE,
        VIDEO_CAM: LoggerNames.VIDEO,
      }[stream];
      const dlogger = LogCreator(logName);
      setPublished(false);
      dlogger.info(`Attempting to unpublish ${target}: ${roomId} ${stream}`);
      agoraClient
        .unpublish(track)
        .catch((reason) =>
          dlogger.info(`Failed to unpublish ${target}: ${roomId} ${reason}`)
        );
    };
  }, []);

  return <></>;
};

export const WaitUntilPublished: React.FC<
  RoomStreamKey & { track: ILocalTrack }
> = ({ roomId, stream, track }) => {
  const target = stream === StreamType.MIC ? 'audio' : 'video';

  const logName = {
    MIC: LoggerNames.MIC,
    SCREENSHARE: LoggerNames.SCREENSHARE,
    VIDEO_CAM: LoggerNames.VIDEO,
  }[stream];
  const dlogger = LogCreator(logName);
  const client = useRecoilValue(
    agoraClientAtom({ roomId, isCameraStream: stream === StreamType.VIDEO_CAM })
  );
  const { isSuccess } = useQuery({
    queryKey: `${roomId}-${target}`,
    queryFn: async () => {
      dlogger.info('Attempting to publish', { roomId, stream });
      return client.publish(track);
    },
    retry: true,
    suspense: true,
    refetchOnWindowFocus: false,
  });

  if (!isSuccess) return null;

  return (
    <UnPublishOnExit
      roomId={roomId}
      stream={stream}
      track={track}
      agoraClient={client}
    />
  );
};
