import { Placement } from '@popperjs/core';
import { ReactNode, useId, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Tooltip } from 'renderer/shared/Tooltip';

type IconButtonProps = {
  clickHandler?: () => void;
  toolTip?: string;
  toolTipPlacement?: Placement;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
};

const ButtonImpl: React.FC<IconButtonProps> = ({
  clickHandler,
  children,
  disabled,
  className,
}) => {
  return (
    <button
      type="button"
      className={
        className ||
        'flex items-center justify-center rounded-md no-drag hover:bg-neutral-2 active:bg-gray-300 disabled:text-gray-400 disabled:hover:bg-transparent'
      }
      disabled={disabled}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
};

export const TooltipOld = ({
  message,
  children,
}: {
  message?: string;
  children: React.ReactNode;
}) => {
  const id = useId();
  const [tooltip, showTooltip] = useState(true);

  return (
    // wrapper div fixes some alignment issues on the tooltip not sure why
    <div>
      <div
        data-tip
        data-for={id}
        // react 18 doesnt work super well with this library so we have to manually
        // hack around to make this work. https://stackoverflow.com/questions/71626891/react-tooltip-doesnt-close-when-mouse-leave
        onMouseEnter={() => showTooltip(true)}
        onMouseLeave={() => {
          showTooltip(false);
          setTimeout(() => showTooltip(true), 50);
        }}
      >
        {children}
      </div>
      {message && tooltip && (
        <ReactTooltip
          id={id}
          type="dark"
          effect="solid"
          className="!px-2 !py-1 z-20"
          scrollHide={false}
        >
          <span>{message}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

export const IconButton: React.FC<IconButtonProps> = ({
  clickHandler,
  children,
  toolTip,
  disabled = false,
  className,
  toolTipPlacement,
}) => {
  if (toolTip) {
    return (
      <Tooltip message={toolTip} placement={toolTipPlacement}>
        <ButtonImpl
          clickHandler={clickHandler}
          disabled={disabled}
          className={className}
        >
          {children}
        </ButtonImpl>
      </Tooltip>
    );
  }

  return (
    <ButtonImpl
      disabled={disabled}
      clickHandler={clickHandler}
      className={className}
    >
      {children}
    </ButtonImpl>
  );
};
