import {useAuth, useStorage} from "reactfire";
import {AuthErrorCodes, OAuthProvider, signInWithPopup, updateProfile} from "firebase/auth";
import {SignInWithMicrosoftIcon} from "./SignInWithMicrosoftIcon";
import {useEffect, useState} from "react";
import {FirebaseError} from "@firebase/util";
import {Client} from "@microsoft/microsoft-graph-client";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {ClipLoader} from "react-spinners";

const microsoftProvider = new OAuthProvider("microsoft.com").setCustomParameters({
  // login_hint: "user@organization.com",
  // tenant: "f4a8766f-e4b1-4bc7-b4ca-061d5e5b2fc1", // Put Tenant Id from Azure registered app,
  prompt: "select_account", // Get Consent from user to access their basic info (optional - Reommended only during SignUp)
});


export const MicrosoftLogin = ({authInProgress, setAuthInProgressFn}:
  {authInProgress: boolean; setAuthInProgressFn: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const auth = useAuth();
  const storage = useStorage();

  const [error, setError] = useState<null | string>();
  const [photoUrlLoading, setPhotoUrlLoading] = useState(false);

  // do this in a useeffect since doing it in the button handler causes some
  // incomplete state for the fb auth objects etc.
  // It'd be easier to just call one of our APIs to do alot of this but there's a bug with the trpc client
  // where it won't add the auth token unless you refresh the whole page.
  useEffect(() => {
    const fn = async () => {
      if (!authInProgress) {
        return;
      }
      try {
        const result = await signInWithPopup(auth, microsoftProvider);
        const credential = OAuthProvider.credentialFromResult(result);
        if (!credential?.accessToken) {
          console.error("No access token found");
          return;
        }
        setPhotoUrlLoading(true);
        const client = Client.init({
          authProvider: (done) => {
            done(null, credential?.accessToken!);
          },
        });

        try {
          console.log("fb auth curr user", auth.currentUser);
          const [photoBlob, userDetails] = await Promise.all([
            await client.api("/me/photo/$value").get(), // this will error if no photo available
            await client.api("/me").get()]);
          console.log("userdetails", {userDetails});
          const userInfo = userDetails as MicrosoftGraph.User;
          const fileref = ref(storage, "profile-photos/" + auth.currentUser?.uid);
          await uploadBytes(fileref, photoBlob);
          const url = await getDownloadURL(fileref);
          console.log("photourl", url);
          await updateProfile(auth.currentUser!, {
            photoURL: url,
            displayName: userInfo.displayName,
          });
        } catch (error) {
          console.log("Error getting user profile: ", error);
        }
      } catch (error: any) {
        console.error(error);
        if (error instanceof FirebaseError) {
          if (!(error.code === AuthErrorCodes.USER_CANCELLED ||
           error.code === AuthErrorCodes.EXPIRED_POPUP_REQUEST || error.code === AuthErrorCodes.POPUP_CLOSED_BY_USER)) {
            setError("Error signing in: error.code");
          }
        } else {
          setError(JSON.stringify(error));
        }
      } finally {
        setAuthInProgressFn(false);
        setPhotoUrlLoading(false);
      }
    };
    fn();
    // other deps left out on purpose to not trigger this effect more than once after
    // auth is triggered.
  }, [authInProgress, setAuthInProgressFn]);

  if (photoUrlLoading) {
    return <ClipLoader color="gray" size={16} />;
  }

  return (
    <>
      <button className="p-0 mt-5 btn btn-dark" onClick={ async () => {
        setAuthInProgressFn(true);
      }

      }>
        {/* <img className="rounded" alt="Ms Auth Btn"/> */}
        <SignInWithMicrosoftIcon />
      </button>
      {error ? (
        <div className="text-sm font-light text-red-600">{error}</div>
      ) : null}
    </>
  );
};
