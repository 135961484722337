import { FeedbackButton } from 'renderer/shared/icons/FeedbackButton';
import { RoomListPanel } from './RoomListPanel';
import { StatusPanel } from './StatusPanel';

export const SidePanel = () => {
  return (
    <div className="flex flex-col justify-between max-w-[200px] lg:max-w-[250px] flex-shrink-0 h-full border-r-[1px] bg-neutral-2 gap-5">
      <RoomListPanel />
      {/* Status panel at the bottom */}
      <div className="flex flex-col px-2">
        <StatusPanel />
        <div className="flex justify-center w-full">
          <FeedbackButton />
        </div>
      </div>
    </div>
  );
};
