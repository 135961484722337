import {
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng';
import { atom, selector, selectorFamily } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { agoraSubscribedTrackAtom, StreamType } from './CallStateAtoms';

export const localVideoCamTrackAtom = atom<{
  video: ICameraVideoTrack;
  audio?: ILocalAudioTrack;
  roomId: string;
  // Changing table closes screenshare.
  convoId: string;
} | null>({
  key: 'localVideoTrackAtom',
  default: null,
  // So we can close the track.
  dangerouslyAllowMutability: true,
});

export const videoCamActiveAtom = selectorFamily<boolean, { roomId: string }>({
  key: 'videoCamActiveAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      return get(localVideoCamTrackAtom)?.roomId === roomId;
    },
});

export const videoCamActiveRoomIdAtom = selector<string | undefined>({
  key: 'videoCamActiveRoomIdAtom',
  get: ({ get }) => {
    return get(localVideoCamTrackAtom)?.roomId;
  },
});

export const videoCamActiveForUserAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'videoCamActiveForUserAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const { userId: selfUserId } = get(selfGlooUserAtom);
      if (selfUserId === userId) {
        return get(videoCamActiveAtom({ roomId }));
      }

      return (
        get(
          agoraSubscribedTrackAtom({
            userId,
            roomId,
            stream: StreamType.VIDEO_CAM,
          })
        ) !== null
      );
    },
});

export const getVideoCamTrackAtom = selector<{
  video: ILocalVideoTrack;
  audio?: ILocalAudioTrack;
  roomId: string;
}>({
  key: 'getVideoTrackAtom',
  get: ({ get }) => {
    const track = get(localVideoCamTrackAtom);
    if (!track) throw new Error('No Track');
    return track;
  },
  dangerouslyAllowMutability: true,
});
