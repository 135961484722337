import { useDateTimeMonitor } from './useDateTimeMonitor';
import { useFirebaseUserMonitor } from './usefirebaseUser';
import { useMusicMonitor } from './useMusicMonitor';
import { useUserInputActiveMonitor } from './useUserInputActiveMonitor';

const useGlobalMonitors = () => {
  useDateTimeMonitor();
  useUserInputActiveMonitor();
  useFirebaseUserMonitor();
  useMusicMonitor();
};

export default useGlobalMonitors;
