import { ChildProps } from 'types/react';

// A roomnotification has child notifications of different types
export const ChildNotifContainer: React.FC<ChildProps> = ({ children }) => {
  return (
    <div className="relative flex flex-col w-full p-1 rounded-md bg-off-black text-gray-blue-1">
      {children}
    </div>
  );
};
