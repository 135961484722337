import { Suspense, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { selectedRoomKeyAtom, useRoomAtomSetters } from 'renderer/atoms/room';
import { selectedTeamKeyAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { Room } from 'renderer/models/Api';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { TeamSelector } from '../TeamSelector';
import { RoomSearchPreview } from './RoomSearchPreview';

const TeamRoomList: React.FC<{ onMutation: any }> = ({ onMutation }) => {
  const selectedTeamId = useRecoilValue(selectedTeamKeyAtom);
  const joinRoomMutation = trpc.useMutation(['rooms.join'], onMutation);
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  const { data, error, refetch } = trpc.useQuery(
    [
      'teams.rooms',
      {
        teamId: selectedTeamId,
      },
    ],
    {
      suspense: true,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 30_000,
      onError: () => {
        console.log('ERROR');
      },
    }
  );

  if (error) {
    return <>Some error: {error}</>;
  }

  if (data === undefined) {
    return <>Unexpected scenario.</>;
  }

  const joinedRooms = data.filter((r) => r.memberList.includes(selfUserId));
  const notJoinedRooms = data.filter((r) => !r.memberList.includes(selfUserId));

  return (
    <>
      <div className="w-full pl-1 text-sm text-left text-gray-4">
        {data.length} rooms
      </div>
      <div className="flex flex-col w-full overflow-scroll max-h-[45vh]">
        {joinedRooms.map((room) => (
          <>
            <div
              className="w-full border-t-[1px] border-black-lightest/20"
              key={`${room.roomId}0`}
            />
            <RoomSearchPreview
              isJoined
              room={room}
              key={room.roomId}
              onLeaveHandler={() => {
                refetch();
              }}
            />
            <div
              className="w-full border-t-[1px] border-black-lightest/20"
              key={`${room.roomId}1`}
            />
          </>
        ))}
        {notJoinedRooms.map((room) => (
          <>
            <div
              className="w-full border-t-[1px] border-black-lightest/20"
              key={`${room.roomId}0`}
            />
            <RoomSearchPreview
              isJoined={false}
              key={room.roomId}
              room={room}
              onJoinHandler={() => {
                joinRoomMutation.mutate({ roomId: room.roomId });
              }}
            />
            <div
              className="w-full border-t-[1px] border-black-lightest/20"
              key={`${room.roomId}1`}
            />
          </>
        ))}
        {data.length === 0 && (
          <div className="flex justify-center text-black-lightest">
            {' '}
            No rooms exist in this team yet.{' '}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center w-full pt-2">
        <GradientButton
          clickHandler={() => setSelectedModal(ModalTypes.kCreateRoom)}
          enableChevron
        >
          Create new room
        </GradientButton>
      </div>
    </>
  );
};

// const TeamIntegrationRoomList: React.FC<{ onMutation: any }> = ({
//   onMutation,
// }) => {
//   const selectedTeamId = useRecoilValue(selectedTeamKeyAtom);
//   const selfUserId = useRecoilValue(selfUserIdAtom);
//   const setSelectedModal = useSetRecoilState(selectedModalAtom);

//   const { data, error, refetch } = trpc.useQuery(
//     [
//       'teams.roomsForIntegration',
//       {
//         teamId: selectedTeamId,
//         integration: 'slack',
//       },
//     ],
//     {
//       suspense: true,
//       refetchOnMount: true,
//       refetchOnWindowFocus: false,
//       onError: () => {
//         console.log('ERROR');
//       },
//     }
//   );

//   if (error) {
//     return <>Some error: {error}</>;
//   }

//   if (data === undefined) {
//     return <>Unexpected scenario.</>;
//   }

//   const joinedRooms = data.filter((r) => r.members.includes(selfUserId));
//   const notJoinedRooms = data.filter((r) => !r.members.includes(selfUserId));

//   return (
//     <>
//       <div className="w-full pl-1 text-sm text-left text-gray-4">
//         {data.length} rooms
//       </div>
//       <div className="flex flex-col w-full overflow-scroll max-h-[45vh]">
//         {joinedRooms.map((room) => (
//           <>
//             <div
//               className="w-full border-t-[1px] border-black-lightest/20"
//               key={`c-${room.id}`}
//             />
//             {room.name}
//             <div
//               className="w-full border-t-[1px] border-black-lightest/20"
//               key={`e-${room.id}`}
//             />
//           </>
//         ))}
//         {notJoinedRooms.map((room) => (
//           <>
//             <div
//               className="w-full border-t-[1px] border-black-lightest/20"
//               key={`s-${room.id}`}
//             />
//             {room.name}
//             <div
//               className="w-full border-t-[1px] border-black-lightest/20"
//               key={`e-${room.id}`}
//             />
//           </>
//         ))}
//         {data.length === 0 && (
//           <div className="flex justify-center text-black-lightest">
//             {' '}
//             No rooms exist in this team yet.{' '}
//           </div>
//         )}
//       </div>
//       <div className="flex items-center justify-center w-full pt-2">
//         <GradientButton
//           clickHandler={() => setSelectedModal(ModalTypes.kCreateRoom)}
//           enableChevron
//         >
//           Create new room
//         </GradientButton>
//       </div>
//     </>
//   );
// };

export const BrowseOrCreateRoomModal: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const closeModal = useResetRecoilState(selectedModalAtom);
  const { selectRoom } = usePageSelector();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const onMutation = {
    onSuccess: (data: Room | null) => {
      if (!data) return;

      addOrUpdateRoom(data);
      selectRoom(data);
      closeModal();
    },
    onError: (error: any) => {
      setErrorMessage(error.message || 'Unknown error.');
      setTimeout(() => setErrorMessage(null), 4000);
    },
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-2 text-black-primary">
        <span className="text-xl font-semibold text-black-primary">
          Browse all rooms
        </span>
        {/* <TeamSelector /> */}
        <Suspense fallback={<PulseLoader />}>
          <TeamRoomList onMutation={onMutation} />
        </Suspense>
        {errorMessage && (
          <div className="text-sm italic font-bold text-red-500">
            {errorMessage}
          </div>
        )}
        <span className="text-sm text-black-primary/50">
          Email contact@gloo.chat to join more than 10 rooms.
        </span>
      </div>
    </>
  );
};
