import clsx from 'clsx';

const RadialProgress: React.FC<{
  percentage: number;
  sqSize: number;
  strokeWidth: number;
  enabled: boolean;
  darkMode?: boolean;
}> = ({ percentage, sqSize, strokeWidth, enabled, darkMode }) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  if (!enabled) return null;

  return (
    <svg
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
      className="absolute top-0"
    >
      <circle
        className={clsx(
          darkMode ? 'stroke-black-lightest' : 'stroke-gray-1',
          'fill-transparent'
        )}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="bg-blue-400 stroke-red-primary fill-transparent "
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
    </svg>
  );
};

export default RadialProgress;
