import { IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import { ErrorBoundary } from 'react-error-boundary';
import { ClipLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import {
  agoraSubscribedTrackAtom,
  StreamType,
} from 'renderer/call/components/atoms/CallStateAtoms';
import { getVideoCamTrackAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import { logger } from 'renderer/common/LogCreator';
import { RoomUserKey } from 'renderer/models/Keys';
import { Spinner } from 'renderer/shared/Spinner';
import {
  LocalCameraMediaPlayer,
  RemoteCameraMediaPlayer,
} from './UserVideoPlayer';

export const VideoStream = ({
  userId,
  roomId,
  isCamActive,
  isSelfUser,
  isEvent,
  isBubbleExpanded,
}: {
  userId: string;
  roomId: string;
  isCamActive: boolean;
  isSelfUser: boolean;
  isEvent: boolean;
  isBubbleExpanded: boolean;
}) => {
  if (!isCamActive) {
    return null;
  }
  logger.info('rerender videostream');
  return (
    <>
      <div className="flex w-full h-full rounded-full bg-inherit">
        <ErrorBoundary fallback={<Spinner />}>
          {isSelfUser ? (
            <LocalTrackDisplay isBubbleExpanded={isBubbleExpanded} />
          ) : (
            <RemoteTrackDisplay
              roomId={roomId}
              userId={userId}
              isEvent={isEvent}
              isBubbleExpanded={isBubbleExpanded}
            />
          )}
        </ErrorBoundary>
      </div>
    </>
  );
};

const LocalTrackDisplay: React.FC<{ isBubbleExpanded: boolean }> = ({
  isBubbleExpanded,
}) => {
  const track = useRecoilValue(getVideoCamTrackAtom);
  return (
    <LocalCameraMediaPlayer
      videoTrack={track.video}
      audioTrack={track.audio}
      isBubbleExpanded={isBubbleExpanded}
    />
  );
};

const RemoteTrackDisplay: React.FC<
  RoomUserKey & { isEvent: boolean; isBubbleExpanded: boolean }
> = ({ roomId, userId, isBubbleExpanded, isEvent }) => {
  const track = useRecoilValue(
    agoraSubscribedTrackAtom({
      userId,
      roomId,
      stream: StreamType.VIDEO_CAM,
    })
  ) as IRemoteVideoTrack;

  if (!track) {
    return (
      <>
        <div className="text-white-1">Waiting for track!</div>
        <ClipLoader color="gray" />
      </>
    );
  }
  logger.info('Displaying camera track', { track });

  return (
    <RemoteCameraMediaPlayer
      videoTrack={track}
      isEvent={isEvent}
      isBubbleExpanded={isBubbleExpanded}
    />
  );
};
