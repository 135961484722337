import {ButtonProps, SimpleButton} from "renderer/shared/SimpleButton";

export const GradientButton : React.FC<ButtonProps> = ({children, clickHandler, disabled}) => {
  return (<SimpleButton
    className="transform hover:scale-105 duration-300 p-2 px-3 bg-gradient-to-r from-blue-primary to-[#62abe0] text-white-1 rounded-md"
    clickHandler={clickHandler}
    disabled={disabled}
  >
    {children}
  </SimpleButton>);
};
