import { ButtonProps } from '@mui/material';
import clsx from 'clsx';
import { ChildProps, ClassNameProps } from 'types/react';

export const NameTag = ({
  name,
  displayAlways,
}: {
  name?: string;
  displayAlways: boolean;
}) => {
  return (
    <div
      className={clsx(
        'z-10 absolute transform -translate-x-1/2 left-1/2 -bottom-[18px] group-hover:flex justify-start items-center',
        [displayAlways ? 'flex' : 'hidden']
      )}
    >
      <div className="text-sm text-center truncate max-w-[64px] w-fit whitespace-nowrap text-black-lightest/60 font-light px-1 rounded-lg">
        {name}
      </div>
    </div>
  );
};

export enum GlooUserTagType {
  ADMIN,
  PROMOTE_ADMIN,
}

export const GlooUserTag: React.FC<
  { tag: GlooUserTagType } & ChildProps & ClassNameProps & ButtonProps
> = ({ tag, className, children, onClick }) => {
  return (
    <div
      className={clsx(
        'z-10 absolute w-fit transform -translate-x-1/2 left-1/2 -bottom-2',
        className
      )}
    >
      <div
        className={clsx(
          'text-xs text-center truncate rounded-lg whitespace-nowrap text-white-1 px-1',
          tag === GlooUserTagType.ADMIN ? 'bg-blue-primary' : '',
          tag === GlooUserTagType.PROMOTE_ADMIN
            ? 'bg-blue-lighter hover:bg-blue-primary'
            : ''
        )}
      >
        <button
          type="button"
          onClick={onClick}
          disabled={onClick === undefined}
        >
          {children}
        </button>
      </div>
    </div>
  );
};
