import {
  IRemoteAudioTrack,
  IRemoteTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import { atomFamily, selectorFamily } from 'recoil';
import {
  isMicActiveAtom,
  isScreensharingActiveAtom,
} from 'renderer/atoms/room';
import { RoomKey, RoomUserKey } from 'renderer/models/Keys';

export enum StreamType {
  MIC = 'MIC',
  SCREENSHARE = 'SCREENSHARE',
  VIDEO_CAM = 'VIDEO_CAM',
}

export type StreamKey = { stream: StreamType };
export type RoomStreamKey = StreamKey & RoomKey;
export type RoomUserStreamKey = StreamKey & RoomUserKey;

// Lists all the users which have the following tracks.
export const agoraRemoteUserListStreamAtom = atomFamily<
  string[],
  RoomStreamKey
>({
  key: 'agoraRemoteUserListStreamAtom',
  default: [],
});

// Informs if a user in a room has a track available.
export const agoraRemoteUserStreamAtom = atomFamily<boolean, RoomUserStreamKey>(
  {
    key: 'agoraRemoteUserStreamAtom',
    default: false,
  }
);

// Tracks the actual track.
export const agoraSubscribedTrackAtom = atomFamily<
  IRemoteTrack | null,
  RoomUserStreamKey
>({
  key: 'agoraSubscribedTrack',
  default: null,
  dangerouslyAllowMutability: true,
});

export const agoraSubscribedMicTrackAtom = selectorFamily<
  IRemoteAudioTrack | null,
  RoomUserKey
>({
  key: 'agoraSubscribedMicTrack',
  dangerouslyAllowMutability: true,
  get:
    (key) =>
    ({ get }) =>
      get(
        agoraSubscribedTrackAtom({ ...key, stream: StreamType.MIC })
      ) as IRemoteAudioTrack | null,
});

export const agoraSubscribedScreenShareTrackAtom = selectorFamily<
  IRemoteVideoTrack | null,
  RoomUserKey
>({
  key: 'agoraSubscribedScreenShareTrack',
  dangerouslyAllowMutability: true,
  get:
    (key) =>
    ({ get }) =>
      get(
        agoraSubscribedTrackAtom({ ...key, stream: StreamType.SCREENSHARE })
      ) as IRemoteVideoTrack | null,
});

// References the call state as defined by firebase for user.
// THIS IS THE MOST RELIABLE STATE AND AGORA SHOULD ALWAYS FOLLOW IT.
// I.E. if this says OFF, we should never be listening to that track or publishing.
export const isStreamActiveFirebase = selectorFamily<
  boolean,
  RoomUserStreamKey
>({
  key: 'firebaseStreamAtom',
  get:
    ({ roomId, userId, stream }) =>
    ({ get }) => {
      const isMicActive = get(isMicActiveAtom({ roomId, userId }));
      const isScreenshareActive = get(
        isScreensharingActiveAtom({ roomId, userId })
      );
      // TODO: add video-cam
      return stream === StreamType.MIC ? isMicActive : isScreenshareActive;
    },
});
