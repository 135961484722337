import clsx from 'clsx';
import { ChildProps } from 'types/react';

export const BottomRightStatusWrapper: React.FC<
  ChildProps & { isBubbleExpanded?: boolean }
> = ({ children, isBubbleExpanded }) => {
  return (
    <div
      className={clsx(
        'absolute flex w-[40%] h-[40%]  bottom-0 right-0 rounded-full bg-inherit bg-white-1',
        isBubbleExpanded
          ? 'max-w-[40px] max-h-[40px]'
          : 'max-w-[28px] max-h-[28px]'
      )}
    >
      {children}
    </div>
  );
};
