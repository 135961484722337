import { Room } from 'renderer/models/Api';
import { Avatarv2WithId } from 'renderer/shared/user/Avatarv2';

export const UsersPreview: React.FC<{
  room: Room;
  maxUsersToShow?: number;
}> = ({ room, maxUsersToShow }) => {
  const usersToShow = maxUsersToShow ?? 4;
  const excessCount = Math.max(0, room.memberList.length - usersToShow + 1);

  return (
    <div className="flex items-center justify-center">
      {room.members.slice(0, usersToShow).map((u) => {
        return (
          <div className="flex" key={u.userId}>
            <Avatarv2WithId
              teamId={room.teamId}
              userId={u.userId}
              iconSizePx={20}
            />
          </div>
        );
      })}
      {excessCount > 0 && (
        <ExcessUserCount count={excessCount} iconSizePx={20} />
      )}
    </div>
  );
};

const ExcessUserCount = ({
  iconSizePx,
  count,
}: {
  iconSizePx: number;
  count: number;
}) => {
  return (
    <div
      className="relative flex rounded-full ring-1 ring-white-1 bg-inherit "
      style={{ width: iconSizePx, height: iconSizePx }}
    >
      <div className="absolute flex items-center justify-center w-full h-full text-xs bg-transparent rounded-full text-black-primary">
        +{count}
      </div>
    </div>
  );
};
