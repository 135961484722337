import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { RoomKey } from 'renderer/models/Keys';
import { StreamType } from '../../atoms/CallStateAtoms';
import { localMicTrackAtom } from '../../MicrophoneProvider';
import { shouldPublishToRoom, WaitUntilPublished } from './WaitToPublish';

export const AgoraAudioPublisher: React.FC<RoomKey> = ({ roomId }) => {
  const shouldPublish = useRecoilValue(
    shouldPublishToRoom({ roomId, stream: StreamType.MIC })
  );
  const localTrack = useRecoilValue(localMicTrackAtom);

  if (!shouldPublish || !localTrack) return null;

  return (
    <Suspense fallback={null}>
      <WaitUntilPublished
        roomId={roomId}
        stream={StreamType.MIC}
        track={localTrack.track}
      />
    </Suspense>
  );
};
