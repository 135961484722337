import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import { PropsWithChildren, Fragment } from 'react';
import { ExitConversationButton } from 'renderer/shared/Buttons/ExitConversationButton';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import StopScreenShareRoundedIcon from '@mui/icons-material/StopScreenShareRounded';
import VideocamOffRoundedIcon from '@mui/icons-material/VideocamOffRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import { msTillLeaveConversation } from 'renderer/constants';
import { RoomContollerButton } from './RoomControllerButton';

const RoomControllerMessage: React.FC<{
  miniView?: boolean;
  micActiveInRoom: boolean;
  leavingConvoTime: number;
}> = ({ leavingConvoTime, micActiveInRoom, miniView }) => {
  const showLeaveMessage = leavingConvoTime >= 0;

  return (
    <span
      className={clsx(
        'absolute w-fit text-xs text-center px-1',
        miniView ? '-bottom-3 ' : '-top-4'
      )}
    >
      {showLeaveMessage &&
        `Expiring in ${Math.ceil(
          (leavingConvoTime * msTillLeaveConversation) / 1_000
        )}`}
      {!showLeaveMessage && !micActiveInRoom && "You're muted"}
    </span>
  );
};

const RoomControllerBase: React.FC<
  {
    show: boolean;
    darkMode?: boolean;
    miniView?: boolean;
    mic: {
      active: boolean;
      currRoomActive: boolean;
      onClick: () => Promise<void>;
    };
    video: {
      active: boolean;
      currRoomActive: boolean;
      onClick: () => Promise<void>;
    };
    screen: {
      active: boolean;
      currRoomActive: boolean;
      onClick: () => Promise<void>;
    };
    onLeave: () => Promise<void>;
    leaveTimer: number;
  } & PropsWithChildren
> = ({
  darkMode,
  miniView,
  show,
  mic,
  video,
  screen,
  onLeave,
  leaveTimer,
  children,
}) => {
  return (
    <Transition
      appear
      show={show}
      as={Fragment}
      enter="transition ease-out duration-400"
      enterFrom="opacity-0 translate-y-4"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-400"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-4"
    >
      <div
        className={clsx(
          'flex flex-row items-center justify-center gap-x-2 no-drag',
          miniView ? 'scale-75' : '',
          darkMode
            ? ''
            : 'px-2 py-1 rounded-md bg-soft-white shadow-shadow/soft/card-2'
        )}
      >
        <RoomContollerButton
          clickHandler={screen.onClick}
          active={{
            currentRoom: screen.currRoomActive,
            device: screen.active,
          }}
          icon={{
            on: StopScreenShareRoundedIcon,
            off: ScreenShareRoundedIcon,
          }}
          text={{ on: 'Stop screensharing', off: 'Screenshare' }}
          darkMode={darkMode}
        />
        <RoomContollerButton
          clickHandler={mic.onClick}
          active={{ currentRoom: mic.currRoomActive, device: mic.active }}
          icon={{ on: MicRoundedIcon, off: MicOffRoundedIcon }}
          text={{ on: 'Mute', off: 'Unmute', swap: 'Switch mic' }}
          darkMode={darkMode}
        />
        <RoomContollerButton
          clickHandler={video.onClick}
          active={{ currentRoom: video.currRoomActive, device: video.active }}
          icon={{ on: VideocamRoundedIcon, off: VideocamOffRoundedIcon }}
          text={{ on: 'Turn off video', off: 'Turn on video' }}
          darkMode={darkMode}
        />
        <ExitConversationButton
          darkMode={darkMode}
          leavingConvoTime={leaveTimer}
          clickHandler={onLeave}
        />
        {children}
        <RoomControllerMessage
          leavingConvoTime={leaveTimer}
          micActiveInRoom={mic.currRoomActive}
          miniView={miniView}
        />
      </div>
    </Transition>
  );
};

export default RoomControllerBase;
