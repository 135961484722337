import { Room } from 'renderer/models/Api';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { GlooIcon } from './GlooIcon';
import SlackIcon from './SlackIcon';

export const RoomIcon: React.FC<{ room: Room; autoScale?: boolean }> = ({
  room,
  autoScale,
}) => {
  // eslint-disable-next-line no-nested-ternary
  return room.integrations?.slack ? (
    <SlackIcon
      className={autoScale ? '!w-3 !h-3 xl:!w-5 xl:!h-5' : '!w-3 !h-3'}
    />
  ) : room.private ? (
    <LockRoundedIcon
      className={autoScale ? '!w-4 !h-4 xl:!w-6 xl:!h-6' : '!w-4 !h-4'}
    />
  ) : (
    <GlooIcon
      className={autoScale ? '!w-4 !h-4 xl:!w-6 xl:!h-6' : '!w-4 !h-4'}
    />
  );
};
