import { atom, selectorFamily } from 'recoil';
import { persistAtom } from './effects';

export enum PageSelector {
  NO_TEAMS,
  TEAM_USER_OVERVIEW,
  TEAM_ROOMS_OVERVIEW,
  TEAM_SETTINGS,
  TEAM_ROOM,
  NOTIFICATIONS_PAGE,
  NO_NON_PERSONAL_TEAMS,
}

export const pageSelectorAtom = atom<PageSelector>({
  key: 'pageSelector',
  default: PageSelector.NO_TEAMS,
  effects_UNSTABLE: [persistAtom],
});

export const showPageAtom = selectorFamily<boolean, PageSelector>({
  key: 'showPageAtom',
  get:
    (key) =>
    ({ get }) =>
      get(pageSelectorAtom) === key,
});
