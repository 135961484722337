import { atom, atomFamily, selectorFamily } from 'recoil';
import { Nudge } from 'renderer/models/Api';
import { RoomKey, RoomUserKey, UserKey } from '../models/Keys';

export const nudgesAtom = atom<Nudge>({
  key: 'nudgesAtom',
  default: { userId: 'default', incomingPings: [], outgoingPings: [] },
});

enum NudgeType {
  NOTIFY = 'NOTIFY',
}

export const hoveringOverNudgeAtom = atomFamily<boolean, RoomUserKey>({
  key: 'hoveringOverNudge',
  default: false,
});

export const userNudgedMeAtom = selectorFamily<
  Nudge['incomingPings'][0] | undefined,
  RoomUserKey
>({
  key: 'userNudgeMeAtom',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      const nudges = get(nudgesAtom);
      return nudges.incomingPings.find(
        (n) => n.senderUserId === userId && n.roomId === roomId
      );
    },
});

export const userNudgedBySelfAtom = selectorFamily<
  Nudge['outgoingPings'][0] | undefined,
  RoomUserKey
>({
  key: 'userNudgedBySelfAtom',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      const nudges = get(nudgesAtom);
      return nudges.outgoingPings.find(
        (n) => n.targetUserId === userId && n.roomId === roomId
      );
    },
});

export const roomWhoNudgedMeAtom = selectorFamily<UserKey[], RoomKey>({
  key: 'roomWhoNudgedMe',
  get:
    (key) =>
    ({ get }) => {
      const nudges = get(nudgesAtom);
      return nudges.incomingPings
        .filter(({ roomId }) => roomId === key.roomId)
        .map((n) => ({ userId: n.senderUserId }));
    },
});
