import {
  DownloadForOfflineRounded,
  Email,
  HighlightOff,
  MarkEmailRead,
  Pending,
  SignalWifiOff,
} from '@mui/icons-material';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { useEffect, useState } from 'react';
import { Tooltip } from 'renderer/shared/Tooltip';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { MicStatusEnum } from 'renderer/atoms/connectionStatus';
import { TeamUser } from 'renderer/models/Api';
import { BottomRightStatusWrapper } from '.';

export const ConnectionStatus = ({
  isBadConnection,
  isBubbleExpanded,
}: {
  isBadConnection: boolean;
  isBubbleExpanded: boolean;
}) => {
  const [showPulse, setShowPulse] = useState(false);

  useEffect(() => {
    if (isBadConnection) {
      const ret = setTimeout(() => setShowPulse(true), 500);
      return () => clearTimeout(ret);
    }
    setShowPulse(false);
  }, [isBadConnection]);

  if (!isBadConnection || !showPulse) return null;

  return (
    <BottomRightStatusWrapper isBubbleExpanded={isBubbleExpanded}>
      <Tooltip message="Connecting...">
        <div className="flex items-center justify-center w-full h-full p-[2px] rounded-full text-yellow-primary bg-white-1">
          <SignalWifiOff className="!w-full !h-full animate-pulse" />
        </div>
      </Tooltip>
    </BottomRightStatusWrapper>
  );
};

export const OfflineStatus = ({
  membership,
}: {
  membership: TeamUser['membership'];
}) => {
  return (
    <BottomRightStatusWrapper isBubbleExpanded={false}>
      <div className="flex items-center justify-center w-full h-full rounded-full bg-white-1">
        {
          // eslint-disable-next-line no-nested-ternary
          membership.isDeleted ? (
            <HighlightOff className="!w-full !h-full text-black-lightest" />
          ) : membership.invite ? (
            <Email className="!w-full !h-full text-black-lightest p-[2px]" />
          ) : (
            <BedtimeIcon className="!w-full !h-full text-yellow-primary p-[2px]" />
          )
        }
      </div>
    </BottomRightStatusWrapper>
  );
};

/**
 * dont show busy if we just muted.
 * @returns
 */
export const OnlineStatus = ({
  isBusy,
  micStatus,
}: {
  isBusy: boolean;
  micStatus: MicStatusEnum;
}) => {
  return (
    <div className="absolute flex w-[35%] h-[35%] bottom-[1px] right-[2px] rounded-full bg-inherit">
      <div className="flex items-center justify-center w-full h-full rounded-full bg-white-1">
        {isBusy ? (
          <div className="flex w-full h-full p-[1px]">
            <DoNotDisturbOnIcon className="text-orange-primary !w-full !h-full" />
          </div>
        ) : (
          <div className="p-[2px] w-full h-full">
            <div className="w-full h-full bg-green-500 rounded-full" />
          </div>
        )}
      </div>
    </div>
  );
};
