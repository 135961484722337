import {
  add,
  addMinutes,
  differenceInMinutes,
  differenceInSeconds,
  Duration,
  hoursToSeconds,
  intervalToDuration,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  isWithinInterval,
  parseISO,
  sub,
} from 'date-fns';
import { selectorFamily } from 'recoil';
import { videoCamActiveForUserAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import { Room, RoomUser } from 'renderer/models/Api';
import { RoomUserInterface } from 'renderer/models/Convo';
import { RoomKey } from 'renderer/models/Keys';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { selfUserIdAtom } from './glooUser';
import {
  isUserOnlineAtom,
  roomAtom,
  roomUserAtom,
  userDisplayNameAtom,
} from './room';

type RoomGroups = {
  SELF_USER_CONVO_IDX: number;
  CONVOS: {
    convoId: string;
    userIds: string[];
    type: 'EVENT' | 'CONVO';
    selfPresent: boolean;
    camerasEnabled: boolean;
  }[];
  USERS_NOT_IN_CONVO: { userId: string }[];
  USERS_AWAY: { userId: string }[];
};

const eventBufferMins = {
  start: 5,
  end: 30,
};

export function toLocalTime(utcDate: Date) {
  return sub(utcDate, { minutes: utcDate.getTimezoneOffset() });
}

export function toUTCTime(localDate: Date) {
  return add(localDate, { minutes: localDate.getTimezoneOffset() });
}

// event has UTC time
// now is local time
export const nextEventActualTimes = (
  event: NonNullable<Room['events']>[0],
  now: Date
): { start: Date; end: Date } | undefined => {
  if ('rrule' in event.schedule) {
    const { rrule, originalStart, originalEnd } = event.schedule;
    const start = parseISO(originalStart);
    const end = parseISO(originalEnd);
    const duration: Duration = intervalToDuration({
      start,
      end,
    });
    const dtStart = toLocalTime(start);
    const dateToCheck = toLocalTime(now);
    const rruleSet = new RRuleSet();
    rruleSet.rrule(
      rrulestr(rrule, {
        dtstart: dtStart,
      })
    );

    event.schedule.exceptionDates.forEach((d) => {
      rruleSet.exdate(toLocalTime(parseISO(d)));
    });
    console.log('rrule string', rruleSet.toString());
    // subtract duration to make sure we still get the start time if we're in the middle of the event.
    const nextOccurrence = rruleSet.after(sub(dateToCheck, duration), false);
    console.log('next occurence', nextOccurrence.toISOString());
    console.log("event's exceptions", event.schedule.exceptionDates);
    // TODO: we should really either return the exception start time and end time, or thenext occurrence
    // that isn't an exception
    // if (
    //   event.schedule.exceptionDates.find((d) =>
    //     isEqual(parseISO(d), toUTCTime(nextOccurrence))
    //   )
    // ) {
    //   return undefined;
    // }

    // We shifted everything back artificially by the timezone offset to run the calculation, so now we
    // shift it forward again.
    if (nextOccurrence) {
      return {
        start: toUTCTime(nextOccurrence),
        end: toUTCTime(add(nextOccurrence, duration)),
      };
    }
  } else {
    // this is either a single event or an occurrence of the recurring event
    const start = parseISO(event.schedule.start);
    const end = parseISO(event.schedule.end);
    if (event.isAllDay) {
      const eventStartDate = new Date(toUTCTime(start));
      //  console.log('allday', { start, end, currDate: eventStartDate });
      eventStartDate.setHours(0, 0, 0, 0);
      eventStartDate.setMinutes(0, 0, 0);
      // if (isToday(eventStartDate)) {
      return {
        start: eventStartDate,
        end: toUTCTime(end),
      };
    }

    if (isBefore(now, end)) {
      return {
        start,
        end,
      };
    }
  }
  return undefined;
};

export const nextEventCriteria = (
  event: NonNullable<Room['events']>[0],
  now: Date
): { start: Date; end: Date } | undefined => {
  const getEventInterval = (candidate: Date, duration: Duration) => {
    const endTime = add(candidate, duration);
    const eventInterval = {
      start: candidate,
      end: endTime,
    };
    const inRange = isWithinInterval(now, {
      start: addMinutes(candidate, -eventBufferMins.start),
      end: addMinutes(endTime, eventBufferMins.end),
    });
    let delta = differenceInSeconds(now, eventInterval.start);
    // Treat as far away if negative.
    delta = delta < 0 ? hoursToSeconds(365) : delta;
    return {
      interval: eventInterval,
      // distance: inRange ? 0 : delta,
    };
  };

  const eventInterval = nextEventActualTimes(event, now);
  if (eventInterval) {
    const duration: Duration = intervalToDuration(eventInterval);
    return getEventInterval(eventInterval.start, duration).interval;
  }
  return undefined;
};

const nextEventInstancesAtom = selectorFamily<
  {
    events: undefined | { eventId: string; instance: Interval | undefined }[];
  },
  RoomKey
>({
  key: 'nextEventInstances',
  get:
    (key) =>
    ({ get }) => {
      const room = get(roomAtom(key.roomId));
      try {
        const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_30));
        return {
          events: room.events?.map((e) => ({
            eventId: e.eventId,
            instance: nextEventCriteria(e, now),
          })),
        };
      } catch (error) {
        console.error(error);
        return { events: [] };
      }
    },
});

export const nextEventInstanceAtom = selectorFamily<
  | { instance: Interval; timeUntil: number }
  | { instance: undefined; timeUntil: undefined },
  RoomKey & { eventId: string }
>({
  key: 'nextEventInstance',
  get:
    (key) =>
    ({ get }) => {
      const { events } = get(nextEventInstancesAtom({ roomId: key.roomId }));
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_30));
      const instance = events?.find((e) => e.eventId === key.eventId)?.instance;
      return instance
        ? {
            timeUntil: differenceInMinutes(instance.start, now),
            instance,
          }
        : { timeUntil: undefined, instance: undefined };
    },
});

export const activeEventsAtom = selectorFamily<
  { eventId: string }[] | undefined,
  RoomKey
>({
  key: 'activeEvents',
  get:
    (key) =>
    ({ get }) => {
      const { events } = get(nextEventInstancesAtom(key));
      if (!events) return undefined;
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND));
      return events
        .filter(
          ({ instance }) =>
            instance &&
            isWithinInterval(now, {
              start: addMinutes(instance.start, -eventBufferMins.start),
              end: instance.end,
            })
        )
        .map(({ eventId }) => ({ eventId }));
    },
});

export const roomGroupsAtom = selectorFamily<RoomGroups, { roomId: string }>({
  key: 'tableGroups',
  get:
    (key) =>
    ({ get }) => {
      const room = get(roomAtom(key.roomId));
      const selfUserId = get(selfUserIdAtom);

      const response: RoomGroups = {
        SELF_USER_CONVO_IDX: -1,
        CONVOS: [],
        USERS_NOT_IN_CONVO: [],
        USERS_AWAY: [],
      };

      const convoMap = new Map<string, string[]>();
      room.memberList.forEach((m) => {
        const member = get(roomUserAtom({ roomId: key.roomId, userId: m }));
        if (!member.loaded || !member.online) {
          response.USERS_AWAY.push({ userId: m });
        } else if (!member.convo.active) {
          response.USERS_NOT_IN_CONVO.push({ userId: m });
        } else if (member.convo.convoId) {
          // User is in some conversation we may not yet know which one.
          if (convoMap.get(member.convo.convoId)?.push(m) === undefined) {
            convoMap.set(member.convo.convoId, [m]);
          }
        }
      });

      // Active events.
      const activeEvents = get(activeEventsAtom(key));
      // All events.
      const allEventIds = room.events?.map(({ eventId }) => eventId);

      convoMap.forEach((users, convoId) => {
        const selfPresent = users.includes(selfUserId);
        const camerasEnabled =
          selfPresent &&
          !!users.find((u) => {
            return get(
              videoCamActiveForUserAtom({ userId: u, roomId: key.roomId })
            );
          });
        if (selfPresent) {
          response.SELF_USER_CONVO_IDX = response.CONVOS.length;
        }
        response.CONVOS.push({
          convoId,
          userIds: users,
          selfPresent,
          camerasEnabled,
          type: allEventIds?.includes(convoId) ? 'EVENT' : 'CONVO',
        });
      });
      activeEvents
        ?.filter(({ eventId }) => !convoMap.has(eventId))
        .forEach(({ eventId }) => {
          response.CONVOS.push({
            convoId: eventId,
            userIds: [],
            selfPresent: false,
            camerasEnabled: false,
            type: 'EVENT',
          });
        });
      return response;
    },
});

// long name lols
export const isUserInSelfUserTableWithVideoAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isUserInATableWithSelfUserAndVideo',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const { SELF_USER_CONVO_IDX, CONVOS } = get(roomGroupsAtom({ roomId }));
      if (SELF_USER_CONVO_IDX === -1) return false;
      const convo = CONVOS[SELF_USER_CONVO_IDX];
      return convo.camerasEnabled && convo.userIds.includes(userId);
    },
});

export const availableUsersAtom = selectorFamily<RoomUserInterface[], RoomKey>({
  key: 'availableUsersAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      return room.members
        .filter((u) => {
          const isUserOnline = get(
            isUserOnlineAtom({ roomId, userId: u.userId })
          );
          return isUserOnline;
        })
        .map((u) => get(roomUserAtom({ roomId, userId: u.userId })));
    },
});

export const awayUsersAtom = selectorFamily<RoomUser[], RoomKey>({
  key: 'awayUsersAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      // Away users are sorted alphabetically.
      return room.members
        .filter((u) => {
          const isUserOnline = get(
            isUserOnlineAtom({ roomId, userId: u.userId })
          );
          return !isUserOnline;
        })
        .sort((a, b) => {
          const aDisplayName = get(
            userDisplayNameAtom({ roomId, userId: a.userId })
          );
          const bDisplayName = get(
            userDisplayNameAtom({ roomId, userId: b.userId })
          );
          if (aDisplayName < bDisplayName) return -1;
          if (aDisplayName > bDisplayName) return 1;
          return 0;
        });
    },
});
