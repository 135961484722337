import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { RoomKey } from 'renderer/models/Keys';
import { StreamType } from '../../atoms/CallStateAtoms';
import { localVideoCamTrackAtom } from '../../atoms/videoCameraAtoms';
import { WaitUntilPublished } from './WaitToPublish';

export const AgoraVideoPublisher: React.FC<RoomKey> = ({ roomId }) => {
  // TODO: this doesn't work the same since we don't have a firebase status for video cam
  // publishing yet.
  // const shouldPublish = useRecoilValue(
  //   shouldPublishToRoom({ roomId, stream: StreamType.VIDEO })
  // );

  // so we just publish when there's a track available
  const localTrack = useRecoilValue(localVideoCamTrackAtom);

  if (!localTrack) return null;
  return (
    <Suspense fallback={null}>
      <WaitUntilPublished
        roomId={roomId}
        stream={StreamType.VIDEO_CAM}
        track={localTrack.video}
      />
    </Suspense>
  );
};
