import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isUserOnlineAtom,
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import { useConvoHooks } from 'renderer/hooks/useConversationExpirationMonitor';
import { ModalTypes, selectedModalAtom } from './atoms';

export const selectedBusyUserIdAtom = atom<string | null>({
  key: 'selectedBusyUserAtom',
  default: null,
});

export const TalkToBusyUserModal = () => {
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const { joinConvo } = useConvoHooks();
  const selectedUserId = useRecoilValue(selectedBusyUserIdAtom)!;
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  const isUserOnline = useRecoilValue(
    isUserOnlineAtom({ roomId, userId: selectedUserId })
  );
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const { profile } = useRecoilValue(
    roomUserAtom({ roomId, userId: selectedUserId })
  );

  return (
    <div className="flex flex-col items-center justify-center w-full gap-3">
      <div className="px-3 font-normal text-center">
        {profile.displayName} is busy and may be talking to someone. Are you
        sure you want to talk to them?
      </div>
      <button
        type="button"
        onClick={() => {
          if (isUserOnline) {
            joinConvo({ teamId, roomId }, { userId: selectedUserId });
          }
          setSelectedModal(ModalTypes.kNone);
        }}
        className="items-center justify-center px-2 py-1 ml-2 text-base font-medium leading-tight transition duration-150 ease-in-out rounded shadow-lg outline-none bg-blue-primary text-white-1 no-drag hover:bg-blue-lighter"
      >
        Confirm
      </button>
    </div>
  );
};
