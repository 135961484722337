import { useEffect, useState } from 'react';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { idleSecondsToMarkAsOffline } from 'renderer/constants';
import { trpc } from '../client/trpc';
import { logger } from '../LogCreator';

// Window Focused
export const isWindowFocusedAtom = atom<boolean>({
  key: 'isWindowFocused',
  default: true,
});

export enum UserActivityState {
  ONLINE,
  IDLE_SHORT,
  IDLE_LONG,
  LOCKED,
}

const getIdleState = (state: UserActivityState) => {
  switch (state) {
    case UserActivityState.ONLINE:
      return undefined;
    case UserActivityState.IDLE_SHORT:
      return 'SHORT';
    case UserActivityState.IDLE_LONG:
    case UserActivityState.LOCKED:
      return 'LONG';
    default:
      throw new Error('Unexpected Idle state');
  }
};

// whether the user is not idle according to electron / system info.
export const isMicUsedExternallyAtom = atom<{ inUse: boolean; time: Date }>({
  key: 'isMicUsedExternally',
  default: { inUse: false, time: new Date(0) },
});

export const isUserInputActiveAtom = atom<UserActivityState>({
  key: 'isUserActive',
  default: UserActivityState.IDLE_SHORT,
});

export const lastModifiedIdleTimeAtom = atom<Date>({
  key: 'isUserActiveTime',
  default: new Date(0),
});

// This hook monitors signals for the user active signal using electron idle state.
// This cannot use DateTimeIntervals because it relies on data from the electron main process and must listen for client changes.
export const useUserInputActiveMonitor = () => {
  const setIsWindowFocused = useSetRecoilState(isWindowFocusedAtom);
  const setMicExternalUsage = useSetRecoilState(isMicUsedExternallyAtom);
  const [micExternal, setMicExternal] = useState(false);
  const userActivityState = useRecoilValue(isUserInputActiveAtom);
  const updateIdle = trpc.useMutation(['users.status.idle']);

  const setActiveTime = useRecoilCallback(
    ({ set }) =>
      (state: UserActivityState) => {
        set(isUserInputActiveAtom, state);
        set(lastModifiedIdleTimeAtom, new Date());
        updateIdle.mutate({ idle: getIdleState(state) });
      }
  );

  useEffect(() => {
    const now = new Date();
    if (micExternal) {
      // Wait for this state to remain true for at least 0.5 seconds.
      const ret = setTimeout(() => {
        setMicExternalUsage({ inUse: true, time: now });
      }, 500);
      return () => clearTimeout(ret);
    }
    setMicExternalUsage({ inUse: false, time: now });
  }, [micExternal]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (userActivityState === UserActivityState.IDLE_SHORT) {
      timeout = setTimeout(() => {
        setActiveTime(UserActivityState.IDLE_LONG);
      }, idleSecondsToMarkAsOffline * 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [userActivityState, setActiveTime]);

  useEffect(() => {
    window?.electron?.ipcRenderer
      .getUserActivityState()
      .then(({ state, mic }) => {
        if (state === 'locked') setActiveTime(UserActivityState.LOCKED);
        else if (state === 'idle') setActiveTime(UserActivityState.IDLE_SHORT);
        else if (state === 'active') setActiveTime(UserActivityState.ONLINE);
        setMicExternal(mic.state);
        return null;
      })
      .catch((err) => {
        logger.error('Err:', err);
      });
    window?.electron?.ipcRenderer?.on('isUserActive', ({ active, locked }) => {
      if (locked) {
        setActiveTime(UserActivityState.LOCKED);
      } else {
        setActiveTime(
          active ? UserActivityState.ONLINE : UserActivityState.IDLE_SHORT
        );
      }
    });
    window?.electron?.ipcRenderer.on('isMicActive', ({ state, time }) => {
      setMicExternal(state);
    });
    const focusListener = () => {
      setActiveTime(UserActivityState.ONLINE);
      setIsWindowFocused(true);
    };
    const blurListener = () => setIsWindowFocused(false);
    if (window.electron) {
      window.addEventListener('focus', focusListener);
      window.addEventListener('blur', blurListener);
    } else {
      focusListener();
    }
    return () => {
      if (window.electron) {
        window.removeEventListener('focus', focusListener);
        window.removeEventListener('blur', blurListener);
      }
      window?.electron?.ipcRenderer.removeAllListeners('isUserActive');
    };
  }, []);
};
