import Logger from 'js-logger';

export enum LoggerNames {
  DEFAULT = 'Main',
  AUTH = 'Authentication',
  CALL = 'Call',
  SCREENSHARE = 'Call:ScreenShare',
  VIDEO = 'Call:Video',
  MIC = 'Call:Mic',
  MEGAPHONE = 'Call:Megaphone',
  CHAT = 'Call:Chat',
}

const LogCreator = (name: LoggerNames = LoggerNames.DEFAULT) =>
  Logger.get(name);
export const logger = LogCreator();
export default LogCreator;
