import {signInWithCredential, GoogleAuthProvider, updateCurrentUser, updateEmail} from "firebase/auth";
import {CredentialResponse} from "google-one-tap";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSigninCheck, useAuth, useFirebaseApp} from "reactfire";
import {useRecoilCallback} from "recoil";
import {trpc} from "renderer/common/client/trpc";
import {trpcAtom} from "renderer/common/components/TRPCReactQuery";
import {Spinner} from "renderer/shared/Spinner";
import {useExternalScript} from "../../hooks/useExternalScript";

const useClientId = () => {
  console.log("ENV Variables", process.env);
  return process.env.REACT_APP_CLIENT_ID;
};

export const GoogleLogin = () => {
  const googleScriptState = useExternalScript(
      "https://accounts.google.com/gsi/client",
  );
  const [googleIdToken, setGoogleIdToken] = useState<string | null>(null);
  const {status} = useSigninCheck();
  const isFirebaseAuthLoading = status === "loading";
  const auth = useAuth();
  const navigate = useNavigate();
  const clientId = useClientId();

  useEffect(() => {
    if (googleScriptState == "loading" || isFirebaseAuthLoading) {
      return;
    }

    if (!clientId) {
      return;
    }

    console.log("initializing google sign in:", clientId);
    try {
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: (response) => setGoogleIdToken(response.credential),
        ux_mode: "popup",
        prompt_parent_id: "google-sign-in",
        cancel_on_tap_outside: false,
        auto_select: false,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("google-sign-in-alt")!,
        {theme: "outline", size: "large", width: 350}, // customization attributes
      );
    } catch (error) {
      console.log("error loading ", error);
    }
    return () => {
      // Cleanup function that runs when component unmounts
      if (window.google) {
        window.google.accounts.id.cancel();
      }
    };
  }, [googleScriptState, isFirebaseAuthLoading, clientId]);

  useEffect(() => {
    if (googleIdToken) {
      const signInAndLaunchApp = async () => {
        // login even if there's already a user signed in
        await signInWithCredential(
            auth,
            GoogleAuthProvider.credential(googleIdToken),
        );
      };
      signInAndLaunchApp();
    }
  }, [googleIdToken]);

  if (googleIdToken) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col space-y-2 text-center">
      <div id="google-sign-in"></div>
      <div id="google-sign-in-alt"></div>
    </div>
  );
};
