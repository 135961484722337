/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SyncLoader } from 'react-spinners';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  isSelfUserConvoActiveAtom,
  roomUserAtom,
  selectedRoomAvailableAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import { FirebaseSyncAvailability } from 'renderer/atoms/team';
import { ChatContainer } from 'renderer/chat/ChatContainer';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import clsx from 'clsx';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { BubbleGrid } from './BubbleGrid';
import { OfflinePanel } from './OfflinePanel';

export const RoomView = () => {
  const roomReady = useRecoilValue(selectedRoomAvailableAtom);

  if (roomReady !== FirebaseSyncAvailability.AVAILABLE) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        <SyncLoader />
      </div>
    );
  }
  return <RoomLoadedView />;
};

const RoomLoadedView = () => {
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const isSelfUserConvoActive = useRecoilValue(
    isSelfUserConvoActiveAtom({ roomId })
  );
  return (
    <div
      className={clsx('flex flex-col w-full h-full', [
        isSelfUserConvoActive && 'lg:flex-row',
      ])}
    >
      <BubbleGrid />

      <OfflinePanel />

      <ChatContainer />
      {/* <div className="absolute bottom-0 left-0 z-50">
        <div className="flex flex-col items-center gap-2 p-1">
          <MegaphoneButton />
        </div>
      </div> */}
    </div>
  );
};
