import { Tab } from '@headlessui/react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { DesktopInputSource } from 'api/models';
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSceenshareToggles } from 'renderer/call/components/ScreenShareProvider';
import { logger } from 'renderer/common/LogCreator';
import { RoomKey } from 'renderer/models/Keys';
import { Spinner } from 'renderer/shared/Spinner';
import {
  ModalTypes,
  screenShareModalRoomIdAtom,
  selectedModalAtom,
} from '../atoms';

const ScreenshareGrid = ({
  images,
  roomId,
}: { images: DesktopInputSource[] } & RoomKey) => {
  const { turnOnTrack } = useSceenshareToggles();
  const setModal = useSetRecoilState(selectedModalAtom);
  const handleScreenCaptureSelection = useCallback(
    async (source: DesktopInputSource) => {
      const stream = await navigator.mediaDevices.getUserMedia({
        // no audio for now so we only get one track back
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: source.id,
          },
        } as MediaTrackConstraints,
      });
      const videoTrack = stream.getVideoTracks()[0];

      const trackToPublish = AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack: videoTrack,
      });

      await turnOnTrack({ video: trackToPublish, roomId });
      setModal(ModalTypes.kNone);
      // TODO: move this inside of turnOnTrack
      window?.electron?.ipcRenderer.setDesktopSources(source.id);
    },
    [roomId, setModal, turnOnTrack]
  );

  return (
    <div className="max-h-[50vh] overflow-y-auto w-fit">
      <div className="flex flex-wrap items-end justify-center gap-6 p-1">
        {images.map((source) => {
          return (
            <div key={source.id} className="flex flex-col items-center">
              <button
                type="button"
                key={source.id}
                className="flex flex-col rounded-sm hover:outline-2 hover:outline hover:outline-red-400 active:bg-gray-300"
                onClick={() => handleScreenCaptureSelection(source)}
              >
                <div className="flex items-center justify-center object-center bg-gray-200">
                  <img
                    className="object-contain w-24 bg-gray-300 sm:w-48"
                    src={source.thumbnailUrl}
                    alt=""
                  />
                </div>
              </button>
              <div className="w-48 font-semibold text-center truncate sm:w-60">
                {source.name}
                {source.id}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ScreenSharePanel = () => {
  const { data: screenShareTracks } = useQuery(
    'fetchDesktopSources',
    async () => {
      // IIRC this is what triggers the MACOS permission dialog to record
      // other apps. Either this or navigator.mediaDevices....
      return window?.electron?.ipcRenderer.getDesktopSources();
    },
    { suspense: true }
  );
  logger.info('screenshare panel');

  const roomId = useRecoilValue(screenShareModalRoomIdAtom);
  if (!roomId) return null;

  const apps: DesktopInputSource[] = [];
  const screens: DesktopInputSource[] = [];
  screenShareTracks?.forEach((s) => {
    if (s.id.startsWith('screen')) {
      screens.push(s);
    } else {
      apps.push(s);
    }
  });

  return (
    <Tab.Group>
      <Tab.List className="p-4 space-x-4">
        <Tab
          className={({ selected }) =>
            `pb-2 ${selected && 'border-b-2 border-gray-300 font-bold'}`
          }
        >
          Applications
        </Tab>
        <Tab
          className={({ selected }) =>
            `pb-2 ${selected && 'border-b-2 border-gray-300 font-bold'}`
          }
        >
          Screens
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <ScreenshareGrid images={apps} roomId={roomId} />
        </Tab.Panel>
        <Tab.Panel>
          <ScreenshareGrid images={screens} roomId={roomId} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export const ScreenShareMenuModal: React.FC = () => {
  return (
    // <DeprecatedModal name="screenshare-room" title="Choose content to share">
    <React.Suspense fallback={<Spinner />}>
      <ScreenSharePanel />
    </React.Suspense>
    // </DeprecatedModal>
  );
};
