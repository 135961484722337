import clsx from 'clsx';
import ReactAvatar from 'react-avatar';
import { useRecoilValue } from 'recoil';
import { teamUserImplAtom } from 'renderer/atoms/team';

export const Avatarv2 = ({
  photoUrl,
  iconSizePx,
  name,
  isBubbleExpanded,
}: {
  photoUrl?: string | null;
  iconSizePx: number;
  name: string;
  isBubbleExpanded?: boolean;
}) => {
  return (
    <div
      className={clsx('flex', isBubbleExpanded ? 'p-2' : '')}
      style={{ height: iconSizePx, width: iconSizePx }}
    >
      {photoUrl ? (
        <img
          src={photoUrl}
          className={clsx(
            'object-contain w-full h-full',
            isBubbleExpanded ? 'rounded-lg' : 'rounded-full'
          )}
          // https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
          referrerPolicy="no-referrer"
        />
      ) : (
        <ReactAvatar
          name={name}
          size={`${iconSizePx}px`}
          textSizeRatio={2}
          className="rounded-full"
        />
      )}
    </div>
  );
};

export const Avatarv2WithId = ({
  iconSizePx,
  userId,
  teamId,
}: {
  iconSizePx: number;
  userId: string;
  teamId: string;
}) => {
  // This is an exception where we must use team user impl due to fetching rooms
  // which we may not have listeners for.
  const profile = useRecoilValue(teamUserImplAtom({ teamId, userId }))
    ?.profile ?? {
    displayName: 'LOADING',
    email: 'LOADING',
  };

  return (
    <Avatarv2
      photoUrl={profile.photoUrl}
      name={profile.displayName}
      iconSizePx={iconSizePx}
    />
  );
};
