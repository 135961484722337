/* eslint-disable max-len */
import {Center} from "components/Center";
import {GradientButton} from "components/GradientButton";
import React, {useState} from "react";
import {AiFillApple, AiFillWindows} from "react-icons/ai";
import {ClipLoader} from "react-spinners";
import {InferQueryOutput, trpc} from "renderer/common/client/trpc";
import {SimpleButton} from "renderer/shared/SimpleButton";
import Smartscreen from "../../assets/smartscreen.png";

type Platform = InferQueryOutput<"download.get">[0]["platform"];
function getOS(): Platform | null {
  const platform = (window.navigator as any).userAgentData?.platform ?? window.navigator.platform;
  const macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return "MACOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return "WINDOWS";
  }
  //   } else if (/Android/.test(userAgent)) {
  //     os = "Android";
  //   } else if (!os && /Linux/.test(platform)) {
  //     os = "Linux";
  //   }

  return null;
}

const Download = () => {
  const os = getOS();


  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    return <div className="w-full h-full p-10 mt-20 font-bold text-center">Unfortunately Gloo only works on desktop at the moment.</div>;
  }

  if (!os) {
    return <div className="w-full h-full p-10 mt-20 font-bold text-center">Unsupported OS. We currently support macOS and Windows</div>;
  }

  return (

    <div className="w-full h-full row-col">
      <React.Suspense fallback={<ClipLoader />}>
        <DownloadValid platform={os} />
      </React.Suspense>
    </div>

  );
};

export const DownloadWrapper = () => {
  return <>
    <Center><Download /></Center>
  </>;
};

// render this when there's an actual valid platform to pass in
const DownloadValid = ({platform}: {platform: Platform }) => {
  const getUrls = trpc.useQuery(["download.get"], {
    staleTime: Infinity,
  });
  const [downloadStarted, setDownloadStarted] = useState(false);

  if (getUrls.isLoading) {
    return <ClipLoader />;
  }

  if (!getUrls.data) return <div className="text-center text-red-900">Error getting download information. You can reach us at contact@gloo.chat if issue persists.</div>;
  const primaryPlatform = getUrls.data.find((d) => d.platform === platform);

  if (!primaryPlatform) return <div>Unknown error</div>;

  return (
    <div className="flex flex-col items-center w-full h-full p-5 space-y-4 mt-30">
      <div className="text-3xl font-bold">Download Gloo</div>
      <div>By clicking the Download button, you agree to our <a className="underline" href="https://app.termly.io/document/privacy-policy/a12938f5-441c-4a59-aa85-9f07499383d2">Privacy Policy</a> & <a className="underline" href="https://app.termly.io/document/terms-of-use-for-saas/9b4ef8ab-aae1-4d73-9b23-cd3ce377aa1b">Terms of Use</a>.
      </div>
      {{
        "MACOS": <AiFillApple className="w-10 h-10"/>,
        "WINDOWS": <AiFillWindows className="w-10 h-10"/>,
      }[platform]}
      <a className="flex w-fit h-fit" href={primaryPlatform?.downloadUrl} download onClick={() => setDownloadStarted(true)}>
        <GradientButton clickHandler={() => {}}>
        Download for {humanReadablePlatform(platform)} <span className="text-xs">
                v{primaryPlatform?.version}</span>
        </GradientButton>
      </a>

      <div className="mt-5">
        {getUrls.data.filter((p) => p.platform !== platform).map((p) =>
          <a key={platform} className="flex text-xs text-blue-600 underline w-fit h-fit" href={p.downloadUrl} download>
          Download for {humanReadablePlatform(p.platform)} (v{p.version})
          </a>)}
      </div>
      {
        platform === "WINDOWS" && downloadStarted && (
          <div className="flex flex-col text-center items-center justify-center w-[70%]">
            <div className="pt-10 pb-5 text-lg font-bold text-center">Your download is starting...</div>
            <div className="font-bold">Note: <div className="inline font-normal">
              {"If you see a 'Windows protected your PC' dialog click on 'more info' and then 'Run anyway'."}
              <div className="flex flex-col items-center">
                <img className="object-center h-32 m-3 outline-1 outline outline-gray-300" src={Smartscreen} />
                <div className="max-w-md text-left">
            The app is currently manually published by <a className="inline text-blue-600 underline" href="https://www.linkedin.com/in/aaron-villalpando-99284576/"> one of us</a> {"(the founders) and we'll be getting rid of this warning soon. Feel free to reach out with any questions 😊."}
                </div>
              </div>
            </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

const humanReadablePlatform = (platform: Platform) => {
  switch (platform) {
    case "MACOS":
      return "macOS";
    case "WINDOWS":
      return "Windows";
  }
};
