import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export const AmbientSoundTitle = ({ muted }: { muted: boolean }) => {
  return (
    <span className="flex items-center justify-center gap-1 text-sm lg:text-base">
      <span className="text-purple-primary">Ambient Sound</span>
      <Popover className="relative">
        <Popover.Button className="underline text-gray-4 decoration-gray-4">
          {muted ? 'disabled' : 'enabled'}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 p-2 overflow-hidden text-sm text-left whitespace-pre-line border border-gray-200 shadow-md bg-white-1 bottom-5 w-52 rounded-xl -left-16">
            {muted ? (
              <>
                Room conversations are disabled for you. You will only hear
                conversations you’re a part of.
              </>
            ) : (
              <>
                You can now hear room conversations.{'\n'} Conversations you’re
                not on sound as if they were in the background, like in a cafe.
                You can adjust their volume with the slider below.
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </span>
  );
};
