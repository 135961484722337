import {AppInfo, DeploymentType} from "api/models";
import {useState, useEffect} from "react";
import {ClipLoader} from "react-spinners";
import {AppInfoContext} from "renderer/providers/AppInfoProvider";
import {Spinner} from "renderer/shared/Spinner";
import {ChildProps} from "types/react";
import {v4 as uuidv4} from "uuid";

const browserIdKey = "browser-id";
export const AppInfoProviderWeb: React.FC<ChildProps> = ({children}) => {
  const [appInfo, setAppInfo] = useState<AppInfo | null>(null);
  const browserId = localStorage.getItem(browserIdKey);
  if (!browserId) {
    localStorage.setItem(browserIdKey, uuidv4());
  }
  useEffect(() => {
    const hostname = window.location.hostname;
    let deploymentType: DeploymentType = "prod";
    // TODO: could also set the stack to call with a search param.
    if (hostname.includes("localhost")) {
      deploymentType = "emulator";
    } else if (hostname.includes("dev")) {
      deploymentType = "dev";
    } else {
      deploymentType = "prod";
    }

    const initialInfo = {
      appVersion: "web",
      deployment: deploymentType,
      machineId: localStorage.getItem(browserIdKey) || "unknown",
    };
    setAppInfo(initialInfo);
  }, []);
  if (appInfo === null) {
    return <ClipLoader />;
  }
  return (
    <AppInfoContext.Provider value={appInfo}>
      {children}
    </AppInfoContext.Provider>
  );
};
