import { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { RoomKey } from 'renderer/models/Keys';
import { StreamType } from '../../atoms/CallStateAtoms';
import { localScreenshareTrackAtom } from '../../ScreenShareProvider';
import { shouldPublishToRoom, WaitUntilPublished } from './WaitToPublish';

export const AgoraScreensharePublisher: React.FC<RoomKey> = ({ roomId }) => {
  const shouldPublish = useRecoilValue(
    shouldPublishToRoom({ roomId, stream: StreamType.SCREENSHARE })
  );
  const localTrack = useRecoilValue(localScreenshareTrackAtom);

  if (!shouldPublish || !localTrack) return null;

  return (
    <Suspense fallback={null}>
      <WaitUntilPublished
        roomId={roomId}
        stream={StreamType.SCREENSHARE}
        track={localTrack.video}
      />
    </Suspense>
  );
};
