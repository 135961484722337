import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { trackIsPublishedAtom } from 'renderer/call/components/agora-publishers/helpers/WaitToPublish';
import {
  agoraSubscribedMicTrackAtom,
  agoraSubscribedTrackAtom,
  StreamType,
} from 'renderer/call/components/atoms/CallStateAtoms';
import { micActiveRoomIdAtom } from 'renderer/call/components/MicrophoneProvider';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import { availableStatusAtom } from 'renderer/connection/state';
import { RoomKey, RoomUserKey } from 'renderer/models/Keys';
import { agoraRemoteUserAtom, connectionStateAtom } from './call';
import { selfGlooUserAtom } from './glooUser';
import {
  isBroadcastingAtom,
  isMicActiveAtom,
  isUserOnlineAtom,
  roomListAtom,
} from './room';

export enum FirebaseStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export const realtimeDbConnectionStatusAtom = atom<FirebaseStatus>({
  key: 'realtimeDBConnectionStatus',
  default: FirebaseStatus.DISCONNECTED,
});

/**
 * Each of these atoms is indexed by roomId
 */
export const firestoreConnectionStatusAtom = atomFamily<FirebaseStatus, string>(
  {
    key: 'firestoreConnectionStatus',
    default: FirebaseStatus.DISCONNECTED,
  }
);

/**
 * Combination of the state of the realtimeDB connection + Firestore updates
 * for the given roomId
 */
export const firebaseConnectionStatusSelector = selectorFamily<
  FirebaseStatus,
  string
>({
  key: 'fbConnectionStatus',
  get:
    (roomId) =>
    ({ get }) => {
      const realtimeDBStatus = get(realtimeDbConnectionStatusAtom);
      const firestoreStatus = get(firestoreConnectionStatusAtom(roomId));

      if (
        [realtimeDBStatus, firestoreStatus].includes(
          FirebaseStatus.DISCONNECTED
        )
      ) {
        return FirebaseStatus.DISCONNECTED;
      }
      return FirebaseStatus.CONNECTED;
    },
});

export const isUserWithBadConnectionAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isUserWithBadConnectionAtom',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      const { userId: selfUid } = get(selfGlooUserAtom);
      if (selfUid === userId) {
        return false;
      }

      // Can't complain about other's connection if you aren't connected yourself.
      const selfConnected =
        get(connectionStatusAtom({ roomId, userId: selfUid })) ===
        ConnectionStatusEnum.Online;
      if (!selfConnected) return false;

      const isOnlineInFirestore =
        get(connectionStatusAtom({ roomId, userId })) ===
        ConnectionStatusEnum.Online;
      // check whether the user is also online in the call. If not there is a mismatch
      // and user is still in a "connecting state"
      const isBadConnection =
        isOnlineInFirestore && !get(agoraRemoteUserAtom({ roomId, userId }));

      return isBadConnection;
    },
});

export const isUserMicWaitingToConnectAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isUserMicWaitingToConnectAtom',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      if (get(selfGlooUserAtom).userId === userId) {
        const shouldPublish =
          get(connectionStateAtom({ roomId, isCameraStream: false })) ===
          'CONNECTED';
        const published = get(
          trackIsPublishedAtom({ roomId, stream: StreamType.MIC })
        );
        return shouldPublish ? !published : false;
      }
      const isUserOnline =
        get(connectionStatusAtom({ roomId, userId })) ===
        ConnectionStatusEnum.Online;
      const micConnected = !!get(
        agoraSubscribedMicTrackAtom({ roomId, userId })
      );
      return isUserOnline && !micConnected;
    },
});

export enum ConnectionStatusEnum {
  Online,
  Disconnecting,
  Offline,
  Connecting,
}

/** Is user online according to firebase status (doesnt mean they have joined call yet) */
export const connectionStatusAtom = selectorFamily<
  ConnectionStatusEnum,
  { roomId: string; userId: string }
>({
  key: 'connectionStatusForUser',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      const isOnlineFirebase = get(isUserOnlineAtom({ roomId, userId }));
      if (get(selfGlooUserAtom).userId === userId) {
        const isOnlineLocally = get(availableStatusAtom);
        if (isOnlineFirebase) {
          return isOnlineLocally
            ? ConnectionStatusEnum.Online
            : ConnectionStatusEnum.Disconnecting;
        }
        return isOnlineLocally
          ? ConnectionStatusEnum.Connecting
          : ConnectionStatusEnum.Offline;
      }

      return isOnlineFirebase
        ? ConnectionStatusEnum.Online
        : ConnectionStatusEnum.Offline;
    },
});

export enum MicStatusEnum {
  On,
  Off,
  Megaphone,
  WaitingForOn,
  WaitingForOff,
  WaitingForMegaphone,
}

const selfMicStatusAtom = selectorFamily<MicStatusEnum, RoomKey>({
  key: 'selfMicStatus',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const { userId } = get(selfGlooUserAtom);
      const localMicRoom = get(micActiveRoomIdAtom);
      const localMicActive = localMicRoom === roomId;
      const localMegaphoneOn = get(
        buttonActive({ button: ButtonType.MEGAPHONE })
      );

      const micActiveFirebase = get(isMicActiveAtom({ roomId, userId }));
      const megaphoneActiveFirebase = get(
        isBroadcastingAtom({ roomId, userId })
      );

      if (!localMicActive) {
        if (!micActiveFirebase) {
          return MicStatusEnum.Off;
        }
        return MicStatusEnum.WaitingForOff;
      }
      if (localMegaphoneOn) {
        if (megaphoneActiveFirebase) return MicStatusEnum.Megaphone;
        return MicStatusEnum.WaitingForMegaphone;
      }
      if (micActiveFirebase) return MicStatusEnum.On;
      return MicStatusEnum.WaitingForOn;
    },
});

const remoteMicStatusAtom = selectorFamily<MicStatusEnum, RoomUserKey>({
  key: 'remoteMicStatus',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const remoteTrack = get(
        agoraSubscribedTrackAtom({ roomId, userId, stream: StreamType.MIC })
      );
      const micActiveFirebase = get(isMicActiveAtom({ roomId, userId }));
      const megaphoneActiveFirebase = get(
        isBroadcastingAtom({ roomId, userId })
      );

      const gotAudioTrack = remoteTrack !== null;
      if (gotAudioTrack) {
        if (megaphoneActiveFirebase) return MicStatusEnum.Megaphone;
        return MicStatusEnum.On;
      }
      if (micActiveFirebase) {
        return MicStatusEnum.WaitingForOn;
      }
      if (megaphoneActiveFirebase) {
        return MicStatusEnum.WaitingForMegaphone;
      }
      return MicStatusEnum.Off;
    },
});

export const userMicStatusAtom = selectorFamily<MicStatusEnum, RoomUserKey>({
  key: 'userMicStatusAtom',
  get:
    ({ userId, roomId }) =>
    ({ get }) => {
      const { userId: selfUid } = get(selfGlooUserAtom);
      const isSelf = selfUid === userId;
      const isAvailable = get(
        connectionStatusAtom({ roomId, userId: selfUid })
      );
      const micStatus = isSelf
        ? get(selfMicStatusAtom({ roomId }))
        : get(remoteMicStatusAtom({ roomId, userId }));
      if (isAvailable === ConnectionStatusEnum.Online) return micStatus;

      const toNonTransientStatus = (status: MicStatusEnum) => {
        switch (status) {
          case MicStatusEnum.WaitingForMegaphone:
            return MicStatusEnum.Megaphone;
          case MicStatusEnum.WaitingForOff:
            return MicStatusEnum.Off;
          case MicStatusEnum.WaitingForOn:
            return MicStatusEnum.On;
          default:
            return status;
        }
      };

      return toNonTransientStatus(micStatus);
    },
});
