import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { agoraClientAtom } from 'renderer/atoms/call';
import { onlineCountRoomAtom } from 'renderer/atoms/room';
import { RoomKey } from 'renderer/models/Keys';
import { AgoraVideoPublisher } from '../agora-publishers/helpers/AgoraVideoPublisher';
import { videoCamActiveAtom } from '../atoms/videoCameraAtoms';
import { WaitToJoinCall } from '../JoinCallMonitor';

export const VideoCameraPublisher = ({ roomId }: RoomKey) => {
  const agoraClient = useRecoilValue(
    agoraClientAtom({ roomId, isCameraStream: true })
  );
  const isVideoTrackAvailable = useRecoilValue(videoCamActiveAtom({ roomId }));
  const onlineUsers = useRecoilValue(onlineCountRoomAtom({ roomId }));
  const minUsersOnline = useMemo(() => onlineUsers >= 2, [onlineUsers]);

  if (!isVideoTrackAvailable || !minUsersOnline) {
    return null;
  }

  console.log('publishing local video track');

  return (
    <>
      <WaitToJoinCall
        roomId={roomId}
        onLeave={() => {}}
        isCameraStream
        agoraClient={agoraClient}
      >
        <AgoraVideoPublisher roomId={roomId} />
      </WaitToJoinCall>
    </>
  );
};
