import { motion } from 'framer-motion';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedRoomKeyAtom, useRoomAtomSetters } from 'renderer/atoms/room';
import { selectedTeamKeyAtom, teamAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { Room } from 'renderer/models/Api';
import { GradientButton } from '../Buttons/helpers/GradientButton';
import { InputField } from '../input/InputField';
import { selectedModalAtom } from './atoms';
import { TeamSelector } from './team/TeamSelector';

export const CreateRoomModal = () => {
  const selectedTeamId = useRecoilValue(selectedTeamKeyAtom);
  const { name } = useRecoilValue(teamAtom(selectedTeamId));
  return (
    <div className="flex flex-col items-center justify-center gap-2 text-black-primary">
      <span className="text-xl font-semibold text-black-primary">
        Create a room in <span className="text-blue-dark/80">{name}</span>
      </span>
      {/* <TeamSelector /> */}
      <RoomPropertiesContainer />
      {/* <TeamSelector />
      <Suspense fallback={<PulseLoader />}>
        <TeamRoomList onMutation={onMutation} />
      </Suspense> */}
      {/* <span className="text-sm text-black-primary/50">
        Email contact@gloo.chat to join more than 10 rooms.
      </span> */}
    </div>
  );
};

const RoomPropertiesContainer = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [name, setName] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();

  const selectedTeamId = useRecoilValue(selectedTeamKeyAtom);
  const closeModal = useResetRecoilState(selectedModalAtom);
  const { selectRoom } = usePageSelector();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const onMutation = {
    onSuccess: (data: Room | null) => {
      if (!data) return;

      addOrUpdateRoom(data);
      selectRoom(data);
      closeModal();
    },
    onError: (error: any) => {
      setErrorMessage(error.message || 'Unknown error.');
      setTimeout(() => setErrorMessage(null), 4000);
    },
  };
  const createRoomMutation = trpc.useMutation(['rooms.create'], onMutation);
  const [isPrivate, setPrivate] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center w-[60vh] gap-y-3 text-lg">
      <InputField
        title="Room name"
        placeholderText="e.g. Sales, Just Chatting, Project XYZ"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setName(e.target.value);
        }}
      />
      <InputField
        title="Description"
        optional
        placeholderText="e.g. for random chats"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDescription(e.target.value);
        }}
      />
      <ToggleSetting
        title="Make Private"
        description="When a room is set to private, it can only be viewed or joined by invitation"
        isOn={isPrivate}
        onChange={() => {
          setPrivate(!isPrivate);
        }}
      />
      {createRoomMutation.isLoading ? (
        <ClipLoader color="gray" />
      ) : (
        <GradientButton
          enableChevron
          clickHandler={() => {
            createRoomMutation.mutate({
              teamId: selectedTeamId,
              name,
              description,
              isPrivate,
            });
          }}
        >
          Create
        </GradientButton>
      )}

      {errorMessage && (
        <div className="text-sm italic font-bold text-red-500">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const ToggleSetting = ({
  isOn,
  title,
  description,
  onChange,
}: {
  isOn: boolean;
  title: string;
  description: string;
  onChange: () => void;
}) => {
  return (
    <>
      <div className="flex flex-row items-stretch justify-between w-full">
        <div className="flex flex-col w-[80%]">
          <span className="text-base font-semibold text-black-lighter">
            {title}
          </span>
          <span className="text-base text-gray-4">{description}</span>
        </div>
        <div className="flex items-center">
          <Switch isOn={isOn} onClick={onChange} />
        </div>
      </div>
    </>
  );
};

const Switch = ({ isOn, onClick }: { isOn: boolean; onClick: () => void }) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={onClick}
      // TODO will this work if we do place-content-center for 3 items?
      className={`items-start flex h-[20px] w-[36px] rounded-full p-[2px] shadow-inner hover:cursor-pointer ${
        isOn ? 'bg-blue-lighter' : 'bg-gray-2'
      } ${isOn && 'place-content-end'}`}
    >
      <motion.div
        className="flex h-[16px] w-[16px] items-center justify-center rounded-full bg-white-1"
        layout
        transition={spring}
      />
    </div>
  );
};

// className="px-2 py-1 pr-8 rounded-xl bg-status_toggle_background border-[1px] border-neutral-4 text-sm text-center w-full"
