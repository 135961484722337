import React from 'react';

export const ChildNotifTitle = ({
  icon,
  title,
}: {
  icon?: React.ReactNode;
  title: string;
}) => {
  return (
    <div className="text-sm flex flex-row gap-1 items-center font-medium text-left capitalize truncate shrink max-w-[120px] pb-1">
      {icon}
      <div>{title}</div>
    </div>
  );
};
