import { Popover, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

export type MenuItem = {
  name: string;
  clickHandler: () => void;
  icon: ReactNode;
};

export const PopoverMenu = ({
  buttonContents,
  menu,
}: {
  buttonContents: ReactNode;
  menu: MenuItem[];
}) => {
  return (
    <Popover className="relative z-50">
      {() => (
        <>
          <Popover.Button className="flex items-end">
            {buttonContents}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 transform -translate-x-[50%] left-[100%]">
              <div className="p-1 rounded-lg shadow-lg ring-1 ring-black-primary ring-opacity-5 bg-white-1">
                <div className="relative grid">
                  {menu.map((item) => (
                    <button
                      type="button"
                      key={item.name}
                      onClick={() => item.clickHandler()}
                      className="flex items-center justify-start p-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 focus:outline-none"
                    >
                      <div className="items-center justify-center w-5 h-auto">
                        {item.icon}
                      </div>
                      <p className="pl-1 text-lg font-normal text-left text-black-primary whitespace-nowrap">
                        {item.name}
                      </p>
                    </button>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
