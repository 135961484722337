import { DeploymentConfig } from './models/DeploymentConfig';

export const minVolumeTrigger = 0.3;

export const buttonOverrideTimeMinutes = 60;

// seconds since last chat was active in which we still consider table to be chatting.
export const chattingThresholdSeconds = 45;

// Mute the microphone after 60 seconds of no conversations.
export const secondsUntilMuted = 90;

// 4MIN
export const idleSecondsToMarkAsOffline = 60 * 4;

// see AgoraVideoPublisher
export const CAMERA_STREAM_UID_PREFIX = 'camera-';

// TODO: these keys should be loaded from env variables.

// TODO: these keys should be loaded from env variables.
export const deploymentConfigs = {
  prod: new DeploymentConfig({
    agoraAppId: '03ad819c20104ce2bff7226984fc1d4b',
    firebaseProject: 'gloo-8bf36',
    functionsUrl: 'https://us-central1-gloo-8bf36.cloudfunctions.net',
    config: {
      apiKey: 'AIzaSyCkSQNYoMZPJWo30qGRKq41DvLTDxvSfc4',
      messagingSenderId: '134834572278',
      appId: '1:134834572278:web:2ce96aeca08b7508ccd5d7',
      measurementId: 'G-6NSVNSKWG0',
    },
    gleapId: 'DlIS5uD8bUcuQYUKIWhxjvNCCoBs2BDP',
    bucketUrl: 'gs://gloo-8bf36-app',
  }),
  dev: new DeploymentConfig({
    agoraAppId: '03ad819c20104ce2bff7226984fc1d4b',
    firebaseProject: 'gloo-dev-6bb7d',
    functionsUrl: 'https://us-central1-gloo-dev-6bb7d.cloudfunctions.net',
    config: {
      apiKey: 'AIzaSyDUpSu2Hfj29dR7eo5z4GGVc_1xJV1Yaeg',
      messagingSenderId: '1015468871243',
      appId: '1:1015468871243:web:24d526bd330a59640587ce',
    },
    gleapId: 'v0nNRdrQzn6uFcGs64GewbkhqtINHXZF',
    bucketUrl: 'gs://gloo-dev-6bb7d-app',
  }),
  emulator: new DeploymentConfig({
    agoraAppId: '03ad819c20104ce2bff7226984fc1d4b',
    firebaseProject: 'gloo-dev-6bb7d',
    functionsUrl: 'http://localhost:5001/gloo-dev-6bb7d/us-central1',
    config: {
      apiKey: 'AIzaSyDUpSu2Hfj29dR7eo5z4GGVc_1xJV1Yaeg',
      messagingSenderId: '1015468871243',
      appId: '1:1015468871243:web:24d526bd330a59640587ce',
    },
    gleapId: 'v0nNRdrQzn6uFcGs64GewbkhqtINHXZF',
    bucketUrl: 'gs://gloo-dev-6bb7d-app',
  }),
};

export const msTillLeaveConversation = 5_000;
