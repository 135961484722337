import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import { RTScreenShareUserInterface } from 'api/models';
import { ref, set as setFirebase } from 'firebase/database';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaMousePointer } from 'react-icons/fa';
import { useDatabase } from 'reactfire';
import { useRecoilValue } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { roomUserAtom } from 'renderer/atoms/room';

import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { ConvoKey, RoomKey } from 'renderer/models/Keys';
import {
  screenShareUserDataAtom,
  screensharingUsersAtom,
} from 'renderer/pages/dashboard/providers/FirebaseScreenShareUpdates';

const logger = LogCreator(LoggerNames.SCREENSHARE);

const MouseHover: React.FC<
  ConvoKey & RoomKey & { sharingUserId: string; userId: string }
> = ({ convoId, sharingUserId, userId, roomId }) => {
  const position = useRecoilValue(
    screenShareUserDataAtom({ convoId, sharingUserId, userId })
  );
  const {
    profile: { displayName },
  } = useRecoilValue(roomUserAtom({ roomId, userId }));

  if (!position || !position.active) return null;

  return (
    <div
      className="absolute z-50 flex flex-row gap-1 w-fit"
      style={{
        left: `${Math.min(Math.max(0, position.mouse.x), 1) * 100}%`,
        top: `${Math.min(Math.max(0, position.mouse.y), 1) * 100}%`,
      }}
    >
      <FaMousePointer className="text-purple-primary" />
      <span className="px-1 text-xs rounded-sm bg-purple-primary text-white-1">
        {displayName}
      </span>
    </div>
  );
};

const ScreensharingOverlay: React.FC<
  ConvoKey &
    RoomKey & { sharingUserId: string; size: { height: number; width: number } }
> = ({ roomId, convoId, sharingUserId, size }) => {
  const realtimeDb = useDatabase();
  const userId = useRecoilValue(selfUserIdAtom);
  const [userPresence, setUserPresence] =
    useState<RTScreenShareUserInterface | null>(null);
  const myRef = useRef<HTMLDivElement>(null);
  const myUserDataRef = useMemo(
    () =>
      convoId
        ? ref(realtimeDb, `/convo/${convoId}/${sharingUserId}/${userId}`)
        : undefined,
    [convoId, realtimeDb, sharingUserId, userId]
  );

  useEffect(() => {
    // Emit updates to userPresence
    if (userPresence && myUserDataRef) {
      setFirebase(myUserDataRef, userPresence);
    }
  }, [userPresence, myUserDataRef]);

  const activeUsers = useRecoilValue(
    screensharingUsersAtom({ convoId, sharingUserId })
  );

  return (
    <div
      ref={myRef}
      className="absolute top-0 z-50 flex items-center justify-center w-full h-full"
    >
      <div
        className="relative"
        style={{ ...size }}
        onMouseLeave={() =>
          setUserPresence((prev) => {
            if (prev === null) return null;
            return {
              ...prev,
              active: false,
              lastUpdated: new Date().getTime(),
            };
          })
        }
        onMouseMoveCapture={(event) => {
          const mouse = {
            x:
              (event.clientX - (event.currentTarget.offsetLeft ?? 0)) /
              event.currentTarget.offsetWidth,
            y:
              (event.clientY -
                // TODO Figure out why we need this instead of just using event.currentTarget.offsetTop
                (event.currentTarget.parentElement?.parentElement?.offsetTop ??
                  0)) /
              event.currentTarget.offsetHeight,
          };
          setUserPresence({
            lastUpdated: new Date().getTime(),
            active: true,
            mouse,
          });
        }}
      >
        {/* <span>Online: {activeUsers.length}</span> */}
        {activeUsers.map((u) => (
          <MouseHover
            key={u}
            roomId={roomId}
            convoId={convoId}
            sharingUserId={sharingUserId}
            userId={u}
          />
        ))}
      </div>
    </div>
  );
};

export const LocalMediaPlayer: React.FC<{
  videoTrack: ILocalVideoTrack;
  audioTrack?: ILocalAudioTrack;
}> = ({ videoTrack }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container.current) return;
    container.current.replaceChildren('');

    if (!videoTrack.isPlaying) {
      videoTrack.play(container.current);
    } else {
      logger.info('Video already playing?');
    }

    return () => {
      if (videoTrack.isPlaying) {
        logger.info('playing track: end');
        videoTrack?.stop();
      }
    };
  }, [container, videoTrack]);

  return (
    <div
      ref={container}
      // className={props?.className}
      className="z-20 flex-grow w-screen shadow-lg not-last-child:hidden"
      style={{ height: '75vh' }}
    />
  );
};

// TODO: delete, or use for video in the future.
export const RemoteMediaPlayer: React.FC<{
  videoTrack: IRemoteVideoTrack;
  audioTrack?: IRemoteAudioTrack;
  sharingUserId: string;
  roomId: string;
  convoId: string | undefined;
}> = ({ videoTrack, convoId, sharingUserId, roomId }) => {
  const [frameReady, setFrameReady] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>(null);
  const [videoParams, setVideoParams] = useState({ width: 1, height: 1 });
  const [divSizeParams, setDivSizeParams] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (!container.current) return;
    const setFrameOn = () => {
      logger.info('FRAME READY');
      // Scaled solution:
      const { receiveResolutionHeight, receiveResolutionWidth } =
        videoTrack.getStats();
      setVideoParams({
        height: receiveResolutionHeight,
        width: receiveResolutionWidth,
      });
      setFrameReady(true);
    };
    // Register video hooks.
    videoTrack.on('first-frame-decoded', setFrameOn);

    const interval = setInterval(() => {
      const { receiveResolutionHeight, receiveResolutionWidth } =
        videoTrack.getStats();
      setVideoParams({
        height: receiveResolutionHeight,
        width: receiveResolutionWidth,
      });
    }, 1000);

    return () => {
      videoTrack.off('first-frame-decoded', setFrameOn);
      clearInterval(interval);
    };
  }, [container.current]);

  useEffect(() => {
    if (!container.current) return;
    container.current.replaceChildren('');

    if (!videoTrack.isPlaying) {
      // This is a hack for typescript because agora doesn't correctly support 'scale-down' in their api.
      videoTrack.play(container.current, { fit: 'scale-down' as 'cover' });
    } else {
      logger.info('Video already playing?');
    }

    return () => {
      if (videoTrack.isPlaying) {
        logger.info('playing track: end');
        videoTrack?.stop();
        setFrameReady(false);
      }
    };
  }, [container, videoTrack]);

  const listener = useCallback(() => {
    if (!container.current) return;

    const maxHeight = {
      height: container.current.offsetHeight,
      width: Math.round(
        (videoParams.width * container.current.offsetHeight) /
          videoParams.height
      ),
    };
    const maxWidth = {
      width: container.current.offsetWidth,
      height: Math.round(
        (videoParams.height * container.current.offsetWidth) / videoParams.width
      ),
    };

    if (maxWidth.height <= container.current.offsetHeight) {
      setDivSizeParams(maxWidth);
    } else {
      setDivSizeParams(maxHeight);
    }
  }, [container.current, videoParams]);

  useEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return (
    <div className="relative flex-grow w-screen">
      <div
        ref={container}
        // className={props?.className}
        className="flex-grow w-screen"
        style={{ height: '85vh' }}
      />
      {convoId && (
        <ScreensharingOverlay
          size={divSizeParams}
          convoId={convoId}
          sharingUserId={sharingUserId}
          roomId={roomId}
        />
      )}
    </div>
  );
};
