import logger from 'js-logger';
import { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { selectedRoomAtom } from 'renderer/atoms/room';
import {
  useTeamAtomSetters,
  selectedTeamKeyAtom,
  teamAtom,
} from 'renderer/atoms/team';
import {
  useMicToggles,
  localMicActiveAtom,
} from 'renderer/call/components/MicrophoneProvider';
import {
  useSceenshareToggles,
  selfScreenshareActiveAtom,
} from 'renderer/call/components/ScreenShareProvider';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { TeamKey } from 'renderer/models/Keys';
import SlackIcon from 'renderer/shared/icons/SlackIcon';
import { useModalErrors } from 'renderer/shared/Modals/atoms';
import { SimpleButton } from 'renderer/shared/SimpleButton';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';
import { NameTag } from 'renderer/shared/user/subcomponents/NameTag';

const openUrl = (url: string) => {
  if (window.electron) {
    window.electron.ipcRenderer.openUrl(url);
  } else {
    window.open(url, '_blank');
  }
};

const useLeaveTeam = () => {
  const { resetPages } = usePageSelector();
  const { setModalError } = useModalErrors();
  const { removeTeam } = useTeamAtomSetters();
  const { turnOffTrack: turnOffMic } = useMicToggles();
  const { turnOffTrack: turnOffScreenShare } = useSceenshareToggles();
  const leaveTeamMutation = trpc.useMutation(['teams.leave'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
  });

  const leaveTeam = useRecoilCallback(({ snapshot, set }) => async () => {
    // There are bugs here and you maybe be screen sharing / mic still.
    let room;
    try {
      room = await snapshot.getPromise(selectedRoomAtom);
    } catch (error: any) {
      logger.info('Error getting room.. continuing leaving the team');
    }
    const selectedTeamId = await snapshot.getPromise(selectedTeamKeyAtom);
    if (room && room.teamId === selectedTeamId) {
      const micOn = await snapshot.getPromise(
        localMicActiveAtom({ roomId: room.roomId })
      );
      if (micOn) await turnOffMic();
      const screenShareOn = await snapshot.getPromise(
        selfScreenshareActiveAtom({ roomId: room.roomId })
      );
      if (screenShareOn) await turnOffScreenShare();
    }

    await leaveTeamMutation.mutateAsync({ teamId: selectedTeamId });
    const id = room?.teamId || selectedTeamId;
    removeTeam(id);
    resetPages();
  });
  return { leaveTeam, isLoading: leaveTeamMutation.isLoading };
};

const LeaveTeamButton: React.FC = () => {
  const keyWord = 'LEAVE';
  const [leaveText, setLeaveText] = useState('');
  const { leaveTeam, isLoading } = useLeaveTeam();
  return (
    <div className="flex flex-col items-center justify-center text-black-primary">
      <div className="flex flex-row items-center gap-2 w-fit">
        {/* <div className="text-sm text-gray-4">Leave</div> */}
        <div className="relative flex flex-row items-center gap-2">
          <input
            className="px-2 py-1 pr-16 bg-neutral-3 rounded-xl"
            value={leaveText}
            onChange={(e) => {
              setLeaveText(e.target.value);
            }}
            placeholder={keyWord}
          />
          {isLoading ? (
            <ClipLoader className="absolute right-2" size={20} color="gray" />
          ) : (
            <button
              type="button"
              onClick={leaveTeam}
              disabled={leaveText !== keyWord}
              className="absolute px-1 text-xs bg-red-500 right-2 rounded-xl text-white-1 disabled:bg-gray-3 hover:bg-red-300"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
      <span className="text-xs text-center max-w-[50%]">
        Type <b>{keyWord}</b> to leave the team. This is not reversable, so once
        you leave, you&apos;ll need to get another invite link to rejoin the
        team.
      </span>
    </div>
  );
};

const RenameTeamButton: React.FC<
  {
    teamName: string;
    isAdmin: boolean;
  } & TeamKey
> = ({ isAdmin, teamName, teamId }) => {
  const [name, setName] = useState(teamName);
  const { setModalError } = useModalErrors();

  useEffect(() => {
    setName(teamName);
  }, [teamName]);

  const renameTeam = trpc.useMutation(['teams.rename'], {
    onError: (error) => {
      const parsedError: { message: string; code: string }[] = JSON.parse(
        error.message
      );
      setModalError(
        parsedError
          .map((e) =>
            e.code === 'invalid_string'
              ? 'Bad characters. Only letters, numbers, and spaces are allowed.'
              : e.message
          )
          .join('\n') || 'Unknown error.'
      );
    },
  });

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="text-sm text-gray-4">Team Name</div>
      <input
        className="px-2 py-1 border-2 rounded-lg disabled:border-none disabled:bg-inherit"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        placeholder={teamName}
        disabled={!isAdmin}
      />
      {isAdmin && (
        <button
          type="button"
          className="p-1 text-sm transition duration-150 ease-in-out rounded shadow-lg outline-none bg-blue-lighter disabled:bg-gray-300 h-fit text-white-1 no-drag hover:bg-blue-primary"
          disabled={name.length === 0 || name === teamName}
          onClick={() => renameTeam.mutateAsync({ teamId, newName: name })}
        >
          Rename
        </button>
      )}
    </div>
  );
};

const SlackIntegration: React.FC<
  { slackId?: string; isAdmin: boolean } & TeamKey
> = ({ slackId, teamId, isAdmin }) => {
  const integrationUrl = trpc.useMutation('teams.integrationUrl');

  if (slackId) {
    return (
      <SimpleButton clickHandler={() => {}} disabled>
        <div className="flex items-center gap-1">
          <SlackIcon className="w-4" />
          Connected
        </div>
      </SimpleButton>
    );
  }

  return (
    <>
      <SimpleButton
        clickHandler={async () => {
          const slackUrl = await integrationUrl.mutateAsync({
            teamId,
            type: 'SLACK',
          });
          openUrl(slackUrl);
        }}
        disabled
      >
        <div className="flex items-center gap-1">
          <SlackIcon className="w-4" />
          Connect Slack
        </div>
      </SimpleButton>
      <span className="text-sm whitespace-normal text-gray-4">
        Coming soon!
      </span>
      {/* {!isAdmin && (
        <span className="text-sm whitespace-normal text-gray-4">
          Please ask your team admin to enable Slack integration
        </span>
      )} */}
    </>
  );
};

const TeamSettings: React.FC = () => {
  const selectedTeamId = useRecoilValue(selectedTeamKeyAtom);
  const team = useRecoilValue(teamAtom(selectedTeamId));
  const selfUserId = useRecoilValue(selfUserIdAtom);

  const admins = team.members.filter(({ permissions }) => permissions.admin);

  const isAdmin = !!admins.find((a) => a.userId === selfUserId);

  return (
    <div className="flex flex-col items-start w-full gap-2 px-4 py-2">
      <RenameTeamButton
        teamName={team.name}
        isAdmin={isAdmin}
        teamId={selectedTeamId}
      />
      <hr className="w-full duration-500 border-t-[1px] cursor-pointer border-gray-4/10" />
      <div className="flex flex-row gap-4">
        <span className="text-sm text-gray-4">Team Admins</span>
        {admins.map((a) => (
          <div className="flex gap-2 text-black-lightest" key={a.userId}>
            <Avatarv2
              photoUrl={a.profile.photoUrl}
              iconSizePx={24}
              name={a.profile.displayName}
            />

            <span className="flex flex-row items-center gap-1 text-base">
              {a.profile.displayName}
              {a.userId === selfUserId && (
                <span className="px-2 text-xs rounded-md bg-blue-primary text-white-1">
                  You
                </span>
              )}
            </span>
          </div>
        ))}
      </div>
      <hr className="w-full duration-500 cursor-pointer border-gray-4/10 border-t-[1px]" />
      <div className="flex flex-row items-center gap-4 text-sm whitespace-nowrap text-gray-4">
        <div>Slack Integration</div>
        <SlackIntegration
          slackId={team.integrations?.slack}
          teamId={selectedTeamId}
          isAdmin={isAdmin}
        />
      </div>

      <hr className="w-full duration-500 border-t-[1px] cursor-pointer border-gray-4/10" />
      <LeaveTeamButton />
    </div>
  );
};

export default TeamSettings;
