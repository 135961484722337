import React, { useEffect } from 'react';
import { RoomUserKey } from 'renderer/models/Keys';
import { useScreenShareViewModal } from 'renderer/shared/Modals/screenshare/ViewScreenShareModal';

export const AgoraUserScreenShare: React.FC<RoomUserKey> = ({
  roomId,
  userId,
}) => {
  const { maybeShowModal } = useScreenShareViewModal();
  // On load show the modal in some cases.
  useEffect(() => {
    maybeShowModal(roomId, userId);
  }, [roomId, userId]);
  return <></>;
};
