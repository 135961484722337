import clsx from 'clsx';
import { PulseLoader, SyncLoader } from 'react-spinners';
import {
  useTranslationContext,
  TypingIndicatorProps,
  useChannelStateContext,
  useChatContext,
  useComponentContext,
  useTypingContext,
  Avatar,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

const useJoinTypingUsers = (names: string[]) => {
  const { t } = useTranslationContext();

  if (!names.length) return null;

  const [name, ...rest] = names;

  if (names.length === 1)
    return t('{{ user }} is typing...', {
      user: name,
    });

  const MAX_JOINED_USERS = 3;

  const isLargeArray = names.length > MAX_JOINED_USERS;

  const joinedUsers = (isLargeArray ? names.slice(0, MAX_JOINED_USERS) : rest)
    .join(', ')
    .trim();

  if (isLargeArray)
    return t('{{ users }} and more are typing...', {
      users: joinedUsers,
    });

  return t('{{ users }} and {{ user }} are typing...', {
    user: name,
    users: joinedUsers,
  });
};

// copied from stream-chat's typing indicator in order to manipulate the CSS easily lols
export const CustomTypingIndicator = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: TypingIndicatorProps<StreamChatGenerics>
) => {
  const { Avatar: PropAvatar, avatarSize = 32, threadList } = props;

  const { channelConfig, thread } =
    useChannelStateContext<StreamChatGenerics>('TypingIndicator');
  const { client, themeVersion } =
    useChatContext<StreamChatGenerics>('TypingIndicator');
  const { Avatar: ContextAvatar } =
    useComponentContext<StreamChatGenerics>('TypingIndicator');
  const { typing = {} } =
    useTypingContext<StreamChatGenerics>('TypingIndicator');

  const Avatar2 = PropAvatar || ContextAvatar || Avatar;

  const typingInChannel = !threadList
    ? Object.values(typing).filter(
        ({ parent_id, user }) => user?.id !== client.user?.id && !parent_id
      )
    : [];

  const typingInThread = threadList
    ? Object.values(typing).filter(
        ({ parent_id, user }) =>
          user?.id !== client.user?.id && parent_id === thread?.id
      )
    : [];

  const typingUserList = (threadList ? typingInThread : typingInChannel)
    .map(({ user }) => user?.name || user?.id)
    .filter(Boolean) as string[];

  const joinedTypingUsers = useJoinTypingUsers(typingUserList);

  const isTypingActive =
    (threadList && typingInThread.length) ||
    (!threadList && typingInChannel.length);

  if (channelConfig?.typing_events === false) {
    return null;
  }

  if (themeVersion === '2') {
    if (!isTypingActive) return null;
    return (
      <div
        className={clsx(
          'text-sm absolute flex flex-row items-center bottom-[50px] pl-3  text-gray-4 text-center w-full gap-x-1'
        )}
        data-testid="typing-indicator"
      >
        <PulseLoader
          size={5}
          color="grey"
          className="animate-pulse"
          speedMultiplier={0.5}
        />
        {joinedTypingUsers}
      </div>
    );
  }

  return (
    <div
      className={clsx('str-chat__typing-indicator', {
        'str-chat__typing-indicator--typing': isTypingActive,
      })}
    >
      <div className="str-chat__typing-indicator__avatars">
        {(threadList ? typingInThread : typingInChannel).map(({ user }, i) => (
          <Avatar2
            image={user?.image}
            // eslint-disable-next-line react/no-array-index-key
            key={`${user?.id}-${i}`}
            name={user?.name || user?.id}
            size={avatarSize}
            user={user}
          />
        ))}
      </div>
      <div className="str-chat__typing-indicator__dots">
        <span className="str-chat__typing-indicator__dot" />
        <span className="str-chat__typing-indicator__dot" />
        <span className="str-chat__typing-indicator__dot" />
      </div>
    </div>
  );
};

export const BlankTypingIndicator = () => {
  return <></>;
};
