import { useEffect, useMemo, useState } from 'react';
import {
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {
  isBroadcastingAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import {
  ambientSoundAtom,
  DEFAULT_OVERHEARING_DAMPEND_VOLUME,
} from 'renderer/atoms/settings';
import {
  makeFilterFarAway,
  makeFilterMuffled,
  setOverhearingFilter,
} from 'renderer/audio/OverhearingFilters';
import { isWindowFocusedAtom } from 'renderer/common/hooks/useUserInputActiveMonitor';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import {
  lastTableSpeakTimeAtom,
  lastUserSpeakTimeAtom,
  convoHoverAtom,
  userSpeakingAtom,
  userSpeakingVolumeAtom,
} from 'renderer/connection/voiceCallState';
import { minVolumeTrigger } from 'renderer/constants';
import { RoomUserKey } from 'renderer/models/Keys';
import {
  IAudioContext,
  IBiquadFilterNode,
  IGainNode,
} from 'standardized-audio-context';
import { agoraSubscribedMicTrackAtom } from '../../atoms/CallStateAtoms';

const logger = LogCreator(LoggerNames.MIC);

export const AgoraUserAudio: React.FC<RoomUserKey> = ({ roomId, userId }) => {
  const track = useRecoilValue(agoraSubscribedMicTrackAtom({ roomId, userId }));

  const isRoomSelected = useRecoilValue(selectedRoomKeyAtom) === roomId;
  const setRoomUserVolume = useRecoilCallback(
    ({ set, snapshot }) =>
      async (volume: number) => {
        const roomUser = await snapshot.getPromise(
          roomUserAtom({ roomId, userId })
        );

        const speaking = volume > minVolumeTrigger;
        set(userSpeakingVolumeAtom({ roomId, userId }), volume);
        set(userSpeakingAtom({ roomId, userId }), speaking);
        if (speaking) {
          const now = new Date();
          set(lastUserSpeakTimeAtom({ roomId, userId }), now);
          set(
            lastTableSpeakTimeAtom({
              roomId,
              convoId: roomUser.convo?.convoId || 'none',
            }),
            now
          );
        }
      },
    [roomId, userId]
  );

  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));

  const { convo } = roomUser;
  const isBroadcasting = useRecoilValue(isBroadcastingAtom({ roomId, userId }));
  const isInConvoWithSelfUser = convo.withPrimaryUser;
  const [hasTalkedToSelfUser, setHasTalkedToSelfUser] = useState(
    isInConvoWithSelfUser
  );
  useEffect(() => {
    if (isInConvoWithSelfUser) {
      setHasTalkedToSelfUser(true);
    }
    // don't clear it if no longer in convo since we want to know historically if user
    // talked to self user
  }, [isInConvoWithSelfUser]);

  const hoveredConvo = useRecoilValue(convoHoverAtom);
  const convoHovered =
    hoveredConvo?.roomId === roomId && hoveredConvo?.convoId === convo?.convoId;
  const useOverHearing = useRecoilValue(
    buttonActive({ button: ButtonType.OVERHEARING })
  );
  const micActive = useRecoilValue(buttonActive({ button: ButtonType.MIC }));
  const { vol: rawOverHearingVolume } = useRecoilValue(ambientSoundAtom);
  // we want to dampen all other rooms' ambient sound while we are talking in the selected room.
  const overhearingVolumePercent = useMemo(() => {
    return micActive && !isRoomSelected
      ? Math.min(rawOverHearingVolume, DEFAULT_OVERHEARING_DAMPEND_VOLUME)
      : rawOverHearingVolume;
  }, [micActive, rawOverHearingVolume, isRoomSelected]);

  const [gainNode, setGainNode] = useState<IGainNode<IAudioContext> | null>(
    null
  );
  const [audioContext, setAudioContext] = useState<IAudioContext | null>(null);
  const [filterNode, setFilterNode] =
    useState<IBiquadFilterNode<IAudioContext> | null>(null);

  useEffect(() => {
    if (!track) return;
    logger.info('AgoraUserAudio set up', {
      roomId,
      userId,
      convoId: convo.convoId,
    });
    if (isInConvoWithSelfUser || isBroadcasting) {
      if (!track.isPlaying) {
        track.setVolume(100);
        track.play();
      }
    } else {
      // enable audio when table is hovered as well even if
      // overhearing setting is disabled
      if (useOverHearing || convoHovered) {
        const data = setOverhearingFilter(track);
        setGainNode(data.gain);
        setAudioContext(data.audioContext);
        setFilterNode(data.filter);
        if (track.isPlaying) track.stop();
        return () => {
          data.loopBack.loopback.destroy();
          data.streamSource.disconnect();
          data.audioContext.close();
          track.getMediaStreamTrack().enabled = true;
          track.play();
        };
      }
      if (track.isPlaying) track.stop();
    }
    // Don't depend on track as it always changes.
  }, [
    isInConvoWithSelfUser,
    track,
    convoHovered,
    useOverHearing,
    convo.convoId,
    isBroadcasting,
    roomId,
    userId,
  ]);

  const isWindowFocused = useRecoilValue(isWindowFocusedAtom);

  // Control the gain based off certain events
  // TODO: right now this resets everytime user unmutes.
  // so in future remember if we've already reached started adjusting
  // and just go from there. Or don't ramp up if convoId is old.
  useEffect(() => {
    if (!gainNode || !audioContext) {
      return;
    }

    // how slow the ramp up of this convo's volume will be
    // when window is in the background.
    // Used so that we don't startle people with a random convo.
    // We use smaller ramp up if your vol is already low
    const defaultDelaySecs = overhearingVolumePercent < 10 ? 40 : 70;
    const adjustedVol =
      overhearingVolumePercent === 0 ? 0 : overhearingVolumePercent / 100;

    const adjustGain = ({ value, delay }: { value: number; delay: number }) => {
      const end = audioContext.currentTime + delay;
      if (delay === 0) {
        gainNode.gain.value = value;
      } else {
        gainNode.gain.value = 0.005; // use this for exponential
        gainNode.gain.exponentialRampToValueAtTime(value, end);
        // we could potentially add more ramp up/downs here
      }
    };

    if (convoHovered) {
      // highest ambient volume
      adjustGain({ value: 1.0, delay: 3 });
    } else if (hasTalkedToSelfUser) {
      // we want to hear other user immediately if i leave convo from minigloo
      // and they said something else (even if window is unfocused)
      // we boost the audio here slightly in case user has overhearing off
      adjustGain({ value: Math.max(adjustedVol, 0.08), delay: 0 });
    } else if (isWindowFocused) {
      if (isRoomSelected) {
        adjustGain({ value: adjustedVol, delay: 0 });
      } else if (gainNode.gain.value === 0) {
        // if gain hasnt been ramped up do so
        adjustGain({ value: adjustedVol, delay: defaultDelaySecs });
      }
    } else {
      // if gain hasnt been ramped up do so
      // eslint-disable-next-line no-lonely-if
      if (gainNode.gain.value === 0) {
        adjustGain({ value: adjustedVol, delay: defaultDelaySecs });
      }
      // otherwise it means we're in the middle of adjusting gain
    }
    const ret = setInterval(
      () => logger.info(`gain for user ${userId}`, gainNode.gain.value),
      10000
    );
    return () => {
      clearInterval(ret);
    };
  }, [
    convoHovered,
    gainNode,
    convo.convoId,
    userId,
    isRoomSelected,
    isWindowFocused,
    overhearingVolumePercent,
    audioContext,
  ]);

  // filters
  useEffect(() => {
    if (!filterNode) {
      return;
    }
    if (!convoHovered) {
      if (isRoomSelected) {
        makeFilterFarAway(filterNode);
      } else {
        // unselected room audio sounds like it's in another room
        makeFilterMuffled(filterNode);
      }
    } else {
      // TODO: other properties we may need to reset here?
      filterNode.type = 'allpass';
    }
  }, [filterNode, convoHovered, isRoomSelected]);

  useEffect(() => {
    if (!track) return;

    const ret = setInterval(
      () => setRoomUserVolume(track.getVolumeLevel()),
      500
    );
    return () => {
      clearInterval(ret);
      setRoomUserVolume(0);
    };
  }, [track]);

  return <></>;
};
