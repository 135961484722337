import { NavItem, NavItemData } from './NavItem';

export interface NavSectionData {
  title?: string;
  navItems: NavItemData[];
}
export const NavSection = ({ title, navItems }: NavSectionData) => {
  // select something here, which makes
  return (
    <div className="flex flex-col mb-2">
      <div className="my-2 text-sm font-semibold uppercase">{title}</div>

      {navItems.map((i) => {
        return <NavItem key={i.name} name={i.name} category={i.category} />;
      })}
    </div>
  );
};
