import { useRecoilValue } from 'recoil';
import { generalErrorAtomm } from 'renderer/atoms/error';

export const ErrorMessage = () => {
  const errorMessage = useRecoilValue(generalErrorAtomm);
  return (
    <>
      {errorMessage && (
        <div className="font-semibold text-red-500">{errorMessage}</div>
      )}
    </>
  );
};
