import AgoraRTC, {
  ICameraVideoTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng';
import logger from 'js-logger';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import {
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import { avDeviceIdAtom } from 'renderer/atoms/settings';
import { trpcAtom } from 'renderer/common/components/TRPCReactQuery';
import {
  ButtonState,
  buttonStateAtom,
  ButtonType,
} from 'renderer/connection/buttons';
import { RoomKey } from 'renderer/models/Keys';
import { localVideoCamTrackAtom } from '../atoms/videoCameraAtoms';

export const useVideoCamToggles = () => {
  const [isLoading, setIsLoading] = useState(false);

  const turnOffTrack = useRecoilCallback(
    ({ set, snapshot }) =>
      async (key?: RoomKey) => {
        if (isLoading) {
          throw new Error("Couldn't turn off cam track, try again later");
        }
        setIsLoading(true);
        try {
          const response = await snapshot.getPromise(localVideoCamTrackAtom);
          logger.debug('useVideoStream: Turning off room', response);
          if (!response) return;
          const { video, audio, roomId } = response;
          if (key) {
            if (roomId !== key.roomId) return;
          }
          video.close();
          audio?.close();
          set(localVideoCamTrackAtom, null);
          set(buttonStateAtom({ button: ButtonType.VIDEO }), ButtonState.OFF);
          const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
          const trpcClient = await snapshot.getPromise(trpcAtom);
          // const now = new Date().getTime();
          await trpcClient.mutation('users.status.device', {
            device: 'camera',
            roomId,
            mode: 'off',
            teamId,
          });
        } finally {
          setIsLoading(false);
        }
      },
    [isLoading]
  );

  const turnOnTrack = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ roomId }: RoomKey) => {
        if (isLoading) {
          throw new Error("Couldn't turn off cam track, try again later");
        }
        setIsLoading(true);
        try {
          const localTrack = await snapshot.getPromise(localVideoCamTrackAtom);
          if (localTrack)
            throw new Error("Can't stream video. Already sharing.");

          const selfUserId = await snapshot.getPromise(selfUserIdAtom);

          const { convo } = await snapshot.getPromise(
            roomUserAtom({ roomId, userId: selfUserId })
          );
          if (!convo.convoId) throw new Error('User must be on table');

          const video = await AgoraRTC.createCameraVideoTrack();

          set(localVideoCamTrackAtom, {
            video,
            audio: undefined,
            roomId,
            convoId: convo.convoId,
          });
          set(buttonStateAtom({ button: ButtonType.VIDEO }), ButtonState.ON);
          const updateDevicePromise = updateDevice(video);

          logger.debug('useVideoStream: Turning on room', {
            video,
            roomId,
            convo,
          });
          const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
          const trpcClient = await snapshot.getPromise(trpcAtom);
          // const now = new Date().getTime();
          await trpcClient.mutation('users.status.device', {
            device: 'camera',
            roomId,
            mode: 'on',
            teamId,
          });
          await updateDevicePromise;
        } finally {
          setIsLoading(false);
        }
      },
    [isLoading]
  );

  const toggleVideoCam = useRecoilCallback(
    ({ snapshot }) =>
      async (key: RoomKey) => {
        const response = await snapshot.getPromise(localVideoCamTrackAtom);
        if (response) {
          if (response.roomId !== key.roomId) {
            await turnOffTrack(key);
            await turnOnTrack(key);
          } else {
            await turnOffTrack(key);
          }
        } else {
          await turnOnTrack(key);
        }
      },
    [turnOffTrack, turnOnTrack]
  );

  const updateDevice = useRecoilCallback(
    ({ snapshot, set }) =>
      async (track?: ICameraVideoTrack) => {
        const deviceId = await snapshot.getPromise(avDeviceIdAtom('video'));
        const localTrack = await snapshot.getPromise(localVideoCamTrackAtom);
        logger.info('VideoCamera: updateDevice', {
          deviceId,
          localTrack,
          track,
        });

        const trackToModify = track ?? localTrack?.video;
        if (!trackToModify) {
          return;
        }
        let actualDeviceId = deviceId;
        // We do this cause agora wont actually switch the mic unless you give it an actual
        // deviceId that is not the "default" string, so we have to find the actual deviceId
        // by linking with groupId.
        if (actualDeviceId === 'default') {
          // Label
          const label = track?.getTrackLabel();
          const cameras = await AgoraRTC.getCameras();
          if (track) {
            const targetDevice = cameras.find((m) => m.label === label);
            if (targetDevice) {
              set(avDeviceIdAtom('video'), targetDevice.deviceId);
              return;
            }
          }

          const defaultDevice = cameras.find((m) => m.deviceId === 'default');

          const actualDevice = cameras.find(
            (m) =>
              m.deviceId !== 'default' && m.groupId === defaultDevice?.groupId
          );
          actualDeviceId = actualDevice?.deviceId ?? 'default';
        }

        logger.info('VideoCamera: updateDevice', {
          deviceId,
          actualDeviceId,
        });
        await trackToModify.setDevice(actualDeviceId);
      }
  );

  return { turnOffTrack, turnOnTrack, toggleVideoCam, updateDevice };
};
