import { LeakAdd } from '@mui/icons-material';
import { NotificationPanelState, SelfUser } from 'api/models';
import { RoomController } from 'renderer/pages/dashboard/components/MainView/RoomView.tsx/RoomController/NotificationRoomController';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ExcessUserCountCircle } from '../user/GlooUser';
import { UserAvatar } from './user';
import { ChildNotifContainer } from './ChildNotifContainer';
import { ChildNotifTitle } from './ChildNotifTitle';
import { Tooltip } from '../Tooltip';

const MAX_CIRCLES_SHOWN = 4;

export const ActiveConversation: React.FC<{
  state: NonNullable<NotificationPanelState['room'][0]['activeConvo']>;
  meta: NotificationPanelState['room'][0]['meta'];
  self: SelfUser;
}> = ({ state, meta, self }) => {
  const useAggregateRendering = state.activeUsers.length > MAX_CIRCLES_SHOWN;
  const excessUsers = state.activeUsers.length - (MAX_CIRCLES_SHOWN - 1);
  const renderedUsers = useAggregateRendering
    ? state.activeUsers.slice(0, MAX_CIRCLES_SHOWN - 1)
    : state.activeUsers;

  return (
    <ChildNotifContainer>
      <ChildNotifTitle
        icon={
          state.event ? (
            <TodayRoundedIcon className="!w-4 !h-4 !text-yellow-primary" />
          ) : (
            <VolumeUpRoundedIcon className="!w-4 !h-4 !text-blue-primary" />
          )
        }
        title={state.event ? state.event.title : 'Conversation'}
      />

      {state.event && (
        <div className="flex flex-row items-center justify-center gap-x-1">
          <div className="text-2xs text-gray-3">
            {state.event.minsUntil > 0
              ? `Starting in ${state.event.minsUntil} mins`
              : `${(state.event.instance.start as Date).toLocaleTimeString(
                  'en-US',
                  {
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                )} - ${(state.event.instance.end as Date).toLocaleTimeString(
                  'en-US',
                  {
                    hour: '2-digit',
                    minute: '2-digit',
                  }
                )}`}
          </div>
        </div>
      )}
      <div className="relative flex flex-wrap items-center justify-center min-h-[32px] w-full gap-1">
        {renderedUsers.length === 0 ? (
          <span className="text-sm">Just You</span>
        ) : (
          renderedUsers.map((u) => <UserAvatar key={u.userId} user={u} />)
        )}
        {useAggregateRendering && (
          <ExcessUserCountCircle
            iconSizePx={30}
            count={excessUsers}
            color="bg-inherit"
          />
        )}
      </div>
      <div className="absolute right-[6px] top-1">
        <Tooltip message="Users ambiently listening">
          <div className="flex flex-row items-center gap-0.5 text-sm text-purple-lighter animate-pulse">
            <LeakAdd className="!w-3.5 !h-3.5" />
            {state.ambientUsers}
          </div>
        </Tooltip>
      </div>
      <RoomController activeConvo={state} meta={meta} self={self} />
    </ChildNotifContainer>
  );
};

export const AmbientConversation: React.FC<{
  state: NotificationPanelState['room'][0]['ambientConvos'][0];
  meta: NotificationPanelState['room'][0]['meta'];
  self: SelfUser;
}> = ({ state, meta, self }) => {
  const useAggregateRendering = state.activeUsers.length > MAX_CIRCLES_SHOWN;
  const excessUsers = state.activeUsers.length - (MAX_CIRCLES_SHOWN - 1);
  const renderedUsers = useAggregateRendering
    ? state.activeUsers.slice(0, MAX_CIRCLES_SHOWN - 1)
    : state.activeUsers;
  const [isLoading, setLoading] = useState(false);

  return (
    <ChildNotifContainer>
      <ChildNotifTitle
        icon={
          state.event ? (
            <TodayRoundedIcon className="!w-4 !h-4 !text-yellow-primary" />
          ) : (
            <VolumeUpRoundedIcon className="!w-4 !h-4 !text-blue-primary" />
          )
        }
        title={state.event ? state.event.title : 'Conversation'}
      />
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-center gap-x-1">
          {state.event ? (
            <div className="text-2xs text-gray-3">
              {state.event.minsUntil > 0
                ? `Starting in ${state.event.minsUntil} mins`
                : `${(state.event.instance.start as Date).toLocaleTimeString(
                    'en-US',
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  )} - ${(state.event.instance.end as Date).toLocaleTimeString(
                    'en-US',
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                    }
                  )}`}
            </div>
          ) : (
            <>
              <LeakAddIcon className="!w-2.5 !h-2.5 !text-gray-3" />
              <div className="text-2xs text-gray-3">Ambiently Listening</div>
            </>
          )}
        </div>
        <div className="relative flex flex-wrap items-center justify-center min-h-[32px] w-full gap-1">
          {renderedUsers.length === 0 ? (
            <span className="text-sm">No users present yet</span>
          ) : (
            renderedUsers.map((u) => <UserAvatar key={u.userId} user={u} />)
          )}
          {useAggregateRendering && (
            <ExcessUserCountCircle
              iconSizePx={30}
              count={excessUsers}
              color="bg-inherit"
            />
          )}
        </div>
        {isLoading ? (
          <ClipLoader color="gray" size={23} />
        ) : (
          <button
            type="button"
            className="p-1 px-2 text-xs rounded-md hover:bg-blue-primary/80 hover:scale-105 w-fit bg-blue-primary text-white-1"
            onClick={async () => {
              setLoading(true);
              await window.electron?.ipcRenderer.setButtonState({
                button: 'JOIN_CONVO',
                roomId: meta.roomId,
                teamId: meta.teamId,
                convoId: state.convoId,
              });
              setLoading(false);
            }}
          >
            <div className="text-xs text-white-1">
              {self.status.online ? 'Join' : 'Go online and join'}
            </div>
          </button>
        )}
      </div>
    </ChildNotifContainer>
  );
};
