import { Placement } from '@popperjs/core';
import { useEffect } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import './TooltipStyle.css';

export const Tooltip = ({
  message,
  children,
  disabled = false,
  delayShowMs,
  className,
  placement,
}: {
  message?: string;
  children: React.ReactNode;
  disabled?: boolean;
  delayShowMs?: number;
  className?: string;
  placement?: Placement;
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    update,
    visible,
    tooltipRef,
  } = usePopperTooltip({
    delayShow: delayShowMs,
    placement: placement || 'auto',
  });

  useEffect(() => {
    if (update && message) update();
  }, [message, update, tooltipRef]);

  if (disabled || !message) {
    return <>{children}</>;
  }

  return (
    // wrapper div fixes some alignment issues on the tooltip not sure why
    <div>
      {visible && (
        <div
          ref={setTooltipRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getTooltipProps({
            className: 'tooltip-container',
          })}
        >
          {message.split('\n').map((p, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className="text-xs whitespace-nowrap" key={`tag-${idx}`}>
              {p}
            </span>
          ))}
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getArrowProps({ className: 'tooltip-arrow' })}
          />
        </div>
      )}
      <div ref={setTriggerRef} className={className}>
        {children}
      </div>
    </div>
  );
};
