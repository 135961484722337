import clsx from 'clsx';
import { add, addSeconds, format, lightFormat, setDay } from 'date-fns';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedRoomAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import { InferMutationInput, trpc } from 'renderer/common/client/trpc';
import { CalEvent } from 'renderer/models/Api';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { OutlineButton } from 'renderer/shared/Buttons/helpers/OutlineButton';
import { InputField } from 'renderer/shared/input/InputField';
import { ModalTypes, selectedModalAtom } from '../atoms';

// set this before opening this modal to modify an event
// we do this rather than passing it thru props since our modal framework doesn't easily accept props yet
export const eventToModifyAtom = atom<CalEvent | undefined>({
  key: 'eventToModifyAtom',
  default: undefined,
});

export const CreateOrUpdateEventModal = () => {
  const room = useRecoilValue(selectedRoomAtom);
  const event = useRecoilValue(eventToModifyAtom);

  return (
    <div className="flex flex-col items-center justify-center gap-2 text-black-primary">
      <span className="text-xl font-semibold text-black-primary">
        {event ? 'Modify event in ' : 'Create an event in '}
        <span className="text-blue-dark/80">{room.name}</span>
      </span>
      {/* <EventPropertiesContainer eventToModify={event} /> */}
    </div>
  );
};

type DurationType = 'hours' | 'minutes';

// const EventPropertiesContainer = ({
//   eventToModify,
// }: {
//   eventToModify?: CalEvent;
// }) => {
//   const roomId = useRecoilValue(selectedRoomKeyAtom);
//   const [errorMessage, setErrorMessage] = useState<string | null>(null);
//   const setSelectedModal = useSetRecoilState(selectedModalAtom);

//   const [name, setName] = useState<string | undefined>(eventToModify?.name);
//   const [description, setDescription] = useState<string | undefined>();
//   const [timeOfDayMins, setTimeOfDayMins] = useState<number>(() => {
//     if (!eventToModify) {
//       return 60 * 9;
//     }
//     const d = new Date();
//     d.setUTCHours(eventToModify.recurrence.time.hour);
//     d.setUTCMinutes(eventToModify.recurrence.time.minute);
//     return d.getMinutes() + d.getHours() * 60;
//   });
//   const [duration, setDuration] = useState<number>(
//     eventToModify?.recurrence.duration || 30
//   );
//   const [durationType, setDurationType] = useState<DurationType>('minutes');
//   const [daysSelected, setDaysSelected] = useState<boolean[]>(
//     eventToModify?.recurrence.days ?? [
//       false,
//       true,
//       true,
//       true,
//       true,
//       true,
//       false,
//     ]
//   );
//   let timeSlot = new Date();
//   timeSlot.setHours(0, 0, 0, 0);
//   timeSlot = add(timeSlot, { minutes: timeOfDayMins });

//   const createEventMutation = trpc.useMutation('rooms.event.create', {
//     onSuccess: (data) => {},
//     onError: (error: any) => {
//       setErrorMessage(error.message || 'Unknown error.');
//       setTimeout(() => setErrorMessage(null), 4000);
//     },
//   });
//   const updateEventMutation = trpc.useMutation('rooms.event.update', {
//     onSuccess: (data) => {
//       console.log('MYEVENTS DONE');
//     },
//     onError: (error: any) => {
//       setErrorMessage(error.message || 'Unknown error.');
//       setTimeout(() => setErrorMessage(null), 4000);
//     },
//   });

//   const meetingDurationMins =
//     durationType === 'hours' ? duration * 60 : duration;

//   return (
//     <div className="flex flex-col items-center justify-center min-w-[30vh] text-lg gap-y-3">
//       <InputField
//         title="Event name"
//         placeholderText="e.g. 1:1 Meeting, Standup, Weekly Review"
//         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//           setName(e.target.value);
//         }}
//         value={name}
//       />
//       <div className="flex flex-row items-center justify-start w-full gap-2 h-fit whitespace-nowrap">
//         <div className="flex h-full text-base font-semibold align-middle">
//           Starts at
//         </div>
//         <TimePicker
//           defaultTimeMins={timeOfDayMins}
//           onChange={(minsOfDay) => {
//             setTimeOfDayMins(minsOfDay);
//           }}
//         />
//         <DaysOfWeekSelection
//           daysSelected={daysSelected}
//           onClick={(i, enabled) => {
//             setDaysSelected((prevArray) => {
//               const newArr = [...prevArray];
//               newArr[i] = enabled;
//               return newArr;
//             });
//           }}
//         />
//       </div>
//       <DurationInput
//         duration={duration}
//         durationType={durationType}
//         onDurationChange={(dur) => setDuration(dur)}
//         onDurationTypeChange={(durType) => setDurationType(durType)}
//       />

//       {/* <InputField
//         title="Description"
//         optional
//         placeholderText="a short description of what the event is about"
//         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
//           setDescription(e.target.value);
//         }}
//       /> */}
//       {/* <ToggleSetting
//         title="Make Private"
//         description="When a room is set to private, it can only be viewed or joined by invitation"
//         isOn={isPrivate}
//         onChange={() => {
//           setPrivate(!isPrivate);
//         }}
//       /> */}
//       {createEventMutation.isLoading || updateEventMutation.isLoading ? (
//         <ClipLoader color="gray" />
//       ) : (
//         <div className="flex flex-row items-center gap-2">
//           <OutlineButton
//             clickHandler={() => setSelectedModal(ModalTypes.kListEventsForRoom)}
//           >
//             Cancel
//           </OutlineButton>
//           <GradientButton
//             enableChevron
//             clickHandler={async () => {
//               if (!daysSelected.find((d) => d)) {
//                 setErrorMessage('A day must be selected');
//                 return;
//               }
//               const newEvent: InferMutationInput<'rooms.event.create'>['event'] =
//                 {
//                   active: true,
//                   name: name ?? 'Unnamed event',
//                   recurrence: {
//                     days: daysSelected,
//                     duration: meetingDurationMins,
//                     time: {
//                       hour: timeSlot.getUTCHours(),
//                       minute: timeSlot.getUTCMinutes(),
//                     },
//                     type: 'WEEKLY',
//                   },
//                 };
//               if (eventToModify) {
//                 await updateEventMutation.mutateAsync({
//                   roomId,
//                   eventId: eventToModify.eventId,
//                   event: newEvent,
//                 });
//               } else {
//                 await createEventMutation.mutateAsync({
//                   roomId,
//                   event: newEvent,
//                 });
//               }
//               // redirect back
//               setSelectedModal(ModalTypes.kListEventsForRoom);
//             }}
//           >
//             {eventToModify ? 'Save' : 'Create'}
//           </GradientButton>
//         </div>
//       )}

//       {errorMessage && (
//         <div className="text-sm italic font-bold text-red-500">
//           {errorMessage}
//         </div>
//       )}
//     </div>
//   );
// };

const DurationInput = ({
  duration,
  durationType,
  onDurationChange,
  onDurationTypeChange,
}: {
  duration: number;
  durationType: DurationType;
  onDurationChange: (v: number) => void;
  onDurationTypeChange: (v: DurationType) => void;
}) => {
  return (
    <div className="flex items-center justify-start w-full gap-2 text-base font-normal">
      <div className="text-base font-semibold">for</div>
      <div className="flex p-2 bg-white-1 rounded-md shadow-sm outline-gray-300 outline outline-[1px]">
        <input
          className="w-10"
          type="number"
          placeholder={duration.toString()}
          step="15"
          value={duration}
          onChange={(v) => onDurationChange(parseInt(v.target.value, 10))}
        />
      </div>
      <div className="flex p-2 bg-white-1 rounded-md shadow-sm outline-gray-300 outline outline-[1px]">
        <select
          name="durationType"
          className="bg-transparent"
          value={durationType}
          onChange={(v) => onDurationTypeChange(v.target.value as DurationType)}
        >
          {['hours', 'minutes'].map((t) => {
            return (
              <option key={t} value={t}>
                {t}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const DaysOfWeekSelection = ({
  daysSelected,
  onClick,
}: {
  daysSelected: boolean[];
  onClick: (dayNum: number, enabled: boolean) => void;
}) => {
  if (daysSelected.length !== 7) {
    throw new Error('Invalid number of days');
  }
  return (
    <div className="flex w-full gap-2">
      <div className="text-base font-semibold">every</div>
      <div className="flex flex-row gap-1 font-normal">
        {daysSelected.map((d, i) => {
          const date = new Date();
          const newDate = setDay(date, i, { weekStartsOn: 6 });
          return (
            <button
              type="button"
              onClick={() => onClick(i, !d)}
              className={clsx(
                'flex w-6 h-6 text-white-1 text-sm items-center justify-center rounded-full',
                [d ? 'bg-blue-primary' : 'bg-gray-3']
              )}
            >
              {format(newDate, 'EEEEE')}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const ToggleSetting = ({
  isOn,
  title,
  description,
  onChange,
}: {
  isOn: boolean;
  title: string;
  description: string;
  onChange: () => void;
}) => {
  return (
    <>
      <div className="flex flex-row items-stretch justify-between w-full">
        <div className="flex flex-col w-[80%]">
          <span className="text-base font-semibold text-black-lighter">
            {title}
          </span>
          <span className="text-base text-gray-4">{description}</span>
        </div>
        <div className="flex items-center">
          <Switch isOn={isOn} onClick={onChange} />
        </div>
      </div>
    </>
  );
};

const Switch = ({ isOn, onClick }: { isOn: boolean; onClick: () => void }) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={onClick}
      // TODO will this work if we do place-content-center for 3 items?
      className={`items-start flex h-[20px] w-[36px] rounded-full p-[2px] shadow-inner hover:cursor-pointer ${
        isOn ? 'bg-blue-lighter' : 'bg-gray-2'
      } ${isOn && 'place-content-end'}`}
    >
      <motion.div
        className="flex h-[16px] w-[16px] items-center justify-center rounded-full bg-white-1"
        layout
        transition={spring}
      />
    </div>
  );
};

const TimePicker = ({
  onChange,
  defaultTimeMins,
}: {
  defaultTimeMins: number;
  onChange: (value: number) => void;
}) => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const maxMins = 24 * 60;
  // selected mins
  const [activeTime, setActiveTime] = useState(defaultTimeMins);
  // const setTimeModified = useSetRecoilState(timeModifiedAtom);
  const startIntervals = useMemo(() => range(0, 24 * 60, 30), []);
  if (activeTime > maxMins) {
    throw new Error('Invalid time chosen');
  }

  return (
    <div className="flex items-center justify-center space-x-1 text-base font-normal">
      <div className="flex p-2 bg-white-1 rounded-md shadow-sm outline-gray-300 outline outline-[1px]">
        <select
          name="hours"
          className="bg-transparent"
          defaultValue={activeTime}
          onChange={(v) => {
            const newTarget = parseInt(v.target.value, 10);
            onChange(newTarget);
            setActiveTime(newTarget);
          }}
        >
          {startIntervals.map((t) => {
            const newDate = add(d, { minutes: t });
            return (
              <option key={t} value={t}>
                {lightFormat(newDate, 'hh:mm aa')}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const range = (start: number, end: number, step = 1) => {
  const output = [];
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
