import { PropsWithChildren, useContext, useEffect } from 'react';
import { RiSwapBoxLine } from 'react-icons/ri';
import { PuffLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { primaryMachineAtom, selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { trpc } from 'renderer/common/client/trpc';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';
import { SimpleButton } from 'renderer/shared/SimpleButton';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';

export const MachineStateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const primaryMachine = useRecoilValue(primaryMachineAtom);
  const myMachineId =
    useContext(AppInfoContext)?.machineId || 'UNKNOWN MACHINE ID';
  const setPrimary = trpc.useMutation('users.setPrimary');

  useEffect(() => {
    if (primaryMachine === undefined) {
      setPrimary.mutate({ machineId: myMachineId });
    }
    // setPrimary changes often
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryMachine, myMachineId]);

  if (primaryMachine !== myMachineId) {
    if (primaryMachine === undefined) return <PuffLoader />;
    return <DifferentMachineNotice />;
  }
  return <>{children}</>;
};

const DifferentMachineNotice = () => {
  const user = useRecoilValue(selfGlooUserAtom);

  const machineId =
    useContext(AppInfoContext)?.machineId || 'UNKNOWN MACHINE ID';
  const setPrimary = trpc.useMutation('users.setPrimary');

  return (
    <>
      <div className="flex flex-col items-center gap-2 my-auto">
        <div className="flex flex-row items-center pb-2 space-x-1 text-sm text-gray-500 border-b-2 border-gray-300">
          <Avatarv2
            photoUrl={user.profile.photoUrl}
            name={user.profile.displayName ?? user.profile.email!}
            iconSizePx={40}
          />
          <div className="">
            {user.profile.displayName ?? user.profile.email}
          </div>
        </div>

        <div>You were using gloo on a different device.</div>
        <SimpleButton clickHandler={() => setPrimary.mutate({ machineId })}>
          <div className="flex flex-row gap-2">
            <RiSwapBoxLine className="h-full w-7" />
            Switch devices
          </div>
        </SimpleButton>
      </div>
    </>
  );
};
