import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { roomUserAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import {
  agoraSubscribedScreenShareTrackAtom,
  isStreamActiveFirebase,
  StreamType,
} from 'renderer/call/components/atoms/CallStateAtoms';
import { videoCamActiveAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import {
  getScreenShareTrackAtom,
  useSceenshareToggles,
} from 'renderer/call/components/ScreenShareProvider';
import { RoomUserKey } from 'renderer/models/Keys';
import { RoomController } from 'renderer/pages/dashboard/components/MainView/RoomView.tsx/RoomController/MainAppRoomController';
import {
  LocalMediaPlayer,
  RemoteMediaPlayer,
} from 'renderer/shared/Modals/screenshare/MediaPlayer';
import { Spinner } from 'renderer/shared/Spinner';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';
import { ModalTypes, selectedModalAtom } from '../atoms';

// Users can only view a single screen share at a time for now.
export const selectedViewTrackAtom = atom<RoomUserKey | null>({
  key: 'selectedViewTrackv2',
  default: null,
});

const LocalTrackDisplay = () => {
  const track = useRecoilValue(getScreenShareTrackAtom);
  return <LocalMediaPlayer videoTrack={track.video} audioTrack={track.audio} />;
};

const RemoteTrackDisplay: React.FC<RoomUserKey> = ({ roomId, userId }) => {
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const track = useRecoilValue(
    agoraSubscribedScreenShareTrackAtom({
      roomId,
      userId,
    })
  );
  const { convoId } = roomUser.convo;

  if (!track || !convoId) {
    return (
      <>
        <div className="text-white-1">Waiting for track!</div>
        <Spinner />
      </>
    );
  }

  return (
    <RemoteMediaPlayer
      roomId={roomId}
      videoTrack={track}
      sharingUserId={userId}
      convoId={convoId}
    />
  );
};

const ScreenShareDisplayWindow: React.FC<RoomUserKey> = ({
  roomId,
  userId,
}) => {
  const isScreenshareActive = useRecoilValue(
    isStreamActiveFirebase({ roomId, userId, stream: StreamType.SCREENSHARE })
  );
  const { closeModal } = useScreenShareViewModal();
  const { turnOffTrack } = useSceenshareToggles();
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const localTrack = selfUserId === userId;

  const teamUser = useRecoilValue(roomUserAtom({ roomId, userId }));

  // Close the modal if firebase says the user isn't screensharing.
  useEffect(() => {
    if (!isScreenshareActive) closeModal();
  }, [isScreenshareActive, closeModal]);

  const isCameraActive = useRecoilValue(videoCamActiveAtom({ roomId }));

  const name = teamUser.profile.displayName;

  // Wait for agora to load the track but we can still render the data otherwise.
  return (
    <div className="flex flex-col items-center max-w-full gap-3 overflow-hidden w-fit">
      <div className="flex items-center gap-2 text-white-1 w-fit">
        <RoomController darkMode roomId={roomId} />
        <div className="w-10 h-10">
          {/* {isCameraActive ? (
            <VideoStream roomId={roomId} userId={userId} />
          ) : ( */}
          <Avatarv2
            name={name}
            photoUrl={teamUser.profile.photoUrl}
            iconSizePx={40}
          />
          {/* )} */}
        </div>
        <div className="flex flex-col text-xs">
          <span>{name}</span>
          <span>screensharing</span>
        </div>
      </div>
      {/** This branch condition exists purely to make typescript happy. */}
      {localTrack ? (
        <ErrorBoundary fallback={<Spinner />}>
          <LocalTrackDisplay />
        </ErrorBoundary>
      ) : (
        <RemoteTrackDisplay roomId={roomId} userId={userId} />
      )}
    </div>
  );
};

export const useScreenShareViewModal = () => {
  const setModal = useSetRecoilState(selectedModalAtom);
  const showModal = useRecoilCallback(
    ({ set }) =>
      (roomId: string, userId: string) => {
        // Show the screen share.
        set(selectedViewTrackAtom, { roomId, userId });
        // set(modalAtom, true);
        setModal(ModalTypes.kScreenshareView);
      }
  );

  const maybeShowModal = useRecoilCallback(
    ({ snapshot }) =>
      async (roomId: string, userId: string) => {
        const [currRoomId, selectedModal] = await Promise.all([
          snapshot.getPromise(selectedRoomKeyAtom),
          snapshot.getPromise(selectedModalAtom),
        ]);
        if (
          currRoomId === roomId &&
          selectedModal !== ModalTypes.kScreenshareView
        ) {
          // Show the screen share.
          showModal(roomId, userId);
        }
      }
  );

  const closeModal = useRecoilCallback(({ set }) => () => {
    setModal(ModalTypes.kNone);
    set(selectedViewTrackAtom, null);
  });

  return { maybeShowModal, showModal, closeModal };
};

const ScreenShareModalWrapper = () => {
  const selectedTrack = useRecoilValue(selectedViewTrackAtom);
  if (!selectedTrack) {
    return null;
  }

  return (
    <ScreenShareDisplayWindow
      roomId={selectedTrack.roomId}
      userId={selectedTrack.userId}
    />
  );
};

export const ViewScreenShareModal: React.FC = () => {
  return <ScreenShareModalWrapper />;
};
