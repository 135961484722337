import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { selectedRoomKeyAtom, useRoomAtomSetters } from 'renderer/atoms/room';
import {
  localMicActiveAtom,
  useMicToggles,
} from 'renderer/call/components/MicrophoneProvider';
import {
  selfScreenshareActiveAtom,
  useSceenshareToggles,
} from 'renderer/call/components/ScreenShareProvider';
import { trpc } from 'renderer/common/client/trpc';
import { ModalTypes, selectedModalAtom, useModalErrors } from './atoms';

export const LeaveRoomModal: React.FC = () => {
  const { setModalError } = useModalErrors();
  const { removeRoom } = useRoomAtomSetters();
  const navigate = useNavigate();
  const leaveRoomMutation = trpc.useMutation(['rooms.leave'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
  });
  const { turnOffTrack: turnOffMic } = useMicToggles();
  const { turnOffTrack: turnOffScreenShare } = useSceenshareToggles();

  const leaveRoom = useRecoilCallback(
    ({ snapshot, set }) =>
      async (muation: typeof leaveRoomMutation) => {
        const roomId = await snapshot.getPromise(selectedRoomKeyAtom);
        const micOn = await snapshot.getPromise(localMicActiveAtom({ roomId }));
        if (micOn) await turnOffMic();
        const screenShareOn = await snapshot.getPromise(
          selfScreenshareActiveAtom({ roomId })
        );
        if (screenShareOn) await turnOffScreenShare();

        await muation.mutateAsync({ roomId });
        removeRoom(roomId);
        set(selectedModalAtom, ModalTypes.kNone);
        navigate('/dashboard');
      }
  );

  return (
    <>
      <div className="flex flex-col justify-center text-black-primary">
        <div className="text-sm text-black-lightest">
          Once you leave, you&apos;ll need to be reinvited again.
        </div>
        <button
          type="button"
          onClick={() => leaveRoom(leaveRoomMutation)}
          className="px-2 py-1 ml-2 text-base font-medium leading-tight transition duration-150 ease-in-out bg-red-500 rounded shadow-lg outline-none no-drag hover:bg-red-700 hover:shadow-lg text-white-1"
        >
          Confirm
        </button>
      </div>
    </>
  );
};
