/* eslint-disable max-len */
import {WebsiteLogin} from "components/auth/WebsiteLogin";
import {GradientButton} from "components/GradientButton";
import {signOut} from "firebase/auth";
import {useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {useAuth, useUser} from "reactfire";
import {trpc} from "renderer/common/client/trpc";
import {Team} from "renderer/models/Api";
import {GlooIcon as GlooSvg} from "renderer/shared/icons/GlooIcon";
import {Avatarv2} from "renderer/shared/user/Avatarv2";
import GlooIcon from "resources/icons/gloo-icon.svg";
import {AltLoginText} from "../../components/auth/AltLoginText";

const AcceptView: React.FC = () => {
  const params = useParams();

  const teamId = params.teamId ?? "";
  const userId = params.userId ?? "";

  const [errMsg, setErrMsg] = useState<string | null>(null);

  const onError = (error: string) => {
    setErrMsg(error);
  };

  const {data: team, isLoading, refetch} = trpc.useQuery(["teams.details", {teamId}], {
    onError: (err) => onError(err.message),
  });

  if (isLoading) {
    return <><ClipLoader /></>;
  }

  return (
    <div className="flex flex-col items-center space-y-2">
      {errMsg && <div className="text-base italic font-bold text-red-900">
        {errMsg}
      </div>}
      {team &&
      <InviteContents errHandler={onError} team={team} targetUserId={userId} />}
    </div>);
};

const InviteContents = ({team, targetUserId, errHandler}: { team: Team, targetUserId: string; errHandler: (err: string) => void }) => {
  const user = useUser();
  const teamUser = team.members.find(({userId}) => userId === targetUserId);
  const activeMembers = team.members.filter(({membership}) => !membership.isDeleted && !membership.invite);
  const previewList = activeMembers.map(({profile}) => profile);
  const usersJoinedCount = activeMembers.length;
  return (
    <div className="flex flex-col w-full h-[100%] justify-center items-center">
      <div className="flex flex-col items-center w-full pt-6 bg-blue-lightest-2/50">
        <div className="flex flex-row items-center pb-8 space-x-2">
          <img src={GlooIcon} className="w-12 h-12" />
          <div className="text-3xl font-bold text-black-lighter">Gloo</div>
        </div>
        <div className="space-x-3 text-5xl font-semibold text-black-primary/85 px-15">
          {/* Start talking in <b className="text-blue-900 capitalize">{invite.name}</b> */}
          Start talking in <b className="text-blue-900 capitalize">{team.name}</b>
        </div>
        {/* <div>Created by {invite.invitedBy}</div> */}
        <div className="flex flex-col items-center gap-2 py-6 text-black-lightest">
          <div className="text-xl text-black-lighter">Gloo is an app to talk to teammates easily.</div>
          <div className="pt-3 flex flex-row-reverse space-x-reverse space-x-1 transition-all duration-[50ms] ease-linear w-fit">
            <>

              {previewList.map((u, i) => {
                return <div key={i.toString()} className="w-[48px] h-[48px]">
                  <Avatarv2 photoUrl={u.photoUrl} name={u.displayName} iconSizePx={48} /></div>;
              })}
            </>
          </div>
          <div className="p-2 pb-6 text-base text-gray-5/80">
            <>
              <div>
                {previewList[0].displayName.split(" ")[0]} {usersJoinedCount - 1 > 1 ? `and ${usersJoinedCount} others` : ""} already joined
              </div>
            </>
          </div>
        </div>


      </div>

      <div className="pt-10 pb-6 space-y-3">
        {user.data ? <AuthSectionWrapper errHandler={errHandler} teamId={team.teamId} targetUserId={targetUserId} /> :
        (
        <>
          <div className="text-base font-medium text-black-lighter">We suggest using the email account you use for work</div>
          <WebsiteLogin showTitle={false} />
        </>
)}
      </div>
    </div>
  );
};

const AuthSectionWrapper = ({teamId, targetUserId, errHandler}: {teamId: string; targetUserId: string; errHandler: (err: string) => void }) => {
  const [isWaiting, setWaiting] = useState(true);

  // wait so that the parent components re-render and the
  // trpc query client gets updated with the client
  // token. If we don't wait then the child
  // will re-render first it seems..
  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 50);
  }, []);

  if (isWaiting) {
    return <ClipLoader />;
  }
  return <AuthSection errHandler={errHandler} teamId={teamId} targetUserId={targetUserId}/>;
};

const AuthSection = ({teamId, targetUserId, errHandler}: {teamId: string; targetUserId:string; errHandler: (err: string) => void }) => {
  const user = useUser();
  const navigate = useNavigate();
  const auth = useAuth();
  // We don't call invite.details again b/c for some
  // goddamn reason the trpc.Provider or QueryClient
  // is caching an old version of the query itself that
  // does not have the user token required to authenticate.
  // see the createTrpcClient code.
  // basically, the state of trpc client at initialization
  // stays forever until the QueryClientProvider is reset.
  const userData = trpc.useQuery(["users.query"], {
    retry: 5,
    retryDelay: 2000,
  });

  useEffect(() => {
    if (userData.data) {
      if (userData.data.teamList.includes(teamId)) {
        // User is already in the team.
        navigate("/launch");
      }
    }
  }, [teamId, navigate, userData]);

  const userMatches = useMemo(()=> userData.data?.notJoinedTeamList.find(({teamId: tid, status}) => tid === teamId && status === "INVITED"), [teamId, userData.data?.notJoinedTeamList]);


  const joinMutation = trpc.useMutation(["teams.invite.reply"], {
    onSuccess: (data) => {
      navigate("/launch");
    },
    onError: (err) => {
      if (err.message.includes("already present")) return navigate("/launch");
      errHandler(err.message);
    },
  });

  if (userData.isLoading || joinMutation.isLoading) {
    return <ClipLoader />;
  }

  // this can never happen lel
  if (!user.data) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-row items-center h-full gap-2 p-2 rounded-md outline-1 outline outline-neutral-4">
          <div className="flex w-[44px] h-auto">
            <Avatarv2 photoUrl={user.data.photoURL} name={user.data.displayName || user.data.email || ""} iconSizePx={44} />
          </div>
          <div className="flex flex-col w-full text-left">
            <div className="font-semibold text-black-lighter">{user.data.displayName || user.data.email}</div>
            <div className="text-xs text-black-lightest">{user.data.email}</div>
          </div>


        </div>
        <GradientButton
          clickHandler={() => joinMutation.mutate({
            teamId,
            accept: true,
          })}
        >
          <div className="flex items-center justify-center min-w-[12rem] gap-2 truncate">
            <GlooSvg className="w-4 h-4 text-white-1"/>
            Join the team as {user.data.email}
          </div>
        </GradientButton>
        <AltLoginText clickHandler={async () => {
          await signOut(auth);
          window.location.reload();
        }} />
      </div>
    </>);
};


export default AcceptView;
