import { IMusicTrack } from 'api/models';
import { useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { logger } from '../LogCreator';

export const musicTrackAtom = atom<IMusicTrack[]>({
  key: 'musicTrack',
  default: [],
});

export const useMusicMonitor = () => {
  const setTracks = useSetRecoilState(musicTrackAtom);

  useEffect(() => {
    window?.electron?.ipcRenderer
      .getMusicTracks()
      .then((tracks) => {
        setTracks(tracks);
        return null;
      })
      .catch((err) => {
        logger.error('Err:', err);
      });

    window.electron?.ipcRenderer.on('musicUpdate', (tracks) => {
      setTracks(tracks);
    });

    return () => {
      window?.electron?.ipcRenderer.removeAllListeners('musicUpdate');
    };
  }, []);
};
