import { useEffect } from 'react';
import { atomFamily, useSetRecoilState } from 'recoil';

export enum DateTimeIntervals {
  SECOND_60 = 60000,
  SECOND_30 = 30000,
  SECOND = 1000,
  MS_500 = 500,
}

export const intervaledDateTimeAtom = atomFamily<Date, DateTimeIntervals>({
  key: 'datetime',
  default: new Date(),
});

// Updates the date time every 500 ms.
const useDateTimeIntervalMonitor = (interval: DateTimeIntervals) => {
  const setDateTime = useSetRecoilState(intervaledDateTimeAtom(interval));

  useEffect(() => {
    const id = setInterval(() => setDateTime(new Date()), interval);
    return () => clearInterval(id);
  });
};

export const useDateTimeMonitor = () => {
  useDateTimeIntervalMonitor(DateTimeIntervals.MS_500);
  useDateTimeIntervalMonitor(DateTimeIntervals.SECOND);
  useDateTimeIntervalMonitor(DateTimeIntervals.SECOND_30);
  useDateTimeIntervalMonitor(DateTimeIntervals.SECOND_60);
};
