/* eslint-disable max-len */
import {Center} from "components/Center";

import {signInWithEmailAndPassword} from "firebase/auth";
import logger from "js-logger";
import {useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth, useUser} from "reactfire";
import {AppInfoContext} from "renderer/providers/AppInfoProvider";
import {SimpleButton} from "renderer/shared/SimpleButton";
import {Spinner} from "renderer/shared/Spinner";
import {GoogleLogin} from "./GoogleLogin";
import {MicrosoftLogin} from "./MicrosoftLogin";

export function WebsiteLogin({targetAfterLogin, showTitle}: { showTitle: boolean, targetAfterLogin?: string}) {
  const user = useUser();
  const navigate = useNavigate();
  const auth = useAuth();
  const appInfo = useContext(AppInfoContext);
  const location = useLocation();
  const [authInProgress, setAuthInProgress] = useState<boolean>(false);

  const completeAuth = useCallback(() => {
    // for msoft login we need to populate profile info to continue
    if (user.data && !authInProgress) {
      // only navigate if target is set
      if (targetAfterLogin) {
        if (location.pathname != targetAfterLogin) {
          navigate(targetAfterLogin);
        }
      }
    }
  }, [user.data, targetAfterLogin, navigate, location.pathname, authInProgress]);

  /**
   * we need to not navigate if user is null initially.
   *
   * check fb state, if user null, render buttons and let those do the navigation.
   *
   * if user not null navigate righta way.
   *
   */

  useEffect(() => {
    completeAuth();
  }, [completeAuth]);
  if (user.status === "loading" && !authInProgress) {
    return <Spinner />;
  }

  const isDev = ["emulator", "dev"].includes(appInfo.deployment);

  const devSignInHandler = () => {
    const {username, password} = {
      username: "aaron-desktop@test.gloo.chat",
      password: "aaronv",
    };
    signInWithEmailAndPassword(auth, username, password)
        .then(() => {
          logger.info("sign in successful");
          return null;
        })
        .catch((error) => {
          logger.error("error signing up", error);
        });
  };

  return (
    <>
      <div className="flex flex-col items-center space-y-3">
        {showTitle && <div className="text-3xl font-bold">Sign in to continue</div>}
        <GoogleLogin />
        <MicrosoftLogin authInProgress={authInProgress} setAuthInProgressFn={setAuthInProgress} />
        {isDev && (<SimpleButton clickHandler={devSignInHandler}>
          Sign in (test)
        </SimpleButton>)}
        <div className="text-xs text-gray-4">By continuing, you agree to our <a className="underline" href="https://app.termly.io/document/privacy-policy/a12938f5-441c-4a59-aa85-9f07499383d2">Privacy Policy</a> & <a className="underline" href="https://app.termly.io/document/terms-of-use-for-saas/9b4ef8ab-aae1-4d73-9b23-cd3ce377aa1b">Terms of Use</a>.
        </div>
      </div>
    </>
  );
}

export const WebsiteLoginWrapper = () => {
  return <>
    <Center>
      <WebsiteLogin targetAfterLogin="/launch" showTitle={true} />
    </Center>
  </>;
};
