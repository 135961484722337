import {
  onDisconnect,
  onValue,
  ref,
  serverTimestamp as realtimeDbTimestamp,
  set,
} from 'firebase/database';
import { useContext, useEffect, useMemo } from 'react';
import { useDatabase } from 'reactfire';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  FirebaseStatus,
  realtimeDbConnectionStatusAtom,
} from 'renderer/atoms/connectionStatus';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';

import LogCreator from 'renderer/common/LogCreator';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import {
  selfFirebaseUserAtom,
  selfFirebaseUserImplAtom,
} from 'renderer/atoms/firebaseUser';

const logger = LogCreator();

// Note: this does use the firebase user ID since there are some permissions already setup for that id
export const useRealtimeDbConnection = () => {
  const realtimeDb = useDatabase();
  const fbUser = useRecoilValue(selfFirebaseUserAtom);
  const myMachineId = useContext(AppInfoContext)?.machineId || 'UNKNOWN';

  const setRealtimeDBStatus = useSetRecoilState(realtimeDbConnectionStatusAtom);
  const userStatusDatabaseRef = useMemo(
    () => ref(realtimeDb, `/status/${fbUser?.uid}/machines/${myMachineId}`),
    [realtimeDb, fbUser.uid, myMachineId]
  );

  // TODO this hook seems to run when we refresh the auth token?
  useEffect(() => {
    const isOfflineRealtimeDb = {
      state: 'offline',
      last_changed: realtimeDbTimestamp(),
    };
    const isOnlineRealtimeDb = {
      state: 'online',
      last_changed: realtimeDbTimestamp(),
    };
    const unsub = onValue(
      ref(realtimeDb, '.info/connected'),
      async (snapshot) => {
        logger.info('snapshot ', snapshot.val());
        if (snapshot.val() === false) {
          setRealtimeDBStatus(FirebaseStatus.DISCONNECTED);
          return;
        }

        try {
          await onDisconnect(userStatusDatabaseRef).set(isOfflineRealtimeDb);
          try {
            await set(userStatusDatabaseRef, isOnlineRealtimeDb);
            setRealtimeDBStatus(FirebaseStatus.CONNECTED);
          } catch (error) {
            logger.error('unable to set onDisconnect handler', error);
            setRealtimeDBStatus(FirebaseStatus.DISCONNECTED);
          }
        } catch (error) {
          setRealtimeDBStatus(FirebaseStatus.DISCONNECTED);
        }
      }
    );

    return () => {
      if (unsub) {
        unsub();
      }
      setRealtimeDBStatus(FirebaseStatus.DISCONNECTED);
    };
  }, [setRealtimeDBStatus, realtimeDb, userStatusDatabaseRef]);
};
