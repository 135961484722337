import { atom, selector } from 'recoil';
import { GlooUser } from 'renderer/models/Api';

export const selfGlooUserImplAtom = atom<GlooUser | null>({
  key: 'glooUserImpl',
  default: null,
});

export const selfGlooUserAtom = selector<GlooUser>({
  key: 'glooUser',
  get: ({ get }) => {
    const user = get(selfGlooUserImplAtom);
    if (!user) throw new Error('Gloo User must be fetched and set');
    return user;
  },
});

export const selfUserIdAtom = selector<string>({
  key: 'userIdAtom',
  get: ({ get }) => {
    return get(selfGlooUserAtom).userId;
  },
});

export const primaryMachineAtom = selector<string | undefined>({
  key: 'primaryMachineAtom',
  get: ({ get }) => {
    const current = get(selfGlooUserAtom);
    return current.primaryMachine;
  },
});

export const myTeamsAtom = selector<string[]>({
  key: 'myTeamsAtom',
  get: ({ get }) => {
    const current = get(selfGlooUserAtom);
    return current.teamList;
  },
});

export const teamsExistsAtom = selector<boolean>({
  key: 'teamsExistsAtom',
  get: ({ get }) => get(myTeamsAtom).length > 0,
});

export const myRoomsAtom = selector<string[]>({
  key: 'myRoomsAtom',
  get: ({ get }) => {
    const current = get(selfGlooUserAtom);
    return current.roomList;
  },
});
