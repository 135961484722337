import {
  differenceInSeconds,
  secondsToMinutes,
  secondsToHours,
} from 'date-fns';

export const friendlyTimeDifferenceString = (start: Date, end: Date) => {
  const seconds = differenceInSeconds(start, end);
  const minutes = secondsToMinutes(seconds);
  const hours = secondsToHours(seconds);
  const days = Math.round(hours / 24);

  if (days >= 2) {
    return `${days} days ago`;
  }
  if (days >= 0.75) {
    return `yesterday`;
  }
  if (hours > 2) {
    return `${hours} hours ago`;
  }
  if (hours >= 1) {
    return '1 hour ago';
  }
  if (minutes > 2) {
    return `${minutes} minutes ago`;
  }
  if (minutes >= 1) {
    return '1 minute ago';
  }
  if (seconds >= 20) {
    return `${seconds} seconds ago`;
  }
  return 'a few seconds ago';
};
