import { FC, ReactNode } from 'react';
import { RecoilState, useRecoilValue } from 'recoil';
import {
  timeRestrictionEnabledAtom,
  restrictOnWeekendsAtom,
  timeModifiedAtom,
} from 'renderer/connection/isRestrictedTime';
import { TimePicker } from './TimePicker';
import { ToggleButton, ToggleButtonWithModifier } from './ToggleButton';

/**
 * Setting description and text with a right-hand side component of choice
 */
export interface SettingData {
  title: string;
  description?: string;
  disabled?: boolean;
}
export const HorizontalSettingContainer: FC<
  SettingData & { children: ReactNode }
> = ({ children, title, description, disabled }) => {
  return (
    <div className="flex flex-row py-4 font-bold">
      <div
        className={`${
          disabled ? 'text-gray-400' : 'text-black/100'
        } flex flex-col`}
      >
        <div>{title}</div>
        {description && (
          <div
            className={`${
              disabled ? 'text-gray-600' : 'text-black/70'
            } text-xs font-semibold`}
          >
            {description}
          </div>
        )}
      </div>
      <div className="ml-auto">{children}</div>
    </div>
  );
};

export const VerticalSettingContainer: FC<
  SettingData & { children: ReactNode }
> = ({ children, title, description, disabled }) => {
  return (
    <div className="flex flex-col py-4 font-bold">
      <div
        className={`${
          disabled ? 'text-gray-400' : 'text-black/100'
        } flex flex-col`}
      >
        <div>{title}</div>
        {description && (
          <div
            className={`${
              disabled ? 'text-gray-600' : 'text-black/70'
            } text-xs font-semibold`}
          >
            {description}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

interface ToggleSettingData extends SettingData {
  atom: RecoilState<boolean>;
  modifierTime?: RecoilState<Date>;
}
export const ToggleSetting: React.FC<ToggleSettingData> = ({
  title,
  description,
  atom,
  modifierTime,
  disabled,
}) => {
  return (
    <HorizontalSettingContainer
      title={title}
      description={description}
      disabled={disabled}
    >
      {modifierTime ? (
        <ToggleButtonWithModifier
          atom={atom}
          modifierTime={modifierTime}
          disabled={disabled}
        />
      ) : (
        <ToggleButton atom={atom} disabled={disabled} />
      )}
    </HorizontalSettingContainer>
  );
};

export const ListeningHoursSetting = () => {
  const listeningTimeEnabled = useRecoilValue(timeRestrictionEnabledAtom);
  const toggleSchedule: ToggleSettingData = {
    title: 'Enable listening schedule',
    description:
      "If enabled, you will only be shown as listening during this time as long as you're not idle",
    atom: timeRestrictionEnabledAtom,
    modifierTime: timeModifiedAtom,
  };

  const toggleWeekends: ToggleSettingData = {
    title: 'Disable on weekends',
    atom: restrictOnWeekendsAtom,
    modifierTime: timeModifiedAtom,
    description:
      'If enabled, you will not be shown as listening on weekends (Saturday / Sunday).',
  };

  const picker: SettingData = {
    title: 'Set listening hours',
  };

  return (
    <>
      <HorizontalSettingContainer
        title={toggleSchedule.title}
        description={toggleSchedule.description}
      >
        <ToggleButton atom={toggleSchedule.atom} />
      </HorizontalSettingContainer>
      <div
        style={{ opacity: listeningTimeEnabled ? 1 : 0.5 }}
        className="select-none"
      >
        <HorizontalSettingContainer title={picker.title}>
          <TimePicker disabled={!listeningTimeEnabled} />
        </HorizontalSettingContainer>
      </div>
      <HorizontalSettingContainer
        title={toggleWeekends.title}
        description={toggleWeekends.description}
      >
        <ToggleButton atom={toggleWeekends.atom} />
      </HorizontalSettingContainer>
    </>
  );
};
