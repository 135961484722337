import {GradientButton} from "components/GradientButton";
import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {trpc} from "renderer/common/client/trpc";
import {logger} from "renderer/common/LogCreator";
import {GlooIcon} from "renderer/shared/icons/GlooIcon";

const OauthCallback = () => {
  const {search} = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  const oauthCodeExchangeResult = trpc.useMutation(["oauth.callback"], {
    retry: false,
  });

  useEffect(() => {
    let t: any;
    const postOauthCode = async () => {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("No user id found");
      }
      const provider = localStorage.getItem("provider");
      if (!provider) {
        throw new Error("No provider found");
      }
      // TODO: handle error
      const res = await oauthCodeExchangeResult.mutateAsync({
        code: params.get("code")!,
        state: "gloo123",
        uid: userId,
        provider: provider as "google" | "microsoft",
      });
      logger.info("oauth code exchange result succeeded ", res);
      const t = setTimeout(() => {
        window.location.replace("gloo://open");
      }, 2000);
    };
    postOauthCode();
    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, []);


  if (oauthCodeExchangeResult.isLoading) {
    return (<div className="flex flex-col items-center w-full h-full p-5 mt-20">
      <div>Linking your calendar...</div>
      <ClipLoader color="gray" />
    </div>);
  }

  if (oauthCodeExchangeResult.error) {
    return (<div className="flex flex-col items-center w-full h-full p-5 mt-20 space-y-4 text-red-500">
      <div>Error linking calendar. {oauthCodeExchangeResult.error.message}</div>
    </div>);
  }

  return (
    <div className="flex flex-col items-center w-full h-full p-5 mt-20 space-y-4">
      <GlooIcon />
      <div className="text-2xl font-bold">Linking succeeded! Redirecting to the desktop app...</div>
      <ClipLoader color="gray" />
      <div className="flex flex-col gap-3">
        <div>If you are not redirected, please click the button below.</div>
        <div>
          <GradientButton enableChevron clickHandler={async () => {
            window.location.replace("gloo://open");
          }}
          >
            Open the desktop app
          </GradientButton>
        </div>
      </div>
    </div>
  );
};

export default OauthCallback;
