import { useRecoilState } from 'recoil';
import { selectedSettingCategoryAtom } from 'renderer/atoms/settings';
import { SettingsCategory } from 'renderer/models/SettingsCategory';

export interface NavItemData {
  category: SettingsCategory;
  name: string;
}
export const NavItem = ({ name, category }: NavItemData) => {
  const [selectedSetting, setSelectedSetting] = useRecoilState(
    selectedSettingCategoryAtom
  );

  return (
    <button
      type="button"
      onClick={() => setSelectedSetting(category)}
      className={`${
        selectedSetting === category ? 'bg-gray-200' : ''
      } rounded-md`}
    >
      <div className="p-2 pr-12 space-y-1 font-semibold text-left text-black">
        {name}
      </div>
    </button>
  );
};
