import { atom, selector } from 'recoil';

type User = {
  uid: string;
  photoURL: string | null;
  displayName: string | null;
  email: string | null;
};

// This is imported because its important in room.ts
export const selfFirebaseUserImplAtom = atom<User | null>({
  key: 'userImpl',
  default: null,
});

export const selfFirebaseUserAtom = selector<User>({
  key: 'user',
  get: ({ get }) => {
    const user = get(selfFirebaseUserImplAtom);
    if (!user) {
      throw new Error('Firebase user not found');
    }
    return user;
  },
});
