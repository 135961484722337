import {OutlineButton} from "components/OutlineButton";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {GlooIcon} from "renderer/shared/icons/GlooIcon";

/**
 * When a user schedules a gloo meeting we will link to his site.
 *
 * This will open the app and/or tell user to open the app.
 */
export const Open = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  // TODO: add param to indicate what kind of "open" this is. For now we assume there's always
  // a teamId and roomId.


  useEffect(() => {
    window.location.replace("gloo://open?" + params.toString());
  }, []);

  return (<div className="flex flex-col items-center w-full h-full p-5 mt-20 space-y-4">
    <GlooIcon />
    <div className="text-2xl font-bold">Opening the app to join the meeting...</div>
    <ClipLoader color="gray" />
    <div className="pt-8 text-base font-light">{"Don't have Gloo installed?"}</div>
    <a className="w-fit h-fit" href="/download">
      <OutlineButton clickHandler={() => {}}>
          Download Gloo
      </OutlineButton>
    </a>
  </div>);
};
