import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';

const logger = LogCreator(LoggerNames.MIC);

export class FadeInAudio {
  private ctx: AudioContext;

  private audio: HTMLAudioElement;

  private targetGain: number;

  private source: MediaElementAudioSourceNode;

  private gainNode: GainNode;

  private playListener: () => void;

  constructor(audio: HTMLAudioElement, targetGain = 0.4) {
    this.targetGain = targetGain;
    this.audio = audio;
    this.ctx = new AudioContext();
    this.source = this.ctx.createMediaElementSource(this.audio);
    this.gainNode = this.ctx.createGain();
    this.playListener = () => {
      try {
        this.audio.play();
      } catch (error) {
        // can happen on browser if autoplay is disabled
        logger.error('failed to play audio', error);
      }
    };
  }

  public play() {
    // rewind first otherwise it wont play in consecutive presses
    this.audio.currentTime = 0;
    this.source.connect(this.gainNode);
    this.gainNode.connect(this.ctx.destination);
    const start = this.ctx.currentTime;
    let middle;
    let end;

    try {
      const { duration } = this.audio;
      if (duration === Infinity || duration === undefined || duration === 0) {
        logger.warn(
          'Skipped playback of sound as duration is invalid. Audio may not have loaded fully yet. Duration:',
          duration
        );
        return;
      }
      middle = start + duration / 4;
      end = start + duration;
      // fade in the audio to prevent clicks and pops
      this.gainNode.gain.linearRampToValueAtTime(0, start);
      this.gainNode.gain.linearRampToValueAtTime(this.targetGain, middle);
      this.gainNode.gain.linearRampToValueAtTime(0, end);

      this.audio.removeEventListener('canplaythrough', this.playListener);
      this.audio.addEventListener('canplaythrough', this.playListener);
    } catch (error: any) {
      logger.error('Issue playing audio track', error, { start, middle, end });
    }
  }
}
