/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import { useRecoilValue } from 'recoil';
import { roomTeamIdAtom, roomUserAtom } from 'renderer/atoms/room';
import {
  localMicActiveAtom,
  useMicToggles,
} from 'renderer/call/components/MicrophoneProvider';
import {
  selfScreenshareActiveAtom,
  useSceenshareToggles,
} from 'renderer/call/components/ScreenShareProvider';
import { availableStatusAtom } from 'renderer/connection/state';
import { videoCamActiveAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { SoundSettingsButton } from 'renderer/shared/Buttons/SoundSettingsButton';
import {
  leaveConvoTimeAtom,
  useConvoHooks,
} from 'renderer/hooks/useConversationExpirationMonitor';
import { RoomKey } from 'renderer/models/Keys';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import { useVideoCamToggles } from 'renderer/call/components/videocam/useVideoCamToggles';
import RoomControllerBase from './RoomContorllerBase';

export const RoomController: React.FC<
  { darkMode?: boolean; miniView?: boolean } & RoomKey
> = ({ darkMode, roomId, miniView }) => {
  const userId = useRecoilValue(selfUserIdAtom);
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const isMicOn = useRecoilValue(buttonActive({ button: ButtonType.MIC }));
  const micActiveInRoom = useRecoilValue(localMicActiveAtom({ roomId }));
  const isScreensharing = useRecoilValue(selfScreenshareActiveAtom({ roomId }));
  const isVideoCamActive = useRecoilValue(videoCamActiveAtom({ roomId }));
  const { toggleMic } = useMicToggles();
  const { toggleScreenshare } = useSceenshareToggles();
  const { toggleVideoCam } = useVideoCamToggles();
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const leavingConvoTime = useRecoilValue(leaveConvoTimeAtom({ roomId }));
  const { leaveConvo } = useConvoHooks();

  const isOnline = useRecoilValue(availableStatusAtom);
  const showController =
    micActiveInRoom ||
    isScreensharing ||
    isVideoCamActive ||
    (isOnline && roomUser.convo.active);

  return (
    <RoomControllerBase
      show={showController}
      leaveTimer={leavingConvoTime}
      onLeave={() => leaveConvo({ teamId, roomId })}
      mic={{
        active: isMicOn,
        currRoomActive: micActiveInRoom,
        onClick: async () => toggleMic({ roomId }),
      }}
      video={{
        active: false,
        currRoomActive: isVideoCamActive,
        onClick: () => toggleVideoCam({ roomId }),
      }}
      screen={{
        active: false,
        currRoomActive: isScreensharing,
        onClick: () => toggleScreenshare({ roomId }),
      }}
      darkMode={darkMode}
      miniView={miniView}
    >
      {!darkMode && <SoundSettingsButton roomId={roomId} />}
    </RoomControllerBase>
  );
};
