import { RiSettings3Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { availableStatusAtom } from 'renderer/connection/state';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';
import { Avatarv2 } from 'renderer/shared/user/Avatarv2';
import { GlooSelfUserWithStatus } from 'renderer/shared/user/GlooUser';

/* eslint-disable react/jsx-no-undef */
export const UserIcon = ({ size }: { size: number }) => {
  const { status } = useRecoilValue(selfGlooUserAtom);
  const navigate = useNavigate();

  return (
    <IconButton
      clickHandler={() => {
        navigate('/dashboard/settings');
      }}
      toolTip="Settings"
      className="relative flex items-center justify-center w-10 hover:bg-transparent group"
    >
      <GlooSelfUserWithStatus iconSizePx={size} status={status} />

      <RiSettings3Fill className="absolute inset-x-0 hidden w-full h-full p-1 rounded-full text-white-1 bg-black-primary/70 group-hover:flex opacity-70" />
    </IconButton>
  );
};
