import { useRecoilValue } from 'recoil';
import {
  NotificationSettingsToggle,
  NotificationType,
  notificationSettingsToggleAtom,
  useNotificationAtom,
} from 'renderer/atoms/settings';
import { SettingsContent, SettingsSubsection } from '../components';
import { ToggleSetting } from '../SettingsItem';

const Title = (type: NotificationType) => {
  switch (type) {
    case NotificationType.BANNER:
      return 'desktop notification';
    case NotificationType.SOUND:
      return 'sounds';
    default:
      throw new Error('Unknow');
  }
};

const SoundNotifications = ({ type }: { type: NotificationType }) => {
  const disable = !useRecoilValue(useNotificationAtom(type));
  return (
    <>
      <ToggleSetting
        atom={useNotificationAtom(type)}
        title={`All ${Title(type)}`}
      />
      <hr />
      <ToggleSetting
        disabled={disable}
        atom={notificationSettingsToggleAtom({
          action: NotificationSettingsToggle.STATUS_CHANGE,
          type,
        })}
        title="Online/Offline"
      />
      <ToggleSetting
        disabled={disable}
        atom={notificationSettingsToggleAtom({
          action: NotificationSettingsToggle.STATUS_MIC,
          type,
        })}
        title="Mic on/off"
      />
      <ToggleSetting
        disabled={disable}
        atom={notificationSettingsToggleAtom({
          action: NotificationSettingsToggle.OTHER_JOIN_TABLE,
          type,
        })}
        title="Conversation started"
      />
      <ToggleSetting
        disabled={disable}
        atom={notificationSettingsToggleAtom({
          action: NotificationSettingsToggle.NEW_MESSAGE,
          type,
        })}
        title="New message"
      />
    </>
  );
};

export const NotificationsSettings = () => {
  return (
    <SettingsContent title="Notifications">
      <SettingsSubsection title="Sounds">
        <SoundNotifications type={NotificationType.SOUND} />
      </SettingsSubsection>
      {/* <SettingsSubsection
        title="Desktop Notifications"
        description="Only shown when Gloo is hidden."
      >
        <SoundNotifications type={NotificationType.BANNER} />
      </SettingsSubsection> */}
    </SettingsContent>
  );
};
