import IpcApi from "api";
import {AppInfoProviderWeb} from "AppInfoProviderWeb";
import {lightFormat} from "date-fns";
import Logger from "js-logger";
import {ReactNode} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";
import {ReactQuery} from "renderer/common/components/TRPCReactQuery";
import {FirebaseProvider} from "renderer/providers/FirebaseProvider";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// https://github.com/TanStack/query/issues/3476
declare module "react-query/types/react/QueryClientProvider" {
  interface QueryClientProviderProps {
    children?: ReactNode;
  }
}

declare global {
  interface Window {
    electron?: {
      ipcRenderer: IpcApi;
    };
  }
};


const container = document.getElementById("root");
if (!container) throw new Error("Container must exists");

const root = createRoot(container);

const RendererWrapper = () => {
  Logger.useDefaults({
    defaultLevel: Logger.DEBUG,
    formatter: function foo(messages: any, context: any) {
      const now = new Date();
      messages.unshift(
          `${lightFormat(now, "hh:mm:ss.SSS aa")} Gloo-App${
          context.name ? ` [${context.name}]` : ""
          } [${context.level.name}]`,
      );
    },
  });

  return (
    <AppInfoProviderWeb>
      <FirebaseProvider>
        <RecoilRoot>
          <ReactQuery>
            <BrowserRouter>
              <App />
            </BrowserRouter>

          </ReactQuery>
        </RecoilRoot>
      </FirebaseProvider>
    </AppInfoProviderWeb>
  );
};

root.render(<RendererWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
