import { showNotification } from 'api/models';
import { RefObject } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedRoomKeyAtom } from 'renderer/atoms/room';
import { notificationPanelStateAtom } from 'renderer/hooks/useConversationStateUpdates';
import { DragableRoomNotification } from './RoomNotification';

const MiniGloo: React.FC<{ appRef: RefObject<HTMLDivElement> }> = ({
  appRef,
}) => {
  const state = useRecoilValue(notificationPanelStateAtom);

  if (!window.electron) return null;

  if (!state) return null;

  return (
    <div className="absolute z-50 flex flex-col items-end gap-1 pointer-events-none top-3 right-3">
      {state.room.map((roomState) => {
        const show = showNotification(roomState, state.current, false);
        if (!show.show) return null;
        return (
          <DragableRoomNotification
            key={roomState.meta.roomId}
            state={roomState}
            self={state.current.selfUser}
            appRef={appRef}
            show={show}
          />
        );
      })}
    </div>
  );
};

export default MiniGloo;
