/* eslint-disable promise/always-return */
import { Tab } from '@headlessui/react';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { differenceInSeconds } from 'date-fns/esm';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { ClipLoader, PuffLoader } from 'react-spinners';
import {
  atom,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import {
  isSelfUserConvoActiveAtom,
  roomAtom,
  roomImplAtom,
  roomMetadataAtom,
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import { trpc } from 'renderer/common/client/trpc';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { RoomController } from 'renderer/pages/dashboard/components/MainView/RoomView.tsx/RoomController/MainAppRoomController';
import { Tooltip } from 'renderer/shared/Tooltip';
import { ErrorFallbackWithReload } from 'renderer/shared/ErrorFallbackWithReload';
import {
  Channel as StreamChannel,
  DefaultGenerics,
  StreamChat,
  UserResponse,
  Event as StreamEvent,
} from 'stream-chat';
import {
  Channel,
  Chat,
  GroupStyle,
  isDate,
  LoadingErrorIndicatorProps,
  MessageInput,
  VirtualizedMessageList,
  StreamMessage,
  Thread,
  Window,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import notifsfx from 'resources/audio/notifsfx.ogg';
import { FadeInAudio } from 'renderer/audio/FadeInAudio';
import {
  NotificationSettingsToggle,
  soundSettingsOnAtom,
} from 'renderer/atoms/settings';
import { availableStatusAtom } from 'renderer/connection/state';
import { ErrorBoundary } from 'react-error-boundary';
import {
  totalUnreadMessagesAtom,
  unreadMesssagesForChannelAtom,
} from './atoms';
import { AvatarChat, CustomMessage } from './CustomMessage';
import {
  BlankTypingIndicator,
  CustomTypingIndicator,
} from './CustomTypingIndicator';

const notifSound = new FadeInAudio(new Audio(notifsfx), 0.8);

const logger = LogCreator(LoggerNames.CHAT);

const useClient = ({
  userData,
}: {
  userData: UserResponse<DefaultStreamChatGenerics>;
}) => {
  const getChatToken = trpc.useMutation('chat.createToken');
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const isAvailable = useRecoilValue(availableStatusAtom);

  useEffect(() => {
    if (!isAvailable) {
      return;
    }
    logger.info('Connecting chat');
    const client = new StreamChat('t9dmr7xw2tqa');
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    const fn = async () => {
      await client.connectUser(userData, async () => {
        // TODO: dont make the API accept the room Id or this whole thing has to reconnect on every room switch.
        // we already add the user to the right teams in the backend so it's fine to just get a token
        // here and have streamChat verify user belongs in right place.
        const res = await getChatToken.mutateAsync();
        return res.token;
      });
      if (!didUserConnectInterrupt) {
        logger.info('setting client to', client);
        setChatClient(client);
      } else {
        logger.info('connection interrupted');
      }
    };
    fn();

    return () => {
      didUserConnectInterrupt = true;
      setChatClient((prev) => {
        prev
          ?.disconnectUser()
          .finally(() => logger.info('Disconnected from chat'));
        return null;
      });
    };
  }, [userData.id, isAvailable]);

  return chatClient;
};

export const ChatContainer = () => {
  const selfUser = useRecoilValue(selfGlooUserAtom);
  const isAvailable = useRecoilValue(availableStatusAtom);
  const { roomId, name: roomName } = useRecoilValue(selectedRoomAtom);

  const { convo } = useRecoilValue(
    roomUserAtom({ roomId, userId: selfUser.userId })
  );
  const newMsgSoundEnabled = useRecoilValue(
    soundSettingsOnAtom(NotificationSettingsToggle.NEW_MESSAGE)
  );

  const chatClient = useClient({
    userData: {
      id: selfUser.userId,
      name: selfUser.profile.displayName,
      image: selfUser.profile.photoUrl,
    },
  });

  const updateNotificationHandler = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: StreamEvent) => {
        if (!event.channel_id || !chatClient) {
          return;
        }
        logger.info('Chat Event Received', event);
        set(totalUnreadMessagesAtom, event.total_unread_count || 0);
        const room = await snapshot.getPromise(roomImplAtom(event.channel_id));
        if (!room) {
          logger.info(
            'Chat Event: Skipping unread count channel thats not for room id',
            event
          );
          return;
        }

        if (!chatClient.user) {
          logger.warn(
            'Chat Event: Skipping unread count for room, client not connected',
            event
          );
          return;
        }
        const response = await chatClient.queryChannels(
          { cid: event.cid },
          {},
          { watch: false, state: true }
        );

        if (response.length === 0) {
          logger.info(
            'Chat event: skipping unread count for room. Blank channel state',
            response
          );
          return;
        }

        const { state } = response[0];
        logger.info('Chat Event channel state', {
          channelId: event.channel_id,
          channelUnread: state.unreadCount,
        });
        set(
          unreadMesssagesForChannelAtom({ channelId: event.channel_id }),
          state.unreadCount
        );
      },
    [chatClient]
  );

  useEffect(() => {
    if (chatClient) {
      logger.info('subscribe to chat events');
      const unsub = chatClient.on((event) => {
        logger.info('Received chat event (unfiltered)', event);
        if (
          ['notification.message_new', 'message.new'].includes(event.type) &&
          event?.user?.id !== selfUser.userId
        ) {
          if (newMsgSoundEnabled) {
            (async () => {
              notifSound.play();
            })();
          }
        }

        if (
          ['notification.message_new', 'notification.mark_read'].includes(
            event.type
          )
        ) {
          updateNotificationHandler(event);
        }
      });

      // TODO: initialize unread counts from chatClient?.user.unreadCounts on first
      // load
      return () => {
        logger.info('unsubscribe to chat events');
        unsub.unsubscribe();
      };
    }
  }, [
    chatClient,
    newMsgSoundEnabled,
    selfUser.userId,
    updateNotificationHandler,
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(convo.active ? 1 : 0);
  }, [convo.active]);

  if (!convo.active || !isAvailable) {
    return null;
  }

  return (
    <>
      {/* first, a helper div to move the main view to the left when screen is large */}
      <div className="hidden w-[35%] flex-auto lg:flex" />
      <div className="flex w-full h-[45%] lg:h-[100%] lg:absolute lg:inset-0 lg:flex lg:justify-end pointer-events-none">
        <div className="flex flex-col relative w-full h-full  lg:w-[35%] bg-transparent pointer-events-auto">
          <Tab.Group as={Fragment} selectedIndex={selectedIndex}>
            <div className="absolute flex items-center h-full">
              <Tab.List className="flex h-full p-0">
                {convo.active && <Tab onClick={() => setSelectedIndex(1)} />}
              </Tab.List>
            </div>

            <div className="relative flex w-full h-full bg-chat border-t-[1px] border-l-[1px] lg:border-t-0">
              <Tab.Panels as={Fragment}>
                {convo.active && convo.convoId && (
                  <Tab.Panel as={Fragment}>
                    <ErrorBoundary FallbackComponent={ErrorFallbackWithReload}>
                      <ChatComponents
                        channelId={convo.convoId}
                        chatClient={chatClient}
                        isConvo
                      />
                    </ErrorBoundary>
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export const CUSTOM_MESSAGE_TYPE = {
  date: 'message.date',
  intro: 'channel.intro',
} as const;

const ChatComponents = ({
  channelId,
  chatClient,
  isConvo,
}: {
  channelId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  isConvo: boolean;
}) => {
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const roomMetadata = useRecoilValue(roomMetadataAtom({ roomId }));

  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const filters = { type: 'messaging' };
  const options = { state: true, presence: true, limit: 10 };
  // const sort = { last_message_at: -1 };

  // TODO: fix message renderer not putting links and shit

  /**
   * msgs can be tagged as "top" "bottom" "middle" or "single".
   * And then in Message we can render an avatar or not depending on this.
   */
  const groupMsg = useCallback(
    (
      message: StreamMessage<DefaultStreamChatGenerics>,
      previousMessage: StreamMessage<DefaultStreamChatGenerics>,
      nextMessage: StreamMessage<DefaultStreamChatGenerics>,
      noGroupByUser: boolean
    ): GroupStyle => {
      // return 'top';
      if (message.customType === CUSTOM_MESSAGE_TYPE.date) return '';
      if (message.customType === CUSTOM_MESSAGE_TYPE.intro) return '';

      if (noGroupByUser || message.attachments?.length !== 0) return 'single';

      const messageTime =
        message.created_at && isDate(message.created_at)
          ? message.created_at.getTime()
          : null;
      const isPrevMsgTooOld =
        messageTime &&
        previousMessage?.created_at &&
        differenceInSeconds(
          new Date(messageTime),
          new Date(previousMessage.created_at)
        ) > 120;

      const isTopMessage =
        !previousMessage ||
        previousMessage.customType === CUSTOM_MESSAGE_TYPE.intro ||
        previousMessage.customType === CUSTOM_MESSAGE_TYPE.date ||
        previousMessage.type === 'system' ||
        previousMessage.attachments?.length !== 0 ||
        message.user?.id !== previousMessage.user?.id ||
        isPrevMsgTooOld ||
        previousMessage.type === 'error' ||
        previousMessage.deleted_at ||
        (message.reaction_counts &&
          Object.keys(message.reaction_counts).length > 0);

      const isBottomMessage =
        !nextMessage ||
        nextMessage.customType === CUSTOM_MESSAGE_TYPE.date ||
        nextMessage.type === 'system' ||
        nextMessage.customType === CUSTOM_MESSAGE_TYPE.intro ||
        nextMessage.attachments?.length !== 0 ||
        message.user?.id !== nextMessage.user?.id ||
        nextMessage.type === 'error' ||
        nextMessage.deleted_at ||
        (nextMessage.reaction_counts &&
          Object.keys(nextMessage.reaction_counts).length > 0);

      if (!isTopMessage && !isBottomMessage) {
        if (message.deleted_at || message.type === 'error') return 'single';
        return 'middle';
      }

      if (isBottomMessage) {
        if (isTopMessage || message.deleted_at || message.type === 'error')
          return 'single';
        return 'bottom';
      }

      if (isTopMessage) return 'top';

      return '';
    },
    []
  );

  const errorLoading = useRecoilValue(errorLoadingChatAtom);
  const [channel, setChannel] = useState<null | StreamChannel>();

  useEffect(() => {
    if (!errorLoading && chatClient) {
      const c = chatClient.channel('team', channelId, {
        team: teamId,
      });
      setChannel(c);
    }
    if (!chatClient) {
      setChannel(null);
    }
  }, [channelId, chatClient, teamId, errorLoading]);

  useEffect(() => {
    const init = async () => {
      logger.info('start watching channel', channelId);
      await channel?.watch();
      await channel?.markRead();
    };
    if (channel && !channel.disconnected) {
      init();
    }
    return () => {
      if (channel && !channel.disconnected) {
        logger.info('stop watching channel', channelId);
        // note this will error out if backend removed user from channel, which is fine
        channel.stopWatching();
      }
    };
  }, [channel, channel?.disconnected]);

  if (!chatClient) {
    return (
      <div className="justify-center w-full h-full text-sm text-gray-4">
        Loading chat <ClipLoader size={10} color="grey" />
      </div>
    );
  }

  if (errorLoading) {
    return <div className="text-sm text-gray-3">Error loading chat</div>;
  }

  if (!channel || channel.disconnected) {
    return null;
  }

  const messageInputText = `Message ${
    isConvo ? 'this group' : roomMetadata.title
  }`;

  return (
    <Chat client={chatClient}>
      <Channel
        TypingIndicator={BlankTypingIndicator}
        channel={channel}
        Avatar={AvatarChat}
        VirtualMessage={CustomMessage}
        LoadingErrorIndicator={CustomLoadingError}
      >
        <CustomTypingIndicator />
        <Window>
          <VirtualizedMessageList
            overscan={4}
            stickToBottomScrollBehavior="auto"
            shouldGroupByUser
            disableDateSeparator={false}
            groupStyles={groupMsg}
          />
          <MessageInput
            grow
            additionalTextareaProps={{
              placeholder: messageInputText,
            }}
            maxRows={4}
          />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

const errorLoadingChatAtom = atom<Error | null | undefined>({
  key: 'errorLoadingChat',
  default: null,
});

const CustomChannelList = () => {
  return <></>;
};

const CustomLoadingError = ({ error }: LoadingErrorIndicatorProps) => {
  const setError = useSetRecoilState(errorLoadingChatAtom);
  useEffect(() => {
    if (error) {
      logger.error('Error loading chat', error);
    }
    setError(error);
  }, [error, setError]);

  // defer to parent component that depends on this atom.
  return <></>;
};
