import { Auth, connectAuthEmulator } from 'firebase/auth';
import { Database, connectDatabaseEmulator } from 'firebase/database';
import { Firestore, connectFirestoreEmulator } from 'firebase/firestore';
import { Functions, connectFunctionsEmulator } from 'firebase/functions';
import Gleap from 'gleap';
import logger from 'js-logger';
import { useContext, useMemo, useCallback } from 'react';
import { deploymentConfigs } from 'renderer/constants';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';

export const useDeploymentConfig = () => {
  const appInfo = useContext(AppInfoContext);
  const { functionUrl, firebaseConfig, agoraAppId, gleapId, bucketUrl } =
    useMemo(() => deploymentConfigs[appInfo.deployment], [appInfo.deployment]);

  const postSetup = useCallback(
    (
      functions: Functions,
      auth: Auth,
      firestore: Firestore,
      database: Database
    ) => {
      if (gleapId) {
        try {
          Gleap.initialize(gleapId);
        } catch (error) {
          logger.error('Gleap error', error);
        }
      }

      if (appInfo.deployment === 'emulator') {
        try {
          connectFunctionsEmulator(functions, 'localhost', 5001);
          connectAuthEmulator(auth, 'http://localhost:9099', {
            disableWarnings: true,
          });
          connectFirestoreEmulator(firestore, 'localhost', 8080);
          connectDatabaseEmulator(database, 'localhost', 8081);
        } catch (err) {
          logger.error(err);
        }
      }
    },
    [appInfo.deployment, gleapId]
  );

  return { functionUrl, firebaseConfig, agoraAppId, postSetup, bucketUrl };
};

export const useBaseUrl = () => {
  const { deployment } = useContext(AppInfoContext);

  switch (deployment) {
    case 'prod':
      return 'https://app.gloo.chat';
    case 'dev':
      // return 'https://gloo-dev-6bb7d.firebaseapp.com';
      return 'https://app.dev.gloo.chat';
    case 'emulator':
      return 'http://localhost:3000';
    default:
      throw new Error(`Invalid type: ${deployment}`);
  }
};
