import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { roomUserAtom, selfUserConvoAtom } from 'renderer/atoms/room';
import { isUserInputActiveAtom } from 'renderer/common/hooks/useUserInputActiveMonitor';
import { availableStatusAtom } from 'renderer/connection/state';
import { usePrevious } from 'renderer/hooks/usePrevious';
import { RoomKey } from 'renderer/models/Keys';
import { useVideoCamToggles } from './useVideoCamToggles';

export const VideoCameraMonitor: React.FC<RoomKey> = ({ roomId }) => {
  // This is empty because we don't automatically ever turn off the video.
  return null;
};
