import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import clsx from 'clsx';
import { MicStatusEnum } from 'renderer/atoms/connectionStatus';
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';

export const MicStatus = ({
  micStatus,
  isSpeaking,
  isAtSelfUserTable,
}: {
  micStatus: MicStatusEnum;
  isSpeaking: boolean;
  isAtSelfUserTable: boolean;
}) => {
  return (
    <>
      {
        {
          [MicStatusEnum.On]: (
            <MicRoundedIcon
              className={clsx(
                '!w-full !h-full !p-[1px]',
                isSpeaking
                  ? [
                      isAtSelfUserTable
                        ? 'text-blue-primary'
                        : 'text-purple-primary',
                    ]
                  : 'text-black-primary'
              )}
            />
          ),
          [MicStatusEnum.Megaphone]: (
            <CampaignRoundedIcon
              className={clsx(
                '!w-full !h-full',
                isSpeaking ? 'text-orange-primary' : 'text-black-primary'
              )}
            />
          ),
          [MicStatusEnum.WaitingForOn]: (
            <MicRoundedIcon className="!w-full !h-full !p-[1px] text-red-800 animate-pulse" />
          ),
          [MicStatusEnum.WaitingForMegaphone]: (
            <CampaignRoundedIcon className="!w-full !h-full text-red-800 animate-pulse" />
          ),
          [MicStatusEnum.WaitingForOff]: (
            <MicOffRoundedIcon className="!w-full !h-full !p-[1px] text-black-primary" />
          ),
          [MicStatusEnum.Off]: (
            <MicOffRoundedIcon className="!w-full !h-full !p-[1px] text-black-primary" />
          ),
        }[micStatus]
      }
    </>
  );
};
