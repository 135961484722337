import { useRecoilValue } from 'recoil';
import { roomAtom } from 'renderer/atoms/room';
import { unreadMesssagesForChannelAtom } from 'renderer/chat/atoms';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { usePageAttributes } from 'renderer/hooks/usePageAttributes';
import { PageSelector } from 'renderer/atoms/pageselectors';
import { CalendarClock } from 'renderer/shared/icons/CalendarClock';
import { activeEventsAtom } from 'renderer/atoms/userUtils';

export const TeamHomeTitle: React.FC<
  {
    selected: boolean;
    title: string;
    calendarIcon?: React.ReactNode;
  } & PropsWithChildren
> = ({ selected, title, children, calendarIcon }) => {
  let titleTextColor = null;
  if (selected) {
    titleTextColor = 'text-black-lighter font-semibold';
  }

  return (
    <div
      className={clsx('flex flex-row items-center gap-[6px]', [
        titleTextColor ?? 'text-inherit',
      ])}
    >
      {/* this wrapper div is magical and makes the icon not get smaller in some cases */}
      <div className="flex items-center">
        <div className="flex items-center w-4 h-4">{children}</div>
      </div>
      <div className="text-base capitalize truncate">{title}</div>
      {calendarIcon && <div className="w-4 h-4">{calendarIcon}</div>}
    </div>
  );
};

export const RoomListTitle: React.FC<{
  selected: boolean;
  roomId: string;
}> = ({ selected, roomId }) => {
  const room = useRecoilValue(roomAtom(roomId));
  const { title, icon } = usePageAttributes(PageSelector.TEAM_ROOM, room);

  const unreadMessageCount = useRecoilValue(
    unreadMesssagesForChannelAtom({ channelId: roomId })
  );
  const activeEvents = useRecoilValue(activeEventsAtom({ roomId }));
  return (
    <TeamHomeTitle
      title={title}
      selected={selected || unreadMessageCount > 0}
      calendarIcon={
        // TODO: change color when about to start
        activeEvents && activeEvents.length > 0 ? (
          <CalendarClock className="text-orange-primary" />
        ) : null
      }
    >
      {icon}
    </TeamHomeTitle>
  );
};
