import { LeakAdd, OpenInNewRounded } from '@mui/icons-material';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';
import {
  NotificationPanelState,
  relevantNotificationEvents,
  relevantNotificationNudges,
  SelfUser,
} from 'api/models';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { RefObject, useRef, useState } from 'react';
import { TeamRoomKey } from 'renderer/models/Keys';
import { GlooIcon } from '../icons/GlooIcon';
import { ActiveConversation, AmbientConversation } from './Conversation';
import { Nudges } from './Nudge';

const RoomNotificationTitle: React.FC<
  {
    title: string;
    teamTitle: string;
    convoActive: boolean;
    nudgeCount: number;
  } & TeamRoomKey
> = ({ title, teamTitle, roomId, teamId, convoActive, nudgeCount }) => {
  // eslint-disable-next-line no-nested-ternary
  // const subtitle = convoActive
  //   ? 'Active convo'
  //   : nudgeCount > 0
  //   ? 'Pending nudge'
  //   : 'Gloo';
  return (
    <div className="relative flex flex-col items-center w-full text-white-1">
      {/* <div
        className={clsx(
          'text-2xs font-medium capitalize truncate shrink max-w-[120px] text-white-1/60'
        )}
      >
        {teamTitle}
      </div> */}
      <div className="flex flex-row items-center justify-center w-full space-x-1 shrink">
        <GlooIcon className="!w-3 !h-3" />
        <div
          className={clsx(
            'text-xs font-medium capitalize truncate shrink max-w-[120px]'
          )}
        >
          {title}
        </div>
      </div>

      <div className="absolute right-0 flex flex-row gap-1 -top-[6px] outline-0">
        <IconButton
          clickHandler={() => {
            window.electron?.ipcRenderer.setButtonState({
              button: 'EXPAND',
              roomId,
              teamId,
              enabled: true,
            });
          }}
          className=" w-[12px] h-[12px] text-gray-blue-dark-2 no-drag hover:text-white-1/50"
        >
          <OpenInNewRounded className="!w-full !h-full" />
        </IconButton>
      </div>
    </div>
  );
};

const RoomNotification: React.FC<{
  state: NotificationPanelState['room'][0];
  self: SelfUser;
  show: {
    convo: boolean;
    nudges: boolean;
    events: boolean;
  };
}> = ({ state, self, show }) => {
  const { meta, activeConvo, incomingNudges, ambientConvos } = state;
  const activeNudges = relevantNotificationNudges(incomingNudges);
  const ambientEvents = relevantNotificationEvents(ambientConvos);
  return (
    <>
      <RoomNotificationTitle
        title={meta.name}
        teamTitle={meta.teamName}
        teamId={meta.teamId}
        roomId={meta.roomId}
        convoActive={!!activeConvo}
        nudgeCount={activeNudges.length}
      />
      <div
        className="flex flex-col w-full rounded-md gap-y-1"
        onDoubleClick={() =>
          window.electron?.ipcRenderer.setButtonState({
            button: 'EXPAND',
            roomId: meta.roomId,
            teamId: meta.teamId,
          })
        }
      >
        <div className="flex flex-col w-full gap-1">
          {show.convo && state.activeConvo && (
            <ActiveConversation
              state={state.activeConvo}
              meta={state.meta}
              self={self}
            />
          )}
          {show.nudges && (
            <Nudges activeNudges={activeNudges} meta={state.meta} self={self} />
          )}
          {show.events &&
            ambientEvents.map((c) => (
              <AmbientConversation
                state={c}
                meta={state.meta}
                self={self}
                key={c.convoId}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export const NonDragableRoomNotification: React.FC<{
  state: NotificationPanelState['room'][0];
  self: SelfUser;
  show: {
    convo: boolean;
    nudges: boolean;
    events: boolean;
  };
}> = ({ state, self, show }) => {
  const [drag, setDrag] = useState(true);

  return (
    // drag needs to be parent level otherwise it wont work well with motion div
    // also we do some other black magic on the motion div to try and not
    // clash with the CSS drag... If we don't add this buttons may not be
    // clickable at times randomly.
    <div className={clsx('flex w-full rounded-lg drag bg-transparent')}>
      <div
        //  initial={{  x: '100%' }}
        //  animate={{  x: 0 }}
        //  // exit={{ opacity: 1, x: '100%' }}
        //  transition={{
        //    duration: 0.3,
        //    ease: 'easeInOut',
        //  }}
        //  onAnimationEnd={() => {
        //   setDrag(true);
        //  }}
        //  onTransitionEnd={() => {
        //   setDrag(true);
        //  }}
        //  onDrag={(e) => {
        //   e.preventDefault();
        //  }}
        //  draggable={false}
        //  dragListener={false}
        //  key={id}
        //  layout="position"
        className="bg-gray-4 pb-[2px] flex h-full w-full rounded-lg"
      >
        <div
          className={clsx(
            'flex flex-col items-center justify-center flex-1 h-full px-2 py-[2px] pb-2 rounded-lg bg-gray-6 text-white-1/70 shadow-shadow/soft/card-2 gap-y-1 w-[167px] m-[1px]'
          )}
        >
          <RoomNotification state={state} self={self} show={show} />
        </div>
      </div>
    </div>
  );
};

export const DragableRoomNotification: React.FC<{
  appRef: RefObject<HTMLDivElement>;
  state: NotificationPanelState['room'][0];
  self: SelfUser;
  show: {
    convo: boolean;
    nudges: boolean;
    events: boolean;
  };
}> = ({ appRef, state, self, show }) => {
  const convoRef = useRef(null);

  return (
    <motion.div
      ref={convoRef}
      drag
      dragMomentum={false}
      dragConstraints={appRef}
      dragSnapToOrigin={false}
      dragElastic={0}
      className={clsx(
        'flex flex-col items-center justify-center flex-1 h-full px-2 pt-1 pb-2 rounded-lg bg-gray-6 text-white-1/70 shadow-shadow/soft/card-2 w-[170px] pointer-events-auto'
      )}
    >
      <RoomNotification state={state} self={self} show={show} />
    </motion.div>
  );
};
