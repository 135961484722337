import { toDate } from 'date-fns';
import { atomFamily, DefaultValue, selectorFamily } from 'recoil';
import { persistAtom } from 'renderer/atoms/effects';

// Buttons are always managed by user actions.
// Buttons can override default behavior (such as schedules).

export enum ButtonState {
  DEFAULT,
  ON,
  OFF,
}

// These buttons are specific to the whole app.
export enum ButtonType {
  STATUS,
  MIC,
  SCREENSHARE,
  OVERHEARING,
  MEGAPHONE,
  VIDEO,
}

type ButtonKey = { button: ButtonType };

const shouldPersist = (key: ButtonKey) => {
  return key.button === ButtonType.STATUS;
};

const ParseButtonState = (state: ButtonState, key: ButtonKey) => {
  if (state === ButtonState.DEFAULT) {
    if (key.button === ButtonType.OVERHEARING) {
      return ButtonState.ON;
    }
    return ButtonState.OFF;
  }
  return state;
};

type ButtonData = {
  state: ButtonState;
  time: number;
};

const kStartTime = new Date(0);
// We used epoch millis since recoilPersist doesn't work well with Date
const persistedButtonImplAtom = atomFamily<ButtonData, ButtonKey>({
  key: 'persistedButtonImpl',
  default: { state: ButtonState.DEFAULT, time: kStartTime.getTime() },
  effects_UNSTABLE: [persistAtom],
});

// We used epoch millis since recoilPersist doesn't work well with Date
const buttonImplAtom = atomFamily<ButtonData, ButtonKey>({
  key: 'buttonImpl',
  default: { state: ButtonState.DEFAULT, time: kStartTime.getTime() },
});

const buttonSelector = selectorFamily<ButtonData, ButtonKey>({
  key: 'buttonSelector',
  get:
    (key) =>
    ({ get }) =>
      shouldPersist(key)
        ? get(persistedButtonImplAtom(key))
        : get(buttonImplAtom(key)),
  set:
    (key) =>
    ({ set }, newVal) =>
      shouldPersist(key)
        ? set(persistedButtonImplAtom(key), newVal)
        : set(buttonImplAtom(key), newVal),
});

export const buttonPressedTimeAtom = selectorFamily<Date, ButtonKey>({
  key: 'statusButton',
  get:
    (key) =>
    ({ get }) => {
      return toDate(get(buttonSelector(key)).time);
    },
});

export const buttonStateAtom = selectorFamily<ButtonState, ButtonKey>({
  key: 'button',
  get:
    (key) =>
    ({ get }) =>
      ParseButtonState(get(buttonSelector(key)).state, key),
  set:
    (key) =>
    ({ set, reset }, new_val) => {
      if (new_val instanceof DefaultValue) reset(buttonSelector(key));
      else {
        set(buttonSelector(key), (prev) => {
          const isStatusButton =
            !('roomId' in key) && key.button === ButtonType.STATUS;
          // keep the previous if state matches
          // so we keep the older timestamp
          if (
            !isStatusButton &&
            prev.state === new_val &&
            prev.time !== kStartTime.getTime()
          ) {
            return prev;
          }
          return { state: new_val, time: new Date().getTime() };
        });
      }
    },
});

export const buttonActive = selectorFamily<boolean, ButtonKey>({
  key: 'buttonActive',
  get:
    (key) =>
    ({ get }) => {
      return get(buttonStateAtom(key)) === ButtonState.ON;
    },
});
