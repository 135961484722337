/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import clsx from 'clsx';
import { PuffLoader } from 'react-spinners';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import {
  userNudgedMeAtom,
  userNudgedBySelfAtom,
  hoveringOverNudgeAtom,
} from 'renderer/atoms/nudge';
import { roomTeamIdAtom, roomUserAtom } from 'renderer/atoms/room';
import { trpc } from 'renderer/common/client/trpc';
import { logger } from 'renderer/common/LogCreator';
import { availableStatusAtom } from 'renderer/connection/state';
import { RoomUserKey } from 'renderer/models/Keys';
import { useModalErrors } from 'renderer/shared/Modals/atoms';
import { ChildProps } from 'types/react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const NudgeIndicator = ({
  userId,
  roomId,
  mini = false,
  enableHover = true,
}: {
  userId: string;
  roomId: string;
  mini?: boolean;
  enableHover?: boolean;
}) => {
  const online = useRecoilValue(availableStatusAtom);
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const didUserNudgedMe = useRecoilValue(userNudgedMeAtom({ userId, roomId }));
  const didINudgeUser = useRecoilValue(
    userNudgedBySelfAtom({ userId, roomId })
  );
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const { setModalError } = useModalErrors();

  const sendNudgeMutation = trpc.useMutation(['users.nudge.deliver'], {
    onError: (error) => {
      setModalError(error.message);
      logger.error(`error sending nudge: ${error.message}`);
    },
  });
  const cancelNudgeMutation = trpc.useMutation(['users.nudge.remove'], {
    onError: (error) => {
      setModalError(error.message);
      logger.error(`error canceling nudge: ${error}`);
    },
  });

  const sendOrDismissNudge = async () => {
    if (didUserNudgedMe || didINudgeUser) {
      await cancelNudgeMutation.mutateAsync({
        targetUserId: didUserNudgedMe ? selfUserId : userId,
        roomId,
      });
    } else {
      await sendNudgeMutation.mutateAsync({
        targetUserId: userId,
        teamId,
        roomId,
      });
    }
  };

  if (selfUserId === userId) {
    return null;
  }

  const content = '❗';

  if (
    enableHover &&
    (cancelNudgeMutation.isLoading || sendNudgeMutation.isLoading)
  ) {
    return (
      <TopLeftContainer
        roomId={roomId}
        userId={userId}
        mini={mini}
        enableHover={enableHover}
      >
        <div className="flex items-center justify-center w-full h-full hover:cursor-pointer">
          <PuffLoader size={20} />
        </div>
      </TopLeftContainer>
    );
  }

  if (didUserNudgedMe) {
    return (
      <>
        <TopLeftContainer
          roomId={roomId}
          userId={userId}
          mini={mini}
          enableHover={enableHover}
        >
          <div className="flex items-center justify-center w-full h-full transition rounded-full bg-white-1 ring-2 ring-white-1">
            <span className="text-red-600 animate-wiggle">
              <PriorityHighIcon className="!w-[18px] !h-[18px]" />
            </span>
          </div>
        </TopLeftContainer>
      </>
    );
  }

  if (didINudgeUser) {
    return (
      <TopLeftContainer
        roomId={roomId}
        userId={userId}
        mini={mini}
        enableHover={enableHover}
      >
        <div
          className="flex items-center justify-center transition w-full h-full rounded-full bg-blue-lightest-2 ring-2 ring-white-1 hover:cursor-pointer hover:scale-[1.4]"
          onClick={(e) => {
            sendOrDismissNudge();
            e.stopPropagation();
          }}
        >
          <PriorityHighIcon className="!w-[18px] !h-[18px]" />
        </div>
      </TopLeftContainer>
    );
  }

  if (!enableHover || !online || roomUser.convo.withPrimaryUser) return null;
  return (
    <>
      <TopLeftContainer
        roomId={roomId}
        userId={userId}
        mini={mini}
        enableHover={enableHover}
      >
        <div
          className="items-center justify-center hidden w-full h-full p-1 transition rounded-full group-hover:flex bg-white-1 hover:scale-[1.2] hover:cursor-pointer text-white-1 ring-2 ring-white-1 shadow-thin"
          onClick={(e) => {
            sendOrDismissNudge();
            e.stopPropagation();
          }}
        >
          <PriorityHighIcon className="!w-[18px] !h-[18px] text-black-lightest" />
        </div>
      </TopLeftContainer>
    </>
  );
};

const TopLeftContainer: React.FC<
  ChildProps & RoomUserKey & { mini: boolean; enableHover: boolean }
> = ({ children, roomId, userId, mini, enableHover }) => {
  const setHoverState = useSetRecoilState(
    hoveringOverNudgeAtom({ roomId, userId })
  );

  return (
    <>
      <div
        className={clsx(
          'absolute w-[50%] h-[50%] rounded-full bg-transparent',
          mini ? '-top-0.5 -left-0.5 scale-75' : '-top-1 -left-1 text-sm'
        )}
        onMouseEnter={enableHover ? () => setHoverState(true) : undefined}
        onMouseLeave={enableHover ? () => setHoverState(false) : undefined}
      >
        {children}
      </div>
    </>
  );
};
