import { HelpOutlineOutlined } from '@mui/icons-material';
import Gleap from 'gleap';

export const FeedbackButton = () => {
  return (
    <button
      type="button"
      className="flex flex-row items-center space-x-1 text-sm text-gray-3 hover:text-black-lightest"
      onClick={() => {
        // Initiates the rating workflow.
        (Gleap.isOpened() ? Gleap.close : Gleap.open)();
      }}
    >
      <HelpOutlineOutlined className="!w-4" />
      <div>Feedback</div>
    </button>
  );
};
