import { atom, atomFamily, DefaultValue, selector } from 'recoil';
import { nudgesAtom } from 'renderer/atoms/nudge';

export const unreadMesssagesForChannelAtom = atomFamily<
  number,
  { channelId: string }
>({
  key: 'totalUnreadChannels',
  default: 0,
});

export const totalUnreadMessagesAtom = atom<number>({
  key: 'totalUnreadChannels',
  default: 0,
});

export const totalNotifsAtom = selector<number>({
  key: 'totalNotifsAtom',
  get: ({ get }) => {
    // TODO: the unreadMsgs seems buggy -- Vaibhav said it keeps counting up
    // maybe it's a hot-reload issue or an actual bug with our unreadMsgCount.
    // for now we only use # of nudges.
    return get(nudgesAtom).incomingPings.length; // + get(totalUnreadMessagesAtom);
  },
});

export const useChatNotifications = () => {};
