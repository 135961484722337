import clsx from 'clsx';
import { ButtonProps, SimpleButton } from 'renderer/shared/SimpleButton';

export const GradientButton: React.FC<ButtonProps> = ({
  children,
  clickHandler,
  disabled,
  enableChevron,
  circular,
}) => {
  return (
    <SimpleButton
      className={clsx(
        'flex w-fit transform duration-300 text-white-1',
        [enableChevron && '!pr-1'],
        [
          !disabled
            ? 'hover:scale-105  bg-gradient-to-r from-blue-primary to-[#62abe0]'
            : 'bg-gray-3',
          circular ? 'rounded-full' : 'rounded-md p-2 px-3',
        ]
      )}
      clickHandler={clickHandler}
      disabled={disabled}
      enableChevron={enableChevron}
    >
      {children}
    </SimpleButton>
  );
};
