// Automatically dismisses nudges in some scenarios.

import { useEffect } from 'react';
import { selectorFamily, useRecoilValue } from 'recoil';
import { roomWhoNudgedMeAtom } from 'renderer/atoms/nudge';
import { selectedRoomKeyAtom, selfUserConvoAtom } from 'renderer/atoms/room';
import { trpcAtom } from 'renderer/common/components/TRPCReactQuery';
import { logger } from 'renderer/common/LogCreator';
import { RoomKey, UserKey } from 'renderer/models/Keys';
import { localMicActiveAtom } from './MicrophoneProvider';

const usersAtMyTableWhoNudgedMeAtom = selectorFamily<UserKey[], RoomKey>({
  key: 'usersAtMyTableWhoNudgedMe',
  get:
    (key) =>
    ({ get }) => {
      try {
        const usersOnTable = get(selfUserConvoAtom(key));
        if (!usersOnTable) {
          return [];
        }
        const whoNudgedMe = get(roomWhoNudgedMeAtom(key));
        return whoNudgedMe.filter(({ userId }) =>
          usersOnTable.userList.includes(userId)
        );
      } catch (error: any) {
        logger.warn('Missing users who nudged me');
        return [];
      }
    },
});

export const NudgeDismisser: React.FC = () => {
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const micActive = useRecoilValue(localMicActiveAtom({ roomId }));
  const onlineUsersWhoNudged = useRecoilValue(
    usersAtMyTableWhoNudgedMeAtom({ roomId })
  );

  const mut = useRecoilValue(trpcAtom);

  useEffect(() => {
    if (micActive) {
      Promise.allSettled(
        onlineUsersWhoNudged.map(({ userId }) =>
          // Deactivate nudges for each of those users.
          mut.mutation('users.nudge.complete', { roomId, senderUserId: userId })
        )
      ).catch(console.error);
    }
  }, [micActive, mut, roomId, onlineUsersWhoNudged]);

  return <></>;
};
