import { FirebaseOptions } from 'firebase/app';

interface DeploymentData {
  firebaseProject: string;
  agoraAppId: string;
  functionsUrl: string;
  gleapId?: string;
  config: Partial<FirebaseOptions>;
  bucketUrl: string;
}

export class DeploymentConfig {
  readonly functionUrl: string;

  readonly agoraAppId: string;

  readonly firebaseConfig: FirebaseOptions;

  readonly gleapId?: string;

  readonly bucketUrl: string;

  constructor({
    firebaseProject,
    functionsUrl,
    agoraAppId,
    config,
    gleapId,
    bucketUrl,
  }: DeploymentData) {
    this.functionUrl = `${functionsUrl}/app/api/v1`;
    this.firebaseConfig = {
      ...config,
      authDomain: `${firebaseProject}.firebaseapp.com`,
      projectId: firebaseProject,
      storageBucket: `${firebaseProject}.appspot.com`,
      databaseURL: `https://${firebaseProject}-default-rtdb.firebaseio.com`,
    };
    this.agoraAppId = agoraAppId;
    this.gleapId = gleapId;
    this.bucketUrl = bucketUrl;
  }
}
