export const GlooIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="50"
      height="43"
      viewBox="0 0 50 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9C0 7.61929 1.11929 6.5 2.5 6.5C3.88071 6.5 5 7.61929 5 9V34C5 35.3807 3.88071 36.5 2.5 36.5C1.11929 36.5 0 35.3807 0 34V9Z"
        fill="currentColor"
      />
      <path
        d="M9 14C9 12.6193 10.1193 11.5 11.5 11.5C12.8807 11.5 14 12.6193 14 14V29C14 30.3807 12.8807 31.5 11.5 31.5C10.1193 31.5 9 30.3807 9 29V14Z"
        fill="currentColor"
      />
      <path
        d="M18 9.5C18 8.11929 19.1193 7 20.5 7C21.8807 7 23 8.11929 23 9.5V33.5C23 34.8807 21.8807 36 20.5 36C19.1193 36 18 34.8807 18 33.5V9.5Z"
        fill="currentColor"
      />
      <path
        d="M27 2.5C27 1.11929 28.1193 0 29.5 0C30.8807 0 32 1.11929 32 2.5V40.5C32 41.8807 30.8807 43 29.5 43C28.1193 43 27 41.8807 27 40.5V2.5Z"
        fill="currentColor"
      />
      <path
        d="M36 10.1111C36 8.7304 37.1193 7.61111 38.5 7.61111C39.8807 7.61111 41 8.7304 41 10.1111V32.8889C41 34.2696 39.8807 35.3889 38.5 35.3889C37.1193 35.3889 36 34.2696 36 32.8889V10.1111Z"
        fill="currentColor"
      />
      <path
        d="M45 12.8889C45 11.5082 46.1193 10.3889 47.5 10.3889C48.8807 10.3889 50 11.5082 50 12.8889V30.1111C50 31.4918 48.8807 32.6111 47.5 32.6111C46.1193 32.6111 45 31.4918 45 30.1111V12.8889Z"
        fill="currentColor"
      />
    </svg>
  );
};
