import { useRecoilTransaction_UNSTABLE } from 'recoil';
import { selfGlooUserImplAtom } from 'renderer/atoms/glooUser';
import { PageSelector, pageSelectorAtom } from 'renderer/atoms/pageselectors';
import { firebaseRoomListAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import {
  selectedTeamKeyAtom,
  teamImplAtom,
  firebaseTeamListAtom,
} from 'renderer/atoms/team';
import { TeamKey, TeamRoomKey } from 'renderer/models/Keys';

export const usePageSelector = () => {
  const touchSelectedPage = useRecoilTransaction_UNSTABLE(
    ({ set, get }) =>
      () => {
        const validTeam = get(selectedTeamKeyAtom) !== 'NONE';
        const validRoom = get(selectedRoomKeyAtom) !== 'NONE';
        set(pageSelectorAtom, (prev) => {
          if (
            prev === PageSelector.NO_TEAMS ||
            prev === PageSelector.NO_NON_PERSONAL_TEAMS
          ) {
            if (validTeam) {
              if (validRoom) {
                return PageSelector.TEAM_ROOM;
              }
              return PageSelector.TEAM_ROOMS_OVERVIEW;
            }
          }
          return prev;
        });
      }
  );

  const selectRoom = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (key: TeamRoomKey) => {
        set(selectedRoomKeyAtom, key.roomId);
        set(selectedTeamKeyAtom, key.teamId);
        set(pageSelectorAtom, PageSelector.TEAM_ROOM);
      }
  );
  const selectTeam = useRecoilTransaction_UNSTABLE(
    ({ set, reset, get }) =>
      (
        key: TeamKey,
        pageType?:
          | PageSelector.TEAM_ROOMS_OVERVIEW
          | PageSelector.TEAM_USER_OVERVIEW
          | PageSelector.TEAM_SETTINGS
      ) => {
        reset(selectedRoomKeyAtom);
        set(selectedTeamKeyAtom, key.teamId);
        set(pageSelectorAtom, pageType ?? PageSelector.TEAM_USER_OVERVIEW);
      }
  );
  const selectPage = useRecoilTransaction_UNSTABLE(
    ({ set, reset }) =>
      (pageType: PageSelector.NOTIFICATIONS_PAGE) => {
        reset(selectedRoomKeyAtom);
        reset(selectedTeamKeyAtom);
        set(pageSelectorAtom, pageType);
      }
  );
  const resetPages = useRecoilTransaction_UNSTABLE(
    ({ get, set, reset }) =>
      () => {
        const glooUser = get(selfGlooUserImplAtom);
        const myTeams = glooUser?.teamList || [];
        const myRooms = glooUser?.roomList || [];

        const firebaseTeamList = get(firebaseTeamListAtom);
        const firebaseRoomList = get(firebaseRoomListAtom);

        const teams = myTeams?.filter((i) => firebaseTeamList.includes(i));
        const roomList = myRooms.filter((i) => firebaseRoomList.includes(i));

        if (teams.length > 0) {
          for (let index = 0; index < teams.length; index += 1) {
            const element = teams[index];
            const team = get(teamImplAtom(element));
            const matchingRoom = roomList.find((r) =>
              team?.roomList.includes(r)
            );
            if (!matchingRoom && team?.isPersonalTeam) {
              // Skip this team
            } else {
              set(selectedTeamKeyAtom, team?.teamId ?? element);
              if (matchingRoom) {
                set(selectedRoomKeyAtom, matchingRoom);
                set(pageSelectorAtom, PageSelector.TEAM_ROOM);
                return;
              }
              reset(selectedRoomKeyAtom);
              set(pageSelectorAtom, PageSelector.TEAM_ROOMS_OVERVIEW);
              return;
            }
          }
          reset(selectedRoomKeyAtom);
          reset(selectedTeamKeyAtom);
          set(pageSelectorAtom, PageSelector.NO_NON_PERSONAL_TEAMS);
        }
        reset(selectedRoomKeyAtom);
        reset(selectedTeamKeyAtom);
        reset(pageSelectorAtom);
      }
  );

  return { selectRoom, selectTeam, resetPages, touchSelectedPage, selectPage };
};
