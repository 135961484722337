import { IRemoteAudioTrack } from 'agora-rtc-sdk-ng';
import { Loopback } from 'renderer/call/loopback';
import {
  IAudioNode,
  AudioContext,
  IMediaStreamAudioDestinationNode,
  IMediaStreamAudioSourceNode,
  IAudioContext,
  IBiquadFilterNode,
} from 'standardized-audio-context';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';

const logger = LogCreator(LoggerNames.MIC);

type OverHearingConfig = {
  selectedRoom: boolean;
  tableHovered: boolean;
};

export const makeFilterMuffled = (filter: IBiquadFilterNode<IAudioContext>) => {
  filter.type = 'lowpass';
  // lower is more muffled
  filter.frequency.value = 550;
  filter.Q.value = 1;
};

export const makeFilterFarAway = (filter: IBiquadFilterNode<IAudioContext>) => {
  filter.type = 'highpass';
  // higher is more far away, metallic sounding
  filter.frequency.value = 500;
  filter.Q.value = 1;
};

const addMuffledFilter = (
  audioContext: AudioContext,
  source: IAudioNode<AudioContext>,
  destination: IMediaStreamAudioDestinationNode<AudioContext>
) => {
  const filter = audioContext.createBiquadFilter();
  filter.type = 'lowpass';
  // lower is more muffled
  filter.frequency.value = 550;
  filter.Q.value = 1;
  source.connect(filter);
  filter.connect(destination);
};

const addFarAwayFilter = (
  audioContext: AudioContext,
  source: IAudioNode<AudioContext>,
  destination: IMediaStreamAudioDestinationNode<AudioContext>
) => {
  const filter = audioContext.createBiquadFilter();
  filter.type = 'highpass';
  // higher is more far away, metallic sounding
  filter.frequency.value = 500;
  filter.Q.value = 1;
  source.connect(filter);
  filter.connect(destination);
};

// This is a workaround for there being no noise cancellation
// when using Web Audio API in Chrome (another bug):
// https://bugs.chromium.org/p/chromium/issues/detail?id=687574
const setLoopback = (stream: MediaStream) => {
  const loopback = new Loopback();
  const audio = new Audio();

  audio.autoplay = true;
  audio.classList.add('invisible');
  const audioTag = audio;
  loopback
    .start(stream)
    .then(() => {
      const s = loopback.getLoopback();
      audioTag.muted = false;
      audioTag.srcObject = s;

      // TODO: if we use this in a browser thats not chrome we can avoid this whole loopback
      // chrome bug/hack and just assign destination to the audio tag
      //  audioTag.srcObject = destination.stream;
      return audioTag.play();
    })
    .catch((err) => logger.info('Failed to play:', err));
  return { loopback, audio };
};

export const setOverhearingFilter = (track: IRemoteAudioTrack) => {
  const mediaTrack = track.getMediaStreamTrack();
  const audioFxTrack = mediaTrack.clone();
  const stream = new MediaStream([audioFxTrack]);

  const mutedAudio = new Audio();
  mutedAudio.muted = true;
  mutedAudio.srcObject = stream;
  mutedAudio.play();

  const audioContext = new AudioContext();
  const source: IMediaStreamAudioSourceNode<AudioContext> =
    audioContext.createMediaStreamSource(stream);
  const destination = audioContext.createMediaStreamDestination();
  const gain = audioContext.createGain();
  // gain.gain.value = targetVolumePercent === 0 ? 0 : targetVolumePercent / 100;
  gain.gain.value = 0; // initialize;
  source.connect(gain);
  const filter = audioContext.createBiquadFilter();
  filter.type = 'allpass';
  gain.connect(filter);
  filter.connect(destination);

  // if (!config.tableHovered) {
  //   if (config.selectedRoom) {
  //     addFarAwayFilter(audioContext, gain, destination);
  //   } else {
  //     // unselected room audio sounds like it's in another room
  //     addMuffledFilter(audioContext, gain, destination);
  //   }
  // } else {

  // // 3 second fade into the full volume
  // const end = audioContext.currentTime + 3;
  // gain.gain.linearRampToValueAtTime(1.0, end);
  // gain.connect(destination);
  // }

  const loopBack = setLoopback(destination.stream);
  return {
    loopBack,
    streamSource: source,
    audioContext,
    streamTrack: stream,
    gain,
    filter,
  };
};
