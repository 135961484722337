export const SpatialAudioIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M16.625 7.375q1.15 1.15 2.675 1.875q1.525.725 2.825.725q.375 0 .625-.287q.25-.288.25-.713q0-.375-.288-.663q-.287-.287-.712-.337q-1.125-.175-2.15-.688q-1.025-.512-1.825-1.312t-1.3-1.825q-.5-1.025-.675-2.15q-.05-.425-.35-.713Q15.4 1 15.025 1q-.425 0-.713.262q-.287.263-.287.638q0 1.275.713 2.8q.712 1.525 1.887 2.675Zm2.8-2.8q.65.65 1.387 1.025q.738.375 1.363.375q.35 0 .588-.3q.237-.3.237-.725q0-.35-.262-.625q-.263-.275-.688-.425q-.35-.125-.65-.288q-.3-.162-.575-.437q-.275-.275-.45-.575q-.175-.3-.3-.65q-.15-.425-.412-.688Q19.4 1 19.05 1q-.425 0-.725.237q-.3.238-.3.588q0 .625.375 1.362q.375.738 1.025 1.388ZM10 13q-1.65 0-2.825-1.175Q6 10.65 6 9q0-1.65 1.175-2.825Q8.35 5 10 5q1.65 0 2.825 1.175Q14 7.35 14 9q0 1.65-1.175 2.825Q11.65 13 10 13Zm-6 8q-.825 0-1.412-.587Q2 19.825 2 19v-.8q0-.825.425-1.55q.425-.725 1.175-1.1q1.275-.65 2.875-1.1Q8.075 14 10 14q1.925 0 3.525.45q1.6.45 2.875 1.1q.75.375 1.175 1.1q.425.725.425 1.55v.8q0 .825-.587 1.413Q16.825 21 16 21Z"
      />
    </svg>
  );
};
