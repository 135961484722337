import { isEqual } from 'lodash';
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  TransactionInterface_UNSTABLE,
  useRecoilTransaction_UNSTABLE,
} from 'recoil';
import { logger } from 'renderer/common/LogCreator';
import { Room, RoomUser } from 'renderer/models/Api';
import { Convo, RoomUserInterface } from 'renderer/models/Convo';
import { RoomKey, RoomUserKey } from 'renderer/models/Keys';
import { persistAtom } from './effects';
import {
  myRoomsAtom,
  selfGlooUserAtom,
  selfGlooUserImplAtom,
  selfUserIdAtom,
} from './glooUser';
import { PageSelector, pageSelectorAtom } from './pageselectors';
import { FirebaseSyncAvailability, teamUserImplAtom } from './team';

export const selectedRoomKeyAtom = atom<string>({
  key: 'selectedRoomKeyAtom',
  default: 'NONE',
  effects_UNSTABLE: [persistAtom],
});

// Only includes rooms which have been loaded.
export const firebaseRoomListAtom = atom<string[]>({
  key: 'firebaseRoomListAtom',
  default: [],
});

// Only includes rooms which have been loaded.
export const roomListAtom = selector<string[]>({
  key: 'availableRoomListAtom',
  get: ({ get }) => {
    return get(myRoomsAtom).filter(
      (roomId) =>
        get(roomAvailableAtom({ roomId })) ===
        FirebaseSyncAvailability.AVAILABLE
    );
  },
});

const roomUserImplAtom = atomFamily<
  RoomUser | null,
  { roomId: string; userId: string }
>({
  key: 'roomUserImplAtom',
  default: null,
});

export const roomImplAtom = atomFamily<Room | null, string>({
  key: 'roomImplAtom',
  default: null,
});

export const roomAvailableAtom = selectorFamily<
  FirebaseSyncAvailability,
  RoomKey
>({
  key: 'roomAvailableAtom',
  get:
    (param) =>
    ({ get }) => {
      const isMyRoom = get(myRoomsAtom).includes(param.roomId);
      const roomReady = get(firebaseRoomListAtom).includes(param.roomId);
      if (roomReady) return FirebaseSyncAvailability.AVAILABLE;
      return isMyRoom
        ? FirebaseSyncAvailability.LOADING
        : FirebaseSyncAvailability.NOT_AVAILABLE;
    },
});

export const selectedRoomAvailableAtom = selector<FirebaseSyncAvailability>({
  key: 'selectedRoomAvailableAtom',
  get: ({ get }) =>
    get(roomAvailableAtom({ roomId: get(selectedRoomKeyAtom) })),
});

export const roomUserAtom = selectorFamily<RoomUserInterface, RoomUserKey>({
  key: 'roomUserAtom',
  get:
    (key) =>
    ({ get }) => {
      const selfUserId = get(selfUserIdAtom);
      const room = get(roomAtom(key.roomId));
      const roomUser = room.members.find((u) => u.userId === key.userId);
      if (!roomUser) throw new Error('Room user not found');
      const teamUser = get(
        teamUserImplAtom({ teamId: room.teamId, userId: key.userId })
      );

      if (!teamUser) {
        return {
          userId: key.userId,
          loaded: false,
          profile: {
            displayName: 'LOADING',
            photoUrl: undefined,
          },
          online: false,
          busy: false,
          invitedUser: undefined,
          convo: {
            active: false,
            withPrimaryUser: false,
            isEvent: false,
            engaged: false,
            devices: {
              mic: false,
              screen: false,
              camera: false,
            },
            deviceBroadcast: {
              mic: false,
              screen: false,
              camera: false,
            },
          },
        };
      }

      const convoId = roomUser.convo?.convoId;
      const allEventIds = room.events?.map(({ eventId }) => eventId);
      const isInEvent =
        convoId !== undefined && !!allEventIds?.includes(convoId);
      let convoActive = false;
      let convoEngaged = false;
      let withPrimaryUser = key.userId === selfUserId;
      if (convoId) {
        const onlineConvoMembers = room.members
          .map(({ convo, userId, status }) => {
            if (convo?.convoId !== convoId) return { valid: false, user: null };
            const otherTeamUser = get(
              teamUserImplAtom({ teamId: room.teamId, userId })
            );
            if (!otherTeamUser) return { valid: false, user: null };

            if (!(otherTeamUser.status.online && status.enabled))
              return { valid: false, user: null };
            return { valid: true, user: otherTeamUser };
          })
          .filter(({ valid }) => valid);
        withPrimaryUser =
          withPrimaryUser ||
          !!onlineConvoMembers.find(({ user }) => user?.userId === selfUserId);
        convoActive = withPrimaryUser;
        // There are at least 2 people in a conversation.
        if (onlineConvoMembers.length > 1) {
          convoActive = true;
          // engaged means one microphone is online
          convoEngaged =
            onlineConvoMembers.filter(
              ({ user }) =>
                user?.device?.mic?.roomId === key.roomId ||
                user?.device?.screen?.roomId === key.roomId
            ).length > 0;
        } else if (!withPrimaryUser) {
          convoEngaged =
            onlineConvoMembers.filter(
              ({ user }) =>
                user?.device?.mic?.roomId === key.roomId ||
                user?.device?.screen?.roomId === key.roomId
            ).length > 0;
          if (convoEngaged) {
            convoActive = true;
          }
        }
      }
      const online = teamUser.status.online && roomUser.status.enabled;
      const devices = {
        mic: teamUser.device?.mic?.roomId === key.roomId,
        screen: teamUser.device?.screen?.roomId === key.roomId,
        camera: teamUser.device?.camera?.roomId === key.roomId,
      };
      const broadcast = {
        mic: !!(devices.mic && teamUser.device?.mic?.broadcast),
        screen: !!(devices.screen && teamUser.device?.screen?.broadcast),
        camera: !!(devices.camera && teamUser.device?.camera?.broadcast),
      };
      return {
        userId: key.userId,
        loaded: true,
        profile: {
          displayName: teamUser.profile.displayName,
          photoUrl: teamUser.profile.photoUrl,
        },
        online,
        busy: online && teamUser.status.details.busy,
        invitedUser: teamUser.membership.invite,
        convo:
          convoActive && convoId
            ? {
                convoId,
                active: true,
                engaged: convoEngaged,
                withPrimaryUser,
                devices,
                deviceBroadcast: broadcast,
                isEvent: isInEvent,
              }
            : {
                convoId,
                active: false,
                engaged: false,
                withPrimaryUser: false,
                devices,
                deviceBroadcast: broadcast,
                isEvent: isInEvent,
              },
      };
    },
});

export const isSelfUserConvoActiveAtom = selectorFamily<boolean, RoomKey>({
  key: 'isSelfUserConvoActive',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const { convo } = get(
        roomUserAtom({ roomId, userId: get(selfUserIdAtom) })
      );
      return convo.active;
    },
});

export const isMicActiveAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isMicActiveAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      return get(roomUserAtom({ roomId, userId })).convo.devices.mic;
    },
});

export const isScreensharingActiveAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isScreensharingActiveAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      return get(roomUserAtom({ roomId, userId })).convo.devices.screen;
    },
});

export const isBroadcastingAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isMegaphoningAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      return get(roomUserAtom({ roomId, userId })).convo.deviceBroadcast.mic;
    },
});

export const useRoomAtomSetters = () => {
  const addRoom = (
    { set }: TransactionInterface_UNSTABLE,
    room: Room,
    prevRoomList: string[]
  ) => {
    if (!prevRoomList.includes(room.roomId)) {
      const updatedRoomList = prevRoomList.concat(room.roomId);

      // Add the room from roomList.
      set(firebaseRoomListAtom, updatedRoomList);
    }
  };

  const removeRoomImpl = (
    { set, reset }: TransactionInterface_UNSTABLE,
    roomId: string,
    userIds: string[],
    prevRoomList: string[],
    isSelected: boolean
  ) => {
    const updatedRoomList = prevRoomList.filter((p) => p !== roomId);

    // First update the selected room the right room.
    if (isSelected) {
      reset(selectedRoomKeyAtom);
      set(pageSelectorAtom, PageSelector.TEAM_USER_OVERVIEW);
    }

    // Remove the room from roomList.
    set(firebaseRoomListAtom, updatedRoomList);

    // Remove all users the room may have created.
    userIds.forEach((u) => reset(roomUserImplAtom({ roomId, userId: u })));

    // Remove the room itself.
    reset(roomImplAtom(roomId));
  };

  const removeRoom = useRecoilTransaction_UNSTABLE(
    ({ get, set, reset }) =>
      (roomId: string | string[]) => {
        const roomIds = typeof roomId === 'string' ? [roomId] : roomId;
        roomIds.forEach((id) => {
          const prevRoom = get(roomImplAtom(id));
          const existingRooms = get(firebaseRoomListAtom);
          const selectedRoomId = get(selectedRoomKeyAtom);
          removeRoomImpl(
            { get, set, reset },
            id,
            prevRoom?.memberList ?? [],
            existingRooms,
            selectedRoomId === roomId
          );
        });
      }
  );

  const addOrUpdateRoom = useRecoilTransaction_UNSTABLE(
    ({ set, get, reset }) =>
      (room: Room) => {
        const user = get(selfGlooUserImplAtom);
        if (!user) {
          throw new Error('Gloo user not set');
        }
        const existingRooms = get(firebaseRoomListAtom);
        const selectedRoomId = get(selectedRoomKeyAtom);

        const isUserInRoom = room.memberList.includes(user.userId);
        logger.info('addOrUpdate', {
          existingRooms,
          selectedRoomId,
          isUserInRoom,
        });
        if (!isUserInRoom) {
          removeRoomImpl(
            { set, reset, get },
            room.roomId,
            room.memberList,
            existingRooms,
            selectedRoomId === room.roomId
          );
        } else {
          const prevRoom = get(roomImplAtom(room.roomId));
          if (prevRoom) {
            // Remove any users which were deleted.
            prevRoom.memberList
              .filter((oldUser) => room.memberList.indexOf(oldUser) === -1)
              .forEach((u) =>
                // clear out this user
                reset(roomUserImplAtom({ roomId: room.roomId, userId: u }))
              );
          }
          const capitalizedName = room.name.replace(/(^\w|\s\w)/g, (m) =>
            m.toUpperCase()
          );
          const sortedMembers = room.members
            .map((m) => {
              const teamUser = get(
                teamUserImplAtom({ teamId: room.teamId, userId: m.userId })
              );

              return {
                m,
                name: teamUser?.profile.displayName ?? 'LOADING',
              };
            })
            .sort((a, b) => {
              if (a.name === 'LOADING')
                return b.name === 'LOADING'
                  ? a.m.userId.localeCompare(b.m.userId)
                  : 1;
              if (b.name === 'LOADING') return -1;
              return a.name.localeCompare(b.name);
            })
            .map(({ m }) => m);

          const memberList = sortedMembers.map((m) => m.userId);
          const newRoom = {
            ...room,
            name: capitalizedName,
            memberList,
            members: sortedMembers,
          };
          if (isEqual(prevRoom, newRoom)) {
            logger.info('room data is the same as stored data, ignoring', {
              newRoom,
            });
            return;
          }
          logger.info('Adding new room data', { room });
          set(roomImplAtom(room.roomId), newRoom);
          room.members.map((u) =>
            set(roomUserImplAtom({ roomId: room.roomId, userId: u.userId }), u)
          );
          addRoom({ get, set, reset }, room, existingRooms);
        }
      }
  );

  return { removeRoom, addOrUpdateRoom };
};

//
// These are all readonly selectors.
//
export const roomsExistAtom = selector<boolean>({
  key: 'roomsExist',
  get: ({ get }) => get(firebaseRoomListAtom).length > 0,
});

export const roomAtom = selectorFamily<Room, string>({
  key: 'roomAtom',
  get:
    (key) =>
    ({ get }) => {
      const room = get(roomImplAtom(key));
      if (!room) {
        throw new Error(`Not initialized ${key}`);
      }
      return room;
    },
});

export const roomTeamIdAtom = selectorFamily<string, RoomKey>({
  key: 'roomTeamIdAtom',
  get:
    (key) =>
    ({ get }) =>
      get(roomAtom(key.roomId)).teamId,
});

export const selectedRoomAtom = selector<Room>({
  key: 'selectedRoomAtom',
  get: ({ get }) => {
    const key = get(selectedRoomKeyAtom);
    if (key === 'NONE') {
      throw new Error('No room selected');
    }

    const room = get(roomImplAtom(key));
    if (!room) {
      throw new Error('Room not initialized');
    }
    return room;
  },
});

export const isUserOnlineAtom = selectorFamily<
  boolean,
  { roomId: string; userId: string }
>({
  key: 'isUserOnline',
  get:
    (param) =>
    ({ get }) =>
      get(roomUserAtom(param)).online,
});

export const onlineCountRoomAtom = selectorFamily<number, RoomKey>({
  key: 'onlineCountRoom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      return room.members.filter((m) =>
        get(isUserOnlineAtom({ roomId, userId: m.userId }))
      ).length;
    },
});

export const roomConvoAtom = selectorFamily<
  Convo | null,
  { roomId: string; convoId?: string }
>({
  key: 'roomTableAtom',
  get:
    ({ roomId, convoId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      if (!room) {
        throw new Error('Room not found');
      }
      if (!convoId) {
        return null;
      }

      const convoIndex = room.convoList.findIndex(
        (convo) => convo.convoId === convoId
      );
      if (convoIndex === -1) {
        // TODO: throw new Error("Table not found").
        // The error causes some race condition so return null for now.
        return null;
      }

      const userList = room.members
        .filter(
          ({ convo, status }) => status.enabled && convo?.convoId === convoId
        )
        .map((u) => ({
          id: u.userId,
          user: get(roomUserAtom({ roomId, userId: u.userId })),
        }))
        .filter(({ user }) => user.online);

      // If only one user is at my convo, return empty.
      if (userList.length <= 1) return null;

      return {
        convoId,
        userList: userList.map(({ id }) => id),
        users: userList.map(({ user }) => user),
      };
    },
});

export const roomConvoHasVideoAtom = selectorFamily<
  boolean,
  { roomId: string; convoId?: string }
>({
  key: 'roomConvoHasVideoAtom',
  get:
    (key) =>
    ({ get }) =>
      !!get(roomConvoAtom(key))?.users.find((u) => u.convo.devices.camera),
});

export const roomEventAtom = selectorFamily<
  NonNullable<Room['events']>[0] | undefined,
  { roomId: string; eventId: string }
>({
  key: 'roomEventAtom',
  get:
    ({ roomId, eventId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      return room.events?.find(({ eventId: eId }) => eventId === eId);
    },
});

export const roomMetadataAtom = selectorFamily<
  {
    title: string;
  },
  RoomKey
>({
  key: 'roomMetadataAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const room = get(roomAtom(roomId));
      if (!room) {
        throw new Error('Room not found');
      }
      return {
        title: room.name,
      };
    },
});

export const selfUserConvoAtom = selectorFamily<
  Convo | null,
  {
    roomId: string;
  }
>({
  key: 'usersAtSelfUserTable',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const selfUserId = get(selfUserIdAtom);
      const selfRoomUser = get(roomUserAtom({ roomId, userId: selfUserId }));
      return get(
        roomConvoAtom({ roomId, convoId: selfRoomUser.convo?.convoId })
      );
    },
});

export const userDisplayNameAtom = selectorFamily<
  string,
  { roomId: string; userId: string }
>({
  key: 'userDisplayNameAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const selfUserId = get(selfGlooUserAtom).userId;
      if (userId === selfUserId) {
        return 'You';
      }
      return get(roomUserAtom({ roomId, userId })).profile.displayName.split(
        ' '
      )[0];
    },
});
