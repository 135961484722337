import { ReactNode } from 'react';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../shared/Buttons/helpers/IconButton';

interface SettingsContentData {
  title: string;
  children: ReactNode;
}
export const SettingsContent: React.FC<SettingsContentData> = ({
  title,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-[350px] lg:w-[500px] p-2 pl-4 text-left">
      <div className="flex flex-row">
        <div className="text-xl font-bold">{title}</div>
        <div className="flex ml-auto">
          <IconButton clickHandler={() => navigate('/dashboard')}>
            <MdClose className="w-8 h-8" />
          </IconButton>
        </div>
      </div>
      {children}
    </div>
  );
};

interface SettingsSubsectionData {
  title?: string;
  description?: string;
  children: ReactNode;
}
export const SettingsSubsection: React.FC<SettingsSubsectionData> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="divide-slate-300">
      {/* subsec title */}
      {title && (
        <div className="pt-4 text-lg font-semibold text-black-primary/70">
          {title}
        </div>
      )}
      {description && (
        <div className="text-sm italic text-gray-600">{description}</div>
      )}
      <div className="divide-y-[1px]">{children}</div>
    </div>
  );
};
