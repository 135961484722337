import { ButtonStateRequest } from 'api/models';
import React, { memo, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  firebaseConnectionStatusSelector,
  FirebaseStatus,
} from 'renderer/atoms/connectionStatus';
import { myRoomsAtom, myTeamsAtom } from 'renderer/atoms/glooUser';
import { roomListAtom } from 'renderer/atoms/room';
import {
  JoinCallMonitor,
  useAgoraCameraStreamListeners,
  useAgoraListeners,
} from 'renderer/call/components/JoinCallMonitor';
import {
  micActiveRoomIdAtom,
  MicrophoneProvider,
  useMicToggles,
} from 'renderer/call/components/MicrophoneProvider';
import { NudgeDismisser } from 'renderer/call/components/NudgeDismisser';
import {
  ScreenShareProvider,
  useSceenshareToggles,
} from 'renderer/call/components/ScreenShareProvider';
import { useVideoCamToggles } from 'renderer/call/components/videocam/useVideoCamToggles';
import { VideoCameraProvider } from 'renderer/call/components/videocam/VideoCameraProvider';
import { logger } from 'renderer/common/LogCreator';
import { availableStatusAtom } from 'renderer/connection/state';
import {
  useConversationExpirationMonitor,
  useConvoHooks,
} from 'renderer/hooks/useConversationExpirationMonitor';
import { useConversationStateUpdates } from 'renderer/hooks/useConversationStateUpdates';
import {
  FirestoreRoomUpdates,
  FirestoreTeamUpdates,
} from 'renderer/hooks/useFirestoreUpdates';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import {
  RoomSoundEffectsProvider,
  SoundEffectsProvider,
} from 'renderer/hooks/useSoundEffects';
import { TeamRoomKey } from 'renderer/models/Keys';
import { ChildProps } from 'types/react';
import { AudioDeviceMonitor } from './AudioDeviceMonitor';
import {
  FirebaseConvoUpdates,
  useElectronUpdates,
} from './FirebaseScreenShareUpdates';
import { FirestoreNudgeUpdates } from './FirestoreNudgeUpdates';
import { OnlineStatusMonitor } from './OnlineStatusMonitor';

const OnlineSingleRoomProvider = ({ roomId }: { roomId: string }) => {
  const firebaseConnection = useRecoilValue(
    firebaseConnectionStatusSelector(roomId)
  );
  const available = useRecoilValue(availableStatusAtom);
  const online = useMemo(
    () => firebaseConnection === FirebaseStatus.CONNECTED && available,
    [firebaseConnection, available]
  );

  if (!online) {
    return null;
  }

  logger.info(`SubTest ${roomId}: OnlineSingleRoomProvider`);
  return (
    <>
      <FirebaseConvoUpdates roomId={roomId} />
      <JoinCallMonitor roomId={roomId} />
    </>
  );
};

const SingleRoomProvider = memo(({ roomId }: { roomId: string }) => {
  // Initializes all the listeners for agora.
  useAgoraListeners({ roomId });
  useAgoraCameraStreamListeners({ roomId });

  return (
    <>
      <React.StrictMode>
        <RoomSoundEffectsProvider roomId={roomId} />
        <ConvoExpirationListener roomId={roomId} />
      </React.StrictMode>
      <OnlineSingleRoomProvider roomId={roomId} />
    </>
  );
});

const RoomsWrapper = () => {
  const roomList = useRecoilValue(roomListAtom);
  const { selectRoom } = usePageSelector();
  const { toggleMic } = useMicToggles();
  const micActiveRoomId = useRecoilValue(micActiveRoomIdAtom);
  const { toggleScreenshare } = useSceenshareToggles();
  const { toggleVideoCam } = useVideoCamToggles();
  const { leaveConvo, joinConvo } = useConvoHooks();

  useEffect(() => {
    window.electron?.ipcRenderer.onEvent(
      'buttonState',
      async (event, request: ButtonStateRequest & { requestId: string }) => {
        try {
          switch (request.button) {
            case 'MIC':
              await toggleMic({ roomId: request.roomId });
              break;
            case 'SCREENSHARE':
              await toggleScreenshare({ roomId: request.roomId });
              break;
            case 'VIDEO':
              await toggleVideoCam({ roomId: request.roomId });
              break;
            case 'EXIT_CONVO':
              await leaveConvo(request);
              break;
            case 'START_CONVO':
              await joinConvo(
                { teamId: request.teamId, roomId: request.roomId },
                { userId: request.userId }
              );
              break;
            case 'JOIN_CONVO':
              await joinConvo(
                { teamId: request.teamId, roomId: request.roomId },
                { convoId: request.convoId }
              );
              break;
            default:
              break;
          }
        } catch (err) {
          console.error('Button handle error', err);
        }
        event.sender.send('buttonState', 'Completed');
      }
    );
    window.electron?.ipcRenderer.on('setSelectedRoom', (param: TeamRoomKey) => {
      console.log('Room Setting: ', param);
      // TODO: Make this work for team ids
      selectRoom(param);
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('buttonState');
      window.electron?.ipcRenderer.removeAllListeners('setSelectedRoom');
    };
  }, [
    leaveConvo,
    micActiveRoomId,
    selectRoom,
    toggleMic,
    toggleScreenshare,
    toggleVideoCam,
  ]);

  return (
    <>
      {roomList.map((pId) => {
        return <SingleRoomProvider key={pId} roomId={pId} />;
      })}
    </>
  );
};

const TeamsWrapper = () => {
  const teamList = useRecoilValue(myTeamsAtom);
  if (!teamList) {
    return null;
  }
  return (
    <>
      {teamList.map((teamId) => {
        return <FirestoreTeamUpdates teamId={teamId} key={teamId} />;
      })}
    </>
  );
};

const RoomsWrapperRaw = () => {
  const roomList = useRecoilValue(myRoomsAtom);
  return (
    <>
      {roomList.map((roomId) => {
        return (
          <>
            <FirestoreRoomUpdates roomId={roomId} key={roomId} />
          </>
        );
      })}
    </>
  );
};

// in its own component to prevent rerenders of other components... since stuff is flaky
const NotificationPanelMonitor = () => {
  useConversationStateUpdates();
  useElectronUpdates();
  return <></>;
};

const ConvoExpirationListener = ({ roomId }: { roomId: string }) => {
  useConversationExpirationMonitor(roomId);
  return <></>;
};

const OnlineRoomsProvider: React.FC = () => {
  const available = useRecoilValue(availableStatusAtom);

  if (!available) return null;
  return (
    <>
      <React.StrictMode>
        <MicrophoneProvider />
        <ScreenShareProvider />
        <NudgeDismisser />
      </React.StrictMode>
      {/* this component publishes a track using agora client so
      we skip strict mode to prevent strange race conditions */}
      <VideoCameraProvider />
      <AudioDeviceMonitor />
    </>
  );
};

// sets up all connections etc for all rooms
export const RoomsProvider: React.FC<ChildProps> = () => {
  return (
    <>
      <OnlineRoomsProvider />
      <OnlineStatusMonitor />

      <React.StrictMode>
        <SoundEffectsProvider />
        <NotificationPanelMonitor />
        <FirestoreNudgeUpdates />
        <TeamsWrapper />
        <RoomsWrapperRaw />
      </React.StrictMode>
      <RoomsWrapper />
    </>
  );
};
