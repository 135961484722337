import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  TransactionInterface_UNSTABLE,
  useRecoilTransaction_UNSTABLE,
} from 'recoil';

import { Team, TeamUser, UserStatus } from 'renderer/models/Api';
import { TeamKey } from 'renderer/models/Keys';
import { persistAtom } from './effects';
import {
  myTeamsAtom,
  selfGlooUserAtom,
  selfGlooUserImplAtom,
} from './glooUser';

export enum FirebaseSyncAvailability {
  NOT_AVAILABLE,
  LOADING,
  AVAILABLE,
}

export const selectedTeamKeyAtom = atom<string>({
  key: 'selectedTeamKeyAtom',
  default: 'NONE',
  effects_UNSTABLE: [persistAtom],
});

export const selectedTeamAtom = selector<Team>({
  key: 'selectedTeamAtom',
  get: ({ get }) => {
    const key = get(selectedTeamKeyAtom);
    if (key === 'NONE') {
      throw new Error('No team selected');
    }

    const team = get(teamImplAtom(key));
    if (!team) {
      throw new Error('Team not initialized');
    }
    return team;
  },
});

export const firebaseTeamListAtom = atom<string[]>({
  key: 'firebaseTeamListAtom',
  default: [],
});

// Only includes team which have been loaded.
export const teamListAtom = selector<string[]>({
  key: 'availableTeamListAtom',
  get: ({ get }) => {
    return get(myTeamsAtom).filter(
      (teamId) =>
        get(teamAvailableAtom({ teamId })) ===
        FirebaseSyncAvailability.AVAILABLE
    );
  },
});

const teamBookmarkedListAtom = atom<string[]>({
  key: 'teamBookmarkListAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const teamAvailableAtom = selectorFamily<
  FirebaseSyncAvailability,
  TeamKey
>({
  key: 'teamAvailableAtom',
  get:
    (param) =>
    ({ get }) => {
      const isMyTeam = get(myTeamsAtom).includes(param.teamId);
      const teamReady = get(firebaseTeamListAtom).includes(param.teamId);
      if (teamReady) return FirebaseSyncAvailability.AVAILABLE;
      return isMyTeam
        ? FirebaseSyncAvailability.LOADING
        : FirebaseSyncAvailability.NOT_AVAILABLE;
    },
});

export const selectedTeamAvailableAtom = selector<FirebaseSyncAvailability>({
  key: 'selectedTeamAvailableAtom',
  get: ({ get }) =>
    get(teamAvailableAtom({ teamId: get(selectedTeamKeyAtom) })),
});

export const sortedTeamListAtom = selector<string[]>({
  key: 'sortedTeamList',
  get: ({ get }) => {
    const selfUserId = get(selfGlooUserAtom).userId;
    const teamList = get(firebaseTeamListAtom);
    const bookmarks = get(teamBookmarkedListAtom);

    return teamList
      .map((t) => {
        const team = get(teamImplAtom(t));
        const selfTeamUser = team?.members.find(
          ({ userId }) => userId === selfUserId
        );

        const getType = () => {
          if (selfTeamUser?.membership.isDeleted) {
            return 3;
          }
          if (selfTeamUser?.permissions.admin) {
            return 0;
          }
          if (selfTeamUser?.membership.guestPermissions) {
            return 1;
          }
          return 2;
        };

        return {
          id: t,
          isPersonal: team?.isPersonalTeam ?? false,
          role: getType(),
          bookmarked: bookmarks.includes(t),
          name: team?.name ?? 'LOADING',
        };
      })
      .sort((a, b) => {
        if (a.isPersonal === b.isPersonal) {
          if (a.bookmarked === b.bookmarked) {
            if (a.role === b.role) {
              if (a.name === 'LOADING') {
                if (b.name === 'LOADING') {
                  return a.id.localeCompare(b.id);
                }
                return 1;
              }
              if (b.name === 'LOADING') return -1;
              return a.name.localeCompare(b.name);
            }
            return a.role < b.role ? -1 : 1;
          }
          return a.bookmarked ? -1 : 1;
        }
        return a.isPersonal ? -1 : 1;
      })
      .map((t) => t.id);
  },
});

export const allowBookmarksAtom = selector<boolean>({
  key: 'allowBookmarksAtom',
  get: ({ get }) => get(firebaseTeamListAtom).length > 3,
});

export const teamBookmarkedAtom = selectorFamily<boolean, string>({
  key: 'teamBookmarkAtom',
  get:
    (key) =>
    ({ get }) =>
      get(teamBookmarkedListAtom).includes(key),
  set:
    (key) =>
    ({ set }, update) => {
      set(teamBookmarkedListAtom, (prev) => {
        if (!update) {
          return prev.filter((i) => i !== key);
        }
        if (prev.includes(key)) return prev;
        return [...prev, key];
      });
    },
});

export const teamImplAtom = atomFamily<Team | null, string>({
  key: 'teamImplAtom',
  default: null,
});

// Used by room.ts
export const teamUserImplAtom = atomFamily<
  TeamUser | null,
  { teamId: string; userId: string }
>({
  key: 'teamUserImplAtom',
  default: null,
});

export const teamUserAtom = selectorFamily<
  TeamUser,
  { teamId: string; userId: string }
>({
  key: 'teamUserAtom',
  get:
    ({ teamId, userId }) =>
    ({ get }) => {
      const user = get(teamUserImplAtom({ teamId, userId }));
      if (!user) throw new Error('User must be set');
      return user;
    },
});

export const useTeamAtomSetters = () => {
  const addTeam = (
    { set }: TransactionInterface_UNSTABLE,
    team: Team,
    prevTeamList: string[],
    isSomethingSelected: boolean
  ) => {
    if (!prevTeamList.includes(team.teamId)) {
      const updatedTeamList = prevTeamList.concat(team.teamId);

      // Add the team from teamList.
      set(firebaseTeamListAtom, updatedTeamList);

      // Update the selected room id.
      if (!isSomethingSelected) {
        set(selectedTeamKeyAtom, team.teamId);
      }
    }
  };

  const removeTeamImpl = (
    { set, reset }: TransactionInterface_UNSTABLE,
    teamId: string,
    userIds: string[],
    prevTeamList: string[],
    isSelected: boolean
  ) => {
    const updatedTeamList = prevTeamList.filter((p) => p !== teamId);

    // First update the selected room the right room.
    if (isSelected) {
      const candidateNewSelection = updatedTeamList.at(0);
      if (candidateNewSelection) {
        set(selectedTeamKeyAtom, candidateNewSelection);
      } else {
        reset(selectedTeamKeyAtom);
      }
    }

    // Remove the team from teamList.
    set(firebaseTeamListAtom, updatedTeamList);

    // Remove all users the team may have created.
    userIds.forEach((u) => reset(teamUserImplAtom({ teamId, userId: u })));

    // Remove the team itself.
    reset(teamImplAtom(teamId));
  };

  const removeTeam = useRecoilTransaction_UNSTABLE(
    ({ get, set, reset }) =>
      (teamId: string | string[]) => {
        const teamIds = typeof teamId === 'string' ? [teamId] : teamId;
        teamIds.forEach((id) => {
          const prevTeam = get(teamImplAtom(id));
          const existingTeams = get(firebaseTeamListAtom);
          const selectedTeamId = get(selectedTeamKeyAtom);
          removeTeamImpl(
            { get, set, reset },
            id,
            prevTeam?.memberList ?? [],
            existingTeams,
            selectedTeamId === teamId
          );
        });
      }
  );

  const addOrUpdateTeam = useRecoilTransaction_UNSTABLE(
    ({ set, get, reset }) =>
      (team: Team) => {
        console.log('LOADING TEAM', team);
        const user = get(selfGlooUserImplAtom);
        if (!user) {
          throw new Error('Gloo user not set');
        }
        const existingTeams = get(firebaseTeamListAtom);
        const selectedTeamId = get(selectedTeamKeyAtom);

        const checkUserInTeam = (t: Team, uid: string) => {
          const idx = t.memberList.indexOf(uid);
          return idx >= 0 && !t.members[idx].membership.isDeleted;
        };

        const isUserInTeam = checkUserInTeam(team, user.userId);
        if (!isUserInTeam) {
          removeTeamImpl(
            { set, reset, get },
            team.teamId,
            team.memberList,
            existingTeams,
            selectedTeamId === team.teamId
          );
        } else {
          const prevTeam = get(teamImplAtom(team.teamId));
          if (prevTeam) {
            // Remove any users which were deleted.
            prevTeam.memberList
              .filter((u) => team.memberList.indexOf(u) === -1)
              .forEach((u) =>
                reset(teamUserImplAtom({ teamId: team.teamId, userId: u }))
              );
          }
          set(teamImplAtom(team.teamId), team);
          team.members.map((u) =>
            set(teamUserImplAtom({ teamId: team.teamId, userId: u.userId }), u)
          );
          addTeam(
            { get, set, reset },
            team,
            existingTeams,
            selectedTeamId !== 'NONE'
          );
        }
      }
  );

  return { removeTeam, addOrUpdateTeam };
};

//
// Below are just read only views.
//
export const teamAtom = selectorFamily<Team, string>({
  key: 'teamAtom',
  get:
    (teamId) =>
    ({ get }) => {
      const team = get(teamImplAtom(teamId));
      if (!team) {
        throw new Error(`Team not found ${teamId}`);
      }
      return team;
    },
});

export const teamListNamesAtom = selector<
  { teamId: string; name: string; selfIsAdmin: boolean }[]
>({
  key: 'teamListNamesAtom',
  get: ({ get }) => {
    const { userId: uid } = get(selfGlooUserAtom);
    const teamList = get(firebaseTeamListAtom);
    return teamList.map((teamId) => {
      const { name, members } = get(teamAtom(teamId));
      return {
        name,
        selfIsAdmin:
          members.filter(
            ({ permissions, userId }) => userId === uid && permissions.admin
          ).length > 0,
        teamId,
      };
    });
  },
});
