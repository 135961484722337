import AgoraRTC, {
  ConnectionState,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng';
import { atom, atomFamily, selectorFamily } from 'recoil';
import { RoomKey, RoomConvoKey, RoomUserKey } from 'renderer/models/Keys';
import { selfGlooUserAtom, selfUserIdAtom } from './glooUser';

export enum AgoraState {
  kRemoteUsers,
}

// Maintain a 1:1 ratio of clients to rooms. This ensures we never mix calls.
AgoraRTC.setLogLevel(1);
/**
 * Agora clients can only publish one video track, so we create a separate client for
 * the camera video stream
 */
export const agoraClientAtom = selectorFamily<
  IAgoraRTCClient,
  RoomKey & { isCameraStream: boolean }
>({
  key: 'agoraClient',
  dangerouslyAllowMutability: true,
  get: () => () => {
    return AgoraRTC.createClient({
      codec: 'h264',
      mode: 'rtc',
      httpRetryConfig: {
        timeout: 15_000,
        maxRetryCount: 1,
        maxRetryTimeout: 20_000,
        timeoutFactor: 1,
      },
    });
  },
});

type IRemoteUsers = {
  version: number;
  users: IAgoraRTCRemoteUser[];
};

export const remoteUsersAtom = atomFamily<IRemoteUsers, RoomKey>({
  key: 'remoteUsersAtom',
  default: { users: [], version: 0 },
  dangerouslyAllowMutability: true,
});

export const agoraRemoteUserAtom = selectorFamily<
  IAgoraRTCRemoteUser | null,
  RoomUserKey
>({
  key: 'agoraRemoteUserAtom',
  get:
    ({ roomId, userId }) =>
    ({ get }) => {
      const { users } = get(remoteUsersAtom({ roomId }));
      const user = users.find((u) => u.uid === userId);
      return user || null;
    },
  dangerouslyAllowMutability: true,
});

export const connectionStateAtom = atomFamily<
  ConnectionState,
  RoomKey & { isCameraStream: boolean }
>({
  key: 'connectionState',
  default: 'DISCONNECTED',
});

export const micWasOnAtom = atom<boolean>({
  key: 'micWasOn',
  default: false,
});
