import {signOut} from "firebase/auth";
import {useUser, useAuth} from "reactfire";

export const AltLoginText = ({clickHandler}: {
  clickHandler?: () => void;
}) => {
  const user = useUser();
  const auth = useAuth();

  if (!user?.data) {
    return null;
  }

  return (
    <div className="text-sm text-gray-4">Not {user?.data?.email}?
      <br />
      <span>
        <button className="text-blue-800 hover:text-blue-700" onClick={() => {
          if (clickHandler) {
            clickHandler();
          } else {
            signOut(auth);
            // navigate doesnt work since then
            // google button won't initialize in the
            // signup page
            // window.location.replace("/auth/signup");
          }
        }}>
        Log in with another account
        </button>
      </span></div>
  );
};
