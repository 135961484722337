import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { selectedRoomAtom } from 'renderer/atoms/room';
import { trpc } from 'renderer/common/client/trpc';
import { GradientButton } from '../Buttons/helpers/GradientButton';
import { ModalTypes, selectedModalAtom } from './atoms';

export const RenameRoomModal: React.FC = () => {
  const { name: roomName, roomId } = useRecoilValue(selectedRoomAtom);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const showModal =
    useRecoilValue(selectedModalAtom) === ModalTypes.kRenameRoom;

  const resetShowModal = useResetRecoilState(selectedModalAtom);

  const renameRoom = trpc.useMutation(['rooms.rename'], {
    onSuccess: (data) => {
      resetShowModal();
    },
    onError: (error) => {
      const parsedError: { message: string; code: string }[] = JSON.parse(
        error.message
      );
      setErrorMessage(
        parsedError
          .map((e) =>
            e.code === 'invalid_string'
              ? 'Bad characters. Only letters, numbers, and spaces are allowed.'
              : e.message
          )
          .join('\n') || 'Unknown error.'
      );
      setTimeout(() => setErrorMessage(null), 4000);
    },
  });
  useEffect(() => {
    if (showModal && name === null) setName(roomName);
    else if (!showModal) setName(null);
  }, [name, roomName, showModal]);

  return (
    <>
      {errorMessage && (
        <div className="font-semibold text-red-500">
          {errorMessage.split('\n').map((m) => (
            <div>{m}</div>
          ))}
        </div>
      )}
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="flex flex-col items-center text-center">
          <div className="font-semibold">Name</div>
          <input
            className="px-2 py-1 border-2 rounded-lg"
            value={name || ''}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="flex justify-center w-full">
          <GradientButton
            clickHandler={() =>
              renameRoom.mutate({ roomId, newName: name || '' })
            }
          >
            Confirm
          </GradientButton>
        </div>
      </div>
    </>
  );
};
