import { Suspense, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import {
  agoraClientAtom,
  agoraRemoteUserAtom,
  connectionStateAtom,
} from 'renderer/atoms/call';
import { isUserOnlineAtom, roomUserAtom } from 'renderer/atoms/room';
import LogCreator, { logger, LoggerNames } from 'renderer/common/LogCreator';
import { CAMERA_STREAM_UID_PREFIX } from 'renderer/constants';
import {
  agoraSubscribedTrackAtom,
  RoomUserStreamKey,
  StreamType,
} from '../../atoms/CallStateAtoms';
import { AgoraUserAudio } from './AgoraUserAudio';
import { AgoraUserScreenShare } from './AgoraUserScreenShare';

const UnsubOnDisconnect: React.FC<RoomUserStreamKey> = ({
  roomId,
  userId,
  stream,
}) => {
  const target = stream === StreamType.MIC ? 'audio' : 'video';
  const loggerCustom = LogCreator(
    stream === StreamType.MIC ? LoggerNames.MIC : LoggerNames.SCREENSHARE
  );

  const unSubscribeToUser = useRecoilCallback(
    ({ snapshot, reset }) =>
      async () => {
        const client = await snapshot.getPromise(
          agoraClientAtom({
            roomId,
            isCameraStream: stream === StreamType.VIDEO_CAM,
          })
        );
        reset(agoraSubscribedTrackAtom({ roomId, userId, stream }));
        const user = await snapshot.getPromise(
          agoraRemoteUserAtom({ roomId, userId })
        );

        // This can happen if the user already stopped publishing.
        if (!user) return;

        loggerCustom.info(
          `Attempting to unsubscribe ${target}: ${roomId} ${userId} ${stream}`
        );
        await client
          .unsubscribe(user, target)
          .catch((reason) =>
            loggerCustom.info(
              `Failed to unsubscribe ${target}: ${roomId} ${userId} ${reason}`
            )
          );
      }
  );

  useEffect(() => {
    loggerCustom.info(`Subscribed to ${target}: ${roomId} ${userId}`);

    return () => {
      unSubscribeToUser();
    };
  }, []);
  return <></>;
};

const AfterSubscription: React.FC<RoomUserStreamKey> = ({
  roomId,
  userId,
  stream,
}) => {
  const track = useRecoilValue(
    agoraSubscribedTrackAtom({ roomId, userId, stream })
  );
  if (!track) return null;
  console.log('remount agorauseraudio');
  return (
    <>
      {
        {
          MIC: <AgoraUserAudio roomId={roomId} userId={userId} />,
          VIDEO_CAM: null,
          SCREENSHARE: <AgoraUserScreenShare roomId={roomId} userId={userId} />,
        }[stream]
      }
      <UnsubOnDisconnect roomId={roomId} userId={userId} stream={stream} />
    </>
  );
};

const GetSubscription: React.FC<RoomUserStreamKey> = ({
  roomId,
  userId,
  stream,
}) => {
  const target = stream === StreamType.MIC ? 'audio' : 'video';
  logger.info('Getting subscription for ', { userId, stream });
  const loggerCustom = LogCreator(
    stream === StreamType.MIC ? LoggerNames.MIC : LoggerNames.SCREENSHARE
  );

  const subscribeToUser = useRecoilCallback(({ snapshot, set }) => async () => {
    // camerastream client is only used for publishing
    const client = await snapshot.getPromise(
      agoraClientAtom({ roomId, isCameraStream: false })
    );
    const user = await snapshot.getPromise(
      // see AgoraVideoPublisher
      agoraRemoteUserAtom({
        roomId,
        userId:
          stream === StreamType.VIDEO_CAM
            ? `${CAMERA_STREAM_UID_PREFIX}${userId}`
            : userId,
      })
    );
    if (!user) throw new Error(`Expected remote user: ${userId}`);

    loggerCustom.info('Got user to subscribe to', { user });
    const track = await client.subscribe(user, target);
    set(agoraSubscribedTrackAtom({ roomId, userId, stream }), track);
  });

  const { isSuccess, status } = useQuery({
    queryKey: `${roomId}-${userId}-${stream}`,
    queryFn: async () => {
      loggerCustom.info(
        `Attempting to subscribe ${target}: ${roomId} ${userId} ${stream}`
      );
      await subscribeToUser();
    },
    retry: true,
    suspense: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  loggerCustom.info(
    `SubTest ${roomId}: GetSubscription: TRACK: ${stream} ${status} ${isSuccess}`,
    { userId }
  );
  if (!isSuccess) return null;

  return <AfterSubscription roomId={roomId} userId={userId} stream={stream} />;
};

const shouldSubscribeToRemoteStreamAtom = selectorFamily<
  boolean,
  RoomUserStreamKey
>({
  key: 'shouldSubscribeToRemoteStream',
  get:
    ({ roomId, userId, stream }) =>
    ({ get }) => {
      logger.info('shouldSubscribeToRemoteStream', { roomId, userId, stream });
      // TODO: Disable firebase constraint for subscribing.
      // const status = get(firebaseUserStreamAtom({ roomId, userId, stream }));
      const isOnline = get(isUserOnlineAtom({ roomId, userId }));
      // // If firebase says no, we should never subscribe.
      if (!isOnline) return false;

      if (stream === StreamType.MIC) {
        return true;
      }

      // For screenshare and video
      // Only if the users are at the same table.
      try {
        const other = get(roomUserAtom({ roomId, userId }));
        return other.convo.withPrimaryUser;
      } catch {
        return false;
      }
    },
});

export const AgoraRemoteUserSubscriber: React.FC<RoomUserStreamKey> = ({
  roomId,
  userId,
  stream,
}) => {
  // for subscriptions we don't want to use the video camera publishing client.
  const state = useRecoilValue(
    connectionStateAtom({ roomId, isCameraStream: false })
  );
  const connected = useMemo(() => state === 'CONNECTED', [state]);

  const shouldSubscribe = useRecoilValue(
    shouldSubscribeToRemoteStreamAtom({ roomId, userId, stream })
  );

  logger.info(`SubTest ${roomId}: AgoraRemoteUserSubscriber: `, {
    stream,
    userId,
    shouldSubscribe,
    state,
    connected,
  });

  if (!shouldSubscribe || !connected) return null;

  return (
    // TODO: We should show a loading indicator while connecting to the user.
    <Suspense>
      <GetSubscription roomId={roomId} userId={userId} stream={stream} />
    </Suspense>
  );
};
