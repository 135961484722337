import { RoomKey } from 'renderer/models/Keys';
import { AgoraAudioPublisher } from './helpers/AgoraAudioPublisher';
import { AgoraScreensharePublisher } from './helpers/AgoraScreensharePublisher';

const AgoraPublisher: React.FC<RoomKey> = ({ roomId }) => {
  return (
    <>
      <AgoraAudioPublisher roomId={roomId} />
      <AgoraScreensharePublisher roomId={roomId} />
    </>
  );
};

export default AgoraPublisher;
