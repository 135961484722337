import { useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedRoomKeyAtom, useRoomAtomSetters } from 'renderer/atoms/room';
import { useTeamAtomSetters } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { Room } from 'renderer/models/Api';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { JoinRoomContainer } from './JoinRoomContainer';

const CreateTeamContainer: React.FC<{ onMutation: any }> = ({ onMutation }) => {
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  return (
    <div className="flex flex-col items-center gap-2 w-fit text-black-primary">
      <span className="text-xl font-semibold">
        Create a team to get started
      </span>
      <GradientButton
        enableChevron
        clickHandler={
          () => setSelectedModal(ModalTypes.kCreateTeam)
          // createTeamMutation.mutateAsync()
        }
      >
        <span className="text-base">Create a team</span>
      </GradientButton>
    </div>
  );
};

export const AddTeamMenu: React.FC<{ inModal?: boolean }> = ({ inModal }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const closeModal = useResetRecoilState(selectedModalAtom);
  const { selectRoom } = usePageSelector();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const onMutation = {
    onSuccess: (data: Room | null) => {
      if (!data) return;

      addOrUpdateRoom(data);
      selectRoom(data);
      if (inModal) {
        closeModal();
      }
    },
    onError: (error: any) => {
      setErrorMessage(error.message || 'Unknown error.');
      setTimeout(() => setErrorMessage(null), 4000);
    },
  };
  return (
    <>
      <div className="flex flex-col items-center justify-center h-[80%] gap-8 text-black-lightest">
        <CreateTeamContainer onMutation={onMutation} />
        <JoinRoomContainer onMutation={onMutation} />
        {errorMessage && (
          <div className="text-sm italic font-bold text-red-500">
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
