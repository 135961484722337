import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  linkedCalendarAtom,
  linkedCalendarsAtom,
} from 'renderer/atoms/calendarEvents';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { selectedRoomAtom } from 'renderer/atoms/room';
import { selectedTeamAtom } from 'renderer/atoms/team';
import { openCreateCalendarUrl } from 'renderer/events';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';
import { CalendarLinkContainer } from 'renderer/shared/integrations/CalendarLinkContainer';
import { ModalTypes, selectedModalAtom } from '../atoms';

export const LinkCalendarModal = () => {
  const setSelectedModal = useSetRecoilState(selectedModalAtom);
  const linkedCalendar = useRecoilValue(linkedCalendarAtom);
  const [succeeded, setSucceeded] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const room = useRecoilValue(selectedRoomAtom);
  const team = useRecoilValue(selectedTeamAtom);
  const baseUrl = useBaseUrl();
  const selfUserId = useRecoilValue(selfUserIdAtom);

  useEffect(() => {
    if (linkedCalendar) {
      setSucceeded(true);
      setTimeout(() => {
        openCreateCalendarUrl({
          linkedCalendarAccount: linkedCalendar,
          baseUrl,
          team,
          room,
          selfUserId,
        });
        setSelectedModal(ModalTypes.kListEventsForRoom);
      }, 3000);
    }
  }, [linkedCalendar, setSelectedModal, baseUrl, room, team]);

  return (
    <div className="flex flex-col items-center justify-center w-full  gap-2 min-w-[350px]">
      {succeeded && (
        <div className="text-sm text-gray-5">
          Calendar is now linked! Redirecting...
        </div>
      )}
      <CalendarLinkContainer />
    </div>
  );
};
