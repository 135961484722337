export const InputField = ({
  title,
  placeholderText,
  onChange,
  value,
  defaultValue,
  optional,
}: {
  title: string;
  placeholderText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  optional?: boolean;
  defaultValue?: string;
  value?: string;
}) => {
  return (
    <div className="flex flex-col items-start w-full">
      <span className="w-full text-base font-semibold text-left text-black-lighter">
        {title}{' '}
        {optional && (
          <span className="font-medium text-gray-4">(optional)</span>
        )}
      </span>
      <input
        className="flex w-full p-2 truncate rounded-lg outline-1 outline outline-neutral-4 bg-white-1"
        placeholder={placeholderText}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
    </div>
  );
};
