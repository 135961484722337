import { ArrowCircleRightRounded } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { trpc } from 'renderer/common/client/trpc';
import LogCreator from 'renderer/common/LogCreator';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';

const logger = LogCreator();

const getTextFromClipboard = async () => {
  if ('clipboard' in navigator) {
    const text = await navigator?.clipboard?.readText();
    return text || null;
  }
  return null;
};

const InviteCodeRegex = /^[A-Z][a-z][A-Z][A-Z][a-z][a-z]$/;
const UrlRegex =
  /^(https?:\/\/)?.+\/join\/([A-Z][a-z][A-Z][A-Z][a-z][a-z])\/?$/;

const ParseInvite = (text: string) => {
  // eslint-disable-next-line react/destructuring-assignment
  const codeMatch = text.match(InviteCodeRegex);
  // eslint-disable-next-line react/destructuring-assignment
  const urlMatch = text.match(UrlRegex);
  if (codeMatch) {
    return codeMatch[1];
  }
  if (urlMatch) {
    return urlMatch[urlMatch.length - 1];
  }
  return null;
};

export const JoinRoomContainer: React.FC<{ onMutation: any }> = ({
  onMutation,
}) => {
  const joinMutation = trpc.useMutation(['invites.reply'], onMutation);
  const [joinLink, setJoinLink] = useState<string | null>(null);
  const baseUrl = useBaseUrl();
  useEffect(() => {
    const fn = async () => {
      try {
        const text = await getTextFromClipboard();
        const parsedInvite = text && ParseInvite(text);
        if (parsedInvite) {
          setJoinLink(parsedInvite);
        }
      } catch (error: any) {
        logger.error(error);
      }
    };
    fn();
  }, [setJoinLink]);

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="text-xl font-semibold text-black-primary">
        or use an invite link
      </div>
      <div className="relative flex flex-row items-center justify-center gap-2">
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter' && joinLink) {
              joinMutation.mutateAsync({
                inviteId: ParseInvite(joinLink) || joinLink,
                accept: true,
              });
            }
          }}
          className="px-2 py-1 pr-8 rounded-xl bg-status_toggle_background border-[1px] border-neutral-4 text-sm text-center w-full"
          value={joinLink || ''}
          onChange={(e) => {
            setJoinLink(e.target.value);
          }}
          placeholder="Enter invite link or code"
        />
        {joinLink && joinLink.length > 0 && (
          <button
            type="button"
            onClick={async () =>
              joinLink &&
              joinMutation.mutate({
                inviteId: ParseInvite(joinLink) || joinLink,
                accept: true,
              })
            }
            disabled={!(joinLink && joinLink.length)}
            className="absolute right-2 text-blue-primary disabled:text-gray-4 hover:text-blue-lighter"
          >
            <ArrowCircleRightRounded />
          </button>
        )}
      </div>
      <div className="text-sm text-center text-gray-3">
        Example https://app.gloo.chat/spgDG
      </div>
    </div>
  );
};
