import { useRecoilValue } from 'recoil';
import { nudgesAtom } from 'renderer/atoms/nudge';
import { PageSelector } from 'renderer/atoms/pageselectors';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export const NotificationBell: React.FC = () => {
  const { selectPage } = usePageSelector();
  const { incomingPings } = useRecoilValue(nudgesAtom);

  return (
    <IconButton
      clickHandler={() => selectPage(PageSelector.NOTIFICATIONS_PAGE)}
      className="relative text-black-lightest !hover:text-gray-4"
    >
      <NotificationsNoneOutlinedIcon className="!text-black-lightest/50 !hover:text-black-lightest !w-6 !h-6" />
      {incomingPings.length > 0 && (
        <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs bg-red-500 border-2 rounded-full text-white-1 border-status_toggle_background -top-0 -right-1">
          {incomingPings.length}
        </div>
      )}
    </IconButton>
  );
};
