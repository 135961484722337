import { atom, selector } from 'recoil';
import { persistAtom } from 'renderer/atoms/effects';
import LogCreator from 'renderer/common/LogCreator';
import { ButtonState, buttonStateAtom, ButtonType } from './buttons';
import { availableStatusAtom } from './state';

const logger = LogCreator();

// What the user can select in the radio group on left panel
export enum UserStatus {
  Listening,
  Offline,
  Table_DEPRECATED, // listen only to their table(s) across rooms
}

const selectedUserStatusButtonImplAtom = atom<UserStatus>({
  key: 'selectedUserStatusButtonImplAtom',
  default: UserStatus.Listening,
  effects_UNSTABLE: [persistAtom],
});

export const lastAvailableSelectedStatusAtom = atom<UserStatus | null>({
  key: 'lastAvailableUserStatusAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const selectedUserStatusButtonAtom = selector<UserStatus>({
  key: 'selectedUserStatusButtonAtom',
  get: ({ get }) => {
    return get(selectedUserStatusButtonImplAtom);
  },
  set: ({ set, get }, newValue) => {
    const prevValue = get(selectedUserStatusButtonAtom);
    set(selectedUserStatusButtonImplAtom, newValue);
    const isAvailable = get(availableStatusAtom);
    // don't "press" the status button override if we're already offline
    // because we may have gone idle, and "pressing" this status button
    // will override the idleness timer.
    if (newValue === UserStatus.Offline) {
      if (prevValue !== UserStatus.Offline) {
        set(lastAvailableSelectedStatusAtom, prevValue);
      }
      if (isAvailable) {
        logger.info('setting button status');
        set(buttonStateAtom({ button: ButtonType.STATUS }), ButtonState.OFF);
      }
    } else {
      set(buttonStateAtom({ button: ButtonType.STATUS }), ButtonState.ON);
    }
  },
});
