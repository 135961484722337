import logger from 'js-logger';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { avDeviceIdAtom } from 'renderer/atoms/settings';
import { useMicToggles } from 'renderer/call/components/MicrophoneProvider';
import { useVideoCamToggles } from 'renderer/call/components/videocam/useVideoCamToggles';
import { useDeviceList } from 'renderer/shared/Buttons/SoundSettingsButton';

/**
 * Be extremely careful about when this runs!
 * The mic may not be cleaned up if updateDevice() runs in parallel
 */
export const AudioDeviceMonitor = () => {
  const devices = useDeviceList();

  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useRecoilState(
    avDeviceIdAtom('audio')
  );
  const resetSelectedAudioDevice = useResetRecoilState(avDeviceIdAtom('audio'));
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useRecoilState(
    avDeviceIdAtom('video')
  );
  const resetSelectedVideoDevice = useResetRecoilState(avDeviceIdAtom('video'));
  const [selectedOutputDeviceId, setSelectedOutputDeviceId] = useRecoilState(
    avDeviceIdAtom('output')
  );
  const resetSelectedOutputDevice = useResetRecoilState(
    avDeviceIdAtom('output')
  );
  const { updateDevice: updateMicDevice } = useMicToggles();
  const { updateDevice: updateVideoDevice } = useVideoCamToggles();

  // TODO: IF selected device is not in the new list, reset it to default device
  useEffect(() => {
    if (!devices.audio.find((d) => d.deviceId === selectedAudioDeviceId)) {
      resetSelectedAudioDevice();
    }
  }, [devices.audio, resetSelectedAudioDevice, selectedAudioDeviceId]);

  // TODO: IF selected device is not in the new list, reset it to default device
  useEffect(() => {
    if (!devices.output.find((d) => d.deviceId === selectedOutputDeviceId)) {
      resetSelectedOutputDevice();
    }
  }, [devices.output, resetSelectedOutputDevice, selectedOutputDeviceId]);

  // TODO: IF selected device is not in the new list, reset it to default device
  useEffect(() => {
    if (!devices.video.find((d) => d.deviceId === selectedVideoDeviceId)) {
      resetSelectedVideoDevice();
    }
  }, [devices.video, resetSelectedVideoDevice, selectedVideoDeviceId]);

  useEffect(() => {
    (async () => {
      logger.info('Microphone settings updateDevice');
      // note, be very careful about updating this -- it is not thread safe.
      await updateMicDevice();
    })();
    // Adding updateMicDevice as a dependency causes the mic to not be released.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAudioDeviceId]);
  useEffect(() => {
    updateVideoDevice();
    // Adding updateVideoDevice as a dependency causes the mic to not be released.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoDeviceId]);

  return <></>;
};
