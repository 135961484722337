import { AppInfo } from 'api/models';
import { createContext, useEffect, useState } from 'react';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { Spinner } from 'renderer/shared/Spinner';
import { ChildProps } from 'types/react';

export const AppInfoContext = createContext<AppInfo>({
  appVersion: 'loading',
  deployment: 'dev',
  machineId: 'loading',
});

const logger = LogCreator(LoggerNames.AUTH);

export const AppInfoProvider: React.FC<ChildProps> = ({ children }) => {
  const [appInfo, setAppInfo] = useState<AppInfo | null>(null);

  useEffect(() => {
    const fetchInfo = async () => {
      const info = await window.electron?.ipcRenderer.getAppInfo();
      if (info) {
        setAppInfo(info);
      }
    };
    fetchInfo();
  }, []);

  if (appInfo === null) {
    return <Spinner />;
  }

  return (
    <AppInfoContext.Provider value={appInfo}>
      {children}
    </AppInfoContext.Provider>
  );
};
