import {
  RTScreenShareInterface,
  RTScreenShareUserInterface,
  ScreenUsers,
} from 'api/models';
import {
  get as firebaseGet,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
} from 'firebase/database';
import { useEffect, useMemo, useState } from 'react';
import { useDatabase } from 'reactfire';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { isScreensharingActiveAtom, roomUserAtom } from 'renderer/atoms/room';
import { localScreenshareTrackAtom } from 'renderer/call/components/ScreenShareProvider';
import { ConvoKey, RoomKey } from 'renderer/models/Keys';

// List of users which are currently viewing a specific users's the screenshare for a given conversation.
const activeSharingSessionsAtom = atom<RTScreenShareInterface>({
  key: 'activeSharingSessionsAtom4',
  default: {},
});

export const screensharingUsersAtom = selectorFamily<
  string[],
  ConvoKey & { sharingUserId: string }
>({
  key: 'screensharingUsersAtom',
  get:
    (key) =>
    ({ get }) => {
      const data = get(activeSharingSessionsAtom);
      if (key.convoId in data) {
        if (key.sharingUserId in data[key.convoId]) {
          return Object.keys(data[key.convoId][key.sharingUserId]);
        }
      }
      return [];
    },
});

export const screenShareUserDataAtom = selectorFamily<
  RTScreenShareUserInterface | null,
  ConvoKey & { sharingUserId: string; userId: string }
>({
  key: 'screenShareUserDataAtom',
  get:
    (key) =>
    ({ get }) => {
      const data = get(activeSharingSessionsAtom);
      if (key.convoId in data) {
        if (key.sharingUserId in data[key.convoId]) {
          if (key.userId in data[key.convoId][key.sharingUserId]) {
            return data[key.convoId][key.sharingUserId][key.userId];
          }
        }
      }
      return null;
    },
});

const myScreenShareAtom = selector<ScreenUsers[]>({
  key: 'myScreenShareAtom',
  get: ({ get }) => {
    const selfId = get(selfUserIdAtom);
    const data = get(activeSharingSessionsAtom);
    const track = get(localScreenshareTrackAtom);
    if (!track) {
      return [];
    }
    const { roomId, convoId } = track;
    if (!(convoId in data && selfId in data[convoId])) {
      return [];
    }
    const screenData = data[convoId][selfId];
    return Object.keys(screenData)
      .map((userId) => {
        const user = get(roomUserAtom({ userId, roomId }));
        return {
          userId,
          name: user.profile.displayName,
          ...screenData[userId],
        };
      })
      .filter((u) => u.active && u.userId !== selfId);
  },
});

export const useElectronUpdates = () => {
  const data = useRecoilValue(myScreenShareAtom);
  useEffect(() => {
    window.electron?.ipcRenderer.setScreenShareState(data);
  }, [data]);
};

export const FirebaseConvoUpdates: React.FC<RoomKey> = ({ roomId }) => {
  // The user can only be in at most one conversation at once per room.
  const realtimeDb = useDatabase();
  const userId = useRecoilValue(selfUserIdAtom);
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const userConvoId = useMemo(
    () => roomUser.convo.convoId ?? 'test-convo-id',
    [roomUser.convo.convoId]
  );

  const roomRef = useMemo(
    () => (userConvoId ? ref(realtimeDb, `/convo/${userConvoId}`) : undefined),
    [realtimeDb, userConvoId]
  );
  const [fetched, setFetched] = useState(false);

  const setActiveSharingSessionsAtom = useSetRecoilState(
    activeSharingSessionsAtom
  );

  useEffect(() => {
    if (!roomRef) return;
    firebaseGet(roomRef)
      .then((data) => {
        setActiveSharingSessionsAtom((prev) => {
          if (data.exists()) {
            return {
              ...prev,
              [userConvoId]: data.val(),
            };
          }
          delete prev[userConvoId];
          return prev;
        });
        setFetched(true);
        return undefined;
      })
      .catch((reason) => {
        console.log(`SCREENSHARE: ROOM CHANGE: Error getting: ${reason}`);
      });
    return () =>
      setActiveSharingSessionsAtom((prev) => {
        delete prev[userConvoId];
        return prev;
      });
  }, [roomRef, setActiveSharingSessionsAtom, userConvoId]);

  useEffect(() => {
    if (!fetched || !roomRef) return;

    const unsub0 = onChildAdded(roomRef, (snap) => {
      const sharingUserId = snap.key;
      const data = snap.val();
      if (sharingUserId && data) {
        setActiveSharingSessionsAtom((orig) => {
          const prev = JSON.parse(JSON.stringify(orig));
          if (!prev[userConvoId]) {
            prev[userConvoId] = {};
          }
          prev[userConvoId][sharingUserId] = data;
          return prev;
        });
      }
    });
    const unsub1 = onChildRemoved(roomRef, (snap) => {
      const sharingUserId = snap.key;
      if (sharingUserId) {
        setActiveSharingSessionsAtom((orig) => {
          const prev = JSON.parse(JSON.stringify(orig));
          if (prev[userConvoId]) {
            delete prev[userConvoId][sharingUserId];
          }
          return prev;
        });
      }
    });
    const unsub2 = onChildChanged(roomRef, (snap) => {
      const sharingUserId = snap.key;
      const data = snap.val();
      if (sharingUserId && data) {
        setActiveSharingSessionsAtom((orig) => {
          const prev = JSON.parse(JSON.stringify(orig));
          if (!prev[userConvoId]) {
            prev[userConvoId] = {};
          }
          prev[userConvoId][sharingUserId] = data;
          return prev;
        });
      }
    });
    return () => {
      unsub0();
      unsub1();
      unsub2();
    };
  }, [roomRef, userConvoId, fetched, setActiveSharingSessionsAtom]);
  return <></>;
};
