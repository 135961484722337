import Gleap from 'gleap';
import { useEffect } from 'react';
import { useUser } from 'reactfire';
import { useSetRecoilState } from 'recoil';
import { selfFirebaseUserImplAtom } from 'renderer/atoms/firebaseUser';
import { logger } from '../LogCreator';

export const useFirebaseUserMonitor = () => {
  const user = useUser();
  const setUser = useSetRecoilState(selfFirebaseUserImplAtom);
  useEffect(() => {
    console.log('SETTING USER: ', user?.data);
    setUser(
      user?.data
        ? {
            uid: user.data.uid,
            photoURL: user.data.photoURL,
            displayName: user.data.displayName,
            email: user.data.email,
          }
        : null
    );
    try {
      if (user?.data && user?.data?.email) {
        const name = user.data.displayName || user.data.email;
        Gleap.identify(user.data.uid, { name, email: user.data.email });
      } else {
        Gleap.clearIdentity();
      }
    } catch (error) {
      logger.error('Gleap identify failed', error);
    }
  }, [user, setUser]);
};
