import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import { TeamUser, TeamUserInConvo } from 'api/models';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { MicDisplay } from '../icons/NotificationMicDisplay';
import { Avatarv2 } from '../user/Avatarv2';

export const UserAvatar = ({
  user,
  showStatus,
}: {
  user: TeamUserInConvo | TeamUser;
  showStatus?: boolean;
}) => {
  const teamUser = 'devices' in user;
  const showScreenshare = teamUser && user.devices.screen;
  const showMic = teamUser && user.devices.mic;
  const showBusy =
    (showStatus || !teamUser) && user.status.online && user.status.busy;
  const showOnline =
    (showStatus || !teamUser) && !showBusy && user.status.online;
  const showOffline = (showStatus || !teamUser) && !user.status.online;

  return (
    <div className="relative flex flex-row items-center h-8">
      <Avatarv2
        photoUrl={user.photoUrl}
        iconSizePx={30}
        name={user.name || ' '}
      />
      {showScreenshare && (
        <div className="absolute -top-1 -right-1">
          <div className="w-4 h-4 rounded-full bg-off-black">
            <ScreenShareRoundedIcon className="!w-[75%] !h-fit -mt-3 scale-75" />
          </div>
        </div>
      )}
      {showMic && (
        <div className="absolute bottom-0 -right-1">
          <MicDisplay speaking={user.isSpeaking} color="bg-off-black" />
        </div>
      )}
      {showOnline && (
        <div className="absolute -bottom-0.5 rounded-full -right-1 bg-off-black">
          <div className="w-2.5 h-2.5 rounded-full m-0.5 bg-green-500" />
        </div>
      )}
      {showBusy && (
        <div className="absolute bottom-0 -right-1 h-3 rounded-full bg-off-black p-0.5">
          <DoNotDisturbOnIcon className="!w-3 text-orange-primary -mt-4" />
        </div>
      )}
      {showOffline && (
        <div className="absolute bottom-0 right-0 h-3 rounded-full bg-white-1">
          <BedtimeIcon className="!w-3 !h-fit text-yellow-primary -mt-4 p-[2px]" />
        </div>
      )}{' '}
    </div>
  );
};
