import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { useMemo } from 'react';
import {
  AuthProvider,
  DatabaseProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  useFirebaseApp,
} from 'reactfire';
import { useDeploymentConfig } from 'renderer/hooks/useDeploymentConfig';
import { ChildProps } from 'types/react';

const FirebaseComponents: React.FC<ChildProps> = ({ children }) => {
  // TODO: Also load firebase analytics.
  const { postSetup, bucketUrl } = useDeploymentConfig();
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app);
  const database = getDatabase(app);
  const storage = getStorage(app, bucketUrl);

  // this needs to run before providers are set (before first render), otherwise the
  // crowdlift client will be connected to the wrong endpoint
  useMemo(() => {
    postSetup(functions, auth, firestore, database);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FirestoreProvider sdk={firestore}>
          <DatabaseProvider sdk={database}>
            <FunctionsProvider sdk={functions}>{children}</FunctionsProvider>
          </DatabaseProvider>
        </FirestoreProvider>
      </StorageProvider>
    </AuthProvider>
  );
};

export const FirebaseProvider: React.FC<ChildProps> = ({ children }) => {
  // TODO: Also load firebase analytics.
  const { firebaseConfig } = useDeploymentConfig();

  return (
    <>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={false}>
        <FirebaseComponents>{children}</FirebaseComponents>
      </FirebaseAppProvider>
    </>
  );
};
