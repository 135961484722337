import { atomFamily, selectorFamily } from 'recoil';
import { ConvoKey, RoomConvoKey } from 'renderer/models/Keys';
import { roomConvoAtom, roomConvoHasVideoAtom } from './room';

export const bubbleExpandedRawAtom = atomFamily<boolean, ConvoKey>({
  key: 'bubbleExpandedRaw',
  default: false,
});

export const bubbleExpandedAtom = selectorFamily<boolean, RoomConvoKey>({
  key: 'bubbleExpanded',
  get:
    (key) =>
    ({ get }) => {
      const expanded = get(bubbleExpandedRawAtom({ convoId: key.convoId }));
      const convoHasVideo = get(roomConvoHasVideoAtom(key));
      return expanded && convoHasVideo;
    },
});
