import { PauseCircle, PlayCircle } from '@mui/icons-material';
import { FaSpotify } from 'react-icons/fa';
import { SiItunes } from 'react-icons/si';
import { useRecoilValue } from 'recoil';
import { musicTrackAtom } from 'renderer/common/hooks/useMusicMonitor';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';

export const MusicPanel: React.FC = () => {
  const tracks = useRecoilValue(musicTrackAtom);

  if (tracks.length === 0) return null;

  const activeTrack = tracks.find((t) => t.playing) || tracks[0];

  const logo =
    activeTrack.mode === 'Spotify' ? (
      <FaSpotify className="w-4 h-4 text-[#1DB954]" />
    ) : (
      <SiItunes className="w-4 h-4 text-[#EA4CC0]" />
    );
  return (
    <div className="flex flex-row items-center justify-start w-full p-1 rounded-lg bg-white-1 h-fit shadow-shadow/soft/card text-black-lightest">
      <IconButton
        className="!w-12 !h-12"
        clickHandler={() =>
          window.electron?.ipcRenderer.controlMusic(
            activeTrack.mode,
            activeTrack.playing ? 'pause' : 'play'
          )
        }
      >
        {activeTrack.playing ? (
          <PauseCircle className="!w-12 !h-12" />
        ) : (
          <PlayCircle className="!w-12 !h-12" />
        )}
      </IconButton>
      <div className="relative flex flex-col justify-start overflow-x-hidden text-left">
        <div className="flex w-full">
          <span className="text-base truncate grow">{activeTrack.title}</span>
          <div className="top-0 right-0 z-10">{logo}</div>
        </div>
        <div className="relative flex text-sm italic">
          <div className="animate-marquee whitespace-nowrap">
            {activeTrack.artist && (
              <>
                {activeTrack.artist}
                <span className="px-2">·</span>
              </>
            )}
            {activeTrack.album && (
              <>
                {activeTrack.album}
                <span className="px-2">·</span>
              </>
            )}
          </div>

          <div className="absolute animate-marquee2 whitespace-nowrap">
            {activeTrack.artist && (
              <>
                {activeTrack.artist}
                <span className="px-2">·</span>
              </>
            )}
            {activeTrack.album && (
              <>
                {activeTrack.album}
                <span className="px-2">·</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
