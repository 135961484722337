import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedRoomKeyAtom, useRoomAtomSetters } from 'renderer/atoms/room';
import { useTeamAtomSetters } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { logger } from 'renderer/common/LogCreator';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { Room } from 'renderer/models/Api';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { InputField } from 'renderer/shared/input/InputField';
import { selectedModalAtom } from '../atoms';

export const CreateTeamModal = () => {
  const [teamName, setTeamName] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { addOrUpdateTeam } = useTeamAtomSetters();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const closeModal = useResetRecoilState(selectedModalAtom);
  const { selectTeam, selectRoom } = usePageSelector();
  const onMutation = {
    onSuccess: (data: Room | null) => {
      if (!data) return;

      logger.info('Created a room, adding info', { data });
      addOrUpdateRoom(data);
      selectRoom(data);
      closeModal();
    },
    onError: (error: any) => {
      setErrorMessage(error.message || 'Unknown error.');
      setTimeout(() => setErrorMessage(null), 4000);
    },
  };
  const createRoomMutation = trpc.useMutation(['rooms.create'], onMutation);
  const createTeamMutation = trpc.useMutation(['teams.create'], {
    onSuccess: (data) => {
      addOrUpdateTeam(data);
      selectTeam(data);
      createRoomMutation.mutate({ teamId: data.teamId, name: 'general' });
    },
    onError: onMutation.onError,
  });

  return (
    <div className="flex flex-col items-center justify-center h-[80%] gap-8 w-[350px] text-black-lightest">
      <InputField
        title="Team Name"
        placeholderText="Enter a team name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTeamName(e.target.value);
        }}
      />
      {createTeamMutation.isLoading || createRoomMutation.isLoading ? (
        <ClipLoader color="gray" />
      ) : (
        <GradientButton
          enableChevron
          disabled={(teamName?.length || 0) < 1}
          clickHandler={() => {
            if (!teamName) {
              return;
            }
            createTeamMutation.mutateAsync({
              name: teamName,
            });
          }}
        >
          Create
        </GradientButton>
      )}

      {errorMessage && (
        <div className="text-sm italic font-bold text-red-500">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
